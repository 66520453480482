import React, { useContext, useEffect } from "react";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import Footer from "../../../Components/Footer";
import Header from "../../../Components/Header";
import GlobalContext from "../../../Context/globalContext";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import BottomBar from "./BottomBar";
import LocalMallOutlinedIcon from "@material-ui/icons/LocalMallOutlined";
import PersonOutlineOutlinedIcon from "@material-ui/icons/PersonOutlineOutlined";
import LocationOnOutlinedIcon from "@material-ui/icons/LocationOnOutlined";
import ListOutlinedIcon from "@material-ui/icons/ListOutlined";
import PowerSettingsNewOutlinedIcon from "@material-ui/icons/PowerSettingsNewOutlined";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import { INIT_CATALOG } from "../../../Context/globalActions.types";
import useSWR from "swr";

const ProfileDrawer = (props) => {
  const { globalContext, dispatch } = useContext(GlobalContext);

  const params = useParams();
  const location = useLocation();
  const history = useHistory();

  function SimpleDialog(props) {
    const { onClose, selectedValue, open } = props;

    const handleClose = () => {
      onClose(selectedValue);
    };

    return (
      <Dialog
        onClose={handleClose}
        aria-labelledby="simple-dialog-title"
        open={open}
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle id="simple-dialog-title">Log Out</DialogTitle>
        <DialogContent>
          <div>Are you sure you want to log out?</div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>No</Button>
          <Button
            onClick={() => {
              history.push(`/${params.mobileNo}/logout`);
            }}
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
    setSelectedValue(value);
  };

  const [open, setOpen] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState(0);

  useEffect(() => {
    // var myElement = document.getElementsByClassName("profile-div");
    // var topPos = myElement.offsetTop;
    // document.getElementsByClassName("scroll-to").scrollTop = topPos;
    if (window.innerWidth < 600) {
      window.scroll(0, 500);
    }
  }, []);

  return (
    <React.Fragment>
      <Header />
      <div className="pt-[1.25rem]">{props.breadcrubms}</div>
      <section className="middle profile-div">
        <div className="container">
          <div className="row align-items-start justify-content-between">
            <div className="col-12 col-md-12 col-lg-4 col-xl-4 text-center miliods">
              <div className="d-block border rounded">
                <div className="dashboard_author px-2 py-5">
                  <div className="dash_auth_thumb circle border d-inline-flex mx-auto mb-2">
                    <AccountCircleIcon className="text-[100px] text-black" />
                  </div>
                  <div className="dash_caption">
                    <h4 className="fs-md ft-medium mb-0 lh-1 capitalize">
                      {globalContext.account.length != 0
                        ? globalContext.account.ACC_NAME
                          ? globalContext.account.ACC_NAME.toString().toLowerCase()
                          : ""
                        : ""}
                    </h4>
                    <span className="text-muted smalls capitalize">
                      {globalContext.account.length != 0
                        ? globalContext.account.CITY
                          ? globalContext.account.CITY.toString().toLowerCase()
                          : ""
                        : ""}
                    </span>
                  </div>
                </div>

                <div className="dashboard_author">
                  <h4 className="px-3 py-3 mb-0 lh-2 gray fs-sm ft-medium text-muted text-uppercase text-left">
                    Dashboard Navigation
                  </h4>
                  <ul className="dahs_navbar">
                    {globalContext.settings.length != 0 ? (
                      globalContext.settings.filter(
                        (e) => e.VARIABLE == "showOnlyProducts"
                      )[0].VALUE == "Y" ? null : (
                        <li>
                          <Link
                            to={`/${params.mobileNo}/orders`}
                            style={{
                              color:
                                location.pathname ==
                                `/${params.mobileNo}/orders`
                                  ? globalContext.owner[0]
                                    ? globalContext.owner[0].COLOR?.primaryColor
                                    : "#000000"
                                  : "#000000",
                            }}
                            className="hover:text-base"
                          >
                            <LocalMallOutlinedIcon className="mr-2 text-lg" />{" "}
                            {globalContext.settings.length != 0
                              ? globalContext.settings.filter(
                                  (e) => e.VARIABLE == "customOrderLabel"
                                )[0].VALUE == "Y"
                                ? "My Inquiry"
                                : "My Order"
                              : ""}
                          </Link>
                        </li>
                      )
                    ) : null}

                    {/* <li>
                      <Link
                        to={`/${params.mobileNo}/wishlist`}
                        className={
                          location.pathname == `/${params.mobileNo}/wishlist`
                            ? "active"
                            : ""
                        }
                      >
                        <i className="lni lni-heart mr-2"></i>Wishlist
                      </Link>
                    </li> */}
                    <li>
                      <Link
                        to={`/${params.mobileNo}/profile`}
                        style={{
                          color:
                            location.pathname == `/${params.mobileNo}/profile`
                              ? globalContext.owner[0]
                                ? globalContext.owner[0].COLOR?.primaryColor
                                : "#000000"
                              : "#000000",
                        }}
                        className="hover:text-base"
                      >
                        <PersonOutlineOutlinedIcon className="mr-2 text-lg" />{" "}
                        Profile Info
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={`/${params.mobileNo}/about`}
                        style={{
                          color:
                            location.pathname == `/${params.mobileNo}/about`
                              ? globalContext.owner[0]
                                ? globalContext.owner[0].COLOR?.primaryColor
                                : "#000000"
                              : "#000000",
                        }}
                        className="hover:text-base"
                      >
                        <ListOutlinedIcon className="mr-2 text-lg" />
                        About Company
                      </Link>
                    </li>
                    <li>
                      {globalContext.settings.length != 0 ? (
                        globalContext.settings.filter(
                          (e) => e.VARIABLE == "customOrderLabel"
                        )[0].VALUE == "N" ? (
                          <Link
                            to={`/${params.mobileNo}/catalog`}
                            style={{
                              color:
                                location.pathname ==
                                `/${params.mobileNo}/catalog`
                                  ? globalContext.owner[0]
                                    ? globalContext.owner[0].COLOR?.primaryColor
                                    : "#000000"
                                  : "#000000",
                            }}
                            className="hover:text-base"
                          >
                            <DescriptionOutlinedIcon className="mr-2 text-lg" />
                            Catalog
                          </Link>
                        ) : null
                      ) : (
                        ""
                      )}
                    </li>
                    <li>
                      <SimpleDialog
                        selectedValue={selectedValue}
                        open={open}
                        onClose={handleClose}
                      />
                      <Link
                        to="#"
                        onClick={handleClickOpen}
                        className={
                          location.pathname == `/${params.mobileNo}/logout`
                            ? "active"
                            : ""
                        }
                      >
                        <PowerSettingsNewOutlinedIcon className="mr-2 text-lg" />
                        Log Out
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-12 col-lg-8 col-xl-8 text-center mt-4 scroll-to px-2 md:px-4">
              {props.children}
            </div>
          </div>
        </div>
      </section>
      <Footer />
      <BottomBar />
    </React.Fragment>
  );
};

export default ProfileDrawer;
