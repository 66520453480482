import React, {
  useEffect,
  useState,
  useContext,
  useRef,
  useCallback,
} from "react";

import Input from "@material-ui/core/Input";

import {
  Button,
  CircularProgress,
  Container,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  withStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  TableSortLabel,
  useTheme,
  IconButton,
  TableContainer,
  Paper,
  Typography,
  TablePagination,
  lighten,
  TableFooter,
  Box,
  Collapse,
} from "@material-ui/core";
import OwnerHeader from "./Components/OwnerHeader";
import { useHistory, useParams, useLocation } from "react-router-dom";
import GlobalContext from "../../Context/globalContext";
import CloseIcon from "@material-ui/icons/Close";
import { Skeleton } from "@material-ui/lab";

import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";

import jsPDF from "jspdf";
import "jspdf-autotable";
import { CSVLink } from "react-csv";

import { Instance } from "../../Config";
import useSWR from "swr";
import { SHOW_SNACKBAR } from "../../Context/globalActions.types";
import SearchIcon from "@material-ui/icons/Search";
import IOSSwitch from "../../Components/IOSSwitch";

import SaveAltIcon from "@material-ui/icons/SaveAlt";

import InsertDriveFileOutlinedIcon from "@material-ui/icons/InsertDriveFileOutlined";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

const useStyles = makeStyles((theme) => ({
  fullWidth: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },

  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: "1 1 100%",
  },
}));

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(even)": {
      backgroundColor: "#FBFBFB",
    },
    "&:hover": {
      backgroundColor: "#F3F3F3",
      transform: "scale(1.0019)",
    },
  },
}))(TableRow);

const OwnerUserSettings = () => {
  const [search, setSearch] = useState("");
  const classes = useStyles();

  const { globalContext, dispatch } = useContext(GlobalContext);

  const [nextPage, setNextPage] = useState(0);

  const [rows, setRows] = useState([]);
  const params = useParams();
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(true);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [page, setPage] = useState(0);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setIsLoading(true);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setIsLoading(true);
  };

  const location = useLocation();
  function getQueryVariable(variable) {
    var query = location.search.substring(1);
    var vars = query.split("&");
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split("=");
      if (decodeURIComponent(pair[0]) == variable) {
        return decodeURIComponent(pair[1]);
      }
    }
  }

  const handleSearch = (search) => {
    setIsLoading(true);
    if (search != "") {
      history.push(`/${params.mobileNo}/owner/usersettings?type=search`);
      setRows([]);
      Instance.get(
        `/account?mobileNo=${params.mobileNo}&query=${search}&type=owner`
      )
        .then((res) => {
          if (res.status == 200) {
            setIsLoading(false);
            setNextPage(undefined);
            setRows(res.data.result);
          }
        })
        .catch((e) => {
          setIsLoading(false);
          dispatch({
            type: SHOW_SNACKBAR,
            payload: {
              message: e.response.message || "Something went wrong",
              type: "error",
            },
          });
        });
    } else {
      history.push(`/${params.mobileNo}/owner/usersettings`);
      setPage(0);
      Instance.get(
        `/account?mobileNo=${params.mobileNo}&per_page=${rowsPerPage}&page=${page}&type=owner`
      )
        .then((res) => {
          if (res.status == 200) {
            setIsLoading(false);
            setNextPage(res.data.pagination.nextPage);
            setRows(res.data.result);
          }
        })
        .catch((e) => {
          setIsLoading(false);
          dispatch({
            type: SHOW_SNACKBAR,
            payload: {
              message: e.response.message || "Something went wrong",
              type: "error",
            },
          });
        });
    }
  };

  const { data: resAccountData, error: resAccountDataError } = useSWR(
    `/account?mobileNo=${params.mobileNo}&per_page=${rowsPerPage}&page=${page}&type=owner`
  );

  // const observer = useRef();
  // const lastAccountElementRef = useCallback(
  //   (node) => {
  //     if (!resAccountData) return;
  //     if (observer.current) observer.current.disconnect();
  //     observer.current = new IntersectionObserver((entries) => {
  //       if (entries[0].isIntersecting && nextPage) {
  //         setPage((prevPageNumber) => prevPageNumber + 1);
  //       }
  //     });
  //     if (node) observer.current.observe(node);
  //   },
  //   [resAccountData, nextPage]
  // );

  const { data: resAccountExport, error: resAccountExportError } = useSWR(
    `/account/export/data?mobileNo=${params.mobileNo}`
  );

  const [exportData, setExportData] = useState([]);
  const [isLoadingExportData, setIsLoadingExportData] = useState(false);
  const [exportCsv, setExportCsv] = useState(false);
  const headers = [
    { label: "Account Cd", key: "ACC_CD" },
    { label: "Account Name", key: "ACC_NAME" },
    { label: "Person Name", key: "PERSON_NM" },
    { label: "Address 1", key: "ADD1" },
    { label: "Address 2", key: "ADD2" },
    { label: "District", key: "ADD3" },
    { label: "City", key: "AREA" },
    { label: "State", key: "CITY" },
    { label: "Pincode", key: "PINCODE" },
    { label: "Phone 1", key: "PHONE1" },
    { label: "Party Grade", key: "PRTYTYP" },
    { label: "Distributor", key: "DISTRIBUTOR" },
    { label: "Role", key: "CST_NO" },
  ];

  const exportPdf = () => {
    const unit = "pt";
    const size = "A4";
    const orientation = "landscape";

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    const title = "Accounts";
    const headers = [
      [
        "Code",
        "Account Name",
        "Person Name",
        "Address",
        "District",
        "City",
        "State",
        "Pincode",
        "Phone 1",
        "Grade",
        "Distributor",
        "Role",
      ],
    ];

    const data = exportData.map((e) => [
      e.ACC_CD,
      e.ACC_NAME,
      e.PERSON_NM,
      e.ADD1 + e.ADD2,
      e.ADD3,
      e.AREA,
      e.CITY,
      e.PINCODE,
      e.PHONE1,
      e.PRTYTYP,
      e.DISTRIBUTOR,
      e.CST_NO,
    ]);

    let content = {
      startY: 70,
      head: headers,
      body: data,
      columnStyles: {
        0: { cellWidth: 50 },
        1: { cellWidth: 100 },
        2: { cellWidth: 100 },
        3: { cellWidth: 100 },
        4: { cellWidth: 50 },
        5: { cellWidth: 50 },
        6: { cellWidth: 50 },
        7: { cellWidth: 50 },
        8: { cellWidth: 80 },
        9: { cellWidth: 30 },
        10: { cellWidth: 60 },
        11: { cellWidth: 60 },
      },
    };

    let width1 = doc.internal.pageSize.getWidth();

    doc.setFontSize(14).setFont(undefined, "bold");
    doc
      .text(globalContext.company[0].COMP_NAME, width1 / 2, 20, {
        align: "center",
      })
      .setFontSize(8)
      .setFont(undefined, "bold");
    doc.text(globalContext.company[0].ADDRS, width1 / 2, 38, {
      align: "center",
    });
    doc.setFontSize(18);
    doc.text(title, marginLeft, 60);
    doc.autoTable(content);
    doc.save("Accounts.pdf");
  };

  // const [openDialog, setOpenDialog] = React.useState(false);

  // const handleClickOpenDialog = () => {
  //   setOpenDialog(true);
  // };

  // const handleCloseDialog = () => {
  //   setOpenDialog(false);
  // };

  const [openDialog1, setOpenDialog1] = React.useState(false);

  const handleClickOpenDialog1 = () => {
    setOpenDialog1(true);
  };

  const handleCloseDialog1 = () => {
    setOpenDialog1(false);
  };

  const [openDialog2, setOpenDialog2] = React.useState(false);

  const handleClickOpenDialog2 = () => {
    setOpenDialog2(true);
  };

  const handleCloseDialog2 = () => {
    setOpenDialog2(false);
  };

  const [updateData, setUpdateData] = useState({
    accCd: "",
    isChecked: false,
    partyGd: "",
    data: "",
  });

  const [isUpdating, setIsUpdating] = useState(false);

  const handleDeleteAccount = () => {
    Instance.delete(`/account/${updateData.accCd}?mobnoCo=${params.mobileNo}`)
      .then(() => {
        handleCloseDialog2();
        const newRows = rows.filter((e) => e.ACC_CD != updateData.accCd);
        setRows(newRows);
      })
      .catch(() => {
        dispatch({
          type: SHOW_SNACKBAR,
          payload: {
            message: "Something went wrong",
            type: "error",
          },
        });
      });
  };

  const headCells = [
    {
      id: "ACC_CD",
      label: "Code",
    },
    {
      id: "ACC_NAME",
      label: "Account Name",
    },
    {
      id: "Actions",
      label: "Actions",
    },
  ];

  function EnhancedTableHead(props) {
    const { classes, order, orderBy, onRequestSort } = props;

    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };

    return (
      <TableHead className="sticky top-0 z-10">
        <TableRow className="grid grid-cols-5 pb-1 py-2 md:grid-cols-12 md:mx-3 md:bg-white bg-gray-200">
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              align="left"
              className={`font-bold border-0 pt-0 pr-[24px] pb-0 pl-[9px] text-[12px] leading-5 w-max bg-transparent ${
                headCell.id == "ACC_NAME"
                  ? "md:col-span-10 col-span-2"
                  : headCell.id == "ACC_CD"
                  ? "md:col-span-1 col-span-2"
                  : ""
              }`}
              padding={headCell.disablePadding ? "none" : "normal"}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </span>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  function TablePaginationActions(props) {
    const classes = useStyles1();
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
      setIsLoading(true);
      onPageChange(event, page + 1);
    };

    return (
      <div className={classes.root}>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={!nextPage}
          aria-label="next page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
      </div>
    );
  }

  const handleUpdateData = (e) => {
    if (updateData.partyGd == "") {
      dispatch({
        type: SHOW_SNACKBAR,
        payload: {
          message: "Please Enter Valid Party Gd. Default Value Should be (.)",
          type: "error",
        },
      });
      return;
    }
    setIsUpdating(true);
    if (updateData.isChecked) {
      Instance.post("/account", {
        type: "add",
        accCd: updateData.accCd,
        variable: "accShowStk",
        mobileNo: params.mobileNo,
      }).then(() => {
        updateAccountDataPromise()
          .then(() => {
            setIsUpdating(false);
            handleCloseDialog1();
            const itemIndex = rows.findIndex(
              (itemE) => itemE.ACC_CD == updateData.accCd
            );
            rows[itemIndex].ACC_NAME = updateData.data.ACC_NAME;
            rows[itemIndex].PERSON_NM = updateData.data.PERSON_NM;
            rows[itemIndex].EMAIL = updateData.data.EMAIL;
            rows[itemIndex].GSTINNO = updateData.data.GSTINNO;
            rows[itemIndex].ADD1 = updateData.data.ADD1;
            rows[itemIndex].ADD2 = updateData.data.ADD2;
            rows[itemIndex].ADD3 = updateData.data.ADD3;
            rows[itemIndex].AREA = updateData.data.AREA;
            rows[itemIndex].CITY = updateData.data.CITY;
            rows[itemIndex].PINCODE = updateData.data.PINCODE;
            rows[itemIndex].PHONE1 = updateData.data.PHONE1;
            rows[itemIndex].PHONE2 = updateData.data.PHONE2;
            rows[itemIndex].BLACKLIST = updateData.data.BLACKLIST;
            if (updateData.data.DISTRIBUTOR) {
              rows[itemIndex].DISTRIBUTOR = updateData.data.DISTRIBUTOR;
            }
            if (updateData.data.CST_NO) {
              rows[itemIndex].CST_NO = updateData.data.CST_NO;
            }
            rows[itemIndex].PRTYTYP = updateData.partyGd;
            rows[itemIndex].accountsSettings.push({
              ACC_CD: updateData.accCd,
              SETTING_NAME: "accShowStk",
              DESCRI: "accShowStk",
              VARIABLE: "accShowStk",
              VALUE: "Y",
              MOBNOCO: params.mobileNo,
            });
            setRows(rows);
          })
          .catch((err) => {
            dispatch({
              type: SHOW_SNACKBAR,
              payload: {
                message: "Something went wrong",
                type: "error",
              },
            });
          });
      });
    } else {
      Instance.post("/account", {
        type: "remove",
        accCd: updateData.accCd,
        variable: "accShowStk",
        mobileNo: params.mobileNo,
      }).then(() => {
        updateAccountDataPromise()
          .then(() => {
            setIsUpdating(false);
            handleCloseDialog1();
            const itemIndex = rows.findIndex(
              (itemE) => itemE.ACC_CD == updateData.accCd
            );
            rows[itemIndex].ACC_NAME = updateData.data.ACC_NAME;
            rows[itemIndex].PERSON_NM = updateData.data.PERSON_NM;
            rows[itemIndex].EMAIL = updateData.data.EMAIL;
            rows[itemIndex].GSTINNO = updateData.data.GSTINNO;
            rows[itemIndex].ADD1 = updateData.data.ADD1;
            rows[itemIndex].ADD2 = updateData.data.ADD2;
            rows[itemIndex].ADD3 = updateData.data.ADD3;
            rows[itemIndex].AREA = updateData.data.AREA;
            rows[itemIndex].CITY = updateData.data.CITY;
            rows[itemIndex].PINCODE = updateData.data.PINCODE;
            rows[itemIndex].PHONE1 = updateData.data.PHONE1;
            rows[itemIndex].PHONE2 = updateData.data.PHONE2;
            rows[itemIndex].BLACKLIST = updateData.data.BLACKLIST;
            if (updateData.data.DISTRIBUTOR) {
              rows[itemIndex].DISTRIBUTOR = updateData.data.DISTRIBUTOR;
            }
            if (updateData.data.CST_NO) {
              rows[itemIndex].CST_NO = updateData.data.CST_NO;
            }
            rows[itemIndex].PRTYTYP = updateData.partyGd;
            rows[itemIndex].accountsSettings = rows[
              itemIndex
            ].accountsSettings.filter((e) => e.VARIABLE != "accShowStk");
            setRows(rows);
          })
          .catch((err) => {
            dispatch({
              type: SHOW_SNACKBAR,
              payload: {
                message: "Something went wrong",
                type: "error",
              },
            });
          });
      });
    }
  };

  const updateAccountDataPromise = () => {
    return new Promise((resolve, reject) => {
      Instance.put("/account", {
        accCd: updateData.accCd,
        accName: updateData.data.ACC_NAME,
        personNm: updateData.data.PERSON_NM,
        email: updateData.data.EMAIL,
        gstInNo: updateData.data.GSTINNO,
        add1: updateData.data.ADD1,
        add2: updateData.data.ADD2,
        add3: updateData.data.ADD3,
        area: updateData.data.AREA,
        city: updateData.data.CITY,
        pincode: updateData.data.PINCODE,
        phone1: updateData.data.PHONE1,
        phone2: updateData.data.PHONE2,
        mobnoCo: params.mobileNo,
        type: "owner",
        distributor: updateData.data.DISTRIBUTOR,
        role: updateData.data.CST_NO,
        partyGd: updateData.partyGd,
        blackList: updateData.data.BLACKLIST,
      })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  useEffect(() => {
    if (resAccountExport) {
      setExportData(resAccountExport.data.result);
      setIsLoadingExportData(false);
    }
  }, [resAccountExport]);

  useEffect(() => {
    if (resAccountData) {
      setIsLoading(false);
      setNextPage(resAccountData.data.pagination.nextPage);
      setRows(resAccountData.data.result);
    }
  }, [resAccountData]);

  useEffect(() => {
    if (resAccountDataError) {
      dispatch({
        type: SHOW_SNACKBAR,
        payload: {
          message: "Something went wrong",
          type: "error",
        },
      });
    }
  }, [resAccountDataError]);

  return (
    <div>
      <OwnerHeader>
        {/* <ViewDrawer
          updateData={updateData}
          open={openDialog}
          handleClose={handleCloseDialog}
        /> */}
        <EditDialog
          updateData={updateData}
          handleSubmit={handleUpdateData}
          setUpdateData={setUpdateData}
          isUpdating={isUpdating}
          open={openDialog1}
          handleClose={handleCloseDialog1}
        />
        <DeleteDialog
          handleClose={handleCloseDialog2}
          open={openDialog2}
          handleDeleteAccount={handleDeleteAccount}
        />
        <div className="md:flex items-center justify-between px-3 md:px-4 py-3">
          <div className="flex justify-between">
            <div>
              <div className="text-indigo-500 font-semibold text-sm md:text-base">
                Store Management
              </div>
              <div className="text-xl md:text-2xl font-extrabold leading-7 sm:leading-10 truncate mt-1">
                User Settings
              </div>
            </div>
            {window.innerWidth < 600 ? (
              <div className="mr-1">
                {isLoadingExportData ? (
                  <CircularProgress size={30} />
                ) : (
                  <>
                    <Button
                      variant="outlined"
                      className="text-blue-600 border-[#3333cc] rounded-full mt-2 md:mt-0"
                      startIcon={<SaveAltIcon />}
                    >
                      <CSVLink
                        data={exportData}
                        headers={headers}
                        filename="Accounts.csv"
                        className="text-blue-600"
                      >
                        Excel
                      </CSVLink>
                    </Button>
                    <Button
                      variant="outlined"
                      className="text-blue-600 border-[#3333cc] rounded-full mt-2 md:mt-0 ml-2"
                      startIcon={<InsertDriveFileOutlinedIcon />}
                      onClick={() => {
                        exportPdf();
                      }}
                    >
                      PDF
                    </Button>
                  </>
                )}
              </div>
            ) : null}
          </div>
          <div className="md:flex">
            {window.innerWidth > 600 ? (
              <div className="mr-2">
                {isLoadingExportData ? (
                  <CircularProgress size={30} />
                ) : (
                  <>
                    <Button
                      variant="outlined"
                      className="text-blue-600 border-[#3333cc] rounded-full mt-2 md:mt-0"
                      startIcon={<SaveAltIcon />}
                    >
                      <CSVLink
                        data={exportData}
                        headers={headers}
                        filename="Accounts.csv"
                        className="text-blue-600"
                      >
                        Excel
                      </CSVLink>
                    </Button>
                    <Button
                      variant="outlined"
                      className="text-blue-600 border-[#3333cc] rounded-full mt-2 md:mt-0 ml-2"
                      startIcon={<InsertDriveFileOutlinedIcon />}
                      onClick={() => {
                        exportPdf();
                      }}
                    >
                      PDF
                    </Button>
                  </>
                )}
              </div>
            ) : null}
            <div className="flex items-center mb-2 mt-2 md:mt-0">
              <Input
                placeholder="Search"
                className="bg-white border-[1px] border-[#3333cc] px-2 py-1 rounded-lg"
                disableUnderline
                value={search}
                onChange={(e) => {
                  if (e.target.value == "") {
                    setSearch("");
                  } else {
                    setSearch(e.target.value);
                  }
                }}
                startAdornment={
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                }
              />
              <Button
                variant="contained"
                disableElevation
                className="ml-2 bg-[#3333cc] text-white"
                onClick={() => {
                  handleSearch(search);
                }}
              >
                Search
              </Button>
            </div>
          </div>
        </div>
        <Container
          maxWidth="xl"
          className="pt-2 bg-[#F1F5F9] min-h-screen pb-7 px-1"
        >
          <div className="bg-white shadow-sm rounded p-2">
            <div className="max-h-screen overflow-x-hidden pr-1">
              <React.Fragment>
                <TableContainer
                  style={{
                    overflowX: "clip",
                  }}
                  component={Paper}
                >
                  <div className="max-h-screen overflow-x-hidden">
                    <Table stickyHeader>
                      <EnhancedTableHead
                        classes={classes}
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                        rowCount={rows ? rows.length : null}
                      />
                      <TableBody>
                        {isLoading ? (
                          <StyledTableRow className="grid grid-cols-5 py-1 items-center md:grid-cols-12 md:mx-3">
                            <StyledTableCell className="border-0 pt-0 pr-[24px] pb-0 pl-[9px] text-[12px] leading-4 md:col-span-1 col-span-2">
                              <Skeleton />
                            </StyledTableCell>
                            <StyledTableCell className="border-0 pt-0 pr-[24px] pb-0 pl-[9px] text-[12px] leading-4 md:col-span-10 col-span-2">
                              <Skeleton />
                            </StyledTableCell>
                            <StyledTableCell className="border-0 pt-0 pr-[24px] pb-0 pl-[9px] text-[12px] leading-4">
                              <Skeleton />
                            </StyledTableCell>
                          </StyledTableRow>
                        ) : !isLoading && rows.length == 0 ? (
                          <TableRow hover tabIndex={-1}>
                            <TableCell align="center" colSpan={6}>
                              No Data Found
                            </TableCell>
                          </TableRow>
                        ) : (
                          stableSort(rows, getComparator(order, orderBy)).map(
                            (item) => {
                              return (
                                <RenderTableRow
                                  item={item}
                                  handleClickOpenDialog1={
                                    handleClickOpenDialog1
                                  }
                                  handleClickOpenDialog2={
                                    handleClickOpenDialog2
                                  }
                                  setUpdateData={setUpdateData}
                                  updateData={updateData}
                                />
                              );
                            }
                          )
                        )}
                      </TableBody>
                      <TableFooter>
                        <TablePagination
                          rowsPerPageOptions={[25, 50, 100]}
                          component={Paper}
                          count={
                            resAccountData
                              ? resAccountData.data.pagination.totalItems
                              : 0
                          }
                          rowsPerPage={rowsPerPage}
                          page={page}
                          onPageChange={handleChangePage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                          ActionsComponent={TablePaginationActions}
                          elevation={0}
                        />
                      </TableFooter>
                    </Table>
                  </div>
                </TableContainer>
              </React.Fragment>

              {/* {rows.length != 0 ? (
                <Table stickyHeader className="overflow-auto">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        className="font-bold"
                        style={{ width: "5%", padding: "6px" }}
                      >
                        #
                      </TableCell>
                      <TableCell
                        className="font-bold"
                        style={{ padding: "6px", width: "8%" }}
                        align="left"
                      >
                        Code
                      </TableCell>
                      <TableCell
                        className="font-bold"
                        style={{ padding: "6px", width: "47%" }}
                        align="left"
                      >
                        User Name
                      </TableCell>
                      <TableCell
                        className="font-bold"
                        style={{ padding: "6px", width: "35%" }}
                        align="left"
                      >
                        Role
                      </TableCell>
                      <TableCell
                        className="font-bold"
                        style={{ padding: "6px", width: "5%" }}
                        align="left"
                      ></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.length != 0
                      ? rows.map((item, index) => {
                          if (rows.length === index + 1) {
                            return (
                              <RenderTableRow
                                item={item}
                                ref={
                                  getQueryVariable("type") != "search"
                                    ? lastAccountElementRef
                                    : null
                                }
                                handleClickOpenDialog={handleClickOpenDialog}
                                handleClickOpenDialog1={handleClickOpenDialog1}
                                handleClickOpenDialog2={handleClickOpenDialog2}
                                index={index}
                                setUpdateData={setUpdateData}
                                updateData={updateData}
                              />
                            );
                          } else {
                            return (
                              <RenderTableRow
                                item={item}
                                handleClickOpenDialog={handleClickOpenDialog}
                                handleClickOpenDialog1={handleClickOpenDialog1}
                                handleClickOpenDialog2={handleClickOpenDialog2}
                                index={index}
                                setUpdateData={setUpdateData}
                                updateData={updateData}
                                ref={null}
                              />
                            );
                          }
                        })
                      : null}
                  </TableBody>
                  <TablePagination
                  rowsPerPageOptions={[25, 50, 100]}
                  component={Paper}
                  count={rows ? rows.length : null}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                  elevation={0}
                />
                </Table>
              ) : null} */}
            </div>
          </div>
        </Container>
      </OwnerHeader>
    </div>
  );
};

export default OwnerUserSettings;

const RenderTableRow = ({
  item,
  setUpdateData,
  handleClickOpenDialog1,
  handleClickOpenDialog2,
  updateData,
}) => {
  const [open, setOpen] = React.useState(false);
  return (
    <>
      <StyledTableRow
        key={item.ACC_CD}
        className="grid grid-cols-5 py-1 items-center md:grid-cols-12 md:mx-3 border-t-[1px] hover:font-semibold"
      >
        <StyledTableCell
          style={{
            fontWeight: "inherit",
            // padding: "6px",
            // width: "8%",
          }}
          onClick={(e) => {
            setUpdateData({
              accCd: item.ACC_CD,
              isChecked:
                item.accountsSettings.length != 0
                  ? item.accountsSettings.filter(
                      (e) => e.VARIABLE == "accShowStk"
                    )
                    ? true
                    : false
                  : false,
              partyGd: item.PRTYTYP,
              data: item,
            });
          }}
          className="border-0 pt-0 pr-[24px] pb-0 pl-[9px] text-[12px] leading-4 col-span-2 md:col-span-1"
        >
          <div className="flex cursor-pointer" onClick={() => setOpen(!open)}>
            <IconButton aria-label="expand row" size="small">
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
            <div className="text-sm">{item.ACC_CD}</div>
          </div>
        </StyledTableCell>
        <StyledTableCell
          style={{
            fontWeight: "inherit",
            // padding: "6px",
            // width: "90%",
          }}
          onClick={(e) => {
            setOpen(!open);
            setUpdateData({
              accCd: item.ACC_CD,
              isChecked:
                item.accountsSettings.length != 0
                  ? item.accountsSettings.filter(
                      (e) => e.VARIABLE == "accShowStk"
                    )
                    ? true
                    : false
                  : false,
              partyGd: item.PRTYTYP,
              data: item,
            });
          }}
          className="border-0 pt-0 pr-[24px] pb-0 pl-[9px] text-[12px] leading-4 md:col-span-10 col-span-2 cursor-pointer"
        >
          <span className="line-break-word-one">{item.ACC_NAME}</span>
        </StyledTableCell>
        <StyledTableCell
          style={{
            fontWeight: "inherit",
            // padding: "6px",
          }}
          className="border-0 pt-0 pr-[24px] pb-0 pl-[9px] text-[12px] leading-4"
        >
          <div className="flex">
            <div
              onClick={(e) => {
                handleClickOpenDialog1();
                setUpdateData({
                  accCd: item.ACC_CD,
                  isChecked:
                    item.accountsSettings.length != 0
                      ? item.accountsSettings.filter(
                          (e) => e.VARIABLE == "accShowStk"
                        )
                        ? true
                        : false
                      : false,
                  partyGd: item.PRTYTYP,
                  data: item,
                });
              }}
            >
              <EditOutlinedIcon className="text-md cursor-pointer" />
            </div>
            {item.ACC_CD.toString().split(0, 1)[0] == "B" ? (
              <div
                onClick={(e) => {
                  handleClickOpenDialog2();
                  setUpdateData({
                    accCd: item.ACC_CD,
                    isChecked: false,
                    partyGd: "",
                    data: "",
                  });
                }}
              >
                <DeleteOutlineOutlinedIcon className="text-md cursor-pointer" />
              </div>
            ) : null}
          </div>
        </StyledTableCell>
      </StyledTableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <div>
              <div className="grid grid-cols-4 gap-2 md:grid-cols-4">
                <div>
                  <div className="font-bold">Acc Cd:</div>
                  <div>{updateData.data.ACC_CD}</div>
                </div>
                <div className="col-span-3 md:col-span-1">
                  <div className="font-bold">Acc Name:</div>
                  <div>{updateData.data.ACC_NAME}</div>
                </div>
                <div className="col-span-2 md:col-span-1">
                  <div className="font-bold">Person Name:</div>
                  <div>{updateData.data.PERSON_NM}</div>
                </div>
                <div className="col-span-2 md:col-span-1">
                  <div className="font-bold">Address:</div>
                  <div>{updateData.data.ADD1 + updateData.data.ADD2}</div>
                </div>{" "}
                <div>
                  <div className="font-bold">District:</div>
                  <div>{updateData.data.ADD3}</div>
                </div>
                <div>
                  <div className="font-bold">City:</div>
                  <div>{updateData.data.AREA}</div>
                </div>
                <div>
                  <div className="font-bold">State:</div>
                  <div>{updateData.data.CITY}</div>
                </div>
                <div>
                  <div className="font-bold">Pincode:</div>
                  <div>{updateData.data.PINCODE}</div>
                </div>
                <div>
                  <div className="font-bold">Phone1:</div>
                  <div>{updateData.data.PHONE1}</div>
                </div>
                {updateData.data.DISTRIBUTOR ? (
                  <div>
                    <div className="font-bold">Distributor:</div>
                    <div>{updateData.data.DISTRIBUTOR}</div>
                  </div>
                ) : null}
                {updateData.data.CST_NO ? (
                  <div>
                    <div className="font-bold">Role:</div>
                    <div>{updateData.data.CST_NO}</div>
                  </div>
                ) : null}
                <div>
                  <div className="font-bold">Party Gd:</div>
                  <div>{updateData.partyGd}</div>
                </div>
                <div className="col-span-2 md:col-span-1">
                  <div className="font-bold">Show Stock: </div>
                  <div>{updateData.isChecked == true ? "Yes" : "No"}</div>
                </div>
                <div className="col-span-2 md:col-span-1">
                  <div className="font-bold">Lock Account: </div>
                  <div>{updateData.data.BLACKLIST == "Y" ? "Yes" : "No"}</div>
                </div>
              </div>
            </div>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

// const ViewDrawer = ({ open, handleClose, updateData }) => {
//   return (
//     <Dialog
//       open={open}
//       onClose={handleClose}
//       aria-labelledby="alert-dialog-title"
//       aria-describedby="alert-dialog-description"
//       maxWidth="md"
//       fullWidth
//     >
//       <DialogTitle id="alert-dialog-title" className="bg-[#3333CC]">
//         <div className="flex justify-between text-white">
//           <div>User Data</div>
//           <div onClick={handleClose} className="cursor-pointer">
//             <CloseIcon />
//           </div>
//         </div>
//       </DialogTitle>
//       <DialogContent className="my-2">
//         <div>
//           <div className="grid grid-cols-1 md:grid-cols-4">
//             <div>
//               <div className="font-bold">Acc Cd:</div>
//               <div>{updateData.data.ACC_CD}</div>
//             </div>
//             <div>
//               <div className="font-bold">Acc Name:</div>
//               <div>{updateData.data.ACC_NAME}</div>
//             </div>
//             <div>
//               <div className="font-bold">Person Name:</div>
//               <div>{updateData.data.PERSON_NM}</div>
//             </div>
//             <div>
//               <div className="font-bold">Address:</div>
//               <div>{updateData.data.ADD1 + updateData.data.ADD2}</div>
//             </div>{" "}
//             <div>
//               <div className="font-bold">District:</div>
//               <div>{updateData.data.ADD3}</div>
//             </div>
//             <div>
//               <div className="font-bold">City:</div>
//               <div>{updateData.data.AREA}</div>
//             </div>
//             <div>
//               <div className="font-bold">State:</div>
//               <div>{updateData.data.CITY}</div>
//             </div>
//             <div>
//               <div className="font-bold">Pincode:</div>
//               <div>{updateData.data.PINCODE}</div>
//             </div>
//             <div>
//               <div className="font-bold">Phone1:</div>
//               <div>{updateData.data.PHONE1}</div>
//             </div>
//             {updateData.data.DISTRIBUTOR ? (
//               <div>
//                 <div className="font-bold">Distributor:</div>
//                 <div>{updateData.data.DISTRIBUTOR}</div>
//               </div>
//             ) : null}
//             {updateData.data.CST_NO ? (
//               <div>
//                 <div className="font-bold">Role:</div>
//                 <div>{updateData.data.CST_NO}</div>
//               </div>
//             ) : null}
//             <div>
//               <div className="font-bold">Show Stock to this User: </div>
//               <div>{updateData.isChecked == true ? "Yes" : "No"}</div>
//             </div>
//             <div>
//               <div className="font-bold">Party Gd:</div>
//               <div>{updateData.partyGd}</div>
//             </div>
//           </div>
//         </div>
//       </DialogContent>
//     </Dialog>
//   );
// };

const EditDialog = ({
  open,
  handleClose,
  updateData,
  setUpdateData,
  handleSubmit,
  isUpdating,
}) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="md"
      fullWidth
    >
      <DialogTitle id="alert-dialog-title" className="bg-[#3333CC]">
        <div className="flex justify-between text-white">
          <div>Edit User Data</div>
          {!isUpdating ? (
            <div onClick={handleClose} className="cursor-pointer">
              <CloseIcon />
            </div>
          ) : null}
        </div>
      </DialogTitle>
      <DialogContent>
        <div>
          <div className="grid grid-cols-1 md:grid-cols-4 gap-3">
            <div>
              <div className="font-bold">Acc Cd:</div>
              <div>{updateData.data.ACC_CD}</div>
            </div>
            <div>
              <div className="font-semibold">Acc Name:</div>
              <Input
                className="bg-white border-[1px] border-[#3333cc] px-2 py-1 rounded-lg w-full"
                disableUnderline
                value={updateData.data.ACC_NAME}
                onChange={(e) => {
                  setUpdateData({
                    ...updateData,
                    data: {
                      ...updateData.data,
                      ACC_NAME: e.target.value,
                    },
                  });
                }}
                disabled={
                  updateData.data
                    ? updateData.data.ACC_CD.toString().split(0, 1)[0] == "B"
                      ? false
                      : true
                    : false
                }
              />
            </div>
            <div>
              <div className="font-semibold">Person Name:</div>
              <Input
                className="bg-white border-[1px] border-[#3333cc] px-2 py-1 rounded-lg w-full"
                disableUnderline
                value={updateData.data.PERSON_NM}
                onChange={(e) => {
                  setUpdateData({
                    ...updateData,
                    data: {
                      ...updateData.data,
                      PERSON_NM: e.target.value,
                    },
                  });
                }}
                disabled={
                  updateData.data
                    ? updateData.data.ACC_CD.toString().split(0, 1)[0] == "B"
                      ? false
                      : true
                    : false
                }
              />
            </div>

            <div>
              <div className="font-semibold">Email:</div>
              <Input
                className="bg-white border-[1px] border-[#3333cc] px-2 py-1 rounded-lg w-full"
                disableUnderline
                value={updateData.data.EMAIL}
                onChange={(e) => {
                  setUpdateData({
                    ...updateData,
                    data: {
                      ...updateData.data,
                      EMAIL: e.target.value,
                    },
                  });
                }}
                disabled={
                  updateData.data
                    ? updateData.data.ACC_CD.toString().split(0, 1)[0] == "B"
                      ? false
                      : true
                    : false
                }
              />
            </div>

            <div>
              <div className="font-semibold">Gstin No:</div>
              <Input
                className="bg-white border-[1px] border-[#3333cc] px-2 py-1 rounded-lg w-full"
                disableUnderline
                value={updateData.data.GSTINNO}
                onChange={(e) => {
                  setUpdateData({
                    ...updateData,
                    data: {
                      ...updateData.data,
                      GSTINNO: e.target.value,
                    },
                  });
                }}
                disabled={
                  updateData.data
                    ? updateData.data.ACC_CD.toString().split(0, 1)[0] == "B"
                      ? false
                      : true
                    : false
                }
              />
            </div>

            <div>
              <div className="font-semibold">Address 1:</div>
              <Input
                className="bg-white border-[1px] border-[#3333cc] px-2 py-1 rounded-lg w-full"
                disableUnderline
                value={updateData.data.ADD1}
                onChange={(e) => {
                  setUpdateData({
                    ...updateData,
                    data: {
                      ...updateData.data,
                      ADD1: e.target.value,
                    },
                  });
                }}
                disabled={
                  updateData.data
                    ? updateData.data.ACC_CD.toString().split(0, 1)[0] == "B"
                      ? false
                      : true
                    : false
                }
              />
            </div>

            <div>
              <div className="font-semibold">Address 2:</div>
              <Input
                className="bg-white border-[1px] border-[#3333cc] px-2 py-1 rounded-lg w-full"
                disableUnderline
                value={updateData.data.ADD2}
                onChange={(e) => {
                  setUpdateData({
                    ...updateData,
                    data: {
                      ...updateData.data,
                      ADD2: e.target.value,
                    },
                  });
                }}
                disabled={
                  updateData.data
                    ? updateData.data.ACC_CD.toString().split(0, 1)[0] == "B"
                      ? false
                      : true
                    : false
                }
              />
            </div>

            <div>
              <div className="font-semibold">District:</div>
              <Input
                className="bg-white border-[1px] border-[#3333cc] px-2 py-1 rounded-lg w-full"
                disableUnderline
                value={updateData.data.ADD3}
                onChange={(e) => {
                  setUpdateData({
                    ...updateData,
                    data: {
                      ...updateData.data,
                      ADD3: e.target.value,
                    },
                  });
                }}
                disabled={
                  updateData.data
                    ? updateData.data.ACC_CD.toString().split(0, 1)[0] == "B"
                      ? false
                      : true
                    : false
                }
              />
            </div>

            <div>
              <div className="font-semibold">City:</div>
              <Input
                className="bg-white border-[1px] border-[#3333cc] px-2 py-1 rounded-lg w-full"
                disableUnderline
                value={updateData.data.AREA}
                onChange={(e) => {
                  setUpdateData({
                    ...updateData,
                    data: {
                      ...updateData.data,
                      AREA: e.target.value,
                    },
                  });
                }}
                disabled={
                  updateData.data
                    ? updateData.data.ACC_CD.toString().split(0, 1)[0] == "B"
                      ? false
                      : true
                    : false
                }
              />
            </div>

            <div>
              <div className="font-semibold">State:</div>
              <Input
                className="bg-white border-[1px] border-[#3333cc] px-2 py-1 rounded-lg w-full"
                disableUnderline
                value={updateData.data.CITY}
                onChange={(e) => {
                  setUpdateData({
                    ...updateData,
                    data: {
                      ...updateData.data,
                      CITY: e.target.value,
                    },
                  });
                }}
                disabled={
                  updateData.data
                    ? updateData.data.ACC_CD.toString().split(0, 1)[0] == "B"
                      ? false
                      : true
                    : false
                }
              />
            </div>

            <div>
              <div className="font-semibold">Pincode:</div>
              <Input
                className="bg-white border-[1px] border-[#3333cc] px-2 py-1 rounded-lg w-full"
                disableUnderline
                value={updateData.data.PINCODE}
                onChange={(e) => {
                  setUpdateData({
                    ...updateData,
                    data: {
                      ...updateData.data,
                      PINCODE: e.target.value,
                    },
                  });
                }}
                disabled={
                  updateData.data
                    ? updateData.data.ACC_CD.toString().split(0, 1)[0] == "B"
                      ? false
                      : true
                    : false
                }
              />
            </div>

            <div>
              <div className="font-semibold">Phone1:</div>
              <Input
                className="bg-white border-[1px] border-[#3333cc] px-2 py-1 rounded-lg w-full"
                disableUnderline
                value={updateData.data.PHONE1}
                onChange={(e) => {
                  setUpdateData({
                    ...updateData,
                    data: {
                      ...updateData.data,
                      PHONE1: e.target.value,
                    },
                  });
                }}
                disabled={
                  updateData.data
                    ? updateData.data.ACC_CD.toString().split(0, 1)[0] == "B"
                      ? false
                      : true
                    : false
                }
              />
            </div>

            <div>
              <div className="font-semibold">Phone2:</div>
              <Input
                className="bg-white border-[1px] border-[#3333cc] px-2 py-1 rounded-lg w-full"
                disableUnderline
                value={updateData.data.PHONE2}
                onChange={(e) => {
                  setUpdateData({
                    ...updateData,
                    data: {
                      ...updateData.data,
                      PHONE2: e.target.value,
                    },
                  });
                }}
                disabled={
                  updateData.data
                    ? updateData.data.ACC_CD.toString().split(0, 1)[0] == "B"
                      ? false
                      : true
                    : false
                }
              />
            </div>

            {updateData.data.DISTRIBUTOR ? (
              <div>
                <div className="font-semibold">Distributor:</div>
                <Input
                  className="bg-white border-[1px] border-[#3333cc] px-2 py-1 rounded-lg w-full"
                  disableUnderline
                  value={updateData.data.DISTRIBUTOR}
                  onChange={(e) => {
                    setUpdateData({
                      ...updateData,
                      data: {
                        ...updateData.data,
                        DISTRIBUTOR: e.target.value,
                      },
                    });
                  }}
                  disabled={
                    updateData.data
                      ? updateData.data.ACC_CD.toString().split(0, 1)[0] == "B"
                        ? false
                        : true
                      : false
                  }
                />
              </div>
            ) : null}
            {updateData.data.CST_NO ? (
              <div>
                <div className="font-semibold">Role:</div>
                <Input
                  className="bg-white border-[1px] border-[#3333cc] px-2 py-1 rounded-lg w-full"
                  disableUnderline
                  value={updateData.data.CST_NO}
                  onChange={(e) => {
                    setUpdateData({
                      ...updateData,
                      data: {
                        ...updateData.data,
                        CST_NO: e.target.value,
                      },
                    });
                  }}
                  disabled={
                    updateData.data
                      ? updateData.data.ACC_CD.toString().split(0, 1)[0] == "B"
                        ? false
                        : true
                      : false
                  }
                />
              </div>
            ) : null}
            <div>
              <div className="font-semibold">Party Gd:</div>
              <Input
                className="bg-white border-[1px] border-[#3333cc] px-2 py-1 rounded-lg w-full"
                disableUnderline
                value={updateData.partyGd}
                onChange={(e) => {
                  setUpdateData({
                    ...updateData,
                    partyGd: e.target.value,
                  });
                }}
                inputProps={{ maxLength: 1 }}
              />
            </div>
            <div className="flex justify-between">
              <div>Show Stock to this User: </div>
              <IOSSwitch
                handleChange={() => {
                  setUpdateData({
                    ...updateData,
                    isChecked: !updateData.isChecked,
                  });
                }}
                name={updateData.accCd}
                isChecked={updateData.isChecked}
              />
            </div>
            <div className="flex justify-between">
              <div>Lock Account: </div>
              <IOSSwitch
                handleChange={(e, newValue) => {
                  setUpdateData({
                    ...updateData,
                    data: {
                      ...updateData.data,
                      BLACKLIST: newValue ? "Y" : "N",
                    },
                  });
                }}
                name={"Lock Account"}
                isChecked={updateData.data.BLACKLIST == "Y" ? true : false}
              />
            </div>
          </div>
        </div>
      </DialogContent>
      <DialogActions className="mt-2">
        {!isUpdating ? (
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        ) : null}
        <Button
          onClick={handleSubmit}
          disabled={isUpdating}
          variant="contained"
          disableElevation
          className="bg-[#3333cc] text-white"
        >
          {isUpdating ? (
            <CircularProgress size={"24px"} className="text-white" />
          ) : (
            "Update"
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

function DeleteDialog({ open, handleClose, handleDeleteAccount }) {
  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      fullWidth
      maxWidth="xs"
    >
      <DialogTitle id="simple-dialog-title">Delete User</DialogTitle>
      <DialogContent>
        <div>Are you sure you want to Delete the user?</div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>No</Button>
        <Button onClick={handleDeleteAccount}>Yes</Button>
      </DialogActions>
    </Dialog>
  );
}
