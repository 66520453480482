import React, { useEffect, useState, useContext } from "react";
import GlobalContext from "../../Context/globalContext";
import { useParams, useHistory } from "react-router-dom";

import { INIT_CATALOG } from "../../Context/globalActions.types";

import {
  Button,
  Container,
  Input,
  Typography,
  InputAdornment,
  CircularProgress,
} from "@material-ui/core";
import { imageUrl } from "../../Config";
import useSWR from "swr";
import Header from "../../Components/Header";

import SearchIcon from "@material-ui/icons/Search";

const Catalog = () => {
  const params = useParams();

  const [search, setSearch] = useState("");

  const [isDownloading, setIsDownloading] = useState("");

  const [rows, setRows] = useState([]);
  const history = useHistory();

  const { globalContext, dispatch } = useContext(GlobalContext);

  const { data: resCatalog, error } = useSWR(
    `/catalog?mobileNo=${params.mobileNo}`
  );

  const handleSearch = (search) => {
    if (search != "") {
      const searchRes = rows.filter(
        (e) =>
          e.TITLE.toLowerCase().includes(search.toString().toLowerCase()) ||
          e.DESCRI.toLowerCase().includes(search.toString().toLowerCase())
      );
      dispatch({
        type: INIT_CATALOG,
        payload: searchRes,
      });
    } else {
      history.push(`/${params.mobileNo}/catalog`);
      dispatch({
        type: INIT_CATALOG,
        payload: rows,
      });
    }
  };

  useEffect(() => {
    if (resCatalog) {
      dispatch({
        type: INIT_CATALOG,
        payload: resCatalog.data.result,
      });
      setRows(resCatalog.data.result);
    }
  }, [resCatalog]);

  return (
    <div>
      <Header>
        <Container className="pt-8 pb-4" maxWidth="xl">
          <div className="flex items-center mb-3 mt-2 md:mt-0 justify-end">
            <Input
              placeholder="Search"
              className="bg-white border-[1px] border-[#3333cc] px-2 py-1 rounded-lg"
              disableUnderline
              value={search}
              onChange={(e) => {
                if (e.target.value == "") {
                  setSearch("");
                } else {
                  setSearch(e.target.value);
                }
              }}
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              }
            />
            <Button
              variant="contained"
              disableElevation
              className="ml-2"
              color="primary"
              onClick={() => {
                history.push(`/${params.mobileNo}/catalog?type=search`);
                handleSearch(search);
              }}
            >
              Search
            </Button>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-4 gap-4 mt-2">
            {globalContext.catalog && globalContext.catalog.length != 0
              ? globalContext.catalog.map((item, index) => (
                  <div
                    className=" bg-white rounded-md border-[.5px] border-[#3333cc]"
                    key={index}
                  >
                    <div className="flex">
                      {item.IMG_URL ? (
                        <img
                          className="object-cover h-24 md:h-32 rounded-t-lg w-20 md:w-28 md:rounded-none md:rounded-l-lg p-1"
                          src={
                            imageUrl +
                            "/" +
                            item.IMG_URL.replace("[", "")
                              .replace("]", "")
                              .replace(/"/g, "")
                              .split(",")[0]
                          }
                          alt={item.TITLE}
                        />
                      ) : (
                        <img
                          src="/img/11.jpg"
                          className="object-cover h-24 md:h-32 rounded-t-lg w-20 md:w-28 md:rounded-none md:rounded-l-lg p-1"
                          alt={item.TITLE}
                        />
                      )}
                      <div className="flex flex-col justify-between px-2 pt-3 w-full">
                        <Typography
                          variant="body1"
                          className="mb-1 text-sm md:text-xl md:leading-4 leading-none"
                        >
                          <div className="float-left">
                            <div className="text-base text-gray-900 font-bold line-break-word-one">
                              {item.TITLE}
                            </div>
                            <div className="text-xs text-slate-400 mt-1 line-break-word">
                              {item.DESCRI ? item.DESCRI : ""}
                            </div>
                          </div>
                        </Typography>
                        <div className="flex mb-2">
                          {isDownloading == item.caId ? (
                            <CircularProgress size={20} />
                          ) : (
                            <Button
                              variant="outlined"
                              onClick={() => {
                                setIsDownloading(item.caId);
                                fetch(imageUrl + "/" + item.FILE_NAME).then(
                                  (response) => {
                                    response.blob().then((blob) => {
                                      const fileURL =
                                        window.URL.createObjectURL(blob);
                                      let alink = document.createElement("a");
                                      alink.href = fileURL;
                                      alink.download = "Catalog.pdf";
                                      alink.click();
                                      setIsDownloading("");
                                    });
                                  }
                                );
                              }}
                            >
                              Download
                            </Button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              : null}
          </div>
        </Container>
      </Header>
    </div>
  );
};

export default Catalog;
