import React, { useContext, useEffect, useState } from "react";
import {
  Container,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Input,
  InputAdornment,
  TextField,
} from "@material-ui/core";
import { Instance } from "../Config";
import GlobalContext from "../Context/globalContext";
import {
  INIT_COMPANY_INFO,
  INIT_SETTINGS,
  INIT_OWNER,
  SIGN_IN,
  SHOW_SNACKBAR,
} from "../Context/globalActions.types";
import {
  useParams,
  useHistory,
  Redirect,
  useLocation,
  Link,
} from "react-router-dom";
import CustomSnakBar from "../Components/CustomSnakBar";
import { decode } from "jsonwebtoken";
import useWindowSize from "../LayoutHook";
import LoginDrawer from "../Components/LoginDrawer";
import useSWR from "swr";
import SearchIcon from "@material-ui/icons/Search";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@material-ui/icons/VisibilityOffOutlined";
import PermIdentityIcon from "@material-ui/icons/PermIdentity";
import StoreOutlinedIcon from "@material-ui/icons/StoreOutlined";
import CallOutlinedIcon from "@material-ui/icons/CallOutlined";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import CreditCardOutlinedIcon from "@material-ui/icons/CreditCardOutlined";
import ContactPhoneOutlinedIcon from "@material-ui/icons/ContactPhoneOutlined";
import HomeOutlinedIcon from "@material-ui/icons/HomeOutlined";
import PersonPinCircleOutlinedIcon from "@material-ui/icons/PersonPinCircleOutlined";
import { StateAndDistrictList } from "../utils/StateAndDistrictList";
import { Autocomplete } from "@material-ui/lab";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import StorefrontIcon from "@material-ui/icons/Storefront";
import { City } from "../utils/CityList";

const SignUp = () => {
  const [width] = useWindowSize();

  const [name, setName] = useState("");
  const [shopName, setShopName] = useState("");
  const [userMobileNo, setUserMobileNo] = useState("");
  const [distributor, setDistributor] = useState("");
  const [pan, setPan] = useState("");
  const [gstNo, setGstNo] = useState("");
  const [password, setPassword] = useState("");
  const [role, setRole] = useState("");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [city, setCity] = useState("");
  const [district, setDistrict] = useState("");
  const [pincode, setPincode] = useState("");
  const [state, setState] = useState("Gujarat");
  const [email, setEmail] = useState("");

  const [showSuccess, setShowSuccess] = useState(false);

  const [passwordVisible, setPasswordVisible] = useState(false);

  const [formError, setFormError] = useState({
    type: null,
    message: null,
  });

  const [showAddress, setShowAddress] = useState(false);

  const params = useParams();
  const history = useHistory();
  let mobileNo = params.mobileNo;

  const [isLoading, setisLoading] = useState(false);

  const [isFetching, setIsFetching] = useState(true);

  const { globalContext, dispatch } = useContext(GlobalContext);

  const { data: resOwnerDetail, error: resOwnerDetailError } = useSWR(
    `/owner?mobileNo=${params.mobileNo}`
  );

  const { data: resSettings, error: resSettingsError } = useSWR(
    `/settings?mobileNo=${params.mobileNo}`
  );

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const [formMobileNo, setFormMobileNo] = useState("");

  useEffect(() => {
    if (resOwnerDetail) {
      dispatch({
        payload: resOwnerDetail.data.result,
        type: INIT_OWNER,
      });
    }
  }, [resOwnerDetail]);

  useEffect(() => {
    if (resSettings) {
      setIsFetching(false);
      if (resSettings.data.result.length == 1) {
        dispatch({
          type: INIT_SETTINGS,
          payload: [],
        });
      } else {
        dispatch({
          type: INIT_SETTINGS,
          payload: resSettings.data.result,
        });
      }
    }
  }, [resSettings]);

  const location = useLocation();
  function getQueryVariable(variable) {
    var query = location.search.substring(1);
    var vars = query.split("&");
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split("=");
      if (decodeURIComponent(pair[0]) == variable) {
        return decodeURIComponent(pair[1]);
      }
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormError({
      type: null,
      message: null,
    });
    setisLoading(true);
    Instance.post("/auth/signup", {
      personNm: name,
      accName: shopName,
      phone1: userMobileNo,
      passwd: password,
      distributor: distributor,
      pan: pan,
      gst: gstNo,
      address1: address1,
      address2: address2,
      city: city,
      state: state,
      district: district,
      pincode: pincode,
      email: email,
      role: role,
      mobnoCo: mobileNo,
    })
      .then((res) => {
        if (res.status == 200) {
          history.push(`/${mobileNo}`);
          dispatch({
            payload: res.data.token,
            type: SIGN_IN,
          });
          localStorage.setItem("user", res.data.token);
          setShowSuccess(true);
          setName("");
          setShopName("");
          setUserMobileNo("");
          setPassword("");
          setDistributor("");
          setPan("");
          setGstNo("");
          setAddress1("");
          setAddress2("");
          setCity("");
          setDistrict("");
          setPincode("");
          setEmail("");
          setRole("");
          setisLoading(false);
        }
      })
      .catch((e) => {
        setisLoading(false);
        dispatch({
          type: SHOW_SNACKBAR,
          payload: {
            message: e.response.data.message || "Something went wrong",
            type: "error",
          },
        });
      });
  };

  const { data: resAbout, error: resAboutError } = useSWR(
    `/saral/about?mobileNo=${params.mobileNo}`
  );

  useEffect(() => {
    if (resAbout) {
      if (resAbout.data.result.length != 0) {
        dispatch({
          type: INIT_COMPANY_INFO,
          payload: resAbout.data.result,
        });
      } else {
        dispatch({
          type: INIT_COMPANY_INFO,
          payload: [{ COMP_NAME: "", PERSON_NM: "", EMAILID: "" }],
        });
      }
    }
  }, [resAbout]);

  useEffect(() => {
    dispatch({
      type: SIGN_IN,
      payload: localStorage.getItem("user"),
    });
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    // document.body.classList.add("overflow-hidden");
    return () => {
      //   document.body.classList.remove("overflow-hidden");
    };
  }, []);

  if (
    localStorage.getItem("user") &&
    decode(localStorage.getItem("user")).role == "owner"
  ) {
    return <Redirect to={`/${mobileNo}/owner`} />;
  } else if (
    localStorage.getItem("user") &&
    decode(localStorage.getItem("user")).role == "user"
  ) {
    return <Redirect to={`/${mobileNo}`} />;
  } else {
    return (
      <div>
        <ShopModal
          handleChange={(e) => {
            setFormMobileNo(e.target.value);
          }}
          handleClose={handleClose}
          handleSubmit={(e) => {
            e.preventDefault();
            history.push(`/${formMobileNo}/login`);
            setOpen(false);
          }}
          open={open}
          mobileNo={formMobileNo}
        />
        {resAbout ? (
          resAbout.data.result.length == 0 ? (
            <div className="bg-[#7156FA] min-h-screen">
              <div className="flex pt-32">
                <div className="border bg-white border-black p-5 rounded-3xl shadow-xl mx-auto flex flex-col items-center">
                  <SearchIcon className="text-9xl text-gray-400/30" />
                  <div className="text-xl font-bold text-black/80">
                    Oops! No store Found
                  </div>
                  <div className="text-gray-400 mt-2">
                    Please check the store id properly
                  </div>
                  <div
                    className="bg-black text-white rounded-full py-2 px-4 mt-3 cursor-pointer"
                    onClick={handleClickOpen}
                  >
                    Try Again
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <Container className="p-0 m-0 min-h-screen" maxWidth="xl">
              <div className="grid md:grid-cols-12">
                <div
                  className="col-span-7"
                  style={{
                    backgroundColor: "#F2F2F2",
                  }}
                >
                  <div className="w-100 pt-5 px-3">
                    <div className="overflow-y-scroll hide-scroll-bar">
                      <p className="uppercase text-3xl font-medium text-center sm:text-left pl-2 text-black">
                        Sign Up
                      </p>
                      <p className="text-center sm:text-left pl-2">
                        Welcome back! Create your account to continue.
                      </p>
                      <form className="md:p-3">
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
                          {!showAddress ? (
                            <>
                              <div>
                                <div className="font-bold text-black mb-1 text-sm">
                                  Name*
                                </div>
                                <Input
                                  type="text"
                                  value={name}
                                  className="bg-white border rounded-2xl w-full px-2 py-2"
                                  onChange={(e) => setName(e.target.value)}
                                  disableUnderline
                                  startAdornment={
                                    <InputAdornment>
                                      <PermIdentityIcon />
                                    </InputAdornment>
                                  }
                                />
                                {formError.type == "name" ? (
                                  <div className="text-red-500 mt-1 text-xs">
                                    {formError.message}
                                  </div>
                                ) : null}
                              </div>
                              <div>
                                <div className="font-bold text-black mb-1 text-sm">
                                  Mobile No*
                                </div>
                                <Input
                                  type="number"
                                  className="bg-white border rounded-2xl w-full px-2 py-2"
                                  value={userMobileNo}
                                  onChange={(e) =>
                                    setUserMobileNo(e.target.value)
                                  }
                                  disableUnderline
                                  startAdornment={
                                    <InputAdornment>
                                      <CallOutlinedIcon />
                                    </InputAdornment>
                                  }
                                />
                                {formError.type == "userMobileNo" ? (
                                  <div className="text-red-500 mt-1 text-xs">
                                    {formError.message}
                                  </div>
                                ) : null}
                              </div>
                              <div>
                                <div className="font-bold text-black mb-1 text-sm">
                                  Password*
                                </div>
                                <Input
                                  type={passwordVisible ? "text" : "password"}
                                  className="bg-white border rounded-2xl w-full px-2 py-2"
                                  value={password}
                                  onChange={(e) => setPassword(e.target.value)}
                                  disableUnderline
                                  startAdornment={
                                    <InputAdornment>
                                      <LockOutlinedIcon />
                                    </InputAdornment>
                                  }
                                  endAdornment={
                                    <div
                                      className="cursor-pointer"
                                      onClick={() => {
                                        setPasswordVisible(!passwordVisible);
                                      }}
                                    >
                                      <InputAdornment>
                                        {passwordVisible ? (
                                          <VisibilityOutlinedIcon />
                                        ) : (
                                          <VisibilityOffOutlinedIcon />
                                        )}
                                      </InputAdornment>
                                    </div>
                                  }
                                />
                                {formError.type == "password" ? (
                                  <div className="text-red-500 mt-1 text-xs">
                                    {formError.message}
                                  </div>
                                ) : null}
                              </div>
                              <div>
                                <div className="font-bold text-black mb-1 text-sm">
                                  User Type*
                                </div>
                                <Autocomplete
                                  id="combo-box-demo"
                                  options={[
                                    "Dealer",
                                    "Distributor",
                                    "Electricians",
                                  ]}
                                  getOptionLabel={(option) => option}
                                  onChange={(e, newValue) => {
                                    if (newValue) {
                                      setRole(newValue);
                                    }
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Please Select State"
                                      className="bg-white"
                                      variant="outlined"
                                    />
                                  )}
                                />
                                {formError.type == "role" ? (
                                  <div className="text-red-500 mt-1 text-xs">
                                    {formError.message}
                                  </div>
                                ) : null}
                              </div>
                              <div>
                                <div className="font-bold text-black mb-1 text-sm">
                                  Your Distributor/Dealer Name
                                </div>
                                <Input
                                  type="text"
                                  className="bg-white border rounded-2xl w-full px-2 py-2"
                                  value={distributor}
                                  onChange={(e) =>
                                    setDistributor(e.target.value)
                                  }
                                  disableUnderline
                                  startAdornment={
                                    <InputAdornment>
                                      <StorefrontIcon />
                                    </InputAdornment>
                                  }
                                />
                                {formError.type == "sman" ? (
                                  <div className="text-red-500 mt-1 text-xs">
                                    {formError.message}
                                  </div>
                                ) : null}
                              </div>
                              {role == "Dealer" || role == "Distributor" ? (
                                <div>
                                  <div className="font-bold text-black mb-1 text-sm">
                                    Shop Name
                                  </div>
                                  <Input
                                    type="text"
                                    className="bg-white border rounded-2xl w-full px-2 py-2"
                                    value={shopName}
                                    onChange={(e) =>
                                      setShopName(e.target.value)
                                    }
                                    disableUnderline
                                    startAdornment={
                                      <InputAdornment>
                                        <StoreOutlinedIcon />
                                      </InputAdornment>
                                    }
                                  />
                                  {formError.type == "shopName" ? (
                                    <div className="text-red-500 mt-1 text-xs">
                                      {formError.message}
                                    </div>
                                  ) : null}
                                </div>
                              ) : null}

                              <div>
                                <div className="font-bold text-black mb-1 text-sm">
                                  City*
                                </div>
                                <Autocomplete
                                  id="combo-box-demo"
                                  options={City}
                                  getOptionLabel={(option) => option}
                                  onChange={(e, newValue) => {
                                    if (newValue) {
                                      setCity(newValue);
                                    }
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Please Select City"
                                      className="bg-white"
                                      variant="outlined"
                                      value={city}
                                    />
                                  )}
                                  value={city}
                                />

                                {/* <Input
                                  type="text"
                                  className="bg-white border rounded-2xl w-full px-2 py-2"
                                  value={city}
                                  onChange={(e) => setCity(e.target.value)}
                                  disableUnderline
                                  startAdornment={
                                    <InputAdornment>
                                      <HomeOutlinedIcon />
                                    </InputAdornment>
                                  }
                                /> */}
                                {formError.type == "city" ? (
                                  <div className="text-red-500 mt-1 text-xs">
                                    {formError.message}
                                  </div>
                                ) : null}
                              </div>

                              {/* <div>
                                <div className="font-bold text-black mb-1 text-sm">
                                  State*
                                </div>
                                <Autocomplete
                                  id="combo-box-demo"
                                  options={StateAndDistrictList.states}
                                  getOptionLabel={(option) => option.state}
                                  onChange={(e, newValue) => {
                                    if (newValue) {
                                      setState(newValue.state);
                                    }
                                  }}
                                  disabled
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Please Select State"
                                      className="bg-white"
                                      variant="outlined"
                                      value={state}
                                    />
                                  )}
                                  value={state}
                                /> */}
                              {/* {formError.type == "sman" ? (
                            <div className="text-red-500 mt-1 text-xs">
                              {formError.message}
                            </div>
                          ) : null} */}
                              {/* </div> */}
                              <div>
                                <div className="font-bold text-black mb-1 text-sm">
                                  District*
                                </div>
                                <Autocomplete
                                  id="combo-box-demo1"
                                  options={
                                    StateAndDistrictList.states.filter(
                                      (e) => e.state == state
                                    ).length != 0
                                      ? StateAndDistrictList.states.filter(
                                          (e) => e.state == state
                                        )[0].districts
                                      : []
                                  }
                                  getOptionLabel={(option) => option}
                                  onChange={(e, newValue) => {
                                    if (newValue) {
                                      setDistrict(newValue);
                                    }
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Please Select District"
                                      className="bg-white"
                                      variant="outlined"
                                    />
                                  )}
                                />
                                {/* {formError.type == "sman" ? (
                            <div className="text-red-500 mt-1 text-xs">
                              {formError.message}
                            </div>
                          ) : null} */}
                              </div>
                            </>
                          ) : (
                            <>
                              <div>
                                <div className="font-bold text-black mb-1 text-sm">
                                  Pan
                                </div>
                                <Input
                                  type="text"
                                  className="bg-white border rounded-2xl w-full px-2 py-2"
                                  value={pan}
                                  onChange={(e) => setPan(e.target.value)}
                                  disableUnderline
                                  startAdornment={
                                    <InputAdornment>
                                      <CreditCardOutlinedIcon />
                                    </InputAdornment>
                                  }
                                />
                                {formError.type == "pan" ? (
                                  <div className="text-red-500 mt-1 text-xs">
                                    {formError.message}
                                  </div>
                                ) : null}
                              </div>
                              <div>
                                <div className="font-bold text-black mb-1 text-sm">
                                  GST No
                                </div>
                                <Input
                                  type="text"
                                  className="bg-white border rounded-2xl w-full px-2 py-2"
                                  value={gstNo}
                                  onChange={(e) => setGstNo(e.target.value)}
                                  disableUnderline
                                  startAdornment={
                                    <InputAdornment>
                                      <ContactPhoneOutlinedIcon />
                                    </InputAdornment>
                                  }
                                />
                                {formError.type == "gstNo" ? (
                                  <div className="text-red-500 mt-1 text-xs">
                                    {formError.message}
                                  </div>
                                ) : null}
                              </div>
                              <div>
                                <div className="font-bold text-black mb-1 text-sm">
                                  Email
                                </div>
                                <Input
                                  type="text"
                                  className="bg-white border rounded-2xl w-full px-2 py-2"
                                  value={email}
                                  onChange={(e) => setEmail(e.target.value)}
                                  disableUnderline
                                  startAdornment={
                                    <InputAdornment>
                                      <MailOutlineIcon />
                                    </InputAdornment>
                                  }
                                />
                                {/* {formError.type == "gstNo" ? (
                            <div className="text-red-500 mt-1 text-xs">
                              {formError.message}
                            </div>
                          ) : null} */}
                              </div>
                              <div>
                                <div className="font-bold text-black mb-1 text-sm">
                                  Address Line 1*
                                </div>
                                <Input
                                  type="text"
                                  className="bg-white border rounded-2xl w-full px-2 py-2"
                                  value={address1}
                                  onChange={(e) => setAddress1(e.target.value)}
                                  disableUnderline
                                  startAdornment={
                                    <InputAdornment>
                                      <HomeOutlinedIcon />
                                    </InputAdornment>
                                  }
                                />
                                {formError.type == "address1" ? (
                                  <div className="text-red-500 mt-1 text-xs">
                                    {formError.message}
                                  </div>
                                ) : null}
                              </div>
                              <div>
                                <div className="font-bold text-black mb-1 text-sm">
                                  Address Line 2*
                                </div>
                                <Input
                                  type="text"
                                  className="bg-white border rounded-2xl w-full px-2 py-2"
                                  value={address2}
                                  onChange={(e) => setAddress2(e.target.value)}
                                  disableUnderline
                                  startAdornment={
                                    <InputAdornment>
                                      <HomeOutlinedIcon />
                                    </InputAdornment>
                                  }
                                />
                                {formError.type == "address2" ? (
                                  <div className="text-red-500 mt-1 text-xs">
                                    {formError.message}
                                  </div>
                                ) : null}
                              </div>

                              <div>
                                <div className="font-bold text-black mb-1 text-sm">
                                  Pincode*
                                </div>
                                <Input
                                  type="number"
                                  className="bg-white border rounded-2xl w-full px-2 py-2"
                                  value={pincode}
                                  onChange={(e) => setPincode(e.target.value)}
                                  disableUnderline
                                  startAdornment={
                                    <InputAdornment>
                                      <PersonPinCircleOutlinedIcon />
                                    </InputAdornment>
                                  }
                                />
                                {/* {formError.type == "gstNo" ? (
                            <div className="text-red-500 mt-1 text-xs">
                              {formError.message}
                            </div>
                          ) : null} */}
                              </div>
                            </>
                          )}
                        </div>
                        {!showAddress ? (
                          <Link to={`/${params.mobileNo}/login`}>
                            <div className="float-right mb-2.5 cursor-pointer text-sm mt-2">
                              Already Have an account?
                            </div>
                          </Link>
                        ) : null}
                        <div className="pb-3 mt-4">
                          <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                            disableElevation
                            className="mb-2 w-full"
                            onClick={(e) => {
                              if (showAddress) {
                                handleSubmit(e);
                              } else {
                                e.preventDefault();
                                if (name == "") {
                                  setFormError({
                                    type: "name",
                                    message: "Please Enter Valid Name",
                                  });
                                  return;
                                }
                                if (
                                  userMobileNo == "" ||
                                  userMobileNo.length != 10
                                ) {
                                  setFormError({
                                    type: "userMobileNo",
                                    message: "Please Enter Valid Mobile No",
                                  });
                                  return;
                                }
                                if (password == "") {
                                  setFormError({
                                    type: "password",
                                    message: "Please Enter Valid Password",
                                  });
                                  return;
                                }
                                if (role == "") {
                                  setFormError({
                                    type: "role",
                                    message: "Please Enter Valid User Type",
                                  });
                                  return;
                                }
                                setisLoading(true);
                                Instance.post("/auth/validate", {
                                  mobnoCo: mobileNo,
                                  mobileNo: userMobileNo,
                                })
                                  .then((res) => {
                                    setisLoading(false);
                                    if (res.data.isPresent) {
                                      dispatch({
                                        type: SHOW_SNACKBAR,
                                        payload: {
                                          message:
                                            "Mobile No is already register.",
                                          type: "error",
                                        },
                                      });
                                      return;
                                    } else {
                                      setShowAddress(true);
                                    }
                                  })
                                  .catch(() => {
                                    dispatch({
                                      type: SHOW_SNACKBAR,
                                      payload: {
                                        message: "Something went wrong",
                                        type: "error",
                                      },
                                    });
                                  });
                              }
                            }}
                            disabled={isLoading}
                          >
                            {isLoading ? (
                              <CircularProgress size={24} color="secondary" />
                            ) : showAddress ? (
                              "Sign Up"
                            ) : (
                              "Next"
                            )}
                          </Button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <div className="col-span-5">
                  <div className="text-center text-black px-3 hidden md:block min-h-screen pt-0">
                    {globalContext.settings.length != 0 &&
                    globalContext.settings.filter(
                      (e) => e.VARIABLE == "customOrderLabel"
                    ).length != 0 ? (
                      globalContext.settings.filter(
                        (e) => e.VARIABLE == "customOrderLabel"
                      )[0].VALUE == "Y" ? (
                        <>
                          <div className="pt-5 font-semibold text-3xl">
                            Welcome to{" "}
                            {globalContext.company &&
                            globalContext.company.length != 0
                              ? globalContext.company[0].COMP_NAME
                              : ""}
                          </div>
                          <img
                            src="/intro_1.png"
                            className="mx-auto md:w-[550px] md:h-[550px] w-[350px] h-[350px]"
                            style={{
                              aspectRatio: "1/1",
                            }}
                          />
                        </>
                      ) : (
                        <>
                          <div className="pt-5 font-semibold text-3xl">
                            Welcome to{" "}
                            {globalContext.company &&
                            globalContext.company.length != 0
                              ? globalContext.company[0].COMP_NAME
                              : ""}
                          </div>
                          <div className="text-sm md:w-4/6 mx-auto mt-2">
                            E-Commerce or Electronic Commerce means buying and
                            selling of goods, products or services over the
                            internet E-commerce is also Know as Electronic
                            Commerce.
                          </div>
                          <img
                            src="/intro.png"
                            className="mx-auto md:w-[550px] md:h-[550px] w-[350px] h-[350px]"
                            style={{
                              aspectRatio: "1/1",
                            }}
                          />
                        </>
                      )
                    ) : null}
                  </div>
                </div>
              </div>
            </Container>
          )
        ) : (
          <div>
            <div className="grid place-items-center h-screen">
              <CircularProgress className="text-center" />
            </div>
          </div>
        )}
      </div>
    );
  }
};

const ShopModal = ({
  open,
  handleClose,
  mobileNo,
  handleChange,
  handleSubmit,
}) => {
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth
      >
        <DialogTitle id="form-dialog-title">
          <div className="flex justify-between">
            <div>Go to Shop</div>
            <button
              type="button"
              className="close"
              aria-label="Close"
              onClick={handleClose}
            >
              <span className="ti-close"></span>
            </button>
          </div>
        </DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label>Mobile No</label>
              <input
                type="text"
                className="form-control"
                placeholder="Mobile No"
                value={mobileNo}
                onChange={handleChange}
              />
            </div>
            <div className="form-group">
              <button
                type="submit"
                className="btn btn-md full-width bg-dark text-light fs-md ft-medium"
                onClick={handleSubmit}
              >
                Login
              </button>
            </div>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default SignUp;
