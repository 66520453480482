import {
  SIGN_IN,
  SIGN_OUT,
  INIT_ORDER,
  INIT_PRODUCT,
  LOAD_PRODUCT,
  INIT_CART,
  ADD_CART,
  INIT_CATEGORY,
  REMOVE_ALL_PRODUCT,
  DELETE_CART,
  UPDATE_IMAGE_PRODUCT,
  LOAD_ORDER,
  ADD_CART_QUANTITY,
  REMOVE_CART_QUANTITY,
  LOAD_PRODUCTBYCATEGORY,
  INIT_SIMILARPRODUCTS,
  INIT_SETTINGS,
  INIT_COMPANY_INFO,
  UPDATE_CART_QUANTITY,
  INIT_OWNER,
  INIT_ACCOUNT_INFO,
  INIT_NOTIFICATION,
  UPDATE_CATEGORY,
  SHOW_SNACKBAR,
  HIDE_SNACKBAR,
  INIT_ACCOUNTS_SETTINGS,
  INIT_CATALOG,
  ADD_CATALOG,
  DELETE_CATALOG,
  UPDATE_CATALOG,
} from "./globalActions.types";

import { Instance } from "../Config";

const GlobalReducer = (state, action) => {
  switch (action.type) {
    case SHOW_SNACKBAR:
      return { ...state, snackBar: action.payload };
    case HIDE_SNACKBAR:
      return { ...state, snackBar: null };

    case SIGN_IN:
      return { ...state, user: action.payload };
    case SIGN_OUT:
      return {
        user: "",
        orders: [],
        products: [],
        category: [],
        cart: [],
        snackBar: null,
        productsByCategory: [],
        similarProducts: [],
        settings: [],
        company: [],
        owner: [],
        account: {},
        notification: [],
        accountsSettings: [],
        catalog: [],
      };

    case INIT_CATEGORY:
      return { ...state, category: action.payload };

    case UPDATE_CATEGORY:
      const categoryElementIndex = state.category.findIndex(
        (e) => e.DEPT_CD == action.payload.code
      );
      if (action.payload.imgid) {
        state.category[categoryElementIndex]["img"] = {};
        state.category[categoryElementIndex]["img"]["IMGURL"] =
          action.payload.IMGURL;
        state.category[categoryElementIndex].img.imgid = action.payload.imgid;
      } else {
        state.category[categoryElementIndex].img.IMGURL = action.payload.IMGURL;
      }
      return { ...state, category: state.category };

    case INIT_PRODUCT:
      return { ...state, products: action.payload };
    case LOAD_PRODUCT:
      const products1 = state.products.concat(action.payload);
      return { ...state, products: products1 };
    case REMOVE_ALL_PRODUCT:
      return { ...state, products: [] };
    case UPDATE_IMAGE_PRODUCT:
      const productIndex = state.products.findIndex(
        (e) => e.ITEM_CD == action.payload.ITEM_CD
      );
      if (state.products) {
        if (action.payload.IMGURL) {
          if (state.products[productIndex].img) {
            state.products[productIndex].img.IMGURL = action.payload.IMGURL;
          } else {
            state.products[productIndex].img = action.payload;
          }
        } else {
          state.products[productIndex].img = null;
        }
      }
      return { ...state, products: state.products };

    case INIT_ORDER:
      return { ...state, orders: action.payload };
    case LOAD_ORDER:
      const orders1 = state.orders.concat(action.payload);
      return { ...state, orders: orders1 };

    case LOAD_PRODUCTBYCATEGORY:
      if (state.productsByCategory) {
        return {
          ...state,
          productsByCategory: state.productsByCategory.concat(action.payload),
        };
      }
      return state;
    case INIT_SIMILARPRODUCTS:
      return {
        ...state,
        similarProducts: action.payload,
      };
    case INIT_NOTIFICATION:
      return { ...state, notification: action.payload };

    case INIT_CART:
      return { ...state, cart: action.payload };
    case ADD_CART_QUANTITY:
      const item = state.cart.filter(
        (item) => item.cartId == action.payload
      )[0];
      item.QUANTITY++;
      Instance.put("/cart", {
        cartId: item.cartId,
        itemCd: item.ITEM_CD,
        itemName: item.ITEM_NAME,
        itemImg: item.ITEM_IMG,
        sizeCd: item.SIZE_CD,
        deptCd: item.DEPT_CD,
        quantity: item.QUANTITY,
        rate: item.RATE,
        rateOfTax: item.RATEOFTAX,
        netAmount:
          (Number(item.RATE) * (item.RATEOFTAX / 100) + Number(item.RATE)) *
          Number(item.QUANTITY),
        mobileNo: item.MOBNOCO,
        partyCd: item.PARTY_CD,
      });
      return state;
    case REMOVE_CART_QUANTITY:
      const item1 = state.cart.filter(
        (item) => item.cartId == action.payload
      )[0];
      if (item1.QUANTITY > 1) {
        item1.QUANTITY--;
        Instance.put("/cart", {
          cartId: item1.cartId,
          itemCd: item1.ITEM_CD,
          itemName: item1.ITEM_NAME,
          itemImg: item1.ITEM_IMG,
          sizeCd: item1.SIZE_CD,
          deptCd: item1.DEPT_CD,
          quantity: item1.QUANTITY,
          rate: item1.RATE,
          rateOfTax: item1.RATEOFTAX,
          netAmount:
            (Number(item1.RATE) * (item1.RATEOFTAX / 100) +
              Number(item1.RATE)) *
            Number(item1.QUANTITY),
          mobileNo: item1.MOBNOCO,
          partyCd: item1.PARTY_CD,
        });
      }
      return state;

    case INIT_CATALOG:
      return { ...state, catalog: action.payload };
    case ADD_CATALOG:
      return { ...state, catalog: [...state.catalog, action.payload] };

    case UPDATE_CATALOG:
      const catalogIndex = state.catalog.findIndex(
        (e) => e.caId == action.payload.caId
      );
      state.catalog[catalogIndex].TITLE = action.payload.TITLE;
      state.catalog[catalogIndex].DESCRI = action.payload.DESCRI;
      state.catalog[catalogIndex].IMG_URL = action.payload.IMG_URL;
      state.catalog[catalogIndex].FILE_NAME = action.payload.FILE_NAME;
      state.catalog[catalogIndex].MOBNOCO = action.payload.MOBNOCO;
      return { ...state, catalog: state.catalog };

    case DELETE_CATALOG:
      const deleteCatalog = state.catalog.filter(
        (e) => e.caId != action.payload
      );
      return { ...state, catalog: deleteCatalog };

    case ADD_CART:
      return { ...state, cart: [...state.cart, action.payload] };
    case DELETE_CART:
      const updatedArray = state.cart.filter(
        (item) => item.cartId != action.payload
      );
      return { ...state, cart: updatedArray };

    case INIT_SETTINGS:
      return { ...state, settings: action.payload };

    case INIT_ACCOUNTS_SETTINGS:
      return { ...state, accountsSettings: action.payload };

    case INIT_OWNER:
      return { ...state, owner: action.payload };

    case INIT_COMPANY_INFO:
      return { ...state, company: action.payload };

    case INIT_ACCOUNT_INFO:
      return { ...state, account: action.payload };

    default:
      return state;
  }
};

export default GlobalReducer;
