import axios from "axios";

export const imageUrl = "https://cdn.skyhubs.in/skyhubsecom";

export const Instance = axios.create({
  baseURL:
    process.env.NODE_ENV === "production"
      ? "https://ecom.skyhubs.in/api"
      : "http://localhost:4000/api",
  headers: {
    "X-API-KEY": "sky123",
  },
});

Instance.interceptors.request.use(function (config) {
  const token = localStorage.getItem("user");
  config.headers.Authorization = token;
  config.headers["x-user"] = window.location.href.split("/")[3];
  return config;
});

Instance.interceptors.response.use(
  (response) => response,
  (error) => {
    const { status } = error.response;
    if (status === 401) {
      window.location.href = `/${
        window.location.href.split("/")[3]
      }/login?continue=${window.location.pathname}`;
      localStorage.removeItem("user");
    }
    return Promise.reject(error);
  }
);

export const webUrl = "https://ecom.skyhubs.in";

//b.replace("[", "").replace("]", "").replace(/"/g, "").split(",")[0];
