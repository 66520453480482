import React, { useContext, useState } from "react";
import {
  Drawer,
  Button,
  CircularProgress,
  InputAdornment,
  Input,
} from "@material-ui/core";
import { Instance } from "../Config";
import GlobalContext from "../Context/globalContext";
import { SHOW_SNACKBAR, SIGN_IN } from "../Context/globalActions.types";
import { useParams, useHistory, useLocation } from "react-router-dom";
import PermIdentityOutlinedIcon from "@material-ui/icons/PermIdentityOutlined";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@material-ui/icons/VisibilityOffOutlined";

export default function LoginDrawer() {
  const [state, setState] = React.useState({
    bottom: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const [isUserNameValid, setIsUserNameValid] = useState(true);
  const [isPasswordValid, setIsPasswordValid] = useState(true);

  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");

  const params = useParams();
  const history = useHistory();
  let mobileNo = params.mobileNo;

  const [isLoading, setisLoading] = useState(false);
  const [isGuestLoading, setIsGuestLoading] = useState(false);

  const { globalContext, dispatch } = useContext(GlobalContext);

  const handleGuestLogin = (e) => {
    e.preventDefault();
    setIsGuestLoading(true);
    Instance.post("/auth/guest/", { mobileNo })
      .then((res) => {
        if (res.status == 200) {
          if (res.data.role == "user") {
            setIsGuestLoading(false);
            dispatch({
              payload: res.data.token,
              type: SIGN_IN,
            });
            // window.location.reload();
            localStorage.setItem("user", res.data.token);
            history.push(`/${mobileNo}`);
          } else {
            setisLoading(false);
            setIsGuestLoading(false);
            dispatch({
              type: SHOW_SNACKBAR,
              payload: {
                message: "Your are not authenticate to this area.",
                type: "error",
              },
            });
          }
        }
      })
      .catch((e) => {
        setisLoading(false);
        setIsGuestLoading(false);
        dispatch({
          type: SHOW_SNACKBAR,
          payload: {
            message: e.response.data.message || "Something went wrong",
            type: "error",
          },
        });
      });
  };

  const location = useLocation();
  function getQueryVariable(variable) {
    var query = location.search.substring(1);
    var vars = query.split("&");
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split("=");
      if (decodeURIComponent(pair[0]) == variable) {
        return decodeURIComponent(pair[1]);
      }
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    setisLoading(true);
    if (userName !== "") {
      if (password !== "") {
        Instance.post("/auth/", { userName, password, mobileNo })
          .then((res) => {
            if (res.status == 200) {
              if (res.data.role == "owner") {
                dispatch({
                  payload: res.data.token,
                  type: SIGN_IN,
                });
                localStorage.setItem("user", res.data.token);
                if (
                  getQueryVariable("continue") &&
                  getQueryVariable("continue").split("/")[2] == "owner"
                ) {
                  history.push(getQueryVariable("continue"));
                  // window.location.reload();
                } else {
                  history.push(`/${mobileNo}/owner`);
                  // window.location.reload();
                }
              } else if (res.data.role == "user") {
                dispatch({
                  payload: res.data.token,
                  type: SIGN_IN,
                });
                localStorage.setItem("user", res.data.token);
                if (
                  getQueryVariable("continue") &&
                  getQueryVariable("continue").split("/")[2] != "owner"
                ) {
                  history.push(getQueryVariable("continue"));
                  // window.location.reload();
                } else {
                  history.push(`/${mobileNo}`);
                  // window.location.reload();
                }
              } else {
                setisLoading(false);
                dispatch({
                  type: SHOW_SNACKBAR,
                  payload: {
                    message: "Your are not authenticate to this area.",
                    type: "error",
                  },
                });
              }
            }
          })
          .catch((e) => {
            setisLoading(false);
            dispatch({
              type: SHOW_SNACKBAR,
              payload: {
                message: e.response.data.message || "Something went wrong",
                type: "error",
              },
            });
          });
      } else {
        setisLoading(false);
        setIsPasswordValid(false);
        setIsUserNameValid(true);
      }
    } else {
      setisLoading(false);
      setIsUserNameValid(false);
      setIsPasswordValid(true);
    }
  };

  return (
    <div>
      {["bottom"].map((anchor) => (
        <React.Fragment key={anchor}>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disableElevation
            className="mb-2 w-full"
            onClick={toggleDrawer(anchor, true)}
            style={{ marginTop: "38px" }}
            disabled={isLoading}
          >
            {isLoading ? (
              <CircularProgress size={24} color="secondary" />
            ) : (
              "Login"
            )}
          </Button>
          {globalContext.settings.length != 0 && globalContext.settings ? (
            globalContext.settings.filter(
              (e) => e.SETTING_NAME == "SHOW_SIGN_UP"
            ).length != 0 ? (
              globalContext.settings.filter(
                (e) => e.SETTING_NAME == "SHOW_SIGN_UP"
              )[0].VALUE == "Y" ? (
                <Button
                  variant="outlined"
                  color="primary"
                  type="submit"
                  disableElevation
                  className="mb-2 w-full"
                  onClick={() => {
                    history.push(`/${params.mobileNo}/signup`);
                  }}
                  style={{ marginTop: "10px" }}
                >
                  Sign Up
                </Button>
              ) : null
            ) : null
          ) : null}
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
            PaperProps={{
              elevation: 0,
              style: {
                borderTopLeftRadius: "15px",
                borderTopRightRadius: "15px",
              },
            }}
          >
            <p className="uppercase pt-6 text-3xl font-bold sm:text-left pl-6">
              Sign In
            </p>
            <p className="sm:text-left pl-6">
              Welcome back! Login into your account.
            </p>
            <form className="p-3">
              <div className="form-group">
                <div className="font-bold text-black mb-1 text-sm">
                  Phone or Acc Cd*
                </div>
                <Input
                  type="text"
                  className="bg-white border rounded-2xl w-full px-2 py-2"
                  value={userName}
                  onChange={(e) => setUserName(e.target.value)}
                  disableUnderline
                  startAdornment={
                    <InputAdornment>
                      <PermIdentityOutlinedIcon />
                    </InputAdornment>
                  }
                />
                {!isUserNameValid ? (
                  <div className="text-red-500 mt-1">
                    Please Enter valid Phone or Acc Cd.
                  </div>
                ) : null}
              </div>

              <div className="form-group">
                <div className="font-bold text-black mb-1 text-sm">
                  Password*
                </div>
                <Input
                  type={isPasswordVisible ? "text" : "password"}
                  className="bg-white border rounded-2xl w-full px-2 py-2"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  disableUnderline
                  startAdornment={
                    <InputAdornment>
                      <LockOutlinedIcon />
                    </InputAdornment>
                  }
                  endAdornment={
                    <div
                      onClick={() => {
                        setIsPasswordVisible(!isPasswordVisible);
                      }}
                      className="cursor-pointer"
                    >
                      <InputAdornment>
                        {isPasswordVisible ? (
                          <VisibilityOutlinedIcon />
                        ) : (
                          <VisibilityOffOutlinedIcon />
                        )}
                      </InputAdornment>
                    </div>
                  }
                />
                {!isPasswordValid ? (
                  <div className="text-red-500 mt-1">
                    Please Enter valid Password.
                  </div>
                ) : null}
              </div>

              <div className="form-group">
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disableElevation
                  className="mb-2 w-full"
                  onClick={handleSubmit}
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <CircularProgress size={24} color="secondary" />
                  ) : (
                    "Login"
                  )}
                </Button>
              </div>
              {globalContext.settings.length != 0 && globalContext.settings ? (
                globalContext.settings.filter(
                  (e) => e.SETTING_NAME == "GUEST_LOGIN"
                )[0].VALUE == "Y" ? (
                  <Button
                    variant="outlined"
                    color="primary"
                    type="submit"
                    disableElevation
                    className="mb-2 w-full"
                    onClick={handleGuestLogin}
                    disabled={isGuestLoading}
                  >
                    {isGuestLoading ? (
                      <CircularProgress size={24} color="secondary" />
                    ) : (
                      "Login as Guest"
                    )}
                  </Button>
                ) : null
              ) : null}
            </form>
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}
