import React, { useState, useContext, useRef, useEffect } from "react";
import clsx from "clsx";
import { IconButton, Typography, AppBar, Button } from "@material-ui/core";
import { Link } from "react-router-dom";

import OwnerDrawer from "./OwnerDrawer";
//Import for all the icons
import MenuIcon from "@material-ui/icons/Menu";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import GlobalContext from "../../../Context/globalContext";
import {
  SIGN_IN,
  INIT_SETTINGS,
  INIT_OWNER,
  INIT_COMPANY_INFO,
  SHOW_SNACKBAR,
} from "../../../Context/globalActions.types";

import { makeStyles } from "@material-ui/core/styles";
import { Redirect, useParams, useHistory } from "react-router";
import { decode } from "jsonwebtoken";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import ShareIcon from "@material-ui/icons/Share";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import useSWR from "swr";
import { Instance } from "../../../Config";

const drawerWidth = 250;

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.up("sm")]: {
      display: "flex",
    },
  },
  appBar: {
    [theme.breakpoints.up("sm")]: {
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
  },
  appBarShift: {
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
  },
  content: {
    flexGrow: 1,
    [theme.breakpoints.up("sm")]: {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginLeft: -drawerWidth,
    },
    marginTop: 60,
  },
  contentShift: {
    [theme.breakpoints.up("sm")]: {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    },
  },
  paper: {
    marginRight: theme.spacing(2),
  },
}));

const OwnerHeader = (props) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  const params = useParams();
  let mobileNo = params.mobileNo;

  const history = useHistory();

  const { globalContext, dispatch } = useContext(GlobalContext);

  const [open1, setOpen1] = React.useState(false);

  const [mobileOpen, setMobileOpen] = useState(
    window.innerWidth < 600 ? false : true
  );

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const prevOpen = React.useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);

  const { data: aboutData, error: aboutDataError } = useSWR(
    `/saral/about?mobileNo=${params.mobileNo}`
  );

  const { data: resSettings, error: resSettingsError } = useSWR(
    `/settings?mobileNo=${params.mobileNo}`
  );

  const { data: resOwnerDetail, error: resOwnerDetailError } = useSWR(
    `/owner?mobileNo=${params.mobileNo}`
  );

  useEffect(() => {
    if (resSettings) {
      dispatch({
        type: INIT_SETTINGS,
        payload: resSettings.data.result,
      });
    }
  }, [resSettings]);

  const createAppLink = (data) => {
    Instance.put("/owner", {
      mobileNo: params.mobileNo,
      type: "appLink",
    }).then((res) => {
      data.APPLINK = res.data.appLink;
      dispatch({
        type: INIT_OWNER,
        payload: data,
      });
    });
  };

  useEffect(() => {
    if (resOwnerDetail) {
      dispatch({
        type: INIT_OWNER,
        payload: resOwnerDetail.data.result,
      });
      if (!resOwnerDetail.data.result[0].APPLINK) {
        createAppLink(resOwnerDetail.data.result[0]);
      }
    }
  }, [resOwnerDetail]);

  useEffect(() => {
    if (aboutData) {
      if (aboutData.data.result.length != 0) {
        dispatch({
          type: INIT_COMPANY_INFO,
          payload: aboutData.data.result,
        });
      } else {
        dispatch({
          type: INIT_COMPANY_INFO,
          payload: { COMP_NAME: "", PERSON_NM: "", EMAILID: "" },
        });
      }
    }
  }, [aboutData]);

  useEffect(() => {
    if (aboutDataError || resSettingsError) {
      dispatch({
        type: SHOW_SNACKBAR,
        payload: {
          message: "Something went wrong",
          type: "error",
        },
      });
    }
  }, [aboutDataError, resSettingsError]);

  useEffect(() => {
    dispatch({
      payload: localStorage.getItem("user"),
      type: SIGN_IN,
    });
  }, []);

  if (
    localStorage.getItem("user") &&
    decode(localStorage.getItem("user")).role == "owner"
  ) {
    return (
      <div className={classes.root}>
        <OwnerDrawer
          mobileOpen={mobileOpen}
          handleDrawerToggle={handleDrawerToggle}
        />
        <AppBar
          position="fixed"
          className={clsx(classes.appBar, {
            [classes.appBarShift]: mobileOpen,
          })}
          elevation={0}
        >
          <div className="px-4 py-1 shadow bg-[#111827] flex justify-between">
            <IconButton
              color="inherit"
              edge="start"
              onClick={handleDrawerToggle}
            >
              <MenuIcon className="text-white" />
            </IconButton>

            <Typography className="pt-2 pb-1">
              <Button
                className="cursor-pointer bg-red-500 text-white"
                onClick={() => {
                  history.push(`/${params.mobileNo}/logout`);
                }}
              >
                <ExitToAppIcon /> Sign Out
              </Button>
            </Typography>
          </div>
        </AppBar>
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: mobileOpen,
          })}
        >
          {props.children}
        </main>
      </div>
    );
  } else {
    return <Redirect to={`/${mobileNo}/login`} />;
  }
};

export default OwnerHeader;
