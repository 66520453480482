import React, { useContext, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";

import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";

//Import for all the icons
import LocalShippingOutlinedIcon from "@material-ui/icons/LocalShippingOutlined";
import AccountCircleOutlinedIcon from "@material-ui/icons/AccountCircleOutlined";
import HomeOutlinedIcon from "@material-ui/icons/HomeOutlined";
import SettingsIcon from "@material-ui/icons/Settings";

import { makeStyles } from "@material-ui/core/styles";

import useWindowSize from "../../../LayoutHook";

const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  drawerPaper: {
    width: drawerWidth,
  },
  hide: {
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
}));

const AdminDrawer = ({ mobileOpen, handleDrawerToggle }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [width] = useWindowSize();

  const location = useLocation();

  const handleClick = () => {
    setOpen(!open);
  };

  const handleClick1 = () => {
    setOpen1(!open1);
  };

  const drawer = (
    <div className="bg-gray-800 text-white" style={{ height: "100vh" }}>
      <div className="mt-5">
        <h1 className="text-center text-xl">Admin Section</h1>
      </div>
      <List>
        {[
          {
            name: "Company",
            icon: <SettingsIcon />,
            link: `/admin`,
          },
          {
            name: "Theme",
            icon: <SettingsIcon />,
            link: `/admin/theme`,
          },
        ].map((item) =>
          item.name === "Products" ? (
            <div key={item.name}>
              <ListItem button onClick={handleClick}>
                <ListItemIcon className="text-white">
                  <LocalShippingOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary="Products" />
                {open ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" className="pl-10 py-0">
                  <Link to="/owner/addproducts">
                    <ListItem
                      button
                      className={
                        location.pathname == "/owner/addproducts"
                          ? "text-blue-500"
                          : "text-white"
                      }
                    >
                      <ListItemText primary="Add Products" />
                    </ListItem>
                  </Link>
                  <Link to="/owner/viewproducts">
                    <ListItem
                      button
                      className={
                        location.pathname == "/owner/viewproducts"
                          ? "text-blue-500"
                          : "text-white"
                      }
                    >
                      <ListItemText primary="View Products" />
                    </ListItem>
                  </Link>
                </List>
              </Collapse>
            </div>
          ) : item.name === "Users" ? (
            <div key={item.name}>
              <ListItem button onClick={handleClick1}>
                <ListItemIcon className="text-white">
                  <AccountCircleOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary="Users" />
                {open ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={open1} timeout="auto" unmountOnExit>
                <List component="div" className="pl-10 py-0">
                  <Link to="/owner/addusers">
                    <ListItem
                      button
                      className={
                        location.pathname == "/owner/addusers"
                          ? "text-blue-500"
                          : "text-white"
                      }
                    >
                      <ListItemText primary="Add Users" />
                    </ListItem>
                  </Link>
                  <Link to="/owner/viewusers">
                    <ListItem
                      button
                      className={
                        location.pathname == "/owner/viewusers"
                          ? "text-blue-500"
                          : "text-white"
                      }
                    >
                      <ListItemText primary="View Users" />
                    </ListItem>
                  </Link>
                </List>
              </Collapse>
            </div>
          ) : (
            <Link to={item.link} key={item.name}>
              <ListItem
                button
                className={
                  location.pathname == item.link
                    ? "text-blue-500"
                    : "text-white"
                }
              >
                <ListItemIcon
                  className={
                    location.pathname == item.link
                      ? "text-blue-500"
                      : "text-white"
                  }
                >
                  {item.icon}
                </ListItemIcon>
                <ListItemText primary={item.name} />
              </ListItem>
            </Link>
          )
        )}
      </List>
    </div>
  );
  return (
    <nav className={classes.drawer}>
      <Hidden implementation="css">
        <Drawer
          classes={{
            paper: classes.drawerPaper,
          }}
          open={mobileOpen}
          variant="persistent"
        >
          {drawer}
        </Drawer>
      </Hidden>
      {width < 600 ? (
        <Hidden implementation="css">
          <Drawer
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            className={classes.hide}
            ModalProps={{
              keepMounted: true,
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
      ) : null}
    </nav>
  );
};

export default AdminDrawer;
