import React, { useContext, useEffect, useState } from "react";

import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import GlobalContext from "../Context/globalContext";
import { HIDE_SNACKBAR } from "../Context/globalActions.types";

const CustomSnakBar = (props) => {
  const [showSnakBar, setShowSnakBar] = useState(null);
  const { dispatch } = useContext(GlobalContext);

  const { message, type } = props.data;

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowSnakBar(null);
      dispatch({
        type: HIDE_SNACKBAR,
      });
    }, 5000);
    return () => {
      clearTimeout(timer);
    };
  }, [showSnakBar]);

  useEffect(() => {
    setShowSnakBar(message);
  }, []);

  return (
    <div>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        open={showSnakBar ? true : false}
        key={"bottom" + "right"}
      >
        <MuiAlert elevation={6} variant="filled" severity={type}>
          {showSnakBar}
        </MuiAlert>
      </Snackbar>
    </div>
  );
};

export default CustomSnakBar;
