import React, { useEffect, useState, useContext } from "react";
import Header from "../../../Components/Header";
import Footer from "../../../Components/Footer";
import GlobalContext from "../../../Context/globalContext";
import { Redirect, useParams } from "react-router";
import { SIGN_IN, SHOW_SNACKBAR } from "../../../Context/globalActions.types";
import { imageUrl, Instance } from "../../../Config";
import CropOriginalIcon from "@material-ui/icons/CropOriginal";

import {
  Container,
  Box,
  Grid,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import CustomSnakBar from "../../../Components/CustomSnakBar";

const DetailOrder = () => {
  const params = useParams();
  let orderId = params.orderId;

  const { globalContext, dispatch } = useContext(GlobalContext);
  const [data, setData] = useState({});
  const [updateOrder, setUpdateOrder] = useState({
    orderId: 0,
    oitmid: 0,
  });

  const [open, setOpen] = React.useState(false);
  const [open1, setOpen1] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpen1 = () => {
    setOpen1(true);
  };

  const handleClose1 = () => {
    setOpen1(false);
  };

  const fetchData = () => {
    const orderData = globalContext.orders.filter((e) => e.orderId == orderId);
    setData(orderData[0]);
  };

  const changeStatus = (type) => {
    Instance.put(`/orders/status?mobileNo=${params.mobileNo}`, {
      id: type == "order" ? updateOrder.orderId : updateOrder.oitmid,
      status: "Canceled",
      type,
    })
      .then((res) => {
        if (res.status == 200) {
          if (type == "order") {
            data.STATUS = "Canceled";
            data.orderItm.map((e) => (e.ISTATUS = "Canceled"));
            setData(data);
            handleClose();
          } else if (type == "orderitm") {
            const oitmIndex = data.orderItm.findIndex(
              (e) => e.oitmid == updateOrder.oitmid
            );
            data.orderItm[oitmIndex].ISTATUS = "Canceled";
            setData(data);
            handleClose1();
          }
        }
      })
      .catch((e) => {
        dispatch({
          type: SHOW_SNACKBAR,
          payload: {
            message: "Something went wrong",
            type: "error",
          },
        });
      });
  };

  const OrderDialogBox = () => {
    return (
      <div>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Delete Order</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to cancel the order. By Canceling the order
              it will cancel all the order Item.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} className="text-blue-800">
              No
            </Button>
            <Button
              onClick={() => changeStatus("order")}
              className="text-red-700"
            >
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  };

  const ItemDialogBox = () => {
    return (
      <div>
        <Dialog
          open={open1}
          onClose={handleClose1}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Delete Item</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to cancel the Item from the order.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose1} className="text-blue-800">
              No
            </Button>
            <Button
              onClick={() => changeStatus("orderitm")}
              className="text-red-700"
            >
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  };

  useEffect(() => {
    fetchData();
    dispatch({
      payload: localStorage.getItem("user"),
      type: SIGN_IN,
    });
  }, []);

  if (globalContext.orders.length == 0) {
    return <Redirect to={`/${params.mobileNo}/orders`} />;
  }

  return (
    <div>
      <OrderDialogBox />
      <ItemDialogBox />
      <Header>
        <Container className="mt-10">
          <Grid container>
            <Grid item xs={12} md={6}>
              <Box className="bg-white border rounded-xl px-6 py-4 my-7 mx-4">
                <div className="flex justify-between">
                  <span>Order No: {data.orderId}</span>
                </div>
                <div className="mt-5 flex justify-between items-start">
                  <div>
                    <div className="font-bold">Created At:</div>
                    <div>{new Date(data.createdAt).toLocaleString()}</div>
                  </div>
                </div>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box className="bg-white border rounded-xl px-6 py-4 my-7 mx-2">
                <div className="my-3 text-xl">
                  <span className="font-bold">Subtotal: </span>
                  <span> ₹{data.ITEM_AMT}</span>
                </div>
                <div className="my-3 text-xl">
                  <span className="font-bold">Total: </span>
                  <span> ₹{data.NET_AMT}</span>
                </div>
              </Box>
            </Grid>
          </Grid>
          {data.orderDtl ? (
            <Box className="bg-white border rounded-xl px-6 py-4 mx-2">
              <div className="my-3 text-md">
                <span className="font-bold">Name: </span>
                <span> {data.orderDtl.NAME}</span>
              </div>
              <div className="my-3 text-md">
                <span className="font-bold">Email: </span>
                <span> {data.orderDtl.EMAIL}</span>
              </div>
              <div className="my-3 text-md">
                <span className="font-bold">Mobile No: </span>
                <span> {data.orderDtl.MOBILENO}</span>
              </div>
              <div className="my-3 text-md">
                <span className="font-bold">Address 1: </span>
                <span> {data.orderDtl.ADD1}</span>
              </div>
              <div className="my-3 text-md">
                <span className="font-bold">Address 2: </span>
                <span> {data.orderDtl.ADD2}</span>
              </div>
              <div className="my-3 text-md">
                <span className="font-bold">City: </span>
                <span> {data.orderDtl.CITY}</span>
              </div>
              <div className="my-3 text-md">
                <span className="font-bold">State: </span>
                <span> {data.orderDtl.STATE}</span>
              </div>
              <div className="my-3 text-md">
                <span className="font-bold">Pincode: </span>
                <span> {data.orderDtl.PINCODE}</span>
              </div>
            </Box>
          ) : null}
          <Grid container>
            <Grid item xs={12}>
              <Box className="bg-white px-6 py-4 mx-4">
                <Typography variant="h6">Order Items</Typography>
                {typeof data.orderItm != "undefined"
                  ? data.orderItm.map((product) => (
                      <div
                        className="border rounded-xl flex flex-wrap md:justify-between justify-start px-4 py-3 my-5 items-start"
                        key={product.ITEM_CD}
                      >
                        {product.img ? (
                          <img
                            style={{
                              minHeight: "5rem",
                              maxHeight: "100%",
                              minWidth: "5rem",
                              maxWidth: "9rem",
                              objectFit: "fill",
                            }}
                            src={imageUrl + "/" + product.img.IMGURL[0]}
                            alt={product.ITEM_NAME}
                          />
                        ) : (
                          <div
                            className="bg-gray-300 flex items-center justify-center"
                            style={{
                              minHeight: "8rem",
                              maxHeight: "100%",
                              minWidth: "9rem",
                              maxWidth: "9rem",
                            }}
                          >
                            <CropOriginalIcon className="text-7xl" />
                          </div>
                        )}
                        <div className="py-2 px-5">{product.ITEM_NAME} </div>
                        <div className="py-2 px-5">
                          <span className="font-bold">Quantity</span>:{" "}
                          {product.QUANTITY}
                        </div>
                        <div className="py-2 px-5">
                          <span className="font-bold">Items Delivered</span>:{" "}
                          {product.DLVR_QUANTITY}
                        </div>
                        <div className="py-2 px-5">
                          <span className="font-bold">Rate</span>: ₹
                          {product.RATE}
                        </div>
                        <div className="py-2 px-5">
                          <span className="font-bold">Total</span>: ₹
                          {product.NET_AMOUNT}
                        </div>
                        <div className="py-2 px-5">
                          <span className="font-bold pr-1"> Status:</span>
                          <span
                            className={
                              product.ISTATUS == "Pending"
                                ? "rounded-md p-2 bg-yellow-300 text-white"
                                : product.ISTATUS == "Dispatch"
                                ? "rounded-md p-2 bg-green-500 text-white"
                                : product.ISTATUS == "Canceled"
                                ? "rounded-md p-2 bg-red-500 text-white"
                                : product.ISTATUS == "Confirm"
                                ? "rounded-md p-2 bg-red-500 text-white"
                                : "rounded-md p-2 bg-blue-900 text-white"
                            }
                          >
                            {product.ISTATUS}
                          </span>
                        </div>
                        {product.ISTATUS == "Pending" &&
                        data.STATUS == "Pending" ? (
                          <Button
                            disableElevation
                            variant="outlined"
                            className="px-5 border-red-500"
                            onClick={() => {
                              setUpdateOrder({
                                orderId: data.orderId,
                                oitmid: product.oitmid,
                              });
                              handleClickOpen1();
                            }}
                          >
                            Cancel Item
                          </Button>
                        ) : null}
                      </div>
                    ))
                  : ""}
              </Box>
            </Grid>
          </Grid>
        </Container>
        <Footer />
      </Header>
    </div>
  );
};

export default DetailOrder;
