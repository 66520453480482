import React, { useEffect, useState } from "react";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import {
  Checkbox,
  FormControlLabel,
  TextField,
  Button,
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  IconButton,
  Collapse,
  Box,
  Paper,
  makeStyles,
  Container,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import useSWR from "swr";
import AdminHeader from "../Components/AdminHeader";

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});

function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();

  const { data: resComapany, error: resComapanyError } = useSWR(
    `/saral/company?mobileNo=9898989946`
  );

  const [company, setCompany] = useState([]);

  useEffect(() => {
    if (resComapany) {
      setCompany(resComapany.data.result);
    }
  }, [resComapany]);

  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.id}
        </TableCell>
        <TableCell align="center">{row.MOBNOCO}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <FormControlLabel
                control={
                  <Checkbox
                    // checked={state.checkedB}
                    // onChange={handleChange}
                    name="checkedB"
                    color="primary"
                  />
                }
                label="Party Wise Rate"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    // checked={state.checkedB}
                    // onChange={handleChange}
                    name="checkedB"
                    color="primary"
                  />
                }
                label="Item Wise Discount"
              />
              <Autocomplete
                multiple
                id="tags-standard"
                className="md:w-1/2 mt-5"
                options={company}
                getOptionLabel={(option) => option.DATANM}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="Company"
                    placeholder="Company"
                  />
                )}
                ChipProps={{
                  color: "primary",
                }}
              />
              <div className="text-center mt-10">
                <Button variant="contained" color="primary" disableElevation>
                  Update
                </Button>
              </div>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

const rows = [{ id: 1, MOBNOCO: "123456789" }];

export default function AdminViewCompany() {
  return (
    <AdminHeader>
      <Container className="mt-10">
        <div className="flex justify-between">
          <div className="text-xl mb-5">Company Details</div>
        </div>
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell>id</TableCell>
                <TableCell align="center">Mobile No</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <Row key={row.id} row={row} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
    </AdminHeader>
  );
}
