import React, { useEffect, useState, useContext } from "react";
import Footer from "../../Components/Footer";
import ProductDetails from "../../Components/ProductDetails";
import GlobalContext from "../../Context/globalContext";
import {
  Redirect,
  useParams,
  useHistory,
  useLocation,
  Link,
} from "react-router-dom";
import { decode } from "jsonwebtoken";
import {
  INIT_SIMILARPRODUCTS,
  SHOW_SNACKBAR,
  SIGN_IN,
} from "../../Context/globalActions.types";

import {
  Container,
  Typography,
  CircularProgress,
  Button,
} from "@material-ui/core";
import BottomBar from "./Components/BottomBar";
import CustomSnakBar from "../../Components/CustomSnakBar";
import ProductCard from "../../Components/ProductCard";
import { Instance } from "../../Config";
import useSWR from "swr";
import Header from "../../Components/Header";
import Slider from "react-slick";

const Products = () => {
  const params = useParams();
  const history = useHistory();
  let mobileNo = params.mobileNo;
  let itemCd = params.ITEM_CD;
  const location = useLocation();

  const { globalContext, dispatch } = useContext(GlobalContext);
  const [data, setData] = useState([]);
  const [category, setCategory] = useState("");

  const settings = {
    slidesToShow: 4,
    arrows: true,
    dots: false,
    infinite: true,
    speed: 500,
    cssEase: "linear",
    autoplaySpeed: 2000,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          arrows: true,
          dots: false,
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          arrows: true,
          dots: false,
          slidesToShow: 1,
        },
      },
    ],
  };

  const fetchDetailData = () => {
    const element = globalContext.products.filter(
      (product) => product.ITEM_CD == itemCd && product.MOBNOCO == mobileNo
    );
    if (element && element.length != 0) {
      setData(element[0]);
      setCategory(element[0].DEPT_CD);
    } else {
      const element1 = globalContext.productsByCategory.filter(
        (product) => product.ITEM_CD == itemCd && product.MOBNOCO == mobileNo
      );
      if (element1 && element1.length != 0) {
        setData(element1[0]);
        setCategory(element1[0].DEPT_CD);
      } else {
        const element2 = globalContext.similarProducts.filter(
          (product) => product.ITEM_CD == itemCd && product.MOBNOCO == mobileNo
        );
        if (element2 && element2.length != 0) {
          setData(element2[0]);
          setCategory(element2[0].DEPT_CD);
        } else {
          Instance.get(
            `/products/itemsById/${
              location.pathname.split("/")[
                location.pathname.split("/").length - 1
              ]
            }?mobileNo=${params.mobileNo}&per_page=${10}&page=${0}&groupCd=${
              decode(localStorage.getItem("user")).groupCd
            }&partyCd=${decode(localStorage.getItem("user")).cd}`
          ).then((res) => {
            setData(res.data.result[0]);
            setCategory(res.data.result[0].DEPT_CD);
          });
        }
      }
    }
  };

  const { data: resSimilarProducts, error: resSimilarProductsError } = useSWR(
    globalContext.user != ""
      ? `/products?mobileNo=${
          params.mobileNo
        }&per_page=${8}&page=${0}&groupCd=${
          decode(localStorage.getItem("user")).groupCd
        }&partyCd=${
          decode(localStorage.getItem("user")).cd
        }&dept_cd=${category}`
      : ""
  );

  useEffect(() => {
    if (resSimilarProducts) {
      dispatch({
        type: INIT_SIMILARPRODUCTS,
        payload: resSimilarProducts.data.result,
      });
    }
  }, [resSimilarProducts]);

  useEffect(() => {
    if (resSimilarProductsError) {
      dispatch({
        type: SHOW_SNACKBAR,
        payload: {
          message: "Something went wrong",
          type: "error",
        },
      });
    }
  }, [resSimilarProductsError]);

  useEffect(() => {
    fetchDetailData();
  }, []);

  useEffect(() => {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }, [location.pathname.split("/")[location.pathname.split("/").length - 1]]);

  useEffect(() => {
    if (
      localStorage.getItem("user") != "" &&
      decode(localStorage.getItem("user")).role == "user"
    ) {
      fetchDetailData();
    }
  }, [itemCd]);

  return (
    <div>
      <Header>
        <ProductDetails product={data} type="" />
        <section className="middle pt-0">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                <div className="sec_title position-relative text-center">
                  <h3 className="ft-bold pt-3">Matching Products</h3>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                {!resSimilarProducts ? (
                  <div className="flex justify-center mt-10">
                    <CircularProgress />
                  </div>
                ) : globalContext.similarProducts ? (
                  <Slider {...settings}>
                    {globalContext.similarProducts
                      .filter((e) => e.ITEM_CD != itemCd)
                      .map((d, index) => (
                        <div className="single_itesm" key={d.ITEM_CD}>
                          <ProductCard
                            product={d}
                            link={`/${params.mobileNo}/product/${d.ITEM_CD}`}
                            type="similar"
                          />
                        </div>
                      ))}
                  </Slider>
                ) : (
                  ""
                )}
              </div>
            </div>
            {resSimilarProducts && globalContext.similarProducts ? (
              globalContext.similarProducts.length > 4 ? (
                <div className="row justify-content-center mb-10">
                  <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                    <div className="position-relative text-center grid justify-center">
                      <Link
                        to={`/${params.mobileNo}/shop?type=category&value=${category}`}
                      >
                        <Button
                          variant="contained"
                          color="primary"
                          className="btn custom-height"
                          disableElevation
                          disableRipple
                          endIcon={<i className="lni lni-arrow-right ml-2"></i>}
                        >
                          Explore More
                        </Button>
                      </Link>
                    </div>
                  </div>
                </div>
              ) : null
            ) : null}
          </div>
        </section>
        <Footer />
        <BottomBar />
      </Header>
    </div>
  );
};

export default Products;
