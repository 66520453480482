import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { Button, Paper } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
  },
  dropdown: {
    position: "absolute",
    top: 22,
    right: 0,
    left: -120,
    zIndex: 10,
    width: "140px",
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function ProductLeadingClickAway({
  children,
  handleButtonClick,
}) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClick = (e) => {
    e.preventDefault();
    setOpen((prev) => !prev);
  };

  const handleClickAway = () => {
    setOpen(false);
  };

  return (
    <ClickAwayListener
      mouseEvent="onMouseDown"
      touchEvent="onTouchStart"
      onClickAway={handleClickAway}
    >
      <div className={classes.root}>
        <div type="button" onClick={handleClick}>
          {children}
        </div>
        {open ? (
          <div className={classes.dropdown}>
            <Paper className="px-2 py-1">
              <Button variant="text" onClick={handleButtonClick}>
                Upload Image
              </Button>
            </Paper>
          </div>
        ) : null}
      </div>
    </ClickAwayListener>
  );
}
