import { Typography } from "@material-ui/core";
import React, { useEffect, useRef, useCallback, useState } from "react";
import { Link } from "react-router-dom";
import useSWR from "swr";
import { Skeleton } from "@material-ui/lab";

export default function Vendors() {
  const { data, error } = useSWR(`/saral/getAllCompany`);

  return (
    <div>
      <div className="header header-light dark-text">
        <div className="container">
          <nav id="navigation" className="navigation navigation-landscape">
            <div className="nav-header">
              <Link to={`/`} className="nav-brand">
                <div>Hub360</div>
              </Link>
              <div className="nav-toggle"></div>
            </div>
          </nav>
        </div>
      </div>
      <Typography className="bg-blue-500 text-white text-4xl h-44 items-center flex justify-center">
        Our Vendors
      </Typography>
      <div>
        <div className="grid md:grid-cols-4 grid-cols-1 sm:grid-cols-2 gap-10 mx-10 my-10">
          {data
            ? data.data.results.map((item, index) => {
                return (
                  <div
                    key={item.COMP_NAME}
                    className="max-w-sm bg-white rounded-lg border border-gray-200 shadow-md dark:bg-gray-800 dark:border-gray-700"
                  >
                    <div className="flex flex-col items-center pb-10 px-4 pt-4">
                      <h5 className="mb-1 text-xl font-medium text-gray-900 dark:text-white">
                        {item.COMP_NAME}
                      </h5>
                      <span className="text-sm text-gray-500 dark:text-gray-400">
                        Shop Id: {item.MOBNO}
                      </span>
                      <div className="flex mt-4 space-x-3 lg:mt-6 w-full">
                        <Link
                          to={`/${item.MOBNO}`}
                          className="btn btn-block custom-height stretched-link borders"
                        >
                          Go to Shop
                        </Link>
                      </div>
                    </div>
                  </div>
                );
              })
            : ["A", "B", "C", "D", "E", "F", "G", "H", "I"].map((item) => (
                <Skeleton
                  variant="rect"
                  animation="wave"
                  width={290}
                  height={150}
                  key={item}
                />
              ))}
        </div>
      </div>
    </div>
  );
}
