import React, { useEffect, useContext } from "react";
import GlobalContext from "../Context/globalContext";
import { SIGN_OUT } from "../Context/globalActions.types";
import { useParams, Redirect } from "react-router";

const Logout = () => {
  const { globalContext, dispatch } = useContext(GlobalContext);
  const params = useParams();

  useEffect(() => {
    dispatch({
      type: SIGN_OUT,
      payload: "",
    });
    localStorage.setItem("user", "");
  }, []);

  if (globalContext.user) {
    return <div>Loading...</div>;
  } else {
    return <Redirect to={`/${params.mobileNo}/login`} />;
  }
};

export default Logout;
