import {
  Container,
  Typography,
  Tabs,
  Tab,
  TextField,
  Button,
  Grid,
  Divider,
  CircularProgress,
  Dialog,
  Input,
} from "@material-ui/core";
import React, { useState } from "react";
import OwnerHeader from "./Components/OwnerHeader";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { useParams } from "react-router-dom";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { imageUrl, Instance } from "../../Config";
import NotificationsNoneOutlinedIcon from "@material-ui/icons/NotificationsNoneOutlined";
import ShoppingCartOutlinedIcon from "@material-ui/icons/ShoppingCartOutlined";
import GetAppOutlinedIcon from "@material-ui/icons/GetAppOutlined";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
      className="min-h-screen bg-[#F1F5F9]"
    >
      {value === index && <div>{children}</div>}
    </div>
  );
}

const OwnerNotification = () => {
  const [value, setValue] = useState(0);
  const [title, setTitle] = useState("");
  const [desc, setDesc] = useState("");
  const [imgUrl, setImgUrl] = useState("");
  const [isUploading, setIsUploading] = useState(false);

  const params = useParams();

  const [open, setOpen] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [type, setType] = useState("");
  const [isSend, setIsSend] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose1 = (value) => {
    setOpen(false);
  };

  const handleClickOpen2 = () => {
    setIsSend(false);
    setOpen2(true);
  };

  const handleClose2 = (value) => {
    setOpen2(false);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function a11yProps(index) {
    return {
      id: `scrollable-auto-tab-${index}`,
      "aria-controls": `scrollable-auto-tabpanel-${index}`,
    };
  }

  const uploadImage = async (uploadData) => {
    setIsUploading(true);
    const formData = new FormData();
    formData.append("image", uploadData);
    try {
      const res = await Instance.post("/uploads/notificationImg", formData);
      setIsUploading(false);
      return res.data.result;
    } catch (error) {
      console.log(error);
    }
  };

  const deleteImage = async (id) => {
    await Instance.delete(`/uploads/${id}`);
  };

  const sendCustomNotification = () => {
    Instance.post("/notification/send/CustomNotification", {
      mobileNo: params.mobileNo,
      title,
      body: desc,
      imgUrl,
    }).then((res) => {
      if (res.status == 200) {
        handleClickOpen();
        setTitle("");
        setDesc("");
        setImgUrl("");
      }
    });
  };

  const sendTemplateNotification = () => {
    setIsLoading(true);
    Instance.post("/notification/send/templateNotification", {
      mobileNo: params.mobileNo,
      type: type,
      title,
      body: desc,
      imgUrl,
    }).then((res) => {
      if (res.status == 200) {
        setIsLoading(false);
        setTitle("");
        setDesc("");
        setImgUrl("");
        setIsSend(true);
      }
    });
  };

  function SimpleDialog() {
    return (
      <Dialog
        onClose={handleClose1}
        aria-labelledby="simple-dialog-title"
        open={open}
        maxWidth="xs"
      >
        <div className="p-4 text-center">
          <CheckCircleIcon className="text-8xl text-blue-600" />
          <Typography variant="h6" className="text-center font-bold">
            Awesome Notification Send
          </Typography>
          <Typography variant="body2" className="text-gray-400">
            Notification has been send successfully to the targeted audience
            which you have selected
          </Typography>
          <Button variant="outlined" className="mt-3" onClick={handleClose1}>
            Close
          </Button>
        </div>
      </Dialog>
    );
  }

  return (
    <OwnerHeader>
      <SimpleDialog />
      <TemplateNotificationDialog
        open2={open2}
        isSend={isSend}
        handleClose2={handleClose2}
        setTitle={setTitle}
        setDesc={setDesc}
        setImgUrl={setImgUrl}
        title={title}
        desc={desc}
        imgUrl={imgUrl}
        isLoading={isLoading}
        isUploading={isUploading}
        sendTemplateNotification={sendTemplateNotification}
        deleteImage={deleteImage}
        uploadImage={uploadImage}
        setIsUploading={setIsUploading}
      />
      <div>
        <div className="md:flex items-center justify-between px-3 md:px-4 py-3">
          <div>
            <div className="text-indigo-500 font-semibold text-sm md:text-base">
              Marketing
            </div>
            <div className="text-xl md:text-2xl font-extrabold leading-7 sm:leading-10 truncate mt-1">
              Notification
            </div>
          </div>
        </div>
        <Tabs
          value={value}
          onChange={handleChange}
          className="ml-2 text-[#3333cc]"
          scrollButtons="auto"
          variant="scrollable"
          TabIndicatorProps={{ style: { background: "#3333cc" } }}
        >
          <Tab label="Custom Message" {...a11yProps(0)} />
          <Tab label="Targeted Message" {...a11yProps(1)} />
        </Tabs>
      </div>
      <TabPanel value={value} index={0}>
        <Container className="pt-4">
          <Grid container>
            <Grid item xs={12} sm={6}>
              <div className="mb-3">
                <Typography variant="body2">Title:</Typography>
                <Input
                  className="bg-white/80 w-full border-[1px] border-[#3333cc] px-2 py-1 rounded-lg"
                  disableUnderline
                  value={title}
                  onChange={(e) => {
                    setTitle(e.target.value);
                  }}
                />
              </div>
              <div>
                <Typography variant="body2">Text:</Typography>
                <Input
                  className="bg-white/80 w-full border-[1px] border-[#3333cc] px-2 py-1 rounded-lg"
                  disableUnderline
                  multiline
                  rows={4}
                  value={desc}
                  onChange={(e) => {
                    setDesc(e.target.value);
                  }}
                />
              </div>
              <div className="flex">
                <div className="mt-3">
                  <Typography variant="body2">
                    Notification Image (Optional):
                  </Typography>
                  <input
                    accept="image/*"
                    id="contained-button-file"
                    type="file"
                    style={{
                      display: "none",
                    }}
                    onChange={async (e) => {
                      if (e.target.files.length > 0) {
                        if (imgUrl != "") {
                          deleteImage(imgUrl);
                        }
                        const data = await uploadImage(e.target.files[0]);
                        setImgUrl(data);
                      }
                    }}
                  />
                  {isUploading ? (
                    <CircularProgress size={25} className="mt-2" />
                  ) : (
                    <label htmlFor="contained-button-file">
                      <Button
                        variant="contained"
                        disableElevation
                        component="span"
                        className="bg-[#3333cc] mb-2 text-white"
                      >
                        <CloudUploadIcon />
                      </Button>
                    </label>
                  )}
                </div>
                {imgUrl != "" ? (
                  <img
                    src={imageUrl + "/" + imgUrl}
                    className="h-56 w-56 ml-3 my-2"
                  />
                ) : null}
              </div>
              <div className="text-center mt-3">
                <Button
                  variant="contained"
                  disableElevation
                  onClick={sendCustomNotification}
                  className="bg-[#3333cc] mb-2 text-white w-full"
                >
                  Send
                </Button>
              </div>
            </Grid>
          </Grid>
        </Container>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Container className="pt-4">
          <div className="grid md:grid-cols-4 grid-cols-1 sm:grid-cols-2 gap-4 items-center">
            <div className="bg-white rounded-lg border shadow md:max-w-xl py-2">
              <div className="flex items-center">
                <div className="ml-3 h-7 w-7 p-4 rounded-3xl flex justify-center items-center bg-red-400 mr-1">
                  <ShoppingCartOutlinedIcon className="text-white" />
                </div>
                <div className="flex flex-col p-2 justify-between leading-normal">
                  <Typography
                    variant="subtitle1"
                    className="font-bold tracking-tight text-gray-900"
                  >
                    Abandoned Cart
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    className="font-normal text-gray-500 text-xs"
                  >
                    Users who have Abandoned cart item
                  </Typography>
                </div>
              </div>
              <Divider variant="middle" className="mt-1" />
              <div className="flex justify-end mt-2">
                <Button
                  variant="text"
                  disableElevation
                  className="text-[#3333cc] mx-3 text-xs"
                  onClick={() => {
                    handleClickOpen2();
                    setType("type1");
                  }}
                >
                  Send
                </Button>
              </div>
            </div>

            <div className="bg-white rounded-lg border shadow md:max-w-xl py-2">
              <div className="flex items-center">
                <div className="ml-3 h-7 w-7 p-4 rounded-3xl flex justify-center items-center bg-purple-500 mr-1">
                  <NotificationsNoneOutlinedIcon className="text-white" />
                </div>
                <div className="flex flex-col p-2 justify-between leading-normal">
                  <Typography
                    variant="subtitle1"
                    className="font-bold tracking-tight text-gray-900"
                  >
                    Recently Order not Placed
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    className="font-normal text-gray-500 text-xs"
                  >
                    Users who have not placed any order in past 7 days
                  </Typography>
                </div>
              </div>
              <Divider variant="middle" className="mt-1" />
              <div className="flex justify-end mt-2">
                <Button
                  variant="text"
                  disableElevation
                  className="text-[#3333cc] mx-3 text-xs"
                  onClick={() => {
                    handleClickOpen2();
                    setType("type2");
                  }}
                >
                  Send
                </Button>
              </div>
            </div>
            <div className="bg-white rounded-lg border shadow md:max-w-xl py-2">
              <div className="flex items-center">
                <div className="ml-3 h-7 w-7 p-4 rounded-3xl flex justify-center items-center bg-green-400 mr-1">
                  <NotificationsNoneOutlinedIcon className="text-white" />
                </div>
                <div className="flex flex-col p-2 justify-between leading-normal">
                  <Typography
                    variant="subtitle1"
                    className="font-bold tracking-tight text-gray-900"
                  >
                    Recently Placed Orders
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    className="font-normal text-gray-500 text-xs"
                  >
                    Users who have placed 1 or more order in past 7 days
                  </Typography>
                </div>
              </div>
              <Divider variant="middle" className="mt-1" />
              <div className="flex justify-end mt-2">
                <Button
                  variant="text"
                  disableElevation
                  className="text-[#3333cc] mx-3 text-xs"
                  onClick={() => {
                    handleClickOpen2();
                    setType("type3");
                  }}
                >
                  Send
                </Button>
              </div>
            </div>
            <div className="bg-white rounded-lg border shadow md:max-w-xl py-2">
              <div className="flex items-center">
                <div className="ml-3 h-7 w-7 p-4 rounded-3xl flex justify-center items-center bg-pink-500 mr-1">
                  <GetAppOutlinedIcon className="text-white" />
                </div>
                <div className="flex flex-col p-2 justify-between leading-normal">
                  <Typography
                    variant="subtitle1"
                    className="font-bold tracking-tight text-gray-900"
                  >
                    Recently Download App
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    className="font-normal text-gray-500 text-xs"
                  >
                    Users who have download the app in last 15 days
                  </Typography>
                </div>
              </div>
              <Divider variant="middle" className="mt-1" />
              <div className="flex justify-end mt-2">
                <Button
                  variant="text"
                  disableElevation
                  className="text-[#3333cc] mx-3 text-xs"
                  onClick={() => {
                    handleClickOpen2();
                    setType("type4");
                  }}
                >
                  Send
                </Button>
              </div>
            </div>
          </div>
        </Container>
      </TabPanel>
    </OwnerHeader>
  );
};

export default OwnerNotification;

function TemplateNotificationDialog({
  open2,
  isSend,
  handleClose2,
  setTitle,
  setDesc,
  setImgUrl,
  title,
  desc,
  imgUrl,
  isLoading,
  isUploading,
  sendTemplateNotification,
  deleteImage,
  uploadImage,
  setIsUploading,
}) {
  return (
    <Dialog
      aria-labelledby="simple-dialog-title"
      open={open2}
      maxWidth="sm"
      fullWidth
    >
      {isSend ? (
        <div className="p-4 text-center">
          <CheckCircleIcon className="text-8xl text-blue-600" />
          <Typography variant="h6" className="text-center font-bold">
            Awesome Notification Send
          </Typography>
          <Typography variant="body2" className="text-gray-400">
            Notification has been send successfully to the targeted audience
            which you have selected
          </Typography>
          <Button
            variant="outlined"
            className="mt-3"
            onClick={() => {
              handleClose2();
              setTitle("");
              setDesc("");
              setImgUrl("");
              setIsUploading(false);
            }}
          >
            Close
          </Button>
        </div>
      ) : (
        <div className="p-4">
          <Typography variant="h6" className="text-center font-bold">
            Notification Message
          </Typography>
          <div className="mb-3">
            <Typography variant="body2">Title:</Typography>
            <Input
              className="bg-white/80 w-full border-[1px] border-[#3333cc] px-2 py-1 rounded-lg"
              disableUnderline
              value={title}
              onChange={(e) => {
                setTitle(e.target.value);
              }}
            />
          </div>
          <div>
            <Typography variant="body2">Text:</Typography>
            <Input
              className="bg-white/80 w-full border-[1px] border-[#3333cc] px-2 py-1 rounded-lg"
              disableUnderline
              multiline
              rows={4}
              value={desc}
              onChange={(e) => {
                setDesc(e.target.value);
              }}
            />
          </div>
          <div className="flex">
            <div className="mt-3">
              <Typography variant="body2">
                Notification Image (Optional):
              </Typography>
              <input
                accept="image/*"
                id="contained-button-file"
                type="file"
                style={{
                  display: "none",
                }}
                onChange={async (e) => {
                  if (e.target.files.length > 0) {
                    if (imgUrl != "") {
                      deleteImage(imgUrl);
                    }
                    const data = await uploadImage(e.target.files[0]);
                    setImgUrl(data);
                  }
                }}
              />
              {isUploading ? (
                <CircularProgress size={25} className="mt-2" />
              ) : (
                <label htmlFor="contained-button-file">
                  <Button
                    variant="contained"
                    disableElevation
                    component="span"
                    className="bg-[#3333cc] mb-2 text-white"
                  >
                    <CloudUploadIcon />
                  </Button>
                </label>
              )}
            </div>
            {imgUrl != "" ? (
              <img
                src={imageUrl + "/" + imgUrl}
                className="h-56 w-56 ml-3 my-2"
              />
            ) : null}
          </div>
          <div className="flex justify-end items-start">
            {isLoading ? null : (
              <Button
                variant="outlined"
                disableElevation
                className="ml-2 border-[#3333cc] text-black"
                onClick={() => {
                  handleClose2();
                  setTitle("");
                  setDesc("");
                  setImgUrl("");
                  setIsUploading(false);
                }}
              >
                Close
              </Button>
            )}
            {isLoading ? (
              <CircularProgress size={18} className="mr-4" />
            ) : (
              <Button
                variant="outlined"
                disableElevation
                className="ml-3 bg-[#3333cc] text-white"
                onClick={sendTemplateNotification}
              >
                Send
              </Button>
            )}
          </div>
        </div>
      )}
    </Dialog>
  );
}
