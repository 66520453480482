import React, { useEffect, useContext, useState } from "react";
import {
  useLocation,
  useParams,
  Link,
  useHistory,
  Redirect,
} from "react-router-dom";

import GlobalContext from "../../Context/globalContext";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import { imageUrl } from "../../Config";
import BottomBar from "./Components/BottomBar";
import { Button, CircularProgress } from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";

import InsertDriveFileOutlinedIcon from "@material-ui/icons/InsertDriveFileOutlined";

import LocalMallOutlinedIcon from "@material-ui/icons/LocalMallOutlined";
import jsPDF from "jspdf";
import useSWR from "swr";
import { Skeleton } from "@material-ui/lab";

const OrderSuccess = () => {
  const { globalContext } = useContext(GlobalContext);

  const history = useHistory();

  const [formLabels, setFormLabels] = useState({
    advancePaymentField: {
      label: "",
      isRequired: false,
      type: "text",
    },
    checkoutRemarks1: {
      label: "",
      isRequired: false,
      type: "text",
    },
    checkoutRemarks2: {
      label: "",
      isRequired: false,
      type: "text",
    },
    checkoutRemarks3: {
      label: "",
      isRequired: false,
      type: "text",
    },
  });

  const params = useParams();
  const location = useLocation();
  function getQueryVariable(variable) {
    var query = location.search.substring(1);
    var vars = query.split("&");
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split("=");
      if (decodeURIComponent(pair[0]) == variable) {
        return decodeURIComponent(pair[1]);
      }
    }
  }

  const { data: resOwnerOrders, error: resOwnerOrdersError } = useSWR(
    `/orders/${getQueryVariable("orderId")}?mobileNo=${params.mobileNo}`
  );

  const { data: resUpdateLabels, error: resUpdateLabelsError } = useSWR(
    `/labels?mobileNo=${params.mobileNo}`
  );

  useEffect(() => {
    if (resUpdateLabels) {
      let temp = {};
      for (let i = 0; i < resUpdateLabels.data.result.length; i++) {
        temp = {
          ...temp,
          [resUpdateLabels.data.result[i].SETTINGS_VARIABLE]: {
            isRequired: resUpdateLabels.data.result[i].IS_REQUIRED,
            label: resUpdateLabels.data.result[i].LABEL,
            type: resUpdateLabels.data.result[i].FIELD_TYPE,
          },
        };
      }
      setFormLabels(temp);
    }
  }, [resUpdateLabels]);

  const [pdfExportData, setPdfExportData] = useState([]);

  const exportPdf = () => {
    const unit = "pt";
    const size = "A4";
    const orientation = "portrait";

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(12);

    let headers;
    let data;

    if (
      globalContext.settings.filter((e) => e.VARIABLE == "prol")[0].VALUE == "Y"
    ) {
      headers = [
        [
          "Item Name",
          "Size",
          "Box",
          "Per Box Qty",
          "Quantity",
          "Delivered Qty",
          "Status",
        ],
      ];

      data = pdfExportData.map((e) => [
        e.ITEM_NAME,
        e.SIZE_CD,
        e.QUANTITY,
        e.DESC1,
        Number(e.QUANTITY) * Number(e.DESC1),
        e.DLVR_QUANTITY,
        e.ISTATUS,
      ]);
    } else {
      headers = [["Item Name", "Size", "Quantity", "Delivered Qty", "Status"]];

      data = pdfExportData.map((e) => [
        e.ITEM_NAME,
        e.SIZE_CD,
        e.QUANTITY,
        e.DLVR_QUANTITY,
        e.ISTATUS,
      ]);
    }

    let content = {
      startY: 160,
      head: headers,
      body: data,
    };

    const width1 = doc.internal.pageSize.getWidth();

    doc.text(globalContext.company[0].COMP_NAME, width1 / 2, 50, {
      align: "center",
    });
    doc.setFontSize(9);
    doc.text(globalContext.company[0].ADDRS, width1 / 2, 65, {
      align: "center",
    });
    doc.text(`Order Id: ${pdfExportData[0].ORDER_NO}`, marginLeft, 90);
    doc.text(
      `Order Date: ${new Date(pdfExportData[0].createdAt).toLocaleString("hi", {
        dateStyle: "short",
      })}`,
      width1 - 140,
      90
    );
    doc.text(
      `Customer Name: ${pdfExportData[0].accounts.ACC_NAME}`,
      marginLeft,
      100
    );
    doc.text(
      `Address: ${
        (pdfExportData[0].accounts.ADD1 ? pdfExportData[0].accounts.ADD1 : "") +
        " " +
        (pdfExportData[0].accounts.ADD2 ? pdfExportData[0].accounts.ADD2 : "") +
        " " +
        (pdfExportData[0].accounts.AREA ? pdfExportData[0].accounts.AREA : "") +
        " " +
        (pdfExportData[0].accounts.CITY ? pdfExportData[0].accounts.CITY : "") +
        " " +
        (pdfExportData[0].accounts.PINCODE
          ? pdfExportData[0].accounts.PINCODE
          : "")
      }`,
      marginLeft,
      110
    );

    if (
      globalContext.settings.filter(
        (e) => e.VARIABLE == "advancePaymentField"
      )[0].VALUE == "Y"
    ) {
      doc.text(
        `${formLabels.advancePaymentField.label}: ${pdfExportData[0].PAID_AMT}`,
        marginLeft,
        120
      );
    }

    if (
      globalContext.settings.filter((e) => e.VARIABLE == "checkoutRemarks1")[0]
        .VALUE == "Y"
    ) {
      doc.text(
        `${formLabels.checkoutRemarks1.label}: ${pdfExportData[0].REMARKS1}`,
        marginLeft,
        130
      );
    }

    if (
      globalContext.settings.filter((e) => e.VARIABLE == "checkoutRemarks2")[0]
        .VALUE == "Y"
    ) {
      doc.text(
        `${formLabels.checkoutRemarks2.label}: ${pdfExportData[0].REMARKS2}`,
        marginLeft,
        140
      );
    }

    if (
      globalContext.settings.filter((e) => e.VARIABLE == "checkoutRemarks3")[0]
        .VALUE == "Y"
    ) {
      doc.text(
        `${formLabels.checkoutRemarks3.label}: ${pdfExportData[0].REMARKS3}`,
        marginLeft,
        150
      );
    }

    doc.setFontSize(15);
    doc.autoTable(content);
    doc.save(`Order_${pdfExportData[0].ORDER_NO}.pdf`);
  };

  const createExportData = (data) => {
    return new Promise((resolve) => {
      const tempData = [];
      for (let i = 0; i < data.orderItm.length; i++) {
        tempData.push({
          ITEM_NAME: data.orderItm[i].item.ITEM_NAME,
          SIZE_CD: data.orderItm[i].SIZE_CD,
          QUANTITY: data.orderItm[i].QUANTITY,
          DLVR_QUANTITY: data.orderItm[i].DLVR_QUANTITY,
          ISTATUS: data.orderItm[i].ISTATUS,
          ORDER_NO: data.ORDER_NO,
          accounts: data.accounts,
          createdAt: data.createdAt,
          NET_AMOUNT: data.orderItm[i].NET_AMOUNT,
          RATE: data.orderItm[i].RATE,
          DESC1: data.orderItm[i].item.variant.find(
            (e) => e.SIZE_CD == data.orderItm[i].SIZE_CD
          )
            ? data.orderItm[i].item.variant.find(
                (e) => e.SIZE_CD == data.orderItm[i].SIZE_CD
              ).DESC1
            : "",
          PAID_AMT: data.PAID_AMT,
          REMARKS1: data.REMARKS1,
          REMARKS2: data.REMARKS2,
          REMARKS3: data.REMARKS3,
        });
      }
      resolve(tempData);
    });
  };

  useEffect(() => {
    if (resOwnerOrders) {
      createExportData(resOwnerOrders.data.result[0]).then((newExportData) => {
        setPdfExportData(newExportData);
      });
    }
  }, [resOwnerOrders]);

  return (
    <div>
      <Header>
        {resOwnerOrders ? (
          <div>
            <div className="pt-[1.25rem]">
              <div className="gray py-3">
                <div className="container">
                  <div className="row">
                    <div className="colxl-12 col-lg-12 col-md-12">
                      <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                          <li className="breadcrumb-item">
                            <Link to={`/${params.mobileNo}`}>Home</Link>
                          </li>
                          <li className="breadcrumb-item">
                            <a href="#">Support</a>
                          </li>
                          <li
                            className="breadcrumb-item active"
                            aria-current="page"
                          >
                            {globalContext.settings.length != 0
                              ? globalContext.settings.filter(
                                  (e) => e.VARIABLE == "customOrderLabel"
                                )[0].VALUE == "Y"
                                ? "Inquiry Completed"
                                : "Complete Order"
                              : ""}
                          </li>
                        </ol>
                      </nav>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <section className="middle">
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-12 col-md-10 col-lg-8 col-xl-6 text-center">
                    <div className="p-4 d-inline-flex align-items-center justify-content-center circle bg-light-success text-success mx-auto mb-4">
                      {globalContext.settings.length != 0 ? (
                        globalContext.settings.filter(
                          (e) => e.VARIABLE == "customOrderLabel"
                        )[0].VALUE == "Y" ? (
                          <CheckIcon />
                        ) : (
                          <i className="lni lni-heart-filled fs-lg"></i>
                        )
                      ) : (
                        ""
                      )}
                    </div>
                    <h2 className="mb-2 ft-bold">
                      {globalContext.settings.length != 0
                        ? globalContext.settings.filter(
                            (e) => e.VARIABLE == "customOrderLabel"
                          )[0].VALUE == "Y"
                          ? "Your Order Inquiry has been sent!"
                          : "Your Order is Completed!"
                        : ""}
                    </h2>
                    {globalContext.settings.length != 0 ? (
                      globalContext.settings.filter(
                        (e) => e.VARIABLE == "customOrderLabel"
                      )[0].VALUE == "Y" ? (
                        <p className="ft-regular fs-md mb-5">
                          Our Contact Person will respond you shorty.
                        </p>
                      ) : (
                        <p className="ft-regular fs-md mb-5">
                          Your order{" "}
                          <span className="text-body text-dark">
                            #
                            {pdfExportData.length !== 0 ? (
                              pdfExportData[0].ORDER_NO
                            ) : (
                              <Skeleton width={70} className="inline" />
                            )}
                          </span>{" "}
                          has been completed. To pay for the order, Please refer
                          below.
                        </p>
                      )
                    ) : (
                      ""
                    )}

                    {globalContext.owner && globalContext.owner.length != 0 ? (
                      globalContext.owner[0].PAYMENTIMG ? (
                        <React.Fragment>
                          <div className="d-inline-flex align-items-center justify-content-center mx-auto mb-4">
                            <img
                              src={
                                imageUrl +
                                "/" +
                                globalContext.owner[0].PAYMENTIMG
                              }
                              height={200}
                              width={200}
                              alt="payment Image"
                            />
                          </div>
                          <p className="ft-regular fs-md mb-5">
                            NOTE: Please Share the reference number/ScreenShot
                            of the payment to the owner to verify the payment.
                          </p>
                        </React.Fragment>
                      ) : null
                    ) : null}
                    {globalContext.settings.length != 0 ? (
                      globalContext.settings.filter(
                        (e) => e.VARIABLE == "customOrderLabel"
                      )[0].VALUE == "Y" ? null : (
                        <div className="flex justify-center">
                          {resOwnerOrders ? (
                            <Button
                              variant="outlined"
                              color="primary"
                              disableElevation
                              className="mr-4"
                              startIcon={<InsertDriveFileOutlinedIcon />}
                              onClick={() => {
                                exportPdf();
                              }}
                            >
                              Download PDF
                            </Button>
                          ) : null}
                          <Button
                            variant="contained"
                            color="primary"
                            disableElevation
                            startIcon={<LocalMallOutlinedIcon />}
                            onClick={() => {
                              history.push(`/${params.mobileNo}/orders`);
                            }}
                          >
                            Track Your Orders
                          </Button>
                        </div>
                      )
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </section>
          </div>
        ) : (
          <div className="h-96 flex justify-center items-center">
            <CircularProgress size={30} />
          </div>
        )}

        <Footer />
        <BottomBar />
      </Header>
    </div>
  );
};

export default OrderSuccess;
