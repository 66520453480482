import React, { useEffect, useRef, useState } from "react";
import {
  AppBar,
  Button,
  Card,
  CardContent,
  Container,
  Toolbar,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  CircularProgress,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import PhoneIcon from "@material-ui/icons/Phone";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { Instance } from "./Config";

const MainScreen = () => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [mobileNoValid, setMobileNoValid] = useState(true);
  const [mobileNo, setMobileNo] = useState("");

  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!mobileNoValid) {
      setMobileNoValid(true);
    }
  }, []);

  const InfoCard = ({ title, description, image }) => {
    return (
      <Card className="w-96 shadow-md">
        <CardContent className="text-center">
          <Typography className="md:mt-4 text-2xl tracking-tight font-extrabold sm:mt-5 sm:text-2xl lg:mt-6 xl:text-2xl">
            {title}
          </Typography>
          <div className="mt-2 text-sm">{description}</div>
        </CardContent>
      </Card>
    );
  };

  const FeatureSection = ({ title, description }) => {
    return (
      <Accordion className="my-3">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className="text-lg">{title}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>{description}</Typography>
        </AccordionDetails>
      </Accordion>
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (mobileNo.length == 10) {
      setIsLoading(true);
      Instance.get(`/saral/about?mobileNo=${mobileNo}`)
        .then((res) => {
          if (res.data.result.length != 0) {
            history.push(`/${res.data.result[0].MOBNO}/login`);
          } else {
            setIsLoading(false);
            setMobileNoValid(false);
          }
        })
        .catch((e) => {
          setIsLoading(false);
          setMobileNoValid(false);
        });
    } else {
      setIsLoading(false);
      setMobileNoValid(false);
    }
  };

  useEffect(() => {
    document.body.style.paddingBottom = "0";
  }, []);

  return (
    <div>
      <ShopModal
        handleClose={handleClose}
        handleSubmit={handleSubmit}
        isLoading={isLoading}
        mobileNo={mobileNo}
        mobileNoValid={mobileNoValid}
        setMobileNo={setMobileNo}
        open={open}
      />
      <div className="header header-light dark-text">
        <div className="container">
          <nav id="navigation" className="navigation navigation-landscape">
            <div className="nav-header">
              <Link to={`/`} className="nav-brand">
                <div>Hub360</div>
              </Link>
              <div className="nav-toggle"></div>
            </div>
          </nav>
        </div>
      </div>
      <div
        style={{
          backgroundColor: "#fafafa",
        }}
      >
        <Container>
          <div className="md:flex justify-between items-center">
            <div className="md:w-1/2 md:mb-10 pt-10 md:pt-0">
              <div className="md:mt-4 text-4xl tracking-tight font-extrabold sm:mt-5 sm:text-6xl lg:mt-6 xl:text-6xl text-black">
                Welcome to
                <span className="bg-clip-text pb-3 text-transparent bg-gradient-to-r from-indigo-300 to-indigo-900 block">
                  Hub360
                </span>
              </div>
              <div className="md:w-96 w-full text-black">
                E-Commerce or Electronic Commerce means buying and selling of
                goods, products or services over the internet E-commerce is also
                Know as Electronic Commerce.
              </div>
              <Button
                data-toggle="modal"
                data-target="#login"
                className="mt-4 btn btn-block custom-height stretched-link borders mb-2 md:w-96 w-full"
                disableElevation
                variant="contained"
                onClick={handleClickOpen}
              >
                Go To Shop
              </Button>
            </div>
            <div>
              <img
                src="/intro.png"
                height={500}
                width={500}
                className="md:mr-10 mt-10 md:mt-0"
              />
            </div>
          </div>
        </Container>
      </div>
      <div>
        <Container className="mt-14">
          <div className="md:mt-4 text-3xl tracking-tight font-extrabold sm:mt-5 sm:text-5xl lg:mt-6 xl:text-5xl flex gap-2 justify-center text-black">
            <span>How</span>
            <span className="bg-clip-text pb-3 text-transparent bg-gradient-to-r from-indigo-300 to-indigo-900 block">
              Hub360
            </span>
            <span>Will help</span>
          </div>
          <div className="mt-6 flex flex-wrap gap-6 justify-center">
            <InfoCard
              title="Faster buying process"
              description="Customers can spend less time shopping for what they want"
            />
            <InfoCard
              title="Product Listing with Images"
              description="A product listing is what the customer sees when they search for an item. This is one advantage in ecommerce meant for the seller. This online business plus point is that you can personalise your product listing your product listing after creating them."
            />
            <InfoCard
              title="Reduce Cost"
              description="One of the biggest advantages of ecommerce to business that keep sellers interested in online selling is cost reduction."
            />
            <InfoCard
              title="Secure"
              description="Only Our Customer Can Login in our site, showing Limited Access to guest user Party Wise Or Discount."
            />
            <InfoCard
              title="Business To Business Work"
              description="Only take order to B2B Customers, Order import to software."
            />
          </div>
        </Container>
      </div>
      <div
        style={{
          backgroundColor: "#fafafa",
        }}
      >
        <Container className="mt-14 pt-8 pb-14">
          <div className="md:mt-4 text-3xl tracking-tight font-extrabold sm:mt-5 sm:text-5xl lg:mt-6 xl:text-5xl flex gap-3 justify-center mb-8 text-black">
            <span>Features of</span>
            <span className="bg-clip-text pb-3 text-transparent bg-gradient-to-r from-indigo-300 to-indigo-900 block">
              Hub360
            </span>
          </div>
          <div>
            <FeatureSection
              title="Category Management"
              description="Manager Can Create different categories which can be applied to
            different items. In report section Manager can see the reports
            category wise."
            />
            <FeatureSection
              title="Item Management"
              description="Manager Can add new Items with their features and details with respected images attached with it."
            />
            <FeatureSection
              title="Order Placing| Modify | Delete"
              description="User can login through the cloud portal and can manage orders. He can place new order. Modify old orders and Delete existing orders if the orders is not accepted."
            />
            <FeatureSection
              title="Order Management"
              description="Order Management includes manager interaction manager can accept the orders and can change the status of order to delivered. Order status will be reflected on the side of client portal also."
            />
            <FeatureSection
              title="Customer wise Performa invoice creation"
              description="Customer can login to this portal and can get the ability to download his performa invoice for accepted and denied products. Performa invoice will contain the details of products which will help to easily identify the orders."
            />
          </div>
        </Container>
      </div>
      <div>
        <Container className="pt-8 pb-14">
          <div>
            <div className="md:mt-4 text-3xl tracking-tight font-extrabold sm:mt-5 sm:text-5xl lg:mt-6 xl:text-5xl md:flex gap-3 justify-center mb-8 text-black">
              <span>Integrated With</span>
              <span className="bg-clip-text pb-3 text-transparent bg-gradient-to-r from-indigo-300 to-indigo-900 block">
                Saral Accounting Software
              </span>
            </div>
            <div className="md:flex justify-between">
              <img />
              <Container className="text-black">
                You will get offline integration of the accounting software and
                that orders will be directly reflected to your accounting
                software(Saral Accounting Software)
                <ul className="list-disc">
                  <li>
                    All the accounting will be managed by your accounting
                    software. So here we are giving the feature of integrating
                    the accounting software with your particular POS.
                  </li>
                  <li>
                    Order and customer will be managed by the Web Manager side
                    portal but the all accounting and Accounting major reports
                    will be managed by Saral POS system.
                  </li>
                  <li>
                    POS is responsible for the accounting. All account related
                    features will be managed by this software.
                  </li>
                  <li>It includes day by day accounting</li>
                  <li>Multi Financial Year accounting</li>
                  <li>
                    Different reports like Balance sheet Profit and Loss
                    statements.
                  </li>
                  <li>
                    Web portal will be directly connected with the POS which
                    will integrate all the orders of web portal.
                  </li>
                </ul>
              </Container>
            </div>
          </div>
        </Container>
      </div>
      <div
        style={{
          backgroundColor: "#fafafa",
        }}
      >
        <Container className="pt-2 pb-14 mt-10">
          <div className="md:mt-4 text-3xl tracking-tight font-extrabold sm:mt-5 sm:text-5xl lg:mt-6 xl:text-5xl flex gap-3 justify-center mb-8 text-black">
            Contact Us
          </div>
          <div className="md:flex justify-around text-black">
            <div>
              <PhoneIcon />
              <span className="ml-2 md:text-2xl">+91 99045 54232</span>
            </div>
            <div>
              <MailOutlineIcon />
              <span className="ml-2 md:text-2xl">care@deepsoftwares.com</span>
            </div>
          </div>
        </Container>
      </div>
      <div className="bg-gray-800 sticky text-white py-3 text-center">
        Designed and Developed by{" "}
        <a href="https://skyhubs.in" className="underline text-white">
          Skyhubs Technolabs
        </a>
      </div>
    </div>
  );
};

export default MainScreen;

const ShopModal = ({
  handleClose,
  mobileNo,
  setMobileNo,
  mobileNoValid,
  handleSubmit,
  isLoading,
  open,
}) => {
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth
      >
        <DialogTitle id="form-dialog-title">
          <div className="flex justify-between">
            <div>Go to Shop</div>
            <button
              type="button"
              className="close"
              aria-label="Close"
              onClick={handleClose}
            >
              <span className="ti-close"></span>
            </button>
          </div>
        </DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label>Mobile No</label>
              <input
                type="text"
                className="form-control"
                placeholder="Mobile No"
                value={mobileNo}
                onChange={(e) => {
                  setMobileNo(e.target.value);
                }}
              />
              {!mobileNoValid ? (
                <p className="text-red-500">Please Enter Proper Mobile No.</p>
              ) : null}
            </div>

            <div className="form-group">
              <button
                type="submit"
                className="btn btn-md full-width bg-dark text-light fs-md ft-medium"
                onClick={handleSubmit}
              >
                {isLoading ? (
                  <CircularProgress className="text-white" size="1.5rem" />
                ) : (
                  "Login"
                )}
              </button>
            </div>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
};
