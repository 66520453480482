import Resizer from "react-image-file-resizer";

export const resizeImg = (file, fileType) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      1000,
      1000,
      fileType.toUpperCase(),
      100,
      0,
      (uri) => {
        resolve(uri);
      },
      "file",
      1000,
      1000
    );
  });
