import React, { useContext } from "react";
import { Container } from "@material-ui/core";
import GlobalContext from "../Context/globalContext";
import { Link, useParams } from "react-router-dom";
import LocationOnOutlinedIcon from "@material-ui/icons/LocationOnOutlined";
import PhoneOutlinedIcon from "@material-ui/icons/PhoneOutlined";
import FacebookIcon from "@material-ui/icons/Facebook";
import YouTubeIcon from "@material-ui/icons/YouTube";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import InstagramIcon from "@material-ui/icons/Instagram";
import MailOutlineIcon from "@material-ui/icons/MailOutline";

const Footer = () => {
  const { globalContext } = useContext(GlobalContext);
  const params = useParams();

  return (
    <div className="mt-3 bg-[#232323]">
      <a id="back2Top" className="top-scroll" title="Back to top" href="#">
        <i className="ti-arrow-up"></i>
      </a>
      <div className="py-5 tracking-wider custom-font px-6 md:pl-20">
        <div className="grid grid-cols-2 md:grid-cols-4 gap-y-3 md:gap-x-24 justify-evenly">
          <div>
            <div>
              <div className="text-md md:text-lg font-semibold text-white underline mb-3">
                About Us
              </div>
              <div className="text-white break-words">
                <LocationOnOutlinedIcon />
                {globalContext.company[0] ? globalContext.company[0].ADDRS : ""}
                {globalContext.company[0] ? globalContext.company[0].AREA : ""}
                {globalContext.company[0] ? globalContext.company[0].CITY : ""}
                {globalContext.company[0]
                  ? globalContext.company[0].PINCODE
                  : ""}
              </div>
            </div>
            <a
              href={`tel:${
                globalContext.company[0] ? globalContext.company[0].PH1 : ""
              }`}
              className="text-white"
            >
              <div className="mt-2">
                <PhoneOutlinedIcon fontSize="small" />{" "}
                {globalContext.company[0] ? globalContext.company[0].PH1 : ""}
              </div>
            </a>
            <a
              href={`mailto:${
                globalContext.company[0] ? globalContext.company[0].EMAIL : ""
              }`}
              className="text-white"
            >
              <div className="mt-2">
                <MailOutlineIcon fontSize="small" />{" "}
                {globalContext.company[0] ? globalContext.company[0].EMAIL : ""}
              </div>
            </a>
          </div>
          <div>
            <div className="text-md md:text-lg font-semibold text-white underline mb-3">
              Quick Links
            </div>
            <Link to={`/${params.mobileNo}`}>
              <div className="text-md my-2 text-white">Home</div>
            </Link>
            <Link to={`/${params.mobileNo}/shop`}>
              <div className="text-md my-2 text-white">
                {globalContext.settings.length != 0
                  ? globalContext.settings.filter(
                      (e) => e.VARIABLE == "customOrderLabel"
                    )[0].VALUE == "Y"
                    ? "Item"
                    : "Shop"
                  : ""}
              </div>
            </Link>
            <Link to={`/${params.mobileNo}/category`}>
              <div className="text-md my-2 text-white">Category</div>
            </Link>
            <Link to={`/${params.mobileNo}/about`}>
              <div className="text-md my-2 text-white">About Us</div>
            </Link>
          </div>
          <div>
            <div className="text-md md:text-lg font-semibold text-white underline mb-3">
              Legal
            </div>
            <Link to={`/${params.mobileNo}/privacy-policy`}>
              <div className="text-md my-2 text-white">Privacy Policy</div>
            </Link>
            <Link to={`/${params.mobileNo}`}>
              <div className="text-md my-2 text-white">Terms & Conditions</div>
            </Link>
          </div>
          <div>
            <div className="text-md md:text-lg font-semibold text-white underline mb-3">
              Social Links
            </div>
            {globalContext.owner[0] ? (
              <div className="cursor-pointer">
                {globalContext.owner[0].FBLINK ? (
                  <a
                    href={
                      window.innerWidth < 600
                        ? `fb://${globalContext.owner[0].FBLINK}`
                        : globalContext.owner[0].FBLINK
                    }
                    target="_blank"
                  >
                    <div className="mr-2 mt-1 hover:font-semibold hover:text-blue-500 text-white">
                      <FacebookIcon
                        fontSize="medium"
                        className="mr-2"
                        color="inherit"
                      />
                      <span className=" text-white">Facebook</span>
                    </div>
                  </a>
                ) : null}
                {globalContext.owner[0].YTLINK ? (
                  <a
                    href={
                      window.innerWidth < 600
                        ? `youtube://${globalContext.owner[0].YTLINK}`
                        : globalContext.owner[0].YTLINK
                    }
                    target="_blank"
                  >
                    <div className="mr-2 mt-2 hover:font-semibold hover:text-red-600 text-white">
                      <YouTubeIcon
                        fontSize="medium"
                        className="mr-2 "
                        color="inherit"
                      />
                      <span className=" text-white">YouTube</span>
                    </div>
                  </a>
                ) : null}
                {/* whatsapp://send?abid=phonenumber */}
                {globalContext.owner[0].WHANO ? (
                  <a
                    href={`https://wa.me/${globalContext.owner[0].WHANO}`}
                    target="_blank"
                  >
                    <div className="mr-2 mt-2 hover:font-semibold hover:text-green-500 text-white">
                      <WhatsAppIcon
                        fontSize="medium"
                        className="mr-2 "
                        color="inherit"
                      />
                      <span className=" text-white">WhatsApp</span>
                    </div>
                  </a>
                ) : null}
                {globalContext.owner[0].IGLINK ? (
                  <a
                    href={
                      window.innerWidth < 600
                        ? `instagram://${globalContext.owner[0].IGLINK}`
                        : globalContext.owner[0].IGLINK
                    }
                    target="_blank"
                  >
                    <div className="mr-2 mt-2 hover:font-semibold hover:text-pink-600 text-white">
                      <InstagramIcon
                        fontSize="medium"
                        className="mr-2"
                        color="inherit"
                      />
                      <span className=" text-white">Instagram</span>
                    </div>
                  </a>
                ) : null}
              </div>
            ) : null}
          </div>
        </div>
      </div>
      <div className="flex justify-center text-xs md:text-base py-1 bg-[#3e3e3e48] text-white">
        Proudly Powered By Skyhubs Technolabs
      </div>
    </div>
  );
};

export default Footer;
