import React, {
  useContext,
  useState,
  useEffect,
  useRef,
  useCallback,
} from "react";
import {
  AppBar,
  Avatar,
  Badge,
  CircularProgress,
  Drawer,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Popover,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  DialogActions,
} from "@material-ui/core";
import { Link, useParams, useHistory, Redirect } from "react-router-dom";
import GlobalContext from "../Context/globalContext";
import CartDrawer from "./CartDrawer";
import SearchDrawer from "./SearchDrawer";
import { imageUrl, Instance } from "../Config";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import { makeStyles } from "@material-ui/core/styles";
import MenuIcon from "@material-ui/icons/Menu";
import useSWR from "swr";
import { decode } from "jsonwebtoken";
import {
  INIT_CART,
  INIT_COMPANY_INFO,
  INIT_OWNER,
  INIT_ACCOUNT_INFO,
  INIT_SETTINGS,
  SHOW_SNACKBAR,
  INIT_NOTIFICATION,
  INIT_CATEGORY,
  INIT_ACCOUNTS_SETTINGS,
} from "../Context/globalActions.types";
import NotificationsIcon from "@material-ui/icons/Notifications";
import usePagination from "../hooks/usePagination";
import Fab from "@material-ui/core/Fab";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import Zoom from "@material-ui/core/Zoom";
import PersonOutlineOutlinedIcon from "@material-ui/icons/PersonOutlineOutlined";
import NotificationsNoneOutlinedIcon from "@material-ui/icons/NotificationsNoneOutlined";
import useScrollPosition from "../hooks/useScrollPosition";

const useStyles = makeStyles((theme) => ({
  list: {
    [theme.breakpoints.down("sm")]: {
      width: 290,
    },
    width: 390,
  },
  fullList: {
    width: "auto",
  },
  root: {
    position: "fixed",
    [theme.breakpoints.down("sm")]: {
      bottom: theme.spacing(9),
    },
    bottom: theme.spacing(3),
    right: theme.spacing(2),
    zIndex: "100",
  },
}));

function ScrollTop(props) {
  const { children, window } = props;
  const classes = useStyles();
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event) => {
    const anchor = (event.target.ownerDocument || document).querySelector(
      "#back-to-top-anchor"
    );

    if (anchor) {
      anchor.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  return (
    <Zoom in={trigger}>
      <div onClick={handleClick} role="presentation" className={classes.root}>
        {children}
      </div>
    </Zoom>
  );
}

const Header = (props) => {
  const { globalContext, dispatch } = useContext(GlobalContext);

  const params = useParams();
  const mobileNo = params.mobileNo;

  const scrollPosition = useScrollPosition();

  const classes = useStyles();

  const [isOpen, setIsOpen] = useState(false);
  const [isOpen1, setIsOpen1] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClickPopover = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setIsOpen(open);
  };

  const toggleDrawer1 = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setIsOpen1(open);
  };

  const toggleDrawer2 = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setIsOpen2(open);
  };

  const { data: resSettings, error: resSettingsError } = useSWR(
    `/settings?mobileNo=${params.mobileNo}`
  );

  const { data: resCart, error: resCartError } = useSWR(
    localStorage.getItem("user")
      ? `/cart?mobileNo=${mobileNo}&partyCd=${
          decode(localStorage.getItem("user")).cd
        }`
      : ""
  );

  const { data: resOwnerDetail, error: resOwnerDetailError } = useSWR(
    `/owner?mobileNo=${mobileNo}`
  );

  const { data: resAccount, error: resAccountError } = useSWR(
    localStorage.getItem("user")
      ? `/account?accCd=${
          decode(localStorage.getItem("user")).cd
        }&mobileNo=${mobileNo}`
      : ""
  );

  const { data: resAbout, error: resAboutError } = useSWR(
    `/saral/about?mobileNo=${params.mobileNo}`
  );

  const { data: resCategory, error: resCategoryError } = useSWR(
    `/dept?mobileNo=${params.mobileNo}`
  );

  const { data: resAccountsSettings, error: resAccountsSettingsError } = useSWR(
    localStorage.getItem("user")
      ? `/account/${decode(localStorage.getItem("user")).cd}?mobileNo=${
          params.mobileNo
        }`
      : ""
  );

  //Infinite scrolling for notification
  const [notificationData, setNotificationData] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);

  const {
    data: resNotification,
    hasMore,
    loading: notificationLoading,
    error: notificationError,
  } = usePagination(
    globalContext.user
      ? `/notification?mobileNo=${params.mobileNo}&accCd=${
          decode(globalContext.user).cd
        }&page=${pageNumber}`
      : "",
    pageNumber
  );

  const observer = useRef();
  const lastBookElementRef = useCallback(
    (node) => {
      if (notificationLoading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPageNumber((prevPageNumber) => prevPageNumber + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [notificationLoading, hasMore]
  );

  useEffect(() => {
    const array = notificationData.concat(resNotification);
    setNotificationData(array);
    dispatch({
      type: INIT_NOTIFICATION,
      payload: resNotification,
    });
  }, [resNotification]);

  useEffect(() => {
    if (resAccountsSettings) {
      dispatch({
        type: INIT_ACCOUNTS_SETTINGS,
        payload: resAccountsSettings.data.result,
      });
    }
  }, [resAccountsSettings]);

  useEffect(() => {
    if (resAbout) {
      if (resAbout.data.result.length != 0) {
        dispatch({
          type: INIT_COMPANY_INFO,
          payload: resAbout.data.result,
        });
      } else {
        dispatch({
          type: INIT_COMPANY_INFO,
          payload: [{ COMP_NAME: "", PERSON_NM: "", EMAILID: "" }],
        });
      }
    }
  }, [resAbout]);

  useEffect(() => {
    if (resCategory) {
      dispatch({
        payload: resCategory.data.result,
        type: INIT_CATEGORY,
      });
    }
  }, [resCategory]);

  useEffect(() => {
    if (resCart) {
      dispatch({
        payload: resCart.data.result,
        type: INIT_CART,
      });
    }
  }, [resCart]);

  useEffect(() => {
    if (resOwnerDetail) {
      dispatch({
        payload: resOwnerDetail.data.result,
        type: INIT_OWNER,
      });
    }
  }, [resOwnerDetail]);

  useEffect(() => {
    if (resAccount) {
      dispatch({
        payload:
          resAccount.data.result.length == 0
            ? []
            : resAccount.data.result[resAccount.data.result.length - 1],
        type: INIT_ACCOUNT_INFO,
      });
    }
  }, [resAccount]);

  useEffect(() => {
    if (resSettings) {
      if (resSettings.data.result.length == 1) {
        dispatch({
          type: INIT_SETTINGS,
          payload: [],
        });
      } else {
        dispatch({
          type: INIT_SETTINGS,
          payload: resSettings.data.result,
        });
      }
    }
  }, [resSettings]);

  useEffect(() => {
    if (
      resSettingsError ||
      resCartError ||
      resAccountError ||
      resOwnerDetailError ||
      resCategoryError
    ) {
      dispatch({
        type: SHOW_SNACKBAR,
        payload: {
          message: "Something went wrong",
          type: "error",
        },
      });
    }
  }, [
    resCartError,
    resAccountError,
    resOwnerDetailError,
    notificationError,
    resCategoryError,
  ]);

  function Menus() {
    return (
      <ul className="nav-menu">
        <li>
          <Link
            to={`/${params.mobileNo}`}
            style={{
              color: globalContext.owner[0]
                ? globalContext.owner[0].COLOR?.primaryColorText
                : "",
            }}
            className="theme-1-font-style text-lg font-semibold hover:underline"
          >
            Home
          </Link>
        </li>
        <li>
          <Link
            to={`/${params.mobileNo}/shop`}
            style={{
              color: globalContext.owner[0]
                ? globalContext.owner[0].COLOR?.primaryColorText
                : "",
            }}
            className="theme-1-font-style text-lg font-semibold hover:underline"
          >
            {globalContext.settings.length != 0
              ? globalContext.settings.filter(
                  (e) => e.VARIABLE == "customOrderLabel"
                )[0].VALUE == "Y"
                ? "Item"
                : "Shop"
              : ""}
          </Link>
        </li>
        <li>
          <Link
            to={`/${params.mobileNo}/category`}
            style={{
              color: globalContext.owner[0]
                ? globalContext.owner[0].COLOR?.primaryColorText
                : "",
            }}
            className="theme-1-font-style text-lg font-semibold hover:underline"
          >
            Category
          </Link>
        </li>
        <li>
          <Link
            to={`/${params.mobileNo}/about`}
            style={{
              color: globalContext.owner[0]
                ? globalContext.owner[0].COLOR?.primaryColorText
                : "",
            }}
            className="theme-1-font-style text-lg font-semibold hover:underline"
          >
            About Us
          </Link>
        </li>
        <li>
          {globalContext.settings.length != 0 ? (
            globalContext.settings.filter(
              (e) => e.VARIABLE == "customOrderLabel"
            )[0].VALUE == "N" ? (
              <Link
                to={`/${params.mobileNo}/catalog`}
                style={{
                  color: globalContext.owner[0]
                    ? globalContext.owner[0].COLOR?.primaryColorText
                    : "",
                }}
                className="theme-1-font-style text-lg font-semibold hover:underline"
              >
                Catalog
              </Link>
            ) : null
          ) : (
            ""
          )}
        </li>
      </ul>
    );
  }

  const [openDialog, setOpenDialog] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState(0);

  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  const handleClose = (value) => {
    setOpenDialog(false);
    setSelectedValue(value);
  };

  function DrawerMenu() {
    return (
      <React.Fragment>
        <div onClick={toggleDrawer2(true)}>
          <MenuIcon />
        </div>

        <Drawer
          className={`${classes.list} max-w-md theme-1-font-style`}
          anchor="left"
          open={isOpen2}
          onClose={toggleDrawer2(false)}
          transitionDuration={{ enter: 500, exit: 1000 }}
        >
          <div
            className="d-flex align-items-center justify-between py-3 px-3"
            style={{
              backgroundColor: globalContext.owner[0]
                ? globalContext.owner[0].COLOR?.primaryColor
                : "",
              color: globalContext.owner[0]
                ? globalContext.owner[0].COLOR?.primaryColorText
                : "",
            }}
          >
            Welcome to,{" "}
            {globalContext.company && globalContext.company.length != 0
              ? globalContext.company[0].COMP_NAME
              : ""}
            <button onClick={toggleDrawer2(false)} className="close_slide">
              <i className="ti-close"></i>
            </button>
          </div>
          <div>
            <div className="pt-3">
              {[
                {
                  link: `/${params.mobileNo}`,
                  name: "Home",
                },
                {
                  link: `/${params.mobileNo}/shop`,
                  name:
                    globalContext.settings.length != 0
                      ? globalContext.settings.filter(
                          (e) => e.VARIABLE == "customOrderLabel"
                        )[0].VALUE == "Y"
                        ? "Item"
                        : "Shop"
                      : "",
                },
                {
                  link: `/${params.mobileNo}/category`,
                  name: "Category",
                },
                {
                  link: `/${params.mobileNo}/about`,
                  name: "About Us",
                },
                {
                  link: `/${params.mobileNo}/catalog`,
                  name: "Catalog",
                },
              ].map((item) => (
                <div key={item.name}>
                  <Link to={item.link} className="mx-4 pr-52">
                    {item.name}
                  </Link>
                  <hr />
                </div>
              ))}
            </div>

            {globalContext.settings.length != 0 ? (
              globalContext.settings.filter((e) => e.VARIABLE == "personalApp")
                .length != 0 ? (
                globalContext.settings.filter(
                  (e) => e.VARIABLE == "personalApp"
                )[0].VALUE == "N" ? (
                  window.innerWidth < 600 ? (
                    <div>
                      <Link
                        className="mx-4 pr-52"
                        to={`/${params.mobileNo}/addshop`}
                      >
                        Add Shop
                      </Link>
                      <hr />
                    </div>
                  ) : null
                ) : null
              ) : null
            ) : null}
            <SimpleDialog
              selectedValue={selectedValue}
              open={openDialog}
              onClose={handleClose}
            />
            {globalContext.settings.length != 0 ? (
              globalContext.settings.filter(
                (e) => e.VARIABLE == "deleteAccount"
              ).length != 0 ? (
                globalContext.settings.filter(
                  (e) => e.VARIABLE == "deleteAccount"
                )[0].VALUE == "Y" ? (
                  window.innerWidth < 600 ? (
                    <div onClick={handleClickOpen}>
                      <Link className="mx-4 " to={`#`}>
                        Delete My Account
                      </Link>
                      <hr />
                    </div>
                  ) : null
                ) : null
              ) : null
            ) : null}
            <div>
              <Link className="mx-4 pr-52" to={`/${params.mobileNo}/logout`}>
                Sign Out
              </Link>
              <hr />
            </div>
          </div>
        </Drawer>
      </React.Fragment>
    );
  }

  // if (!globalContext.owner && globalContext.owner.length == 0) {
  //   if (!globalContext.owner[0].COLOR) {
  //     return <div></div>;
  //   }
  //   return <div></div>;
  // }

  if (!globalContext.owner[0]) {
    return <div></div>;
  }

  if (
    localStorage.getItem("user") &&
    decode(localStorage.getItem("user")).role == "user"
  ) {
    return (
      <React.Fragment>
        <div className="header header-light dark-text">
          <AppBar
            elevation={0}
            className={
              scrollPosition > 0 ? "shadow-md  text-black" : " text-black"
            }
            color="primary"
            variant="elevation"
          >
            <div
              className={
                window.innerWidth < 900 ? "container my-3" : "container"
              }
            >
              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  {window.innerWidth < 900 ? <DrawerMenu /> : null}
                  {globalContext.owner ? (
                    globalContext.owner.length != 0 &&
                    globalContext.owner[0].LOGO ? (
                      <Link
                        to={`/${params.mobileNo}`}
                        className="nav-brand mt-2"
                      >
                        <img
                          src={imageUrl + "/" + globalContext.owner[0].LOGO}
                          className="logo"
                          alt=""
                        />
                      </Link>
                    ) : (
                      <Link to={`/${params.mobileNo}`} className="nav-brand">
                        <div
                          style={{
                            color: globalContext.owner[0]
                              ? globalContext.owner[0].COLOR?.primaryColorText
                              : "",
                          }}
                        >
                          Hub360
                        </div>
                      </Link>
                    )
                  ) : (
                    <Link to={`/${params.mobileNo}`} className="nav-brand">
                      <div
                        style={{
                          color: globalContext.owner[0]
                            ? globalContext.owner[0].COLOR?.primaryColorText
                            : "",
                        }}
                      >
                        Hub360
                      </div>
                    </Link>
                  )}
                  {window.innerWidth > 900 ? <Menus /> : null}
                </div>

                <ul className="nav-menu nav-menu-social flex items-center">
                  <div className="mr-2">
                    <SearchDrawer isOpen={isOpen} toggleDrawer={toggleDrawer} />
                  </div>
                  <Link
                    className="mr-2"
                    to={`/${params.mobileNo}/profile`}
                    style={{
                      color: globalContext.owner[0]
                        ? globalContext.owner[0].COLOR?.primaryColorText
                        : "",
                    }}
                  >
                    <PersonOutlineOutlinedIcon className="text-xl cursor-pointer" />
                  </Link>
                  {globalContext.settings.length != 0 ? (
                    globalContext.settings.filter(
                      (e) => e.VARIABLE == "showOnlyProducts"
                    )[0].VALUE == "Y" ? null : (
                      <div className="mr-2">
                        <CartDrawer
                          isOpen={isOpen1}
                          toggleDrawer={toggleDrawer1}
                        />
                      </div>
                    )
                  ) : null}
                  <div>
                    <li>
                      {window.innerWidth < 600 ? (
                        <Link
                          to={`/${params.mobileNo}/notification`}
                          style={{
                            color: globalContext.owner[0]
                              ? globalContext.owner[0].COLOR?.primaryColorText
                              : "",
                          }}
                        >
                          <Badge
                            badgeContent={
                              globalContext.notification
                                ? globalContext.notification.filter(
                                    (e) => e.isRead == false
                                  ).length
                                : 0
                            }
                            color="secondary"
                            showZero
                          >
                            <NotificationsNoneOutlinedIcon className="text-xl cursor-pointer" />
                          </Badge>
                        </Link>
                      ) : (
                        <Link
                          to="#"
                          onClick={handleClickPopover}
                          style={{
                            color: globalContext.owner[0]
                              ? globalContext.owner[0].COLOR?.primaryColorText
                              : "",
                          }}
                        >
                          <Badge
                            badgeContent={
                              globalContext.notification
                                ? globalContext.notification.filter(
                                    (e) => e.isRead == false
                                  ).length
                                : 0
                            }
                            color="secondary"
                            showZero
                          >
                            <NotificationsNoneOutlinedIcon className="text-xl cursor-pointer" />
                          </Badge>
                        </Link>
                      )}
                      {window.innerWidth > 600 ? (
                        <Popover
                          id={id}
                          open={open}
                          anchorEl={anchorEl}
                          onClose={handleClosePopover}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "right",
                          }}
                          PaperProps={{
                            style: {
                              width: "370px",
                              height: "500px",
                              borderRadius: "0.75rem",
                            },
                          }}
                        >
                          <div
                            className="fixed z-10 w-[371px] rounded-t-xl"
                            style={{
                              backgroundColor: globalContext.owner[0]
                                ? globalContext.owner[0].COLOR?.primaryColor
                                : "",
                              color: globalContext.owner[0]
                                ? globalContext.owner[0].COLOR?.primaryColorText
                                : "",
                            }}
                          >
                            <Typography
                              variant="body1"
                              className="text-white py-4 px-4"
                            >
                              Notification
                            </Typography>
                          </div>
                          <div className="mt-16">
                            {notificationData.length != 0
                              ? notificationData.map((data, index) => {
                                  if (notificationData.length === index + 1) {
                                    if (data) {
                                      return (
                                        <div
                                          key={data.id}
                                          ref={lastBookElementRef}
                                        >
                                          <ListItem divider={true}>
                                            <ListItemAvatar>
                                              <Avatar>
                                                <NotificationsIcon />
                                              </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText
                                              primary={data ? data.body : ""}
                                              secondary={
                                                data
                                                  ? new Date(
                                                      data.createdAt
                                                    ).toLocaleDateString(
                                                      "us-en",
                                                      {
                                                        dateStyle: "long",
                                                      }
                                                    )
                                                  : ""
                                              }
                                            />
                                            {data ? (
                                              data.imgUrl ? (
                                                <img
                                                  height={50}
                                                  width={50}
                                                  src={
                                                    imageUrl + "/" + data.imgUrl
                                                  }
                                                />
                                              ) : null
                                            ) : null}
                                          </ListItem>
                                        </div>
                                      );
                                    }
                                  } else {
                                    return (
                                      <div key={data.id}>
                                        <ListItem divider={true}>
                                          <ListItemAvatar>
                                            <Avatar>
                                              <NotificationsIcon />
                                            </Avatar>
                                          </ListItemAvatar>
                                          <ListItemText
                                            primary={data ? data.body : ""}
                                            secondary={
                                              data
                                                ? new Date(
                                                    data.createdAt
                                                  ).toLocaleDateString(
                                                    "us-en",
                                                    {
                                                      dateStyle: "long",
                                                    }
                                                  )
                                                : ""
                                            }
                                          />
                                          {data ? (
                                            data.imgUrl ? (
                                              <img
                                                height={50}
                                                width={50}
                                                src={
                                                  imageUrl + "/" + data.imgUrl
                                                }
                                              />
                                            ) : null
                                          ) : null}
                                        </ListItem>
                                      </div>
                                    );
                                  }
                                })
                              : null}
                          </div>
                          {notificationLoading ? (
                            <div className="flex justify-center">
                              <CircularProgress />
                            </div>
                          ) : null}
                        </Popover>
                      ) : null}
                    </li>
                  </div>
                </ul>
              </div>
            </div>
          </AppBar>
          <div id="back-to-top-anchor"></div>
          <ScrollTop {...props}>
            <Fab color="primary" size="small" aria-label="scroll back to top">
              <KeyboardArrowUpIcon />
            </Fab>
          </ScrollTop>
        </div>
        <div className="mt-5">{props.children}</div>
      </React.Fragment>
    );
  } else {
    return <Redirect to={`/${mobileNo}/login`} />;
  }
};

function SimpleDialog(props) {
  const { onClose, selectedValue, open } = props;

  const params = useParams();
  const history = useHistory();

  const handleClose = () => {
    onClose(selectedValue);
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      fullWidth
      maxWidth="xs"
    >
      <DialogTitle id="simple-dialog-title">Delete Account</DialogTitle>
      <DialogContent>
        <div>Are you sure you want to Delete Account?</div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>No</Button>
        <Button
          onClick={() => {
            Instance.post("/account/deactivate", {
              mobileNo: params.mobileNo,
              accCd: decode(localStorage.getItem("user")).cd,
            }).then(() => {
              handleClose();
              history.push(`/${params.mobileNo}/logout`);
            });
          }}
        >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default Header;
