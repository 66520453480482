import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import React, { useEffect, useState, useContext } from "react";
import { imageUrl, Instance } from "../Config";
import { decode } from "jsonwebtoken";
import GlobalContext from "../Context/globalContext";
import { ADD_CART, UPDATE_IMAGE_PRODUCT } from "../Context/globalActions.types";
import { Link, useParams, useHistory, useLocation } from "react-router-dom";
import Slider from "react-slick";
import ShareIcon from "@material-ui/icons/Share";
import { resizeImg } from "../utils/resizeImg";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import ShoppingCartOutlinedIcon from "@material-ui/icons/ShoppingCartOutlined";
import axios from "axios";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";

const ProductDetails = (props) => {
  const {
    ITEM_CD,
    ITEM_NAME,
    dept,
    img,
    RATEOFTAX,
    MOBNOCO,
    DEPT_CD,
    variant,
    ITEM_SNAME,
    ITEM_LNAME,
    CATALOG,
    UOM,
    ITEM_DESC,
  } = props.product;

  const [price1, setPrice1] = useState(0);
  const [price2, setPrice2] = useState(0);
  const [price3, setPrice3] = useState(0);
  const [price4, setPrice4] = useState(0);
  const [price5, setPrice5] = useState(0);
  const [price, setPrice] = useState(0);
  const [disPrice, setDisPrice] = useState(0);
  const [quantity, setQuantity] = useState(1);
  const [sizeError, setSizeError] = useState(false);
  const [size, setSize] = useState("");
  const [localImg, setLocalImg] = useState([]);
  const [stock, setStock] = useState([]);

  const [desc1, setDesc1] = useState(0);

  const [isDownloading, setIsDownloading] = useState(false);

  const params = useParams();
  const history = useHistory();
  const location = useLocation();

  const [minQty, setMinQty] = useState(0);
  const [qtyMulti, setQtyMulti] = useState(0);
  const [showQtyError, setShowQtyError] = useState(false);

  const [imageError, setImageError] = useState(false);
  const [isUploading, setIsUploading] = useState(false);

  const [value, setValue] = React.useState(0);

  const [tempImg, setTempImg] = useState([]);

  const { globalContext, dispatch } = useContext(GlobalContext);

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const settings = {
    centerMode: false,
    slidesToShow: 1,
    arrows: true,
    dots: true,
    infinite: true,
    speed: 500,
    cssEase: "linear",
    autoplaySpeed: 6000,
    autoplay: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: true,
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          arrows: true,
          slidesToShow: 1,
        },
      },
    ],
  };

  //   function multiple(number,no){
  //     if (number % no === 0) { // divide by the number
  //       return true; // add the current multiple found to the multiples array
  //     }
  //     return false;
  // }

  //If the user setting if itemDisc is Y then set the value of disc state to show to user
  //Or else set the value of disc and price
  // disc state is used to show discounted rate and price is used to show value without discount

  const partyRate = (variant) => {
    if (globalContext.settings.length != 0) {
      if (
        globalContext.settings.filter((e) => e.VARIABLE == "showStk")[0]
          .VALUE == "Y"
      ) {
        if (
          globalContext.settings.filter((e) => e.VARIABLE == "prtyStk")[0]
            .VALUE == "Y"
        ) {
          if (globalContext.accountsSettings.length != 0) {
            if (
              globalContext.accountsSettings.filter(
                (e) => e.VARIABLE == "accShowStk"
              )
            ) {
              setStock(variant.CL_STK);
            }
          }
        } else {
          setStock(variant.CL_STK);
        }
      }

      if (
        globalContext.settings.filter((e) => e.VARIABLE == "itemDisc")[0]
          .VALUE == "Y"
      ) {
        if (
          globalContext.settings.filter(
            (e) => e.SETTING_NAME == "PARTY_WISE_RATE"
          )[0].VALUE == "Y"
        ) {
          const discount = variant.SALE_DISC / 100;
          const discountAmt = discount * variant.SALE_RATE1;
          setDisPrice(Math.round(variant.SALE_RATE1 - discountAmt));
          setPrice(variant.SALE_RATE1);
        } else {
          const grade = decode(globalContext.user).partyGd;
          const discount = variant.SALE_DISC / 100;
          if (grade == "A") {
            const discountAmt = discount * variant.SALE_RATE1;
            setDisPrice(Math.round(variant.SALE_RATE1 - discountAmt));
            setPrice(variant.SALE_RATE1);
          } else if (grade == "B") {
            const discountAmt = discount * variant.SALE_RATE2;
            setDisPrice(Math.round(variant.SALE_RATE2 - discountAmt));
            setPrice(variant.SALE_RATE2);
          } else if (grade == "C") {
            const discountAmt = discount * variant.SALE_RATE3;
            setDisPrice(Math.round(variant.SALE_RATE3 - discountAmt));
            setPrice(variant.SALE_RATE3);
          } else if (grade == "D") {
            const discountAmt = discount * variant.SALE_RATE4;
            setDisPrice(Math.round(variant.SALE_RATE4 - discountAmt));
            setPrice(variant.SALE_RATE4);
          } else if (grade == "E") {
            const discountAmt = discount * variant.SALE_RATE5;
            setDisPrice(Math.round(variant.SALE_RATE5 - discountAmt));
            setPrice(variant.SALE_RATE5);
          } else {
            setDisPrice(0);
            setPrice(0);
          }
        }
      } else {
        if (
          globalContext.settings.filter(
            (e) => e.SETTING_NAME == "PARTY_WISE_RATE"
          )[0].VALUE == "Y"
        ) {
          setDisPrice(Math.round(variant.SALE_RATE1));
          setPrice(variant.SALE_RATE1);
        } else {
          const grade = decode(globalContext.user).partyGd;
          if (grade == "A") {
            setDisPrice(Math.round(variant.SALE_RATE1));
            setPrice(variant.SALE_RATE1);
          } else if (grade == "B") {
            setDisPrice(Math.round(variant.SALE_RATE2));
            setPrice(variant.SALE_RATE2);
          } else if (grade == "C") {
            setDisPrice(Math.round(variant.SALE_RATE3));
            setPrice(variant.SALE_RATE3);
          } else if (grade == "D") {
            setDisPrice(Math.round(variant.SALE_RATE4));
            setPrice(variant.SALE_RATE4);
          } else if (grade == "E") {
            setDisPrice(Math.round(variant.SALE_RATE5));
            setPrice(variant.SALE_RATE5);
          } else {
            setDisPrice(0);
            setPrice(0);
          }
        }
      }
    }
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (img) {
      if (typeof img.IMGURL == "string") {
        setLocalImg(img.IMGURL.length == 0 ? [] : img.IMGURL);
      } else {
        setLocalImg(img.IMGURL.length == 0 ? [] : img.IMGURL);
      }
    } else {
      setLocalImg([]);
      setTempImg([]);
    }
    if (variant && props.type != "preview") {
      partyRate(variant[0]);
      setSize(variant[0].SIZE_CD);
      setDesc1(variant[0].DESC1);
      setMinQty(variant[0].MIN_OQTY ? variant[0].MIN_OQTY : 1);
      setQtyMulti(variant[0].QYT_MULTI ? variant[0].QYT_MULTI : 1);
      setQuantity(variant[0].MIN_OQTY ? variant[0].MIN_OQTY : 1);
    }
    if (variant && props.type == "preview") {
      setPrice1(
        Math.round(
          variant[0].SALE_RATE1 -
            (variant[0].SALE_DISC / 100) * variant[0].SALE_RATE1
        )
      );
      setPrice2(
        Math.round(
          variant[0].SALE_RATE2 -
            (variant[0].SALE_DISC / 100) * variant[0].SALE_RATE2
        )
      );
      setPrice3(
        Math.round(
          variant[0].SALE_RATE3 -
            (variant[0].SALE_DISC / 100) * variant[0].SALE_RATE3
        )
      );
      setPrice4(
        Math.round(
          variant[0].SALE_RATE4 -
            (variant[0].SALE_DISC / 100) * variant[0].SALE_RATE4
        )
      );
      setPrice5(
        Math.round(
          variant[0].SALE_RATE5 -
            (variant[0].SALE_DISC / 100) * variant[0].SALE_RATE5
        )
      );
    }
  }, [variant]);

  useEffect(() => {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }, []);

  const sendImage = (image) => {
    return new Promise((resolve) => {
      Instance.post("/uploads/signUrl", {
        fileType: image.type,
      })
        .then(async (res) => {
          const img = await resizeImg(image, image.type.split("/")[1]);
          axios
            .put(res.data.result, img, {
              headers: {
                "Content-Type": image.type,
                "x-amz-acl": "public-read",
              },
            })
            .then(() => {
              resolve(res.data.fileName);
            })
            .catch((e) => {
              console.log(e);
            });
        })
        .catch((err) => {
          console.log(err.data);
        });
    });
  };

  const uploadImage = async (uploadData) => {
    let imgArry = [];
    setIsUploading(true);
    for (const image of uploadData) {
      const data = await sendImage(image);
      imgArry.push(data);
    }
    setIsUploading(false);
    return imgArry;
  };

  // const uploadImage = async (uploadData) => {
  //   setIsUploading(true);
  //   const formData = new FormData();
  //   for (const image of uploadData) {
  //     const img = await resizeImg(image, image.type.split("/")[1]);
  //     formData.append("productImage", img);
  //   }
  //   try {
  //     const res = await Instance.post("/uploads", formData);
  //     setIsUploading(false);
  //     return res.data.result;
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const deleteImage = async (id) => {
    await Instance.delete(`/uploads/${id}`);
    setLocalImg(localImg.filter((e) => e != id));
  };

  const saveData = () => {
    if (img) {
      if (localImg.length == 0) {
        Instance.delete(`/products/${img.imgid}`)
          .then((res) => {
            if (res.status == 200) {
              setLocalImg([]);
              handleClose();
              dispatch({
                payload: {
                  ITEM_CD: ITEM_CD,
                },
                type: UPDATE_IMAGE_PRODUCT,
              });
            }
          })
          .catch((e) => {
            handleClose();
          });
      } else {
        Instance.put("/products/image", {
          imgid: img.imgid,
          code: ITEM_CD,
          imgUrl: localImg,
          mobileNo: MOBNOCO,
        })
          .then((res) => {
            if (res.status == 200) {
              dispatch({
                payload: {
                  imgid: img.imgid,
                  ITEM_CD: ITEM_CD,
                  IMGURL: localImg,
                  MOBNOCO: MOBNOCO,
                },
                type: UPDATE_IMAGE_PRODUCT,
              });
              handleClose();
            }
          })
          .catch((e) => {
            handleClose();
            console.log(e);
          });
      }
    } else {
      if (localImg.length != 0) {
        Instance.post("/products/image", {
          code: ITEM_CD,
          imgUrl: localImg,
          mobileNo: MOBNOCO,
        })
          .then((res) => {
            if (res.status == 200) {
              dispatch({
                payload: {
                  imgid: res.data.result.imgid,
                  ITEM_CD: ITEM_CD,
                  IMGURL: localImg,
                  MOBNOCO: MOBNOCO,
                },
                type: UPDATE_IMAGE_PRODUCT,
              });
              handleClose();
            }
          })
          .catch((e) => {
            handleClose();
            console.log(e);
          });
      }
    }
  };

  const handleCart = (
    ITEM_CD,
    ITEM_NAME,
    IMGURL,
    size,
    DEPT_CD,
    quantity,
    rate,
    RATEOFTAX,
    MOBNOCO,
    partyCd,
    desc1
  ) => {
    if (
      typeof globalContext.cart.find(
        (e) => e.ITEM_CD == ITEM_CD && e.SIZE_CD == size
      ) == "undefined"
    ) {
      if (quantity >= minQty) {
        if (size != "") {
          setSizeError(false);
          let tempQty = Number(quantity);
          if (
            globalContext.settings.filter((e) => e.VARIABLE == "prol")[0]
              .VALUE == "Y"
          ) {
            tempQty = Number(quantity) * Number(desc1);
          }
          const netAmt =
            (Number(rate) * (RATEOFTAX / 100) + Number(rate)) * Number(tempQty);
          Instance.post("/cart", {
            itemCd: ITEM_CD,
            itemName: ITEM_NAME,
            sizeCd: size,
            deptCd: DEPT_CD,
            quantity: quantity,
            rate: rate,
            rateOfTax: RATEOFTAX,
            netAmount: netAmt,
            mobileNo: MOBNOCO,
            partyCd: partyCd,
          })
            .then((res) => {
              if (res.status == 200) {
                dispatch({
                  payload: {
                    cartId: res.data.insertId,
                    ITEM_CD: ITEM_CD,
                    ITEM_NAME: ITEM_NAME,
                    img: {
                      imgid: 2,
                      CODE: ITEM_CD,
                      IMGURL: IMGURL ? IMGURL[0] : "",
                      MOBNOCO: MOBNOCO,
                    },
                    DEPT_CD: DEPT_CD,
                    SIZE_CD: size,
                    quantity: quantity,
                    rate: rate,
                    RATEOFTAX: RATEOFTAX,
                    netAmount: netAmt,
                    MOBNOCO: MOBNOCO,
                    partyCd: partyCd,
                    item: props.product,
                  },
                  type: ADD_CART,
                });
                setQuantity(minQty);
                setShowQtyError(false);
                setSizeError(false);
              }
            })
            .catch((e) => {
              console.log(e);
            });
        } else {
          setSizeError(true);
        }
      } else {
        setShowQtyError(true);
      }
    }
  };

  return (
    <React.Fragment>
      <UploadForm
        deleteImage={deleteImage}
        handleClose={handleClose}
        imageError={imageError}
        isUploading={isUploading}
        localImg={localImg}
        open={open}
        saveData={saveData}
        setImageError={setImageError}
        setLocalImg={setLocalImg}
        setTempImg={setTempImg}
        tempImg={tempImg}
        uploadImage={uploadImage}
        img={img}
      />
      {sizeError ? (
        <div className="mb-5">
          <Alert severity="error">Please Select Size</Alert>
        </div>
      ) : (
        ""
      )}
      {props.type == "modal" ? null : (
        <div
          className={`gray py-3 ${props.type == "preview" ? "mt-2" : "mt-16"}`}
        >
          <div className="container">
            <div className="row">
              <div className="colxl-12 col-lg-12 col-md-12">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to={`/${params.mobileNo}/`}>Home</Link>
                    </li>
                    <li className="breadcrumb-item">
                      <Link
                        to={
                          props.type == "preview"
                            ? `/${params.mobileNo}/owner/viewproducts`
                            : `/${params.mobileNo}/shop`
                        }
                      >
                        Product
                      </Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      {ITEM_NAME}
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      )}
      <section className="middle">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-xl-5 col-lg-6 col-md-12 col-sm-12">
              <div className="quick_view_slide">
                {localImg.length != 0 ? (
                  <Slider {...settings}>
                    {localImg.map((productImage) => (
                      <div key={productImage}>
                        <a
                          href={imageUrl + "/" + productImage}
                          data-lightbox="roadtrip"
                          className="d-block mb-4"
                        >
                          <img
                            src={imageUrl + "/" + productImage}
                            className="img-fluid w-screen h-[300px] md:w-[500px] md:h-[500px] object-contain"
                            alt=""
                          />
                        </a>
                      </div>
                    ))}
                  </Slider>
                ) : (
                  <div>
                    <a
                      href="/img/11.jpg"
                      data-lightbox="roadtrip"
                      className="d-block mb-4"
                    >
                      <img
                        src="/img/11.jpg"
                        className="img-fluid w-screen h-[300px] md:w-[500px] md:h-[500px] object-contain"
                        alt=""
                      />
                    </a>
                  </div>
                )}
              </div>
              {globalContext.settings.length != 0 ? (
                globalContext.settings.filter(
                  (e) => e.VARIABLE == "showOnlyProducts"
                )[0].VALUE == "N" ? (
                  <div
                    className="badge position-absolute ft-regular ab-right text-upper mr-4 mt-2"
                    style={{
                      backgroundColor: globalContext.owner[0]
                        ? globalContext.owner[0].COLOR?.primaryColor
                        : "",
                      color: globalContext.owner[0]
                        ? globalContext.owner[0].COLOR?.primaryColorText
                        : "",
                    }}
                  >
                    {variant ? variant[0].SALE_DISC + "%" : "--"}
                  </div>
                ) : null
              ) : null}

              {window.innerWidth < 600 ? (
                <div className="badge position-absolute ft-regular ab-right mr-4 mt-5 text-black cursor-pointer rounded-full py-2">
                  <ShareIcon
                    stroke="white"
                    strokeWidth="0.4px"
                    onClick={() => {
                      if (window.innerWidth < 600) {
                        if (props.type == "preview") {
                          history.push(
                            `/${params.mobileNo}/owner/editproduct?id=${ITEM_CD}&link=https://ecom.skyhubs.in/${params.mobileNo}/product/${ITEM_CD}&type=share`
                          );
                        } else {
                          history.push(
                            `/${params.mobileNo}/product/${ITEM_CD}?link=https://ecom.skyhubs.in/${params.mobileNo}/product/${ITEM_CD}&type=share`
                          );
                        }
                      } else {
                        navigator.share({
                          url: `https://ecom.skyhubs.in/${params.mobileNo}/product/${ITEM_CD}`,
                          title: globalContext.company
                            ? globalContext.company.length != 0
                              ? globalContext.company[0].COMP_NAME
                              : ""
                            : "",
                        });
                      }
                    }}
                  />
                </div>
              ) : null}
            </div>
            <div className="col-xl-7 col-lg-6 col-md-12 col-sm-12">
              <div className="prd_details pl-3">
                <div className="prt_02 mb-3">
                  <div className="md:flex justify-between items-start">
                    <h2 className="ft-bold mb-1 capitalize">
                      {ITEM_NAME ? ITEM_NAME.toString().toLowerCase() : null}
                    </h2>
                    {window.innerWidth > 600 ? (
                      <Button
                        variant="outlined"
                        className="mr-3 min-w-min"
                        onClick={() => {
                          if (window.innerWidth < 600) {
                            if (props.type == "preview") {
                              history.push(
                                `/${params.mobileNo}/owner/editproduct?id=${ITEM_CD}&link=https://ecom.skyhubs.in/${params.mobileNo}/product/${ITEM_CD}&type=share`
                              );
                            } else {
                              history.push(
                                `/${params.mobileNo}/product/${ITEM_CD}?link=https://ecom.skyhubs.in/${params.mobileNo}/product/${ITEM_CD}&type=share`
                              );
                            }
                          } else {
                            navigator.share({
                              url: `https://ecom.skyhubs.in/${params.mobileNo}/product/${ITEM_CD}`,
                              title: globalContext.company
                                ? globalContext.company.length != 0
                                  ? globalContext.company[0].COMP_NAME
                                  : ""
                                : "",
                            });
                          }
                        }}
                        startIcon={<ShareIcon />}
                      >
                        Share
                      </Button>
                    ) : null}
                  </div>
                  {props.type == "preview" ? (
                    <span className="rounded px-2 py-1 bg-[#3333cc] text-white">
                      {dept ? dept.dept_name : ""}
                    </span>
                  ) : (
                    <span
                      className="rounded px-2 py-1"
                      style={{
                        backgroundColor: globalContext.owner[0]
                          ? globalContext.owner[0].COLOR?.primaryColor
                          : "",
                        color: globalContext.owner[0]
                          ? globalContext.owner[0].COLOR?.primaryColorText
                          : "",
                      }}
                    >
                      {dept ? dept.dept_name : ""}
                    </span>
                  )}
                  <div className="text-left mt-2">
                    <div className="elis_rty">
                      {props.type == "preview" ? (
                        <div>
                          <div className="fs-lg">
                            Price 1:{" "}
                            <span className="ft-bold theme-cl fs-lg mr-2">
                              ₹{price1}
                            </span>
                          </div>
                          <div className="fs-lg">
                            Price 2:{" "}
                            <span className="ft-bold theme-cl fs-lg mr-2">
                              ₹{price2}
                            </span>
                          </div>
                          <div className="fs-lg">
                            Price 3:{" "}
                            <span className="ft-bold theme-cl fs-lg mr-2">
                              ₹{price3}
                            </span>
                          </div>
                          <div className="fs-lg">
                            Price 4:{" "}
                            <span className="ft-bold theme-cl fs-lg mr-2">
                              ₹{price4}
                            </span>
                          </div>
                          <div className="fs-lg">
                            Price 5:{" "}
                            <span className="ft-bold theme-cl fs-lg mr-2">
                              ₹{price5}
                            </span>
                          </div>
                        </div>
                      ) : globalContext.settings.length != 0 ? (
                        globalContext.settings.filter(
                          (e) => e.VARIABLE == "showOnlyProducts"
                        )[0].VALUE == "N" ? (
                          decode(localStorage.getItem("user")).groupCd ==
                          "88" ? (
                            globalContext.settings.filter(
                              (e) => e.VARIABLE == "guestRate"
                            )[0].VALUE == "Y" ? (
                              globalContext.settings.filter(
                                (e) => e.VARIABLE == "itemDisc"
                              )[0].VALUE == "Y" ? (
                                <React.Fragment>
                                  <span className="ft-medium line-through fs-md mr-2">
                                    ₹ {price}
                                  </span>
                                  <span
                                    className="ft-bold fs-lg mr-2"
                                    style={{
                                      color: globalContext.owner[0]
                                        ? globalContext.owner[0].COLOR
                                            ?.primaryColor
                                        : "",
                                    }}
                                  >
                                    ₹{disPrice}
                                  </span>
                                </React.Fragment>
                              ) : (
                                <span
                                  className="ft-bold fs-lg mr-2"
                                  style={{
                                    color: globalContext.owner[0]
                                      ? globalContext.owner[0].COLOR
                                          ?.primaryColor
                                      : "",
                                  }}
                                >
                                  ₹{disPrice}
                                </span>
                              )
                            ) : null
                          ) : globalContext.settings.filter(
                              (e) => e.VARIABLE == "itemDisc"
                            )[0].VALUE == "Y" ? (
                            <React.Fragment>
                              <span className="ft-medium line-through fs-md mr-2">
                                ₹ {price}
                              </span>
                              <span
                                className="ft-bold fs-lg mr-2"
                                style={{
                                  color: globalContext.owner[0]
                                    ? globalContext.owner[0].COLOR?.primaryColor
                                    : "",
                                }}
                              >
                                ₹{disPrice}
                              </span>
                            </React.Fragment>
                          ) : (
                            <span
                              className="ft-bold fs-lg mr-2"
                              style={{
                                color: globalContext.owner[0]
                                  ? globalContext.owner[0].COLOR?.secondaryColor
                                  : "",
                              }}
                            >
                              ₹{disPrice}
                            </span>
                          )
                        ) : null
                      ) : null}
                      {/* <span className="ft-regular text-danger bg-light-danger py-1 px-2 fs-sm">
                        Out of Stock
                      </span> */}
                    </div>
                  </div>
                  {globalContext.settings.length != 0 ? (
                    globalContext.settings.filter(
                      (e) => e.VARIABLE == "showStk"
                    )[0].VALUE == "Y" ? (
                      <div className="text-left mt-2">
                        <div className="elis_rty">
                          Stock Available:
                          <span className="fs-sm text-dark ft-medium ml-1">
                            {stock} {size}
                          </span>
                        </div>
                      </div>
                    ) : null
                  ) : null}
                </div>
                <div className="prt_03 mb-4">
                  <p>{ITEM_SNAME ? ITEM_SNAME : null}</p>
                </div>
                {globalContext.settings.length != 0 ? (
                  globalContext.settings.filter(
                    (e) => e.VARIABLE == "enableUom"
                  )[0].VALUE == "Y" ? (
                    <div className="text-left mt-2">
                      <div className="elis_rty">
                        <span className="text-dark ft-medium">Unit:</span>
                        <span className="fs-sm ml-1">{UOM}</span>
                      </div>
                    </div>
                  ) : null
                ) : null}
                <div className="prt_04 mb-4">
                  <p className="d-flex align-items-center mb-0 text-dark ft-medium">
                    Size:
                  </p>
                  <div className="text-left pb-0 pt-2">
                    {variant
                      ? variant.map((va) => (
                          <div
                            key={va.SIZE_CD}
                            className="form-check size-option form-option form-check-inline mb-2"
                          >
                            <input
                              className="form-check-input"
                              type="radio"
                              name="size"
                              id={va.SIZE_CD}
                              onClick={() => {
                                if (props.type != "preview") {
                                  setSize(va.SIZE_CD);
                                  setDesc1(va.DESC1);
                                  partyRate(va);
                                  setMinQty(va.MIN_OQTY ? va.MIN_OQTY : 1);
                                  setQtyMulti(va.QYT_MULTI ? va.QYT_MULTI : 1);
                                  setQuantity(va.MIN_OQTY ? va.MIN_OQTY : 1);
                                } else {
                                  setPrice1(
                                    Math.round(
                                      va.SALE_RATE1 -
                                        (va.SALE_DISC / 100) * va.SALE_RATE1
                                    )
                                  );
                                  setPrice2(
                                    Math.round(
                                      va.SALE_RATE2 -
                                        (va.SALE_DISC / 100) * va.SALE_RATE2
                                    )
                                  );
                                  setPrice3(
                                    Math.round(
                                      va.SALE_RATE3 -
                                        (va.SALE_DISC / 100) * va.SALE_RATE3
                                    )
                                  );
                                  setPrice4(
                                    Math.round(
                                      va.SALE_RATE4 -
                                        (va.SALE_DISC / 100) * va.SALE_RATE4
                                    )
                                  );
                                  setPrice5(
                                    Math.round(
                                      va.SALE_RATE5 -
                                        (va.SALE_DISC / 100) * va.SALE_RATE5
                                    )
                                  );
                                }
                              }}
                            />
                            <label
                              className="form-option-label"
                              htmlFor={va.SIZE_CD}
                              style={{
                                borderColor: globalContext.owner[0]
                                  ? va.SIZE_CD == size
                                    ? globalContext.owner[0].COLOR?.primaryColor
                                    : ""
                                  : "",
                              }}
                            >
                              {va.SIZE_CD}
                            </label>
                          </div>
                        ))
                      : null}
                  </div>
                </div>

                <div className="prt_05 mb-4">
                  <div className="form-row mb-2">
                    {globalContext.settings.length != 0 ? (
                      globalContext.settings.filter(
                        (e) => e.VARIABLE == "showOnlyProducts"
                      )[0].VALUE == "Y" ? null : typeof globalContext.cart.find(
                          (e) => e.ITEM_CD == ITEM_CD && e.SIZE_CD == size
                        ) == "undefined" ? (
                        <div className="col-12 col-lg-auto">
                          {props.type != "preview" ? (
                            <div className="form-group">
                              <input
                                type="number"
                                className="form-control"
                                placeholder="Qty"
                                value={quantity}
                                onChange={(e) => {
                                  setQuantity(Number(e.target.value));
                                }}
                              />
                              {globalContext.settings.length != 0 ? (
                                globalContext.settings.filter(
                                  (e) => e.VARIABLE == "prol"
                                )[0].VALUE == "Y" ? (
                                  <div className="text-xs mt-1">
                                    {quantity} X {desc1} = {quantity * desc1}
                                  </div>
                                ) : null
                              ) : null}
                            </div>
                          ) : null}
                          {showQtyError ? (
                            <div className="text-red-500 text-xs">
                              Minumin quantity of a item must be {minQty}
                            </div>
                          ) : null}
                        </div>
                      ) : null
                    ) : null}

                    {props.type == "preview" ? (
                      <React.Fragment>
                        <div className="col-12 col-lg">
                          <Button
                            variant="contained"
                            disableElevation
                            className="mb-2 bg-[#3333cc] text-white w-full"
                            onClick={handleClickOpen}
                          >
                            Upload Image
                          </Button>
                        </div>

                        <div className="col-6 col-lg">
                          <Button
                            variant="outlined"
                            className="mb-2 border-[.5px] border-[#3333cc] w-full"
                            onClick={() => {
                              window.scroll(0, 0);
                              const elementIndex =
                                globalContext.products.findIndex(
                                  (e) =>
                                    e.ITEM_CD == ITEM_CD &&
                                    e.MOBNOCO == params.mobileNo
                                );
                              if (globalContext.products[elementIndex - 1]) {
                                history.push(
                                  `/${params.mobileNo}/owner/editproduct?id=${
                                    globalContext.products[elementIndex - 1]
                                      .ITEM_CD
                                  }`
                                );
                              }
                            }}
                          >
                            Previous
                          </Button>
                        </div>
                        <div className="col-6 col-lg">
                          <Button
                            variant="outlined"
                            className="mb-2 border-[.5px] border-[#3333cc] w-full"
                            onClick={() => {
                              window.scroll(0, 0);
                              const elementIndex =
                                globalContext.products.findIndex(
                                  (e) =>
                                    e.ITEM_CD == ITEM_CD &&
                                    e.MOBNOCO == params.mobileNo
                                );
                              if (globalContext.products[elementIndex + 1]) {
                                console.log(
                                  location.pathname ==
                                    `/${params.mobileNo}/owner/editproduct`
                                );
                                history.push(
                                  `/${params.mobileNo}/owner/editproduct?id=${
                                    globalContext.products[elementIndex + 1]
                                      .ITEM_CD
                                  }`
                                );
                              }
                            }}
                          >
                            Next
                          </Button>
                        </div>
                      </React.Fragment>
                    ) : globalContext.settings.length != 0 ? (
                      globalContext.settings.filter(
                        (e) => e.VARIABLE == "showOnlyProducts"
                      )[0].VALUE == "Y" ? null : (
                        <div className="col-12 col-lg">
                          {typeof globalContext.cart.find(
                            (e) => e.ITEM_CD == ITEM_CD && e.SIZE_CD == size
                          ) == "undefined" ? (
                            <Button
                              variant="contained"
                              color="primary"
                              type="submit"
                              disableElevation
                              className="mb-2 w-full btn btn-block custom-height"
                              onClick={() => {
                                if (img) {
                                  handleCart(
                                    ITEM_CD,
                                    ITEM_NAME,
                                    img.IMGURL,
                                    size,
                                    DEPT_CD,
                                    quantity,
                                    disPrice,
                                    RATEOFTAX,
                                    MOBNOCO,
                                    decode(globalContext.user).cd,
                                    desc1
                                  );
                                } else {
                                  handleCart(
                                    ITEM_CD,
                                    ITEM_NAME,
                                    null,
                                    size,
                                    DEPT_CD,
                                    quantity,
                                    disPrice,
                                    RATEOFTAX,
                                    MOBNOCO,
                                    decode(globalContext.user).cd,
                                    desc1
                                  );
                                }
                              }}
                            >
                              {globalContext.settings.filter(
                                (e) => e.VARIABLE == "customOrderLabel"
                              )[0].VALUE == "Y" ? (
                                "Order Inquiry"
                              ) : (
                                <>
                                  <ShoppingCartOutlinedIcon
                                    fontSize="small"
                                    className="mr-1"
                                  />
                                  Add to Cart
                                </>
                              )}
                            </Button>
                          ) : (
                            <div className="font-bold text-black flex items-center">
                              <CheckCircleOutlineIcon className="text-green-500 mr-1" />{" "}
                              {globalContext.settings.filter(
                                (e) => e.VARIABLE == "customOrderLabel"
                              )[0].VALUE == "Y"
                                ? "Item in Order List"
                                : "Item in Cart"}
                            </div>
                          )}
                        </div>
                      )
                    ) : null}
                  </div>
                  {CATALOG ? (
                    <div>
                      {isDownloading ? (
                        <CircularProgress size={22} />
                      ) : (
                        <Button
                          variant="outlined"
                          onClick={() => {
                            setIsDownloading(true);
                            Instance.get(
                              `/catalog/${CATALOG}?mobileNo=${params.mobileNo}`
                            ).then((catalog_result) => {
                              fetch(
                                imageUrl +
                                  "/" +
                                  catalog_result.data.result[0].FILE_NAME
                              ).then((response) => {
                                response.blob().then((blob) => {
                                  const fileURL =
                                    window.URL.createObjectURL(blob);
                                  let alink = document.createElement("a");
                                  alink.href = fileURL;
                                  alink.download = "Catalog.pdf";
                                  alink.click();
                                  setIsDownloading(false);
                                });
                              });
                            });
                          }}
                        >
                          <DescriptionOutlinedIcon />
                          Catalog
                        </Button>
                      )}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {ITEM_SNAME && ITEM_LNAME && ITEM_DESC ? (
        <section className="middle">
          <div className="container">
            <div className="row align-items-center justify-content-center">
              <div className="col-xl-11 col-lg-12 col-md-12 col-sm-12">
                <ul
                  className="nav nav-tabs b-0 d-flex align-items-center justify-content-center simple_tab_links mb-4"
                  id="myTab"
                  role="tablist"
                >
                  <li className="nav-item" role="presentation">
                    <a
                      className="nav-link active"
                      id="description-tab"
                      href="#description"
                      data-toggle="tab"
                      role="tab"
                      aria-controls="description"
                      aria-selected="true"
                    >
                      Description
                    </a>
                  </li>
                </ul>
                <div className="tab-content" id="myTabContent">
                  <div
                    className="tab-pane fade show active"
                    id="description"
                    role="tabpanel"
                    aria-labelledby="description-tab"
                  >
                    <div className="description_info">
                      <p className="p-0 mb-2">{ITEM_SNAME + ITEM_LNAME}</p>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: ITEM_DESC,
                        }}
                      ></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      ) : null}
    </React.Fragment>
  );
};

const UploadForm = ({
  open,
  handleClose,
  setImageError,
  img,
  localImg,
  setTempImg,
  isUploading,
  tempImg,
  deleteImage,
  saveData,
  imageError,
  setLocalImg,
  uploadImage,
}) => {
  return (
    <div>
      <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth="sm">
        <DialogTitle>
          <div className="flex justify-between">
            <p>Upload Images</p>
            <label htmlFor="contained-button-file">
              <input
                accept="image/*"
                id="contained-button-file"
                multiple
                type="file"
                style={{
                  display: "none",
                }}
                onChange={async (e) => {
                  setImageError(false);
                  if (img) {
                    const total = e.target.files.length + localImg.length;
                    if (total <= 4) {
                      let a = [];
                      for (let i = 0; i < e.target.files.length; i++) {
                        a.push(URL.createObjectURL(e.target.files[i]));
                        if (a.length == e.target.files.length) {
                          setTempImg(a.concat(localImg));
                        }
                      }
                      const imgUrls = await uploadImage(e.target.files);
                      setLocalImg(localImg.concat(imgUrls));
                      setTempImg([]);
                    } else {
                      setImageError(true);
                    }
                  } else {
                    const total = e.target.files.length + localImg.length;
                    if (total <= 4) {
                      let a = [];
                      for (let i = 0; i < e.target.files.length; i++) {
                        a.push(URL.createObjectURL(e.target.files[i]));
                        if (a.length == e.target.files.length) {
                          setTempImg(a.concat(localImg));
                        }
                      }
                      const imgUrls = await uploadImage(e.target.files);
                      setLocalImg(localImg.concat(imgUrls));
                      setTempImg([]);
                    } else {
                      setImageError(true);
                    }
                  }
                }}
              />
              <Button
                variant="contained"
                disableElevation
                className="mb-2 bg-[#3333cc] text-white w-full"
                component="span"
              >
                Select Image
              </Button>
              {imageError ? (
                <div className="text-sm pt-2 text-red-500">
                  You can upload only 4 images.
                </div>
              ) : (
                ""
              )}
            </label>
          </div>
        </DialogTitle>
        <DialogContent>
          {isUploading ? <LinearProgress /> : null}
          <div className="wrapper">
            {!isUploading
              ? localImg.map((im) => (
                  <div className="cs-card" key={im}>
                    <img src={imageUrl + "/" + im} />
                    <div className="cs-info">
                      <button onClick={() => deleteImage(im)}>Delete</button>
                    </div>
                  </div>
                ))
              : tempImg.map((im) => {
                  return (
                    <div className="cs-card" key={im}>
                      {im.split(":")[0] == "blob" ? (
                        <img src={im} />
                      ) : (
                        <img src={imageUrl + "/" + im} />
                      )}
                    </div>
                  );
                })}
          </div>
        </DialogContent>
        <DialogActions>
          {!isUploading ? (
            <>
              <Button onClick={handleClose} variant="text">
                Close
              </Button>
              <Button onClick={saveData} variant="text">
                Save
              </Button>
            </>
          ) : null}
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ProductDetails;
