import { useEffect, useState } from "react";
import axios from "axios";
import { Instance } from "../Config";
import { useParams } from "react-router";

export default function useSearch(query, groupCd, partyCd) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [data, setData] = useState([]);

  const params = useParams();

  useEffect(() => {
    setData([]);
  }, [query]);

  useEffect(() => {
    setLoading(true);
    setError(false);
    let cancel;
    if (query != "") {
      axios({
        method: "GET",
        url: Instance.defaults.baseURL + "/products",
        params: {
          query: query,
          mobileNo: params.mobileNo,
          groupCd: groupCd,
          partyCd: partyCd,
        },
        cancelToken: new axios.CancelToken((c) => (cancel = c)),
        headers: {
          "X-API-KEY": "sky123",
          Authorization: localStorage.getItem("user"),
          "x-user": window.location.href.split("/")[3],
        },
      })
        .then((res) => {
          setData(res.data.result);
          setLoading(false);
        })
        .catch((e) => {
          if (axios.isCancel(e)) return;
          setError(true);
        });
      return () => cancel();
    }
  }, [query]);

  return { loading, error, data };
}
