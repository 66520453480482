import React, { useEffect, useState } from "react";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import {
  Checkbox,
  FormControlLabel,
  TextField,
  Button,
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  IconButton,
  Collapse,
  Box,
  Paper,
  makeStyles,
  Container,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import useSWR from "swr";
import AdminHeader from "../Components/AdminHeader";

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});

function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();

  const { data: resComapany, error: resComapanyError } = useSWR(
    `/saral/company?mobileNo=9898989946`
  );

  const [company, setCompany] = useState([]);

  useEffect(() => {
    if (resComapany) {
      setCompany(resComapany.data.result);
    }
  }, [resComapany]);

  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.id}
        </TableCell>
        <TableCell align="center">{row.MOBNOCO}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <div className="h-48 md:h-[200px] w-[250px] border-dashed border-4">
                {/* {imgUrl != "" ? (
                  <div className="flex flex-wrap gap-3 ml-4 my-2">
                    {imgUrl.map((img) => (
                      <Image
                        key={img}
                        src={process.env.NEXT_PUBLIC_IMG_URL + img}
                        height={150}
                        width={150}
                      />
                    ))}
                  </div>
                ) : ( */}
                <div className="flex justify-center items-center h-full">
                  <input
                    accept="image/*"
                    id="contained-button-file"
                    multiple
                    type="file"
                    onChange={(e) => {
                      //
                    }}
                    style={{
                      display: "none",
                    }}
                  />
                  <label htmlFor="contained-button-file">
                    <Button
                      variant="contained"
                      color="primary"
                      component="span"
                      disableElevation
                    >
                      Upload Logo
                    </Button>
                  </label>
                  {/* {isMaxImg ? (
                      <div className="text-red-500 mt-1">
                        You can upload maximum of 6 images.
                      </div>
                    ) : (
                      ""
                    )} */}
                </div>
                {/* )} */}
              </div>
              <div className="h-48 md:h-[300px] w-[700px] border-dashed border-4 mt-5">
                {/* {imgUrl != "" ? (
                  <div className="flex flex-wrap gap-3 ml-4 my-2">
                    {imgUrl.map((img) => (
                      <Image
                        key={img}
                        src={process.env.NEXT_PUBLIC_IMG_URL + img}
                        height={150}
                        width={150}
                      />
                    ))}
                  </div>
                ) : ( */}
                <div className="flex justify-center items-center h-full">
                  <input
                    accept="image/*"
                    id="contained-button-file"
                    multiple
                    type="file"
                    onChange={(e) => {
                      //
                    }}
                    style={{
                      display: "none",
                    }}
                  />
                  <label htmlFor="contained-button-file">
                    <Button
                      variant="contained"
                      color="primary"
                      component="span"
                      disableElevation
                    >
                      Upload Banner
                    </Button>
                  </label>
                  {/* {isMaxImg ? (
                      <div className="text-red-500 mt-1">
                        You can upload maximum of 6 images.
                      </div>
                    ) : (
                      ""
                    )} */}
                </div>
                {/* )} */}
              </div>
              <div className="mt-5">
                <span className="text-lg mr-2">Primary Color:</span>
                <TextField
                  variant="outlined"
                  size="small"
                  type="color"
                  className="w-[50px]"
                />
              </div>
              <div className="mt-5">
                <span className="text-lg mr-2">Primary Color Text:</span>
                <TextField
                  variant="outlined"
                  size="small"
                  type="color"
                  className="w-[50px]"
                />
              </div>
              <div className="mt-5">
                <span className="text-lg mr-2">Secondary Color:</span>
                <TextField
                  variant="outlined"
                  size="small"
                  type="color"
                  className="w-[50px]"
                />
              </div>
              <div className="mt-5">
                <span className="text-lg mr-2">Secondary Color Text:</span>
                <TextField
                  variant="outlined"
                  size="small"
                  type="color"
                  className="w-[50px]"
                />
              </div>
              <div className="text-center mt-10">
                <Button variant="contained" color="primary" disableElevation>
                  Update
                </Button>
              </div>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

const rows = [{ id: 1, MOBNOCO: "123456789" }];

export default function AdminViewTheme() {
  return (
    <AdminHeader>
      <Container className="mt-10">
        <div className="flex justify-between">
          <div className="text-xl mb-5">Company Theme Details</div>
        </div>
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell>id</TableCell>
                <TableCell align="center">Mobile No</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <Row key={row.id} row={row} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
    </AdminHeader>
  );
}
