import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import HomeOutlinedIcon from "@material-ui/icons/HomeOutlined";
import StoreOutlinedIcon from "@material-ui/icons/StoreOutlined";
import CategoryOutlinedIcon from "@material-ui/icons/CategoryOutlined";
import AccountCircle from "@material-ui/icons/AccountCircleOutlined";

import { useHistory, useLocation, useParams } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "none",
    position: "fixed",
    bottom: "0",
    width: "100%",
    zIndex: "99",
  },
  [theme.breakpoints.down("sm")]: {
    root: {
      display: "flex",
      justifyContent: "space-between",
    },
  },
}));

export default function BottomBar() {
  const classes = useStyles();
  const location = useLocation();
  const [value, setValue] = React.useState(location.pathname);

  const history = useHistory();
  const params = useParams();

  return (
    <BottomNavigation
      value={value}
      onChange={(event, newValue) => {
        setValue(newValue);
      }}
      showLabels
      className={classes.root}
      style={{
        boxShadow: "rgb(135 135 135) -2px 2px 4px 2px",
      }}
    >
      {[
        {
          label: "Home",
          icon: <HomeOutlinedIcon />,
          link: `/${params.mobileNo}`,
        },
        {
          label: "Shop",
          icon: <StoreOutlinedIcon />,
          link: `/${params.mobileNo}/shop`,
        },
        {
          label: "Category",
          icon: <CategoryOutlinedIcon />,
          link: `/${params.mobileNo}/category`,
        },
        {
          label: "Account",
          icon: <AccountCircle />,
          link: `/${params.mobileNo}/profile`,
        },
      ].map((menu) => (
        <BottomNavigationAction
          key={menu.label}
          label={menu.label}
          icon={menu.icon}
          onClick={() => {
            history.push(menu.link);
          }}
          value={menu.link}
          disableRipple
        />
      ))}
    </BottomNavigation>
  );
}
