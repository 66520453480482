import React, { useContext } from "react";
import GlobalContext from "../Context/globalContext";
import { Link, useHistory, Redirect, useParams } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import { decode } from "jsonwebtoken";

const AddShop = () => {
  const { globalContext } = useContext(GlobalContext);

  const history = useHistory();
  const params = useParams();

  if (globalContext.user && decode(globalContext.user).role == "user") {
    return (
      <div>
        <Header />
        <div className="gray py-3">
          <div className="container">
            <div className="row">
              <div className="colxl-12 col-lg-12 col-md-12">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to={`/${params.mobileNo}`}>Home</Link>
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>

        <section className="middle">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12 col-md-10 col-lg-8 col-xl-6 text-center">
                <div className="p-4 d-inline-flex align-items-center justify-content-center circle bg-light-success text-success mx-auto mb-4">
                  <i className="lni lni-heart-filled fs-lg"></i>
                </div>
                <h2 className="mb-2 ft-bold">
                  Please Press back button again or Close the app and re-open
                  it.
                </h2>
                <button
                  className="btn btn-dark"
                  onClick={() => {
                    history.push(`/${params.mobileNo}`);
                  }}
                >
                  Back to Home Page
                </button>
              </div>
            </div>
          </div>
        </section>

        <Footer />
      </div>
    );
  } else {
    return <Redirect to={`/${params.mobileNo}/login`} />;
  }
};

export default AddShop;
