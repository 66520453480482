import React, { useEffect, useState, useContext } from "react";

import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import VisibilityIcon from "@material-ui/icons/Visibility";
import {
  Container,
  Button,
  Menu,
  MenuItem,
  InputAdornment,
  Input,
} from "@material-ui/core";
import OwnerHeader from "../Components/OwnerHeader";
import CustomSnakBar from "../../../Components/CustomSnakBar";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import GlobalContext from "../../../Context/globalContext";
import Chip from "@material-ui/core/Chip";

import SearchIcon from "@material-ui/icons/Search";
import StorefrontIcon from "@material-ui/icons/Storefront";
import SaveAltIcon from "@material-ui/icons/SaveAlt";

import { Instance } from "../../../Config";
import {
  INIT_ORDER,
  LOAD_ORDER,
  SHOW_SNACKBAR,
} from "../../../Context/globalActions.types";

import jsPDF from "jspdf";
import "jspdf-autotable";
import { CSVLink } from "react-csv";
import useSWR from "swr";
import { Skeleton } from "@material-ui/lab";
import InsertDriveFileOutlinedIcon from "@material-ui/icons/InsertDriveFileOutlined";

const OwnerViewOrders = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [nextPage, setNextPage] = useState(undefined);
  const [previousPage, setPreviousPage] = useState(undefined);
  const [totalItems, setTotalItems] = useState(0);

  const [search, setSearch] = useState("");

  const [rows, setRows] = useState([]);
  const history = useHistory();
  const params = useParams();

  const { globalContext, dispatch } = useContext(GlobalContext);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open1 = Boolean(anchorEl);
  const id = open1 ? "simple-popover" : undefined;

  const location = useLocation();
  function getQueryVariable(variable) {
    var query = location.search.substring(1);
    var vars = query.split("&");
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split("=");
      if (decodeURIComponent(pair[0]) == variable) {
        return decodeURIComponent(pair[1]);
      }
    }
  }

  const handleSearch = (search) => {
    if (search != "") {
      setRows([]);
      Instance.get(`/orders?mobileNo=${params.mobileNo}&query=${search}`)
        .then((res) => {
          if (res.status == 200) {
            setRows(res.data.result);
            dispatch({
              type: INIT_ORDER,
              payload: res.data.result,
            });
          }
        })
        .catch((e) => {
          dispatch({
            type: SHOW_SNACKBAR,
            payload: {
              message: e.response.message || "Something went wrong",
              type: "error",
            },
          });
        });
    } else {
      setPage(0);
      Instance.get(
        `/orders?mobileNo=${params.mobileNo}&per_page=${rowsPerPage}&page=${page}`
      )
        .then((res) => {
          if (res.status == 200) {
            setRows(res.data.result);
            dispatch({
              type: INIT_ORDER,
              payload: res.data.result,
            });
          }
        })
        .catch((e) => {
          dispatch({
            type: SHOW_SNACKBAR,
            payload: {
              message: e.response.message || "Something went wrong",
              type: "error",
            },
          });
        });
    }
  };

  const sendToDetailPage = (id) => {
    history.push(`/${params.mobileNo}/owner/orderdetail?id=${id}`);
  };

  const { data: resOwnerOrders, error: resOwnerOrdersError } = useSWR(
    getQueryVariable("type")
      ? `/orders/filter?mobileNo=${params.mobileNo}&type=${getQueryVariable(
          "type"
        )}&per_page=${rowsPerPage}&page=${page}`
      : `/orders?mobileNo=${params.mobileNo}&per_page=${rowsPerPage}&page=${page}`
  );

  const { data: resOwnerOrdersExport, error: resOwnerOrdersExportError } =
    useSWR(
      getQueryVariable("type")
        ? getQueryVariable("type") == "total"
          ? `/orders/export/data?mobileNo=${params.mobileNo}`
          : `/orders/export/data?mobileNo=${
              params.mobileNo
            }&type=${getQueryVariable("type")}`
        : `/orders/export/data?mobileNo=${params.mobileNo}`
    );

  const [exportData, setExportData] = useState([]);
  const [isLoadingExportData, setIsLoadingExportData] = useState(false);
  const [exportCsv, setExportCsv] = useState(false);
  const headers = [
    { label: "Order Id", key: "ORDER_NO" },
    { label: "Customer Name", key: "ACC_NAME" },
    { label: "Item Amount", key: "ITEM_AMT" },
    { label: "Total Items", key: "NO_OF_ITEM" },
    { label: "Total Amount", key: "NET_AMT" },
    { label: "Payment Mode", key: "PAYMENT_MODE" },
    { label: "Item Name", key: "ITEM_NAME" },
    { label: "Size", key: "SIZE_CD" },
    { label: "Quantity Delivered", key: "DLVR_QUANTITY" },
    { label: "Quantity", key: "QUANTITY" },
    { label: "Rate", key: "RATE" },
    { label: "Net Amount", key: "NET_AMOUNT" },
    { label: "Status", key: "ISTATUS" },
    { label: "Order Date", key: "createdAt" },
  ];

  // const fetchExportData = () => {
  //   Instance.get(`/orders/export/data?mobileNo=${params.mobileNo}`)
  //     .then((res) => {
  //       if (res.status == 200) {
  //         let tempArray = res.data.result;
  //         for (let i = 0; i < res.data.result.length; i++) {
  //           Instance.get(
  //             `/saral/account?accCd=${res.data.result[i].PARTY_CD}&mobileNo=${res.data.result[i].MOBNOCO}`
  //           ).then((res1) => {
  //             if (res1.status == 200) {
  //               tempArray.map((e) => {
  //                 if (e.PARTY_CD == res1.data.result[0].ACC_CD) {
  //                   e.ACC_NAME = res1.data.result[0].ACC_NAME;
  //                   e.ADD1 = res1.data.result[0].ADD1;
  //                   e.ADD2 = res1.data.result[0].ADD2;
  //                   e.AREA = res1.data.result[0].AREA;
  //                   e.CITY = res1.data.result[0].CITY;
  //                   e.PINCODE = res1.data.result[0].PINCODE;
  //                   e.PHONE1 = res1.data.result[0].PHONE1;
  //                   e.PHONE2 = res1.data.result[0].PHONE2;
  //                 }
  //               });
  //               setExportData(tempArray);
  //             }
  //             if (i == tempArray.length - 1) {
  //               setIsLoadingExportData(false);
  //             }
  //           });
  //         }
  //       }
  //     })
  //     .catch((e) => {
  //       setError(e.response.message || "Something went wrong");
  //     });
  // };

  const exportPdf = () => {
    const unit = "pt";
    const size = "A4";
    const orientation = "portrait";

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    const title = "Orders Report";
    const headers = [
      [
        "Order Id",
        "Customer Name",
        "Payment Mode",
        "Order Date",
        "Total",
        "Item Name",
        "Status",
      ],
    ];

    const data = exportData.map((e) => [
      e.ORDER_NO,
      e.ACC_NAME,
      e.PAYMENT_MODE,
      new Date(e.createdAt).toLocaleString("us-en", {
        dateStyle: "long",
      }),
      e.NET_AMT,
      e.ITEM_NAME,
      e.ISTATUS,
    ]);

    let content = {
      startY: 50,
      head: headers,
      body: data,
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save("orders.pdf");
  };

  useEffect(() => {
    if (resOwnerOrdersExport) {
      setExportData(resOwnerOrdersExport.data.result);
      setIsLoadingExportData(false);
    }
  }, [resOwnerOrdersExport]);

  // useEffect(() => {
  //   dispatch({
  //     type: INIT_ORDER,
  //     payload: [],
  //   });
  //   setRows([]);
  // }, [getQueryVariable("type")]);

  useEffect(() => {
    if (resOwnerOrders) {
      const uniqueArray = (a) =>
        [...new Set(a.map((o) => JSON.stringify(o)))].map((s) => JSON.parse(s));
      const newData = rows.concat(resOwnerOrders.data.result);
      const unique = uniqueArray(newData);
      setRows(unique);
      dispatch({
        type: LOAD_ORDER,
        payload: unique,
      });
      setNextPage(resOwnerOrders.data.pagination.nextPage);
      setPreviousPage(resOwnerOrders.data.pagination.previousPage);
      setTotalItems(resOwnerOrders.data.pagination.totalItems);
    }
  }, [resOwnerOrders]);

  useEffect(() => {
    if (resOwnerOrdersError || resOwnerOrdersExportError) {
      dispatch({
        type: SHOW_SNACKBAR,
        payload: {
          message: "Something went wrong",
          type: "error",
        },
      });
    }
  }, [resOwnerOrdersError, resOwnerOrdersExportError]);

  return (
    <div>
      <OwnerHeader>
        <div className="md:flex items-center justify-between px-3 md:px-4 py-3">
          <div>
            <div className="text-indigo-500 font-semibold text-sm md:text-base">
              Store Management
            </div>
            <div className="text-xl md:text-2xl font-extrabold leading-7 sm:leading-10 truncate mt-1">
              Orders
            </div>
          </div>
          <div className="flex items-center mb-3 mt-2 md:mt-0">
            <Input
              placeholder="Search"
              className="bg-white border-[1px] border-[#3333cc] px-2 py-1 rounded-lg"
              disableUnderline
              value={search}
              onChange={(e) => {
                if (e.target.value == "") {
                  setSearch("");
                } else {
                  setSearch(e.target.value);
                }
              }}
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              }
            />
            <Button
              variant="contained"
              disableElevation
              className="ml-2 bg-[#3333cc] text-white"
              onClick={() => {
                history.push(`/${params.mobileNo}/owner/orders?type=search`);
                handleSearch(search);
              }}
            >
              Search
            </Button>
          </div>
        </div>
        <Container
          maxWidth="xl"
          className="pt-2 bg-[#F1F5F9] pb-5 min-h-screen"
        >
          <div>
            <div>
              <div className="mb-3 md:flex items-center justify-between mt-2">
                <div className="overflow-x-scroll flex hide-scroll-bar">
                  {[
                    { label: "Today", key: "today" },
                    { label: "Pending", key: "Pending" },
                    { label: "Canceled", key: "Canceled" },
                    { label: "Dispatched", key: "Dispatched" },
                    { label: "This Week", key: "thisweek" },
                  ].map((item) => (
                    <Chip
                      key={item.label}
                      label={item.label}
                      onClick={(e) => {
                        dispatch({
                          type: INIT_ORDER,
                          payload: [],
                        });
                        setRows([]);
                        if (getQueryVariable("type") == item.key) {
                          history.push(`/${params.mobileNo}/owner/orders`);
                        } else {
                          history.push(
                            `/${params.mobileNo}/owner/orders?type=${item.key}`
                          );
                        }
                      }}
                      variant={
                        getQueryVariable("type") == item.key
                          ? "default"
                          : "outlined"
                      }
                      className={
                        getQueryVariable("type") == item.key
                          ? "mx-1 bg-[#3333cc] text-white"
                          : "mx-1 border-[#3333cc]"
                      }
                    />
                  ))}
                </div>
                <div>
                  {isLoadingExportData ? (
                    <CircularProgress size={30} />
                  ) : (
                    <>
                      <Button
                        variant="outlined"
                        className="text-blue-600 border-[#3333cc] rounded-full mt-2 md:mt-0"
                        startIcon={<SaveAltIcon />}
                      >
                        <CSVLink
                          data={exportData}
                          headers={headers}
                          filename="Orders.csv"
                          className="text-blue-600"
                        >
                          Excel
                        </CSVLink>
                      </Button>
                      <Button
                        variant="outlined"
                        className="text-blue-600 border-[#3333cc] rounded-full mt-2 md:mt-0 ml-2"
                        startIcon={<InsertDriveFileOutlinedIcon />}
                        onClick={() => {
                          exportPdf();
                        }}
                      >
                        PDF
                      </Button>
                    </>
                  )}
                </div>
              </div>
              {!resOwnerOrders && rows.length == 0 ? (
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-5">
                  {[
                    "A",
                    "B",
                    "C",
                    "D",
                    "E",
                    "F",
                    "G",
                    "H",
                    "I",
                    "J",
                    "K",
                    "L",
                  ].map((item) => (
                    <div key={item}>
                      <Skeleton
                        animation="wave"
                        variant="rect"
                        width={350}
                        height={118}
                      />
                    </div>
                  ))}
                </div>
              ) : resOwnerOrders && rows.length == 0 ? (
                <div className="min-h-screen text-center">
                  <StorefrontIcon className="text-black text-[12rem]" />
                  <Typography className="text-2xl font-bold text-black">
                    Oops!
                  </Typography>
                  <Typography>No Match Found.</Typography>
                </div>
              ) : (
                <React.Fragment>
                  <div className="grid grid-cols-1 md:grid-cols-3 gap-3">
                    {rows.map((row) => {
                      return (
                        <div
                          className="border-[.5px] text-black border-[#3333cc] rounded-lg p-2 flex justify-between items-center bg-white text-sm"
                          key={row.orderId}
                        >
                          <Link
                            to={`/${params.mobileNo}/owner/orderdetail?id=${row.orderId}`}
                            className="w-full flex justify-between items-start"
                          >
                            <div>
                              <div className="mr-1">
                                <span>
                                  Order No :
                                  <span className="font-bold">
                                    {" "}
                                    #{row.ORDER_NO ? row.ORDER_NO : ""}
                                  </span>
                                </span>
                              </div>
                              <div>
                                Order Date :
                                <span className="font-bold">
                                  {" "}
                                  {new Date(row.createdAt).toLocaleString(
                                    "us-en",
                                    {
                                      dateStyle: "long",
                                    }
                                  )}
                                </span>
                              </div>
                              <div>
                                Customer :
                                <span className="font-bold">
                                  {" "}
                                  {row.accounts ? row.accounts.ACC_NAME : "N.A"}
                                </span>
                              </div>
                              <div>
                                Net Amount :
                                <span className="font-bold">
                                  {" "}
                                  ₹{row.NET_AMT}
                                </span>
                              </div>
                            </div>
                            <div className="mt-1">
                              <span
                                className={
                                  row.orderItm.find(
                                    (e) => e.ISTATUS == "Pending"
                                  )
                                    ? "rounded-md px-2 py-1 bg-[#ff6600] text-white"
                                    : row.orderItm.every(
                                        (e) => e.ISTATUS == "Dispatched"
                                      )
                                    ? "rounded-md px-2 py-1 bg-[#049aab] text-white"
                                    : row.orderItm.every(
                                        (e) => e.ISTATUS == "Canceled"
                                      )
                                    ? "rounded-md px-2 py-1 bg-[#3333cc] text-white"
                                    : "rounded-md px-2 py-1 bg-[#009999] text-white"
                                }
                              >
                                {row.orderItm.find(
                                  (e) => e.ISTATUS == "Pending"
                                )
                                  ? "Pending"
                                  : row.orderItm.every(
                                      (e) => e.ISTATUS == "Dispatched"
                                    )
                                  ? "Dispatched"
                                  : row.orderItm.every(
                                      (e) => e.ISTATUS == "Canceled"
                                    )
                                  ? "Canceled"
                                  : "Completed"}
                              </span>
                            </div>
                          </Link>
                        </div>
                      );
                    })}
                  </div>
                  {nextPage ? (
                    <div className="text-center mb-2">
                      <Button
                        variant="contained"
                        className="bg-[#3333cc] text-white"
                        disableElevation
                        onClick={() => {
                          setPage((pr) => pr + 1);
                        }}
                      >
                        Load More
                      </Button>
                    </div>
                  ) : null}
                </React.Fragment>
              )}
            </div>
          </div>
        </Container>
      </OwnerHeader>
    </div>
  );
};

export default OwnerViewOrders;
