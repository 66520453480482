import React, {
  useEffect,
  useState,
  useContext,
  useRef,
  useCallback,
} from "react";
import {
  Container,
  Typography,
  Tabs,
  CircularProgress,
} from "@material-ui/core";
import ProductCard from "../../Components/ProductCard";
import Header from "../../Components/Header";
import BottomBar from "./Components/BottomBar";
import Footer from "../../Components/Footer";
import { imageUrl, webUrl } from "../../Config";
import { useParams, useHistory } from "react-router";
import GlobalContext from "../../Context/globalContext";
import { decode } from "jsonwebtoken";
import {
  INIT_ACCOUNT_INFO,
  INIT_CART,
  INIT_CATEGORY,
  INIT_COMPANY_INFO,
  INIT_PRODUCT,
  INIT_SETTINGS,
  INIT_OWNER,
  SIGN_IN,
  SHOW_SNACKBAR,
} from "../../Context/globalActions.types";
import CustomSnakBar from "../../Components/CustomSnakBar";
import useSWR from "swr";
import CustomCardCategory from "../../Components/HomePage/Category";
import BestSelling from "../../Components/HomePage/BestSellings";
import { Skeleton } from "@material-ui/lab";
import usePagination from "../../hooks/usePagination";
import { Link } from "react-router-dom";
import Slider from "react-slick";

export default function Home() {
  const params = useParams();
  const history = useHistory();

  // const [isLoading, setIsLoading] = useState(true);

  const settings = {
    centerMode: false,
    slidesToShow: 1,
    arrows: false,
    dots: true,
    infinite: true,
    speed: 500,
    cssEase: "linear",
    autoplaySpeed: 3000,
    autoplay: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          arrows: false,
          slidesToShow: 1,
        },
      },
    ],
  };

  const { globalContext, dispatch } = useContext(GlobalContext);

  const [categoryName, setCategoryName] = useState([]);

  let mobileNo = params.mobileNo;

  const { data: resProducts, error: resProductsError } = useSWR(
    globalContext.user != "" && localStorage.getItem("user")
      ? `/products?mobileNo=${
          params.mobileNo
        }&per_page=${12}&page=${0}&groupCd=${
          decode(globalContext.user).groupCd
        }&partyCd=${decode(globalContext.user).cd}`
      : ""
  );

  //Pagination for category
  const [pageNumber, setPageNumber] = useState(0);

  const {
    data,
    hasMore,
    loading: categoryLoading,
    error: categoryError,
  } = usePagination(
    `/dept?mobileNo=${params.mobileNo}&page=${pageNumber}`,
    pageNumber
  );

  const observer = useRef();
  const lastBookElementRef = useCallback(
    (node) => {
      if (categoryLoading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPageNumber((prevPageNumber) => prevPageNumber + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [categoryLoading, hasMore]
  );

  useEffect(() => {
    const array = categoryName.concat(data);
    setCategoryName(array);
  }, [data]);

  //TODO: Add the logic for the best selling

  useEffect(() => {
    if (resProductsError) {
      dispatch({
        type: SHOW_SNACKBAR,
        payload: {
          message: "Something went wrong",
          type: "error",
        },
      });
    }
  }, [resProductsError]);

  useEffect(() => {
    if (resProducts) {
      dispatch({
        type: INIT_PRODUCT,
        payload: resProducts.data.result,
      });
    }
  }, [resProducts]);

  useEffect(() => {
    dispatch({
      payload: localStorage.getItem("user"),
      type: SIGN_IN,
    });
    if (localStorage.getItem("user")) {
      const user = decode(localStorage.getItem("user"));
      if (user.mobileNo != params.mobileNo) {
        localStorage.setItem("user", "");
      } else if (user.mobileNo != params.mobileNo) {
        localStorage.setItem("user", "");
      }
    }
  }, []);

  return (
    <div className="overflow-x-hidden bg-gray-100">
      <Header>
        <div className="margin-bottom-0">
          {globalContext.owner ? (
            globalContext.owner.length != 0 && globalContext.owner[0].BANNER ? (
              <Slider {...settings} className="home-slider">
                {globalContext.owner[0].BANNER.map((e) => (
                  <img
                    key={e}
                    src={imageUrl + "/" + e}
                    className="item w-full item"
                  />
                ))}
              </Slider>
            ) : (
              <Slider {...settings} className="home-slider">
                {[
                  "/img/1.jpg",
                  "/img/2.jpg",
                  "/img/3.jpg",
                  "/img/4.jpg",
                  "/img/5.jpg",
                  "/img/6.jpg",
                  "/img/7.jpg",
                  "/img/8.jpg",
                  "/img/9.jpg",
                  "/img/10.jpg",
                ].map((e) => (
                  <div key={e}>
                    <img src={e} className="item w-full" />
                  </div>
                ))}
              </Slider>
            )
          ) : (
            <div className="home-slider">
              <Skeleton variant="rect" width="100%" height="22rem" />
            </div>
          )}
        </div>

        <section className="middle bg-white mt-2 mx-2 rounded-lg shadow">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                <div className="position-relative text-center">
                  <h3 className="ft-bold pt-1">Trending Categories</h3>
                </div>
              </div>
            </div>

            <div className="flex overflow-x-scroll hide-scroll-bar left-0.5">
              {globalContext.category
                ? globalContext.category.length != 0
                  ? globalContext.category.map((e) => (
                      <div
                        className="col-xl-2 col-lg-2 col-md-3 col-sm-6 col-4"
                        key={e.DEPT_CD}
                      >
                        <Link
                          to={`/${params.mobileNo}/shop?type=category&value=${e.DEPT_CD}`}
                        >
                          <div className="cats_side_wrap text-center mx-auto mb-3">
                            <div className="sl_cat_01">
                              <div className="d-inline-flex align-items-center justify-content-center p-2 circle mb-2 border">
                                {e.img ? (
                                  <img
                                    src={imageUrl + "/" + e.img.IMGURL}
                                    className="img-fluid circle"
                                    width="90"
                                    alt={e.dept_name ? e.dept_name : ""}
                                  />
                                ) : (
                                  <img
                                    src="/img/11.jpg"
                                    className="img-fluid circle"
                                    width="90"
                                    alt={e.dept_name ? e.dept_name : ""}
                                  />
                                )}
                              </div>
                            </div>
                            <div className="sl_cat_02">
                              <h6 className="m-0 ft-medium fs-sm capitalize line-break-word-one">
                                {e.dept_name
                                  ? e.dept_name.toString().toLowerCase()
                                  : ""}
                              </h6>
                            </div>
                          </div>
                        </Link>
                      </div>
                    ))
                  : null
                : null}
            </div>
          </div>
        </section>

        <section className="mx-2 bg-white p-0 mt-2 rounded-lg middle">
          <div className="container-fluid shadow">
            <div className="row justify-content-center">
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                <div className="position-relative text-center">
                  <h3 className="ft-bold py-2">Feature Products</h3>
                </div>
              </div>
            </div>
            <div className="row rows-products">
              {!resProducts
                ? new Array(12)
                    .fill()
                    .map((a, i) => (a = i))
                    .sort(() => Math.random() - 0.5)
                    .map((e, index) => (
                      <div
                        key={index}
                        className="col-xl-2 col-lg-3 col-md-3 col-6 mb-2"
                      >
                        <Skeleton
                          variant="rect"
                          animation="wave"
                          style={{
                            height: "10rem",
                            objectFit: "fill",
                          }}
                        />
                        <Skeleton variant="text" />
                        <Skeleton variant="text" />
                      </div>
                    ))
                : globalContext.products.length != 0
                ? globalContext.products.slice(0, 12).map((d) => (
                    <div
                      className="col-xl-2 col-lg-3 col-md-3 col-6 px-2 d-flex align-items-stretch"
                      key={d.ITEM_CD}
                    >
                      <ProductCard product={d} />
                    </div>
                  ))
                : null}
            </div>
          </div>
        </section>

        <div className="mt-3 mx-2">
          <BestSelling />
        </div>
        <div className="mt-3 mx-2">
          {categoryName.length != 0
            ? categoryName.map((ca, index) => {
                if (categoryName.length === index + 1) {
                  return (
                    <div key={ca.DEPT_CD} ref={lastBookElementRef}>
                      <CustomCardCategory
                        dept_cd={ca.DEPT_CD}
                        dept_name={ca.dept_name ? ca.dept_name : ""}
                      />
                    </div>
                  );
                } else {
                  return (
                    <div key={ca.DEPT_CD}>
                      <CustomCardCategory
                        dept_cd={ca.DEPT_CD}
                        dept_name={ca.dept_name ? ca.dept_name : ""}
                      />
                    </div>
                  );
                }
              })
            : null}
          {categoryLoading ? (
            <div className="flex justify-center">
              <CircularProgress />
            </div>
          ) : null}
        </div>
        <Footer />
        <BottomBar />
      </Header>
    </div>
  );
}
