import React, { useState, useContext } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";

//Import for all the icons
import CategoryOutlinedIcon from "@material-ui/icons/CategoryOutlined";
import PersonOutlineOutlinedIcon from "@material-ui/icons/PersonOutlineOutlined";
import LocalShippingOutlinedIcon from "@material-ui/icons/LocalShippingOutlined";
import StoreOutlinedIcon from "@material-ui/icons/StoreOutlined";
import HomeOutlinedIcon from "@material-ui/icons/HomeOutlined";
import TuneIcon from "@material-ui/icons/Tune";
import NotificationsNoneOutlinedIcon from "@material-ui/icons/NotificationsNoneOutlined";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import BarChartIcon from "@material-ui/icons/BarChart";

import { makeStyles } from "@material-ui/core/styles";

import useWindowSize from "../../../LayoutHook";
import GlobalContext from "../../../Context/globalContext";
import { Avatar, Typography } from "@material-ui/core";

const drawerWidth = 250;
const useStyles = makeStyles((theme) => ({
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: "rgba(17, 24, 39, 1)",
  },
  hide: {
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
}));

const OwnerDrawer = ({ mobileOpen, handleDrawerToggle }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [width] = useWindowSize();

  const location = useLocation();

  const params = useParams();

  const { globalContext } = useContext(GlobalContext);

  const handleClick = () => {
    setOpen(!open);
  };

  const handleClick1 = () => {
    setOpen1(!open1);
  };

  const drawer = (
    <div className="text-white px-2">
      <List>
        <ListItem className="flex justify-center">
          <div className="text-center mt-3">
            <Link to={`/${params.mobileNo}/owner`}>
              <Avatar className="bg-blue-800 w-20 h-20 text-3xl mx-auto">
                {globalContext.company
                  ? globalContext.company.length != 0
                    ? globalContext.company[0].COMP_NAME[0]
                    : ""
                  : ""}
              </Avatar>
              <Typography
                variant="body1"
                className="text-white capitalize mt-3"
              >
                {globalContext.company
                  ? globalContext.company.length != 0
                    ? globalContext.company[0].COMP_NAME.toString().toLowerCase()
                    : ""
                  : ""}
              </Typography>
            </Link>
          </div>
        </ListItem>
      </List>
      <List>
        <ListItem className="pb-0">
          <Typography
            variant="h6"
            className="text-indigo-500 uppercase font-bold tracking-wider text-xs"
          >
            Dashboards
          </Typography>
        </ListItem>
        <ListItem className="pt-0">
          <Typography
            variant="h6"
            className="text-gray-500 uppercase font-bold tracking-wider text-[9px]"
          >
            Summary of the Bussiness
          </Typography>
        </ListItem>
        {[
          {
            name: "Dashboard",
            icon: <HomeOutlinedIcon />,
            link: `/${params.mobileNo}/owner`,
          },
        ].map((item) => (
          <Link to={item.link} key={item.name} className="w-full">
            <ListItem
              button
              className={
                location.pathname == item.link
                  ? "text-white bg-white/20 rounded-lg font-medium text-sm"
                  : "text-white/80 font-medium text-sm"
              }
            >
              <ListItemIcon
                className={
                  location.pathname == item.link
                    ? "text-white text-sm min-w-[42px]"
                    : "text-white/80 text-sm min-w-[42px]"
                }
              >
                {item.icon}
              </ListItemIcon>
              <ListItemText
                primary={item.name}
                disableTypography={true}
                className="font-medium tracking-wide"
              />
            </ListItem>
          </Link>
        ))}
      </List>
      <List>
        <ListItem className="pb-0">
          <Typography
            variant="h6"
            className="text-indigo-500 uppercase font-bold tracking-wider text-xs"
          >
            Store Management
          </Typography>
        </ListItem>
        <ListItem className="pt-0">
          <Typography
            variant="h6"
            className="text-gray-500 uppercase font-bold tracking-wider text-[9px]"
          >
            Manage all the store data
          </Typography>
        </ListItem>
        {[
          {
            name: "Orders",
            icon: <StoreOutlinedIcon />,
            link: `/${params.mobileNo}/owner/orders`,
          },
          {
            name: "Products",
            icon: <LocalShippingOutlinedIcon />,
            link: `/${params.mobileNo}/owner/viewproducts`,
          },
          {
            name: "Category",
            icon: <CategoryOutlinedIcon />,
            link: `/${params.mobileNo}/owner/category`,
          },
          {
            name: "Catalog",
            icon: <DescriptionOutlinedIcon />,
            link: `/${params.mobileNo}/owner/catalog`,
          },
          {
            name: "User Settings",
            icon: <PersonOutlineOutlinedIcon />,
            link: `/${params.mobileNo}/owner/usersettings`,
          },
        ].map((item) => {
          return (
            <Link to={item.link} key={item.name} className="w-full">
              <ListItem
                button
                className={
                  location.pathname == item.link
                    ? "text-white bg-white/20 rounded-lg font-medium text-sm"
                    : "text-white/80 font-medium text-sm"
                }
              >
                <ListItemIcon
                  className={
                    location.pathname == item.link
                      ? "text-white min-w-[42px]"
                      : "text-white/80 min-w-[42px]"
                  }
                >
                  {item.icon}
                </ListItemIcon>
                <ListItemText
                  primary={item.name}
                  disableTypography={true}
                  className="font-medium tracking-wide"
                />
              </ListItem>
            </Link>
          );
        })}
      </List>
      <List>
        <ListItem className="pb-0">
          <Typography
            variant="h6"
            className="text-indigo-500 uppercase font-bold tracking-wider text-xs"
          >
            Marketing
          </Typography>
        </ListItem>
        <ListItem className="pt-0">
          <Typography
            variant="h6"
            className="text-gray-500 uppercase font-bold tracking-wider text-[9px]"
          >
            Send Marketing messages to users
          </Typography>
        </ListItem>
        {[
          {
            name: "Notification",
            icon: <NotificationsNoneOutlinedIcon />,
            link: `/${params.mobileNo}/owner/notification`,
          },
        ].map((item) => (
          <Link to={item.link} key={item.name} className="w-full">
            <ListItem
              button
              className={
                location.pathname == item.link
                  ? "text-white bg-white/20 rounded-lg font-semibold text-sm"
                  : "text-white/80 font-semibold text-sm"
              }
            >
              <ListItemIcon
                className={
                  location.pathname == item.link
                    ? "text-white min-w-[42px]"
                    : "text-white/80 min-w-[42px]"
                }
              >
                {item.icon}
              </ListItemIcon>
              <ListItemText
                primary={item.name}
                disableTypography={true}
                className="font-medium tracking-wide"
              />
            </ListItem>
          </Link>
        ))}
      </List>
      <List>
        <ListItem className="pb-0">
          <Typography
            variant="h6"
            className="text-indigo-500 uppercase font-bold tracking-wider text-xs"
          >
            Reporting
          </Typography>
        </ListItem>
        <ListItem className="pt-0">
          <Typography
            variant="h6"
            className="text-gray-500 uppercase font-bold tracking-wider text-[9px]"
          >
            Reports of the Bussiness
          </Typography>
        </ListItem>
        {[
          {
            name: "Orders Report",
            icon: <BarChartIcon />,
            link: `/${params.mobileNo}/owner/report/orders`,
          },
          {
            name: "Party Report",
            icon: <BarChartIcon />,
            link: `/${params.mobileNo}/owner/report/partywise`,
          },
          {
            name: "Category Report",
            icon: <BarChartIcon />,
            link: `/${params.mobileNo}/owner/report/categorywise`,
          },
          {
            name: "Status Report",
            icon: <BarChartIcon />,
            link: `/${params.mobileNo}/owner/report/statuswise`,
          },
        ].map((item) => (
          <Link to={item.link} key={item.name} className="w-full">
            <ListItem
              button
              className={
                location.pathname == item.link
                  ? "text-white bg-white/20 rounded-lg font-semibold text-sm"
                  : "text-white/80 font-semibold text-sm"
              }
            >
              <ListItemIcon
                className={
                  location.pathname == item.link
                    ? "text-white min-w-[42px]"
                    : "text-white/80 min-w-[42px]"
                }
              >
                {item.icon}
              </ListItemIcon>
              <ListItemText
                primary={item.name}
                disableTypography={true}
                className="font-medium tracking-wide"
              />
            </ListItem>
          </Link>
        ))}
      </List>
      <List>
        <ListItem className="pb-0">
          <Typography
            variant="h6"
            className="text-indigo-500 uppercase font-bold tracking-wider text-xs"
          >
            Settings
          </Typography>
        </ListItem>
        <ListItem className="pt-0">
          <Typography
            variant="h6"
            className="text-gray-500 uppercase font-bold tracking-wider text-[9px]"
          >
            Manage the settings of the store
          </Typography>
        </ListItem>
        {[
          {
            name: "Utilities",
            icon: <TuneIcon />,
            link: `/${params.mobileNo}/owner/utility`,
          },
        ].map((item) => (
          <Link to={item.link} key={item.name} className="w-full">
            <ListItem
              button
              className={
                location.pathname == item.link
                  ? "text-white bg-white/20 rounded-lg font-semibold text-sm"
                  : "text-white/80 font-semibold text-sm"
              }
            >
              <ListItemIcon
                className={
                  location.pathname == item.link
                    ? "text-white min-w-[42px]"
                    : "text-white/80 min-w-[42px]"
                }
              >
                {item.icon}
              </ListItemIcon>
              <ListItemText
                primary={item.name}
                disableTypography={true}
                className="font-medium tracking-wide"
              />
            </ListItem>
          </Link>
        ))}
      </List>
    </div>
  );
  return (
    <nav className={classes.drawer}>
      <Hidden implementation="css">
        <Drawer
          classes={{
            paper: classes.drawerPaper,
          }}
          open={mobileOpen}
          variant="persistent"
        >
          {drawer}
        </Drawer>
      </Hidden>
      {width < 600 ? (
        <Hidden implementation="css">
          <Drawer
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            className={classes.hide}
            ModalProps={{
              keepMounted: true,
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
      ) : null}
    </nav>
  );
};

export default OwnerDrawer;
