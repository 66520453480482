import React, { useEffect, useState, useContext } from "react";

import Typography from "@material-ui/core/Typography";
import Input from "@material-ui/core/Input";

import CircularProgress from "@material-ui/core/CircularProgress";
import {
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
  TextField,
} from "@material-ui/core";
import OwnerHeader from "./Components/OwnerHeader";
import { Link, useHistory, useParams } from "react-router-dom";
import GlobalContext from "../../Context/globalContext";

import { imageUrl, Instance } from "../../Config";
import useSWR from "swr";
import {
  INIT_CATEGORY,
  SHOW_SNACKBAR,
  UPDATE_CATEGORY,
} from "../../Context/globalActions.types";
import { resizeImg } from "../../utils/resizeImg";
import SearchIcon from "@material-ui/icons/Search";
import StorefrontIcon from "@material-ui/icons/Storefront";
import { Autocomplete, Skeleton } from "@material-ui/lab";

import jsPDF from "jspdf";
import "jspdf-autotable";
import { CSVLink } from "react-csv";
import SaveAltIcon from "@material-ui/icons/SaveAlt";
import InsertDriveFileOutlinedIcon from "@material-ui/icons/InsertDriveFileOutlined";

const OwnerCategory = () => {
  const [search, setSearch] = useState("");

  const { globalContext, dispatch } = useContext(GlobalContext);

  const [rows, setRows] = useState([]);
  const params = useParams();
  const history = useHistory();

  const [updatePriority, setUpdatePriority] = useState({
    deptCd: "",
    priority: "",
    isLoading: false,
  });
  const [open1, setOpen1] = useState(false);
  const handleClickOpen1 = () => {
    setOpen1(true);
  };

  const handleClose1 = () => {
    setOpen1(false);
  };

  const [imgDetail, setImgDetail] = useState({
    oldImgUrl: "",
    code: "",
    imgId: "",
  });

  const handleSearch = (search) => {
    if (search != "") {
      const searchRes = rows.filter(
        (e) =>
          e.dept_name.toLowerCase().includes(search.toString().toLowerCase()) ||
          e.DEPT_CD.toLowerCase().includes(search.toString().toLowerCase())
      );
      dispatch({
        type: INIT_CATEGORY,
        payload: searchRes,
      });
    } else {
      dispatch({
        type: INIT_CATEGORY,
        payload: rows,
      });
    }
  };
  // type=category&value=${selectedCategory}

  const { data: resCategoryData, error: resCategoryDataError } = useSWR(
    `/dept?mobileNo=${params.mobileNo}`
  );

  const [exportData, setExportData] = useState([]);
  const [excelExport, setExcelExport] = useState([]);
  const headers = [
    { label: "Dept Cd", key: "DEPT_CD" },
    { label: "Dept Name", key: "dept_name" },
    { label: "Image Uploaded", key: "img" },
  ];

  const generatePdfRows = () => {
    return new Promise((resolve, reject) => {
      let tempArry = [];

      if (resCategoryData) {
        for (let i = 0; i < resCategoryData.data.result.length; i++) {
          tempArry.push([
            resCategoryData.data.result[i].DEPT_CD,
            resCategoryData.data.result[i].dept_name,
            resCategoryData.data.result[i].img ? "Y" : "N",
          ]);
        }
      }
      resolve(tempArry);
    });
  };

  const generateExcelRows = () => {
    return new Promise((resolve, reject) => {
      let tempArry = [];

      if (resCategoryData) {
        for (let i = 0; i < resCategoryData.data.result.length; i++) {
          tempArry.push({
            DEPT_CD: resCategoryData.data.result[i].DEPT_CD,
            dept_name: resCategoryData.data.result[i].dept_name,
            img: resCategoryData.data.result[i].img ? "Y" : "N",
          });
        }
      }
      resolve(tempArry);
    });
  };

  const exportPdf = (data) => {
    const unit = "pt";
    const size = "A4";
    const orientation = "potrait";

    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    const headers = [["Dept Cd", "Dept Name", "Image Uploaded"]];

    let content = {
      startY: 90,
      head: headers,
      body: data,
    };

    let width1 = doc.internal.pageSize.getWidth();

    doc.setFontSize(14).setFont(undefined, "bold");
    doc
      .text(globalContext.company[0].COMP_NAME, width1 / 2, 30, {
        align: "center",
      })
      .setFontSize(8)
      .setFont(undefined, "bold");
    doc.text(globalContext.company[0].ADDRS, width1 / 2, 50, {
      align: "center",
    });
    doc.setFontSize(14).setFont(undefined, "bold");
    doc.text("Category List", width1 / 2, 80, {
      align: "center",
    });
    doc.setFontSize(10).setFont(undefined, "bold");
    doc.autoTable(content);
    doc.text(
      "Generated By Hub360",
      width1 / 2,
      doc.previousAutoTable.finalY + 30,
      {
        align: "center",
      }
    );
    doc.save("CategoryList.pdf");
  };

  const [updateData, setUpdateData] = useState({
    deptCd: "",
    catalog: "",
  });

  const updateCatalog = () => {
    setUpdatePriority({
      ...updatePriority,
      isLoading: true,
    });
    return new Promise((resolve, reject) => {
      Instance.put("/products/catalog", {
        deptCd: updateData.deptCd,
        catalog: updateData.catalog,
        mobileNo: params.mobileNo,
      })
        .then(() => {
          resolve();
          setUpdateData({
            deptCd: "",
            catalog: "",
          });
        })
        .catch((e) => {
          reject(e);
        });
    });
  };

  const handleUpdatePriority = () => {
    updateCatalog()
      .then(() => {
        Instance.put("/dept/priority", {
          deptCd: updatePriority.deptCd,
          priority: updatePriority.priority,
          mobileNo: params.mobileNo,
        })
          .then(() => {
            handleClose1();
            setUpdatePriority({
              isLoading: false,
              deptCd: "",
              priority: "",
            });
          })
          .catch((e) => {
            handleClose1();
            console.log(e);
          });
      })
      .catch((e) => {
        handleClose1();
        console.log(e);
      });
  };

  useEffect(() => {
    if (resCategoryData) {
      setRows(resCategoryData.data.result);
      dispatch({
        type: INIT_CATEGORY,
        payload: resCategoryData.data.result,
      });
      generateExcelRows().then((excel) => {
        setExcelExport(excel);
      });
    }
  }, [resCategoryData]);

  useEffect(() => {
    if (resCategoryDataError) {
      dispatch({
        type: SHOW_SNACKBAR,
        payload: {
          message: "Something went wrong",
          type: "error",
        },
      });
    }
  }, [resCategoryDataError]);

  return (
    <div>
      <OwnerHeader>
        <EditForm
          handleClose={handleClose1}
          open={open1}
          updatePriority={updatePriority}
          setUpdatePriority={setUpdatePriority}
          isLoading={updatePriority.isLoading}
          saveData={handleUpdatePriority}
          updateData={updateData}
          setUpdateData={setUpdateData}
        />
        <div className="md:flex items-center justify-between px-3 md:px-4 py-3">
          <div>
            <div className="text-indigo-500 font-semibold text-sm md:text-base">
              Store Management
            </div>
            <div className="text-xl md:text-2xl font-extrabold leading-7 sm:leading-10 truncate mt-1">
              Category
            </div>
          </div>
          <div className="flex items-center mb-3 mt-2 md:mt-0">
            <Input
              placeholder="Search"
              className="bg-white border-[1px] border-[#3333cc] px-2 py-1 rounded-lg"
              disableUnderline
              value={search}
              onChange={(e) => {
                if (e.target.value == "") {
                  setSearch("");
                } else {
                  setSearch(e.target.value);
                }
              }}
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              }
            />
            <Button
              variant="contained"
              disableElevation
              className="ml-2 bg-[#3333cc] text-white"
              onClick={() => {
                history.push(`/${params.mobileNo}/owner/category?type=search`);
                handleSearch(search);
              }}
            >
              Search
            </Button>
          </div>
        </div>
        <Container maxWidth="xl" className="pt-2 bg-[#F1F5F9] min-h-screen">
          <div className="mb-2 grid justify-end">
            {!resCategoryData ? (
              <CircularProgress size={22} />
            ) : (
              <div>
                <Button
                  variant="outlined"
                  className="text-blue-600 border-[#3333cc] rounded-full mt-2 md:mt-0"
                  startIcon={<SaveAltIcon />}
                >
                  <CSVLink
                    data={excelExport}
                    headers={headers}
                    filename="CategoryList.csv"
                    className="text-blue-600"
                  >
                    Excel
                  </CSVLink>
                </Button>
                <Button
                  variant="outlined"
                  className="text-blue-600 border-[#3333cc] rounded-full mt-2 md:mt-0 ml-2"
                  startIcon={<InsertDriveFileOutlinedIcon />}
                  onClick={() => {
                    generatePdfRows().then((pdfData) => {
                      exportPdf(pdfData);
                    });
                  }}
                >
                  PDF
                </Button>
              </div>
            )}
          </div>
          {!resCategoryData && globalContext.category == 0 ? (
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-5">
              {["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L"].map(
                (item) => (
                  <div key={item}>
                    <Skeleton
                      animation="wave"
                      variant="rect"
                      width={350}
                      height={118}
                    />
                  </div>
                )
              )}
            </div>
          ) : globalContext.category.length == 0 ? (
            <div className="min-h-screen text-center">
              <StorefrontIcon className="text-black text-[12rem]" />
              <Typography className="text-2xl font-bold text-black">
                Oops!
              </Typography>
              <Typography>No Data Found.</Typography>
            </div>
          ) : (
            <>
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-5 w-full pb-6">
                {globalContext.category.map((item) => (
                  <CustomCard
                    item={item}
                    params={params}
                    imgDetail={imgDetail}
                    setImgDetail={setImgDetail}
                    handleClickOpen1={handleClickOpen1}
                    setUpdatePriority={setUpdatePriority}
                    updatePriority={updatePriority}
                    setUpdateData={setUpdateData}
                  />
                ))}
              </div>
            </>
          )}
        </Container>
      </OwnerHeader>
    </div>
  );
};

export default OwnerCategory;

const CustomCard = ({
  item,
  params,
  handleClickOpen1,
  updatePriority,
  setUpdatePriority,
  setUpdateData,
}) => {
  const { dispatch } = useContext(GlobalContext);
  const [dragging, setDragging] = React.useState(false);
  const [isUploading, setIsUploading] = useState(false);

  const drop = React.useRef(null);
  const drag = React.useRef(null);

  React.useEffect(() => {
    drop.current.addEventListener("dragover", handleDragOver);
    drop.current.addEventListener("drop", handleDrop);
    drop.current.addEventListener("dragenter", handleDragEnter);
    drop.current.addEventListener("dragleave", handleDragLeave);

    // return () => {
    //   if (drop) {
    //     drop.current.removeEventListener("dragover", handleDragOver);
    //     drop.current.removeEventListener("drop", handleDrop);
    //   }
    // };
  }, []);

  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (e.target !== drag.current) {
      setDragging(true);
    }
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (e.target === drag.current) {
      setDragging(false);
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const uploadImage = async (newImgUrl, imgDetail) => {
    if (typeof imgDetail.oldImgUrl != "undefined") {
      Instance.delete(`/uploads/${imgDetail.oldImgUrl}`);
      const formData = new FormData();
      const img = await resizeImg(newImgUrl, newImgUrl.type.split("/")[1]);
      formData.append("productImage", img);
      Instance.post("/uploads", formData)
        .then((res) => {
          if (res.status == 200) {
            Instance.put("/products/image", {
              imgid: imgDetail.imgId,
              code: imgDetail.code,
              imgUrl: res.data.result,
              mobileNo: params.mobileNo,
            })
              .then((resi) => {
                if (resi.status == 200) {
                  dispatch({
                    type: UPDATE_CATEGORY,
                    payload: {
                      code: imgDetail.code,
                      IMGURL: res.data.result,
                    },
                  });
                  setIsUploading(false);
                }
              })
              .catch((e) => {
                console.log(e);
                dispatch({
                  type: SHOW_SNACKBAR,
                  payload: {
                    message: "Something went wrong",
                    type: "error",
                  },
                });
              });
          }
        })
        .catch(() => {
          dispatch({
            type: SHOW_SNACKBAR,
            payload: {
              message: "Something went wrong",
              type: "error",
            },
          });
        });
    } else {
      const formData = new FormData();
      const img = await resizeImg(newImgUrl, newImgUrl.type.split("/")[1]);
      formData.append("productImage", img);
      Instance.post("/uploads", formData)
        .then((res) => {
          if (res.status == 200) {
            Instance.post("/products/image", {
              code: imgDetail.code,
              imgUrl: res.data.result,
              mobileNo: params.mobileNo,
            })
              .then((resi) => {
                if (resi.status == 200) {
                  dispatch({
                    type: UPDATE_CATEGORY,
                    payload: {
                      code: imgDetail.code,
                      IMGURL: res.data.result,
                      imgid: resi.data.result.imgid,
                    },
                  });
                  setIsUploading(false);
                }
              })
              .catch((e) => {
                console.log(e);
                dispatch({
                  type: SHOW_SNACKBAR,
                  payload: {
                    message: "Something went wrong",
                    type: "error",
                  },
                });
              });
          }
        })
        .catch(() => {
          dispatch({
            type: SHOW_SNACKBAR,
            payload: {
              message: "Something went wrong",
              type: "error",
            },
          });
        });
    }
  };

  const setValue = () => {
    return new Promise((resolve) => {
      if (item.img) {
        resolve({
          oldImgUrl: item.img.IMGURL[0],
          imgId: item.img.imgid,
          code: item.DEPT_CD,
        });
      } else {
        resolve({
          oldImgUrl: undefined,
          code: item.DEPT_CD,
        });
      }
    });
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);

    const { files } = e.dataTransfer;

    if (files.length > 1) {
      dispatch({
        type: SHOW_SNACKBAR,
        payload: {
          message: `Only 1 Images are allowed for Category ${item.DEPT_CD}`,
          type: "error",
        },
      });
      return;
    }

    if (files && files.length) {
      setIsUploading(true);
      setValue().then((imgDetail) => {
        uploadImage(files[0], imgDetail);
      });
    }
  };

  return (
    <div
      ref={drop}
      className=" bg-white rounded-md  FilesDragAndDrop border-[.5px] border-[#3333cc] "
    >
      {/* {dragging && (
        <div ref={drag} className="FilesDragAndDrop__placeholder"></div>
      )} */}
      {isUploading && (
        <div className="FilesDragAndDrop__placeholder">
          <CircularProgress size={30} />
        </div>
      )}
      <Link
        to={`/${params.mobileNo}/owner/viewproducts?type=category&value=${item.DEPT_CD}`}
      >
        <div className="flex flex-row">
          {item.img ? (
            <img
              className="object-cover h-24 md:h-28 rounded-t-lg w-20 md:w-24 md:rounded-none md:rounded-l-lg p-1"
              src={imageUrl + "/" + item.img.IMGURL[0]}
              alt={item.dept_name}
            />
          ) : (
            <img
              src="/img/11.jpg"
              className="object-cover h-24 md:h-28 rounded-t-lg w-20 md:w-24 md:rounded-none md:rounded-l-lg p-1"
              alt={item.dept_name}
            />
          )}
          <div className="flex flex-col justify-between px-2 pt-3 w-full">
            <div>
              <Typography
                variant="body1"
                className="mb-1 text-sm md:text-base font-bold text-gray-900 md:leading-4 leading-none "
              >
                <div className="flex items-start justify-between">
                  <div>{item.dept_name}</div>
                </div>
              </Typography>
              <Typography
                variant="body2"
                className="text-xs font-normal text-gray-700 mt-1"
              >
                {item.DEPT_CD}
              </Typography>
            </div>
            <div className="grid justify-end w-full">
              <Button
                variant="outlined"
                disableElevation
                className="border-[#3333cc] mb-2"
                onClick={(e) => {
                  e.preventDefault();
                  handleClickOpen1();
                  setUpdatePriority({
                    ...updatePriority,
                    deptCd: item.DEPT_CD,
                    priority: item.PRIORITY,
                  });
                  setUpdateData({
                    deptCd: "",
                    catalog: "",
                  });
                }}
              >
                Edit
              </Button>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};

const EditForm = ({
  open,
  handleClose,
  isLoading,
  saveData,
  setUpdatePriority,
  updatePriority,
  updateData,
  setUpdateData,
}) => {
  const params = useParams();

  const { data: resCatalog, error } = useSWR(
    `/catalog?mobileNo=${params.mobileNo}`
  );

  return (
    <div>
      <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth="sm">
        <DialogTitle>Edit Category</DialogTitle>
        <DialogContent>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
            <div>
              <div className="font-bold">Priority:</div>
              <Input
                className="bg-white border-[1px] border-[#3333cc] px-2 py-1 rounded-lg"
                disableUnderline
                value={updatePriority.priority}
                onChange={(e) => {
                  setUpdatePriority({
                    ...updatePriority,
                    priority: e.target.value,
                  });
                }}
                type="number"
              />
            </div>
            <div>
              <div className="font-bold">Catalog:</div>
              <Autocomplete
                id="combo-box-demo"
                options={resCatalog ? resCatalog.data.result : []}
                getOptionLabel={(option) => option.TITLE}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setUpdateData({
                      ...updateData,
                      deptCd: updatePriority.deptCd,
                      catalog: newValue.caId,
                    });
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    className="bg-white"
                    variant="outlined"
                  />
                )}
              />
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          {!isLoading ? (
            <>
              <Button onClick={handleClose} variant="text">
                Close
              </Button>
              <Button onClick={saveData} variant="text">
                Save
              </Button>
            </>
          ) : (
            <CircularProgress size={20} className="mr-3 mb-2" />
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
};
