import React, { useEffect, useState, useContext } from "react";

import { Container, Button } from "@material-ui/core";
import OwnerHeader from "../Components/OwnerHeader";
import { useParams, useLocation, useHistory, Link } from "react-router-dom";
import GlobalContext from "../../../Context/globalContext";
import ProductDetails from "../../../Components/ProductDetails";
import { Instance } from "../../../Config";

const OwnerEditProduct = () => {
  const [data, setData] = useState({});
  const params = useParams();
  const location = useLocation();
  const history = useHistory();

  const { globalContext } = useContext(GlobalContext);

  const itemCd = location.search.split("=")[1];

  const fetchDetailData = () => {
    const element = globalContext.products.filter(
      (product) =>
        product.ITEM_CD == itemCd && product.MOBNOCO == params.mobileNo
    );
    if (element.length !== 0) {
      setData(element[0]);
    } else {
      Instance.get(
        `/products/itemsById/${itemCd}?mobileNo=${params.mobileNo}`
      ).then((res) => {
        setData(res.data.result[0]);
      });
      //TODO: Handle when prod
      // history.push(`/${params.mobileNo}/owner/viewproducts`);
    }
  };

  useEffect(() => {
    fetchDetailData();
  }, []);

  useEffect(() => {
    fetchDetailData();
  }, [itemCd]);

  return (
    <div>
      <OwnerHeader>
        <ProductDetails product={data} type="preview" />
      </OwnerHeader>
    </div>
  );
};

export default OwnerEditProduct;
