import React, { useContext, useState, useEffect } from "react";
import ProductCard from "../ProductCard";
import GlobalContext from "../../Context/globalContext";
import useSWR from "swr";
import { LOAD_PRODUCT } from "../../Context/globalActions.types";
import { useParams, Link } from "react-router-dom";
import { decode } from "jsonwebtoken";
import { Skeleton } from "@material-ui/lab";

const BestSelling = () => {
  const [data, setData] = useState([]);

  const params = useParams();

  const { globalContext, dispatch } = useContext(GlobalContext);

  const { data: resProduct, error: resProductError } = useSWR(
    globalContext.user != ""
      ? `/products?mobileNo=${
          params.mobileNo
        }&per_page=${12}&page=${1}&groupCd=${
          decode(globalContext.user).groupCd
        }&partyCd=${decode(globalContext.user).cd}`
      : ""
  );

  useEffect(() => {
    if (resProduct) {
      dispatch({
        type: LOAD_PRODUCT,
        payload: resProduct.data.result,
      });
      setData(resProduct.data.result);
    }
  }, [resProduct]);

  return (
    <section className="pt-0 pb-0 bg-white rounded-lg">
      <div className="container-fluid pt-3 shadow">
        <div className="row justify-content-center">
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
            <div className="position-relative text-center">
              <h3 className="ft-bold pt-1">
                {globalContext.settings.length != 0
                  ? globalContext.settings.filter(
                      (e) => e.VARIABLE == "customOrderLabel"
                    )[0].VALUE == "Y"
                    ? "Most Liked Items"
                    : "Best Selling"
                  : ""}
              </h3>
            </div>
          </div>
        </div>
        <div className="row rows-products">
          {!resProduct
            ? new Array(12)
                .fill()
                .map((a, i) => (a = i))
                .sort(() => Math.random() - 0.5)
                .map((e, index) => (
                  <div
                    key={index}
                    className="col-xl-2 col-lg-3 col-md-3 col-6 mb-2"
                  >
                    <Skeleton
                      variant="rect"
                      animation="wave"
                      style={{
                        height: "10rem",
                        objectFit: "fill",
                      }}
                    />
                    <Skeleton variant="text" />
                    <Skeleton variant="text" />
                  </div>
                ))
            : data.length != 0
            ? data.map((d) => (
                <div
                  className="col-xl-2 col-lg-3 col-md-3 col-6 px-2 d-flex align-items-stretch"
                  key={d.ITEM_CD}
                >
                  <ProductCard product={d} />
                </div>
              ))
            : null}
        </div>
      </div>
    </section>
  );
};

export default BestSelling;
