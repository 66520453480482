import React, { useState, useContext, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { imageUrl } from "../Config";
import { decode } from "jsonwebtoken";
import GlobalContext from "../Context/globalContext";

const ProductCard = (props) => {
  const params = useParams();

  // const [disPrice, setDisPrice] = useState(null);
  // const [price, setPrice] = useState(null);
  const { globalContext } = useContext(GlobalContext);
  const [isValid, setIsValid] = useState(false);

  // const partyRate = (variant) => {
  //   if (globalContext.settings.length != 0) {
  //     if (
  //       globalContext.settings.filter(
  //         (e) => e.SETTING_NAME == "PARTY_WISE_RATE"
  //       )[0].VALUE == "Y"
  //     ) {
  //       const discount = variant.SALE_DISC / 100;
  //       const discountAmt = discount * variant.SALE_RATE1;
  //       setPrice(variant.SALE_RATE1);
  //       setDisPrice(Math.round(variant.SALE_RATE1 - discountAmt));
  //     } else {
  //       const grade = decode(globalContext.user).partyGd;
  //       const discount = variant.SALE_DISC / 100;
  //       if (grade == "A") {
  //         const discountAmt = discount * variant.SALE_RATE1;
  //         setPrice(variant.SALE_RATE1);
  //         setDisPrice(Math.round(variant.SALE_RATE1 - discountAmt));
  //       } else if (grade == "B") {
  //         const discountAmt = discount * variant.SALE_RATE2;
  //         setPrice(variant.SALE_RATE2);
  //         setDisPrice(Math.round(variant.SALE_RATE2 - discountAmt));
  //       } else if (grade == "C") {
  //         const discountAmt = discount * variant.SALE_RATE3;
  //         setPrice(variant.SALE_RATE2);
  //         setDisPrice(Math.round(variant.SALE_RATE3 - discountAmt));
  //       } else {
  //         setDisPrice(0);
  //         setPrice(0);
  //       }
  //     }
  //   }
  // };

  // useEffect(() => {
  //   if (props.product) {
  //     partyRate(props.product.variant[0]);
  //   }
  // }, []);

  useEffect(() => {
    if (globalContext.settings.length != 0) {
      setIsValid(true);
    }
  }, [globalContext.settings]);

  if (!globalContext.user) return <div></div>;

  if (props.product) {
    const { ITEM_CD, ITEM_NAME, img, variant } = props.product;

    return (
      <div className="product_grid card border-[0.5px] border-gray-200 shadow-md md:shadow-none ">
        {globalContext.settings.length != 0 ? (
          globalContext.settings.filter(
            (e) => e.VARIABLE == "showOnlyProducts"
          )[0].VALUE == "N" ? (
            decode(globalContext.user).groupCd == "88" ? (
              globalContext.settings.filter((e) => e.VARIABLE == "guestRate")[0]
                .VALUE == "Y" ? (
                globalContext.settings.length != 0 ? (
                  globalContext.settings.filter(
                    (e) => e.VARIABLE == "itemDisc"
                  )[0].VALUE == "Y" ? (
                    variant[0].SALE_DISC != "0" ? (
                      <React.Fragment>
                        <div
                          className="badge position-absolute ft-regular ab-left text-upper"
                          style={{
                            backgroundColor: globalContext.owner[0]
                              ? globalContext.owner[0].COLOR?.secondaryColor
                              : "",
                            color: globalContext.owner[0]
                              ? globalContext.owner[0].COLOR?.secondaryColorText
                              : "",
                          }}
                        >
                          Sale
                        </div>
                        <div
                          className="badge position-absolute ft-regular ab-right text-upper"
                          style={{
                            backgroundColor: globalContext.owner[0]
                              ? globalContext.owner[0].COLOR?.primaryColor
                              : "",
                            color: globalContext.owner[0]
                              ? globalContext.owner[0].COLOR?.primaryColorText
                              : "",
                          }}
                        >
                          {variant[0].SALE_DISC + "%"}
                        </div>
                      </React.Fragment>
                    ) : null
                  ) : null
                ) : null
              ) : null
            ) : variant[0].SALE_DISC != "0" ? (
              <React.Fragment>
                <div
                  className="badge position-absolute ft-regular ab-left text-upper"
                  style={{
                    backgroundColor: globalContext.owner[0]
                      ? globalContext.owner[0].COLOR?.secondaryColor
                      : "",
                    color: globalContext.owner[0]
                      ? globalContext.owner[0].COLOR?.secondaryColorText
                      : "",
                  }}
                >
                  Sale
                </div>
                <div
                  className="badge position-absolute ft-regular ab-right text-upper"
                  style={{
                    backgroundColor: globalContext.owner[0]
                      ? globalContext.owner[0].COLOR?.primaryColor
                      : "",
                    color: globalContext.owner[0]
                      ? globalContext.owner[0].COLOR?.primaryColorText
                      : "",
                  }}
                >
                  {variant[0].SALE_DISC + "%"}
                </div>
              </React.Fragment>
            ) : null
          ) : null
        ) : null}

        <div className="card-body p-0">
          <div className="shop_thumb position-relative">
            <Link
              className="card-img-top d-block overflow-hidden"
              to={
                props.link
                  ? props.link
                  : `/${params.mobileNo}/product/${ITEM_CD}`
              }
            >
              {img ? (
                <img
                  className={
                    props.type == "similar"
                      ? "card-img-top w-screen h-[300px] md:w-[268px] md:h-[268px] object-contain hover:scale-110 transition ease-in-out"
                      : "card-img-top w-screen h-[200px] md:w-[268px] md:h-[268px] object-contain hover:scale-110 transition ease-in-out"
                  }
                  src={imageUrl + "/" + img.IMGURL[0]}
                  alt="..."
                />
              ) : (
                <img
                  className={
                    props.type == "similar"
                      ? "card-img-top w-screen h-[300px] md:w-[268px] md:h-[268px] object-contain hover:scale-110 transition ease-in-out"
                      : "card-img-top w-screen h-[200px] md:w-[268px] md:h-[268px] object-contain hover:scale-110 transition ease-in-out"
                  }
                  src="/img/11.jpg"
                  alt="Image Not Found"
                />
              )}
            </Link>
            <div className="product-left-hover-overlay">
              <ul className="left-over-buttons">
                {/* <li>
                  <a
                    className="d-inline-flex circle align-items-center justify-content-center"
                    data-toggle="modal"
                    data-target={`#${ITEM_CD}`}
                  >
                    <i className="fas fa-expand-arrows-alt position-absolute"></i>
                  </a>
                </li> */}
                {/* <li>
                  <a className="d-inline-flex circle align-items-center justify-content-center snackbar-wishlist">
                    <i className="far fa-heart position-absolute"></i>
                  </a>
                </li>
                <li>
                  <a className="d-inline-flex circle align-items-center justify-content-center snackbar-addcart">
                    <i className="fas fa-shopping-basket position-absolute"></i>
                  </a>
                </li> */}
              </ul>
            </div>
          </div>
        </div>
        <div className="card-footer b-0 p-1 pt-2 bg-white d-flex align-items-start justify-content-between">
          <div className="text-left w-[200px] md:w-[268px]">
            <div className="text-left">
              <h5 className="fs-md lh-1 mb-1 capitalize line-break-word">
                <Link
                  to={
                    props.link
                      ? props.link
                      : `/${params.mobileNo}/product/${ITEM_CD}`
                  }
                >
                  {ITEM_NAME.toString().toLowerCase()}
                </Link>
              </h5>
              {/* if (
        globalContext.settings.filter((e) => e.VARIABLE == "showStk")[0]
          .VALUE == "Y"
      ) {
        if (
          globalContext.settings.filter((e) => e.VARIABLE == "prtyStk")[0]
            .VALUE == "Y"
        ) {
          if (globalContext.accountsSettings.length != 0) {
            if (
              globalContext.accountsSettings.filter(
                (e) => e.VARIABLE == "accShowStk"
              )
            ) {
              setStock(variant.CL_STK);
            }
          }
        } else {
          setStock(variant.CL_STK);
        }
      } */}
              {globalContext.settings.length != 0 ? (
                globalContext.settings.filter((e) => e.VARIABLE == "showStk")[0]
                  .VALUE == "Y" ? (
                  globalContext.settings.filter(
                    (e) => e.VARIABLE == "prtyStk"
                  )[0].VALUE == "Y" ? (
                    globalContext.accountsSettings.length != 0 &&
                    globalContext.accountsSettings.filter(
                      (e) => e.VARIABLE == "accShowStk"
                    ) ? (
                      <div className="text-xs">
                        <span>
                          {Math.round(
                            variant.reduce((accumulator, object) => {
                              return accumulator + object.CL_STK;
                            }, 0)
                          )}
                        </span>{" "}
                        Item Available
                      </div>
                    ) : null
                  ) : (
                    <div className="text-xs">
                      <span>
                        {Math.round(
                          variant.reduce((accumulator, object) => {
                            return accumulator + object.CL_STK;
                          }, 0)
                        )}
                      </span>{" "}
                      Item Available
                    </div>
                  )
                ) : null
              ) : null}

              {/* {globalContext.settings.length != 0 ? (
                decode(globalContext.user).groupCd == "88" ? (
                  globalContext.settings.filter(
                    (e) => e.VARIABLE == "guestRate"
                  )[0].VALUE == "Y" ? (
                    <div className="elis_rty">
                      <span className="ft-bold text-dark fs-sm">
                        ₹
                        {globalContext.settings.length != 0
                          ? globalContext.settings.filter(
                              (e) => e.VARIABLE == "itemDisc"
                            )[0].VALUE == "Y"
                            ? decode(globalContext.user).partyGd == "A"
                              ? Math.round(
                                  variant[0].SALE_RATE1 -
                                    (variant[0].SALE_DISC / 100) *
                                      variant[0].SALE_RATE1
                                )
                              : decode(globalContext.user).partyGd == "B"
                              ? Math.round(
                                  variant[0].SALE_RATE2 -
                                    (variant[0].SALE_DISC / 100) *
                                      variant[0].SALE_RATE2
                                )
                              : decode(globalContext.user).partyGd == "C"
                              ? Math.round(
                                  variant[0].SALE_RATE3 -
                                    (variant[0].SALE_DISC / 100) *
                                      variant[0].SALE_RATE3
                                )
                              : 0
                            : decode(globalContext.user).partyGd == "A"
                            ? Math.round(variant[0].SALE_RATE1)
                            : decode(globalContext.user).partyGd == "B"
                            ? Math.round(variant[0].SALE_RATE2)
                            : decode(globalContext.user).partyGd == "C"
                            ? Math.round(variant[0].SALE_RATE3)
                            : 0
                          : null}
                      </span>
                    </div>
                  ) : null
                ) : (
                  <div className="elis_rty">
                    <span className="ft-bold text-dark fs-sm">
                      ₹
                      {globalContext.settings.length != 0
                        ? globalContext.settings.filter(
                            (e) => e.VARIABLE == "itemDisc"
                          )[0].VALUE == "Y"
                          ? decode(globalContext.user).partyGd == "A"
                            ? Math.round(
                                variant[0].SALE_RATE1 -
                                  (variant[0].SALE_DISC / 100) *
                                    variant[0].SALE_RATE1
                              )
                            : decode(globalContext.user).partyGd == "B"
                            ? Math.round(
                                variant[0].SALE_RATE2 -
                                  (variant[0].SALE_DISC / 100) *
                                    variant[0].SALE_RATE2
                              )
                            : decode(globalContext.user).partyGd == "C"
                            ? Math.round(
                                variant[0].SALE_RATE3 -
                                  (variant[0].SALE_DISC / 100) *
                                    variant[0].SALE_RATE3
                              )
                            : 0
                          : decode(globalContext.user).partyGd == "A"
                          ? Math.round(variant[0].SALE_RATE1)
                          : decode(globalContext.user).partyGd == "B"
                          ? Math.round(variant[0].SALE_RATE2)
                          : decode(globalContext.user).partyGd == "C"
                          ? Math.round(variant[0].SALE_RATE3)
                          : 0
                        : null}
                    </span>
                  </div>
                )
              ) : null} */}
              {globalContext.settings.length != 0 ? (
                globalContext.settings.filter(
                  (e) => e.VARIABLE == "showOnlyProducts"
                )[0].VALUE == "N" ? (
                  decode(globalContext.user).groupCd == "88" ? (
                    globalContext.settings.filter(
                      (e) => e.VARIABLE == "guestRate"
                    )[0].VALUE == "Y" ? (
                      <div className="elis_rty">
                        {globalContext.settings.length != 0 ? (
                          globalContext.settings.filter(
                            (e) => e.VARIABLE == "itemDisc"
                          )[0].VALUE == "Y" ? (
                            <div>
                              <span
                                className="ft-medium fs-md ft-bold"
                                style={{
                                  color: globalContext.owner[0]
                                    ? globalContext.owner[0].COLOR?.primaryColor
                                    : "",
                                }}
                              >
                                ₹
                                {decode(globalContext.user).partyGd == "A"
                                  ? Math.round(
                                      variant[0].SALE_RATE1 -
                                        (variant[0].SALE_DISC / 100) *
                                          variant[0].SALE_RATE1
                                    )
                                  : decode(globalContext.user).partyGd == "B"
                                  ? Math.round(
                                      variant[0].SALE_RATE2 -
                                        (variant[0].SALE_DISC / 100) *
                                          variant[0].SALE_RATE2
                                    )
                                  : decode(globalContext.user).partyGd == "C"
                                  ? Math.round(
                                      variant[0].SALE_RATE3 -
                                        (variant[0].SALE_DISC / 100) *
                                          variant[0].SALE_RATE3
                                    )
                                  : decode(globalContext.user).partyGd == "D"
                                  ? Math.round(
                                      variant[0].SALE_RATE4 -
                                        (variant[0].SALE_DISC / 100) *
                                          variant[0].SALE_RATE4
                                    )
                                  : decode(globalContext.user).partyGd == "E"
                                  ? Math.round(
                                      variant[0].SALE_RATE5 -
                                        (variant[0].SALE_DISC / 100) *
                                          variant[0].SALE_RATE5
                                    )
                                  : 0}
                              </span>
                              <span className="text-xs line-through ml-1">
                                ₹
                                {decode(globalContext.user).partyGd == "A"
                                  ? Math.round(variant[0].SALE_RATE1)
                                  : decode(globalContext.user).partyGd == "B"
                                  ? Math.round(variant[0].SALE_RATE2)
                                  : decode(globalContext.user).partyGd == "C"
                                  ? Math.round(variant[0].SALE_RATE3)
                                  : decode(globalContext.user).partyGd == "D"
                                  ? Math.round(variant[0].SALE_RATE4)
                                  : decode(globalContext.user).partyGd == "E"
                                  ? Math.round(variant[0].SALE_RATE5)
                                  : 0}
                              </span>
                            </div>
                          ) : (
                            <div>
                              ₹
                              {decode(globalContext.user).partyGd == "A"
                                ? Math.round(variant[0].SALE_RATE1)
                                : decode(globalContext.user).partyGd == "B"
                                ? Math.round(variant[0].SALE_RATE2)
                                : decode(globalContext.user).partyGd == "C"
                                ? Math.round(variant[0].SALE_RATE3)
                                : decode(globalContext.user).partyGd == "D"
                                ? Math.round(variant[0].SALE_RATE4)
                                : decode(globalContext.user).partyGd == "E"
                                ? Math.round(variant[0].SALE_RATE5)
                                : 0}
                            </div>
                          )
                        ) : null}
                      </div>
                    ) : null
                  ) : (
                    <div className="elis_rty">
                      {globalContext.settings.length != 0 ? (
                        globalContext.settings.filter(
                          (e) => e.VARIABLE == "itemDisc"
                        )[0].VALUE == "Y" ? (
                          <div>
                            <span
                              className="ft-medium fs-md ft-bold"
                              style={{
                                color: globalContext.owner[0]
                                  ? globalContext.owner[0].COLOR?.primaryColor
                                  : "",
                              }}
                            >
                              ₹
                              {decode(globalContext.user).partyGd == "A"
                                ? Math.round(
                                    variant[0].SALE_RATE1 -
                                      (variant[0].SALE_DISC / 100) *
                                        variant[0].SALE_RATE1
                                  )
                                : decode(globalContext.user).partyGd == "B"
                                ? Math.round(
                                    variant[0].SALE_RATE2 -
                                      (variant[0].SALE_DISC / 100) *
                                        variant[0].SALE_RATE2
                                  )
                                : decode(globalContext.user).partyGd == "C"
                                ? Math.round(
                                    variant[0].SALE_RATE3 -
                                      (variant[0].SALE_DISC / 100) *
                                        variant[0].SALE_RATE3
                                  )
                                : decode(globalContext.user).partyGd == "D"
                                ? Math.round(
                                    variant[0].SALE_RATE4 -
                                      (variant[0].SALE_DISC / 100) *
                                        variant[0].SALE_RATE4
                                  )
                                : decode(globalContext.user).partyGd == "E"
                                ? Math.round(
                                    variant[0].SALE_RATE5 -
                                      (variant[0].SALE_DISC / 100) *
                                        variant[0].SALE_RATE5
                                  )
                                : 0}
                            </span>
                            <span className="text-xs line-through ml-1">
                              ₹
                              {decode(globalContext.user).partyGd == "A"
                                ? Math.round(variant[0].SALE_RATE1)
                                : decode(globalContext.user).partyGd == "B"
                                ? Math.round(variant[0].SALE_RATE2)
                                : decode(globalContext.user).partyGd == "C"
                                ? Math.round(variant[0].SALE_RATE3)
                                : decode(globalContext.user).partyGd == "D"
                                ? Math.round(variant[0].SALE_RATE4)
                                : decode(globalContext.user).partyGd == "E"
                                ? Math.round(variant[0].SALE_RATE5)
                                : 0}
                            </span>
                          </div>
                        ) : (
                          <div>
                            ₹
                            {decode(globalContext.user).partyGd == "A"
                              ? Math.round(variant[0].SALE_RATE1)
                              : decode(globalContext.user).partyGd == "B"
                              ? Math.round(variant[0].SALE_RATE2)
                              : decode(globalContext.user).partyGd == "C"
                              ? Math.round(variant[0].SALE_RATE3)
                              : decode(globalContext.user).partyGd == "D"
                              ? Math.round(variant[0].SALE_RATE4)
                              : decode(globalContext.user).partyGd == "E"
                              ? Math.round(variant[0].SALE_RATE5)
                              : 0}
                          </div>
                        )
                      ) : null}
                    </div>
                  )
                ) : null
              ) : null}
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return <div></div>;
  }
};

export default ProductCard;
