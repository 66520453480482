import React, { useContext, useState, useEffect } from "react";
import ProductCard from "../ProductCard";
import GlobalContext from "../../Context/globalContext";
import useSWR from "swr";
import { LOAD_PRODUCTBYCATEGORY } from "../../Context/globalActions.types";
import { useParams, Link } from "react-router-dom";
import { decode } from "jsonwebtoken";
import { Skeleton } from "@material-ui/lab";
import { Button } from "@material-ui/core";

const CustomCardCategory = ({ dept_name, dept_cd }) => {
  const [data, setData] = useState([]);

  const params = useParams();

  const { globalContext, dispatch } = useContext(GlobalContext);

  const { data: resProductByCategory, error: resProductByCategoryError } =
    useSWR(
      dept_cd != ""
        ? globalContext.user != ""
          ? `/products?mobileNo=${
              params.mobileNo
            }&per_page=${12}&page=${0}&groupCd=${
              decode(globalContext.user).groupCd
            }&partyCd=${decode(globalContext.user).cd}&dept_cd=${dept_cd}`
          : ""
        : ""
    );

  useEffect(() => {
    if (resProductByCategory) {
      dispatch({
        type: LOAD_PRODUCTBYCATEGORY,
        payload: resProductByCategory.data.result,
      });
      setData(resProductByCategory.data.result);
    }
  }, [resProductByCategory]);

  return (
    <div>
      {data.length != 0 ? (
        <React.Fragment>
          <section className="pt-0 pb-0 bg-white mt-3 rounded-lg">
            <div className="container-fluid pt-3 shadow">
              <div className="row justify-content-center">
                <div className="position-relative col-12 text-center">
                  <h3 className="ft-bold flex justify-between pt-1">
                    <div></div>
                    <div>{dept_name ? dept_name : ""}</div>
                    <div>
                      {data.length > 12 ? (
                        <Button variant="text" color="primary">
                          <Link
                            to={`/${params.mobileNo}/shop?type=category&value=${dept_cd}`}
                          >
                            Load More
                            <i className="lni lni-arrow-right ml-2"></i>
                          </Link>
                        </Button>
                      ) : null}
                    </div>
                  </h3>
                </div>
              </div>
              <div className="row rows-products">
                {!resProductByCategory
                  ? new Array(12)
                      .fill()
                      .map((a, i) => (a = i))
                      .sort(() => Math.random() - 0.5)
                      .map((e, index) => (
                        <div
                          key={index}
                          className="col-xl-2 col-lg-3 col-md-3 col-6 mb-2"
                        >
                          <Skeleton
                            variant="rect"
                            animation="wave"
                            style={{
                              height: "10rem",
                              objectFit: "fill",
                            }}
                          />
                          <Skeleton variant="text" />
                          <Skeleton variant="text" />
                        </div>
                      ))
                  : data.length != 0
                  ? data.slice(0, 12).map((d) => (
                      <div
                        className="col-xl-2 col-lg-3 col-md-3 col-6 px-2 d-flex align-items-stretch"
                        key={d.ITEM_CD}
                      >
                        <ProductCard product={d} />
                      </div>
                    ))
                  : null}
              </div>
            </div>
          </section>
        </React.Fragment>
      ) : null}
    </div>
  );
};

export default CustomCardCategory;
