import React, { useEffect, useState, useContext } from "react";
import GlobalContext from "../../Context/globalContext";
import { Redirect, useParams } from "react-router";
import { SIGN_IN } from "../../Context/globalActions.types";

import { imageUrl } from "../../Config";
import ProfileDrawer from "./Components/ProfileDrawer";
import { Link } from "react-router-dom";
import Slider from "react-slick";

const About = () => {
  const params = useParams();
  // let mobileNo = params.mobileNo;

  const { globalContext, dispatch } = useContext(GlobalContext);

  // const settings = {
  //   centerMode: false,
  //   slidesToShow: 1,
  //   arrows: true,
  //   dots: true,
  //   responsive: [
  //     {
  //       breakpoint: 768,
  //       settings: {
  //         arrows: true,
  //         slidesToShow: 1,
  //       },
  //     },
  //     {
  //       breakpoint: 480,
  //       settings: {
  //         arrows: true,
  //         slidesToShow: 1,
  //       },
  //     },
  //   ],
  // };

  return (
    <ProfileDrawer
      breadcrubms={
        <div class="gray py-3">
          <div class="container">
            <div class="row">
              <div class="colxl-12 col-lg-12 col-md-12">
                <nav aria-label="breadcrumb">
                  <ol class="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to={`/${params.mobileNo}/`}>Home</Link>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">
                      About Us
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      }
    >
      <h2 class="ft-medium mb-4">About the Company</h2>
      <form className="row m-0">
        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
          <div className="form-group">
            <label className="small text-dark ft-medium float-left">
              Company Name
            </label>
            <input
              type="text"
              className="form-control"
              value={
                globalContext.company[0]
                  ? globalContext.company[0].COMP_NAME
                  : ""
              }
              disabled={true}
            />
          </div>
        </div>
        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
          <div className="form-group">
            <label className="small text-dark ft-medium float-left">
              Address
            </label>
            <input
              type="text"
              className="form-control"
              value={
                globalContext.company[0] ? globalContext.company[0].ADDRS : ""
              }
              disabled={true}
            />
          </div>
        </div>
        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
          <div className="form-group">
            <label className="small text-dark ft-medium float-left">City</label>
            <input
              type="text"
              className="form-control"
              value={
                globalContext.company[0] ? globalContext.company[0].CITY : ""
              }
              disabled={true}
            />
          </div>
        </div>
        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
          <div className="form-group">
            <label className="small text-dark ft-medium float-left">
              Pincode
            </label>
            <input
              type="text"
              className="form-control"
              value={
                globalContext.company[0] ? globalContext.company[0].PINCODE : ""
              }
              disabled={true}
            />
          </div>
        </div>

        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
          <div className="form-group">
            <label className="small text-dark ft-medium float-left">
              Owner Name
            </label>
            <input
              type="text"
              className="form-control"
              value={
                globalContext.company[0]
                  ? globalContext.company[0].PERSON_NM
                  : ""
              }
              disabled={true}
            />
          </div>
        </div>
        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
          <div className="form-group">
            <label className="small text-dark ft-medium float-left">
              Mobile No 1
            </label>
            <input
              type="text"
              className="form-control"
              value={
                globalContext.company[0] ? globalContext.company[0].PH1 : ""
              }
              disabled={true}
            />
          </div>
        </div>
        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
          <div className="form-group">
            <label className="small text-dark ft-medium float-left">
              Mobile No 2
            </label>
            <input
              type="text"
              className="form-control"
              value={
                globalContext.company[0] ? globalContext.company[0].PH2 : ""
              }
              disabled={true}
            />
          </div>
        </div>

        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
          <div className="form-group">
            <label className="small text-dark ft-medium float-left">
              Company Email
            </label>
            <input
              type="text"
              className="form-control"
              value={
                globalContext.company[0] ? globalContext.company[0].EMAILID : ""
              }
              disabled={true}
            />
          </div>
        </div>

        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
          <div className="form-group">
            <label className="small text-dark ft-medium float-left">
              Gstin No
            </label>
            <input
              type="text"
              className="form-control"
              value={
                globalContext.company[0] ? globalContext.company[0].GSTINNO : ""
              }
              disabled={true}
            />
          </div>
        </div>
        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
          <div className="form-group">
            <label className="small text-dark ft-medium float-left">
              State Cd
            </label>
            <input
              type="text"
              className="form-control"
              value={
                globalContext.company[0] ? globalContext.company[0].STATECD : ""
              }
              disabled={true}
            />
          </div>
        </div>
        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
          <div className="form-group">
            <label className="small text-dark ft-medium float-left">
              Bank Name:
            </label>
            <input
              type="text"
              className="form-control"
              value={
                globalContext.company[0]
                  ? globalContext.company[0].C_BANK_NAME
                  : ""
              }
              disabled={true}
            />
          </div>
        </div>
        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
          <div className="form-group">
            <label className="small text-dark ft-medium float-left">
              Bank Branch
            </label>
            <input
              type="text"
              className="form-control"
              value={
                globalContext.company[0] ? globalContext.company[0].C_BRNCH : ""
              }
              disabled={true}
            />
          </div>
        </div>
        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
          <div className="form-group">
            <label className="small text-dark ft-medium float-left">
              Bank Account No
            </label>
            <input
              type="text"
              className="form-control"
              value={
                globalContext.company[0] ? globalContext.company[0].C_AC_NO : ""
              }
              disabled={true}
            />
          </div>
        </div>
        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
          <div className="form-group">
            <label className="small text-dark ft-medium float-left">
              Bank IFSC
            </label>
            <input
              type="text"
              className="form-control"
              value={
                globalContext.company[0] ? globalContext.company[0].C_IFSC : ""
              }
              disabled={true}
            />
          </div>
        </div>
      </form>
    </ProfileDrawer>
  );
};

export default About;
