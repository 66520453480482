import {
  Button,
  Card,
  ListItem,
  Typography,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  TableContainer,
  TablePagination,
  useTheme,
  TableSortLabel,
  IconButton,
  makeStyles,
  lighten,
  Paper,
  TextField,
  withStyles,
  TableFooter,
} from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import OwnerHeader from "../Components/OwnerHeader";
import { KeyboardDatePicker } from "@material-ui/pickers";
import moment from "moment";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import { useHistory, useParams } from "react-router-dom";
import { Autocomplete } from "@material-ui/lab";
import CustomSnakBar from "../../../Components/CustomSnakBar";
import { Instance } from "../../../Config";

import jsPDF from "jspdf";
import "jspdf-autotable";
import { CSVLink } from "react-csv";

import InsertDriveFileOutlinedIcon from "@material-ui/icons/InsertDriveFileOutlined";
import SaveAltIcon from "@material-ui/icons/SaveAlt";
import GlobalContext from "../../../Context/globalContext";
import { SHOW_SNACKBAR } from "../../../Context/globalActions.types";
import { Skeleton } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  fullWidth: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },

  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: "1 1 100%",
  },
}));

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(even)": {
      backgroundColor: theme.palette.action.hover,
    },
    "&:hover": {
      backgroundColor: "#F3F3F3",
      transform: "scale(1.0019)",
    },
  },
}))(TableRow);

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export default function StatusWiseReport() {
  const classes = useStyles();
  const { globalContext, dispatch } = useContext(GlobalContext);
  const [fromDate, setFromDate] = useState(
    moment().subtract(1, "year").format("YYYY-MM-DD hh:mm:ss")
  );
  const [toDate, setToDate] = useState(
    moment().subtract(1, "days").format("YYYY-MM-DD hh:mm:ss")
  );
  const [status, setStatus] = useState("");
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("CREATED_AT");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  const [totalAmt, setTotalAmt] = useState(0);
  const [totalItem, setTotalItem] = useState(0);

  const [rows, setRows] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [filterStatus, setFilterStatus] = useState([
    "Pending",
    "Dispatched",
    "Canceled",
  ]);

  const params = useParams();
  const history = useHistory();

  const handleFromDateChange = (date) => {
    setFromDate(moment(date).format("YYYY-MM-DD hh:mm:ss"));
  };

  const handleToDateChange = (date) => {
    setToDate(moment(date).format("YYYY-MM-DD hh:mm:ss"));
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const fetchData = () => {
    setIsLoading(true);
    Instance.get(
      `/reports/statusWise?mobileNo=${params.mobileNo}&fromDate=${fromDate}&toDate=${toDate}&status=${status}`
    )
      .then((res) => {
        if (res) {
          setIsLoading(false);
          setRows(res.data.result);
        }
      })
      .catch(() => {
        dispatch({
          type: SHOW_SNACKBAR,
          payload: {
            message: "Something went wrong",
            type: "error",
          },
        });
      });
  };

  const [exportCsv, setExportCsv] = useState(false);
  const headers = [
    { label: "Order Date", key: "createdAt" },
    { label: "Order Id", key: "ORDER_NO" },
    { label: "Customer Name", key: "accounts.ACC_NAME" },
    { label: "Total Items", key: "orderItm.length" },
    { label: "Amount", key: "NET_AMT" },
  ];

  const exportPdf = () => {
    const unit = "pt";
    const size = "A4";
    const orientation = "portrait";

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    const title = "Orders Report";
    const headers = [
      ["Order Date", "Order Id", "Customer Name", "Total Items", "Amount"],
    ];

    const data = rows.map((e) => [
      new Date(e.createdAt).toLocaleString("us-en", {
        dateStyle: "long",
      }),
      e.ORDER_NO,
      e.accounts?.ACC_NAME,
      e.orderItm.length,
      e.NET_AMT,
    ]);

    let content = {
      startY: 70,
      head: headers,
      body: data,
    };

    let width1 = doc.internal.pageSize.getWidth();

    doc.setFontSize(14).setFont(undefined, "bold");
    doc
      .text(globalContext.company[0].COMP_NAME, width1 / 2, 20, {
        align: "center",
      })
      .setFontSize(8)
      .setFont(undefined, "bold");
    doc.text(globalContext.company[0].ADDRS, width1 / 2, 38, {
      align: "center",
    });
    doc.text(
      `From Date: ${moment(fromDate).format("DD-MM-YYYY")} To: ${moment(
        toDate
      ).format("DD-MM-YYYY")}`,
      width1 / 2,
      60,
      {
        align: "center",
      }
    );
    doc.autoTable(content);
    doc.save("StatusWiseReport.pdf");
  };

  const headCells = [
    {
      id: "createdAt",
      label: "Order Date",
    },
    {
      id: "ORDER_NO",
      label: "Order Id",
    },
    {
      id: "ACC_NAME",
      label: "Customer Name",
    },
    {
      id: "TOTAL_QTY",
      label: "Total Qty",
    },
    {
      id: "NO_OF_ITEM",
      label: "Order Total Items",
    },
    {
      id: "NET_AMT",
      label: "Amount",
    },
  ];

  function EnhancedTableHead(props) {
    const { classes, order, orderBy, onRequestSort } = props;

    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };

    return (
      <TableHead className="sticky top-0">
        <TableRow className="grid grid-cols-3 pb-1 md:grid-cols-8 md:mx-3 md:bg-white bg-gray-200">
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              align="left"
              className={`font-bold border-0 pt-0 pr-[24px] pb-0 pl-[9px] text-[12px] leading-5 w-max bg-transparent ${
                headCell.id == "ACC_NAME" ? "md:col-span-3" : ""
              }`}
              padding={headCell.disablePadding ? "none" : "normal"}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </span>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  function TablePaginationActions(props) {
    const classes = useStyles1();
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };

    return (
      <div className={classes.root}>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
      </div>
    );
  }

  useEffect(() => {
    if (rows) {
      setTotalItem(
        rows.reduce((accumulator, object) => {
          return accumulator + object.orderItm.length;
        }, 0)
      );
      setTotalAmt(
        rows.reduce((accumulator, object) => {
          return accumulator + object.NET_AMT;
        }, 0)
      );
    }
  }, [rows]);

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <OwnerHeader>
      <div className="md:flex items-center justify-between px-3 md:px-4 py-3">
        <div>
          <div className="text-indigo-500 font-semibold text-sm md:text-base">
            Reporting
          </div>
          <div className="text-xl md:text-2xl font-extrabold leading-7 sm:leading-10 truncate mt-1">
            Status Wise Summary
          </div>
        </div>
      </div>
      <div className="py-1 min-h-screen bg-[#F1F5F9] px-2">
        <Card>
          <ListItem className="pb-0">
            <Typography
              variant="h6"
              className="text-indigo-500 uppercase font-bold tracking-wider text-xs"
            >
              Filters
            </Typography>
          </ListItem>
          <ListItem className="pt-0">
            <Typography
              variant="h6"
              className="text-gray-500 uppercase font-bold tracking-wider text-[9px]"
            >
              Apply all the the required filters
            </Typography>
          </ListItem>
        </Card>
        <div className="mx-2 md:flex items-end gap-10 mb-4">
          {window.innerWidth < 600 ? (
            <KeyboardDatePicker
              margin="normal"
              id="date-picker-dialog"
              label="From"
              format="DD/MM/yyyy"
              value={fromDate}
              onChange={handleFromDateChange}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
          ) : (
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              format="DD/MM/yyyy"
              margin="normal"
              id="date-picker-inline"
              label="From"
              value={fromDate}
              onChange={handleFromDateChange}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
          )}
          {window.innerWidth < 600 ? (
            <KeyboardDatePicker
              margin="normal"
              id="date-picker-dialog"
              label="To"
              format="DD/MM/yyyy"
              value={toDate}
              onChange={handleToDateChange}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
          ) : (
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              format="DD/MM/yyyy"
              margin="normal"
              id="date-picker-inline"
              label="To"
              value={toDate}
              onChange={handleToDateChange}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
          )}
          <Autocomplete
            id="Status"
            autoHighlight
            options={filterStatus}
            getOptionLabel={(option) => option}
            renderOption={(option) => <React.Fragment>{option}</React.Fragment>}
            className="md:w-52 w-full"
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                size="small"
                label="Item Status"
                margin="normal"
              />
            )}
            onChange={(e, value) => {
              if (value) {
                setStatus(value);
              }
            }}
          />
          <br />
          <Button
            variant="contained"
            disableElevation
            className="mb-2 bg-[#3333cc] text-white"
            onClick={fetchData}
          >
            Apply
          </Button>
        </div>

        <React.Fragment>
          <TableContainer
            style={{
              overflowX: "clip",
            }}
            component={Paper}
          >
            <div className="md:flex justify-between w-full my-3 px-2 pt-3">
              <Typography className="md:ml-2 text-md md:text-lg mb-2 font-medium">
                Status Wise Report
              </Typography>
              <div>
                <Button
                  variant="outlined"
                  className="text-blue-600 border-[#3333cc] mt-2 md:mt-0"
                  startIcon={<SaveAltIcon />}
                >
                  <CSVLink
                    data={rows}
                    headers={headers}
                    filename="StatusWiseReport.csv"
                    className="text-blue-600"
                  >
                    Excel
                  </CSVLink>
                </Button>
                <Button
                  variant="outlined"
                  className="text-blue-600 border-[#3333cc] mt-2 md:mt-0 ml-2"
                  startIcon={<InsertDriveFileOutlinedIcon />}
                  onClick={() => {
                    exportPdf();
                  }}
                >
                  PDF
                </Button>
              </div>
            </div>
            <div className="max-h-screen overflow-x-hidden">
              <Table stickyHeader>
                <EnhancedTableHead
                  classes={classes}
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  rowCount={rows ? rows.length : null}
                />
                <TableBody>
                  {isLoading && rows.length == 0 ? (
                    <StyledTableRow className="grid grid-cols-3 py-1 items-center md:grid-cols-8 md:mx-3">
                      <StyledTableCell className="border-0 pt-0 pr-[24px] pb-0 pl-[9px] text-[12px] leading-4">
                        <Skeleton />
                      </StyledTableCell>
                      <StyledTableCell className="border-0 pt-0 pr-[24px] pb-0 pl-[9px] text-[12px] leading-4">
                        <Skeleton />
                      </StyledTableCell>
                      <StyledTableCell className="border-0 pt-0 pr-[24px] pb-0 pl-[9px] text-[12px] leading-4 md:col-span-3">
                        <Skeleton />
                      </StyledTableCell>
                      <StyledTableCell className="border-0 pt-0 pr-[24px] pb-0 pl-[9px] text-[12px] leading-4">
                        <Skeleton />
                      </StyledTableCell>
                      <StyledTableCell className="border-0 pt-0 pr-[24px] pb-0 pl-[9px] text-[12px] leading-4">
                        <Skeleton />
                      </StyledTableCell>
                      <StyledTableCell className="border-0 pt-0 pr-[24px] pb-0 pl-[9px] text-[12px] leading-4">
                        <Skeleton />
                      </StyledTableCell>
                    </StyledTableRow>
                  ) : !isLoading && rows.length == 0 ? (
                    <TableRow hover tabIndex={-1}>
                      <TableCell align="center" colSpan={6}>
                        No Data Found
                      </TableCell>
                    </TableRow>
                  ) : (
                    stableSort(rows, getComparator(order, orderBy))
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row) => {
                        return (
                          <StyledTableRow
                            key={row.orderId}
                            className="grid grid-cols-3 py-1 items-center md:grid-cols-8 md:mx-3 border-t-[1px] hover:font-semibold cursor-pointer"
                            onClick={() => {
                              history.push(
                                `/${params.mobileNo}/owner/orderdetail?id=${row.orderId}`
                              );
                            }}
                          >
                            <StyledTableCell
                              align="left"
                              style={{
                                fontWeight: "inherit",
                              }}
                              className="border-0 pt-0 pr-[24px] pb-0 pl-[9px] text-[12px] leading-4"
                            >
                              {new Date(row.createdAt).toLocaleString("en-IN", {
                                year: "numeric",
                                month: "2-digit",
                                day: "2-digit",
                              })}
                            </StyledTableCell>
                            <StyledTableCell
                              align="left"
                              style={{
                                fontWeight: "inherit",
                              }}
                              className="border-0 pt-0 pr-[24px] pb-0 pl-[9px] text-[12px] leading-4"
                            >
                              {row.ORDER_NO ? row.ORDER_NO : ""}
                            </StyledTableCell>
                            <StyledTableCell
                              align="left"
                              style={{
                                fontWeight: "inherit",
                              }}
                              className="border-0 pt-0 pr-[24px] pb-0 pl-[9px] text-[12px] leading-4 md:col-span-3"
                            >
                              {row.accounts?.ACC_NAME}
                            </StyledTableCell>
                            <StyledTableCell
                              align="left"
                              style={{
                                fontWeight: "inherit",
                              }}
                              className="border-0 pt-0 pr-[24px] pb-0 pl-[9px] text-[12px] leading-4"
                            >
                              {parseFloat(
                                row.orderItm.reduce((accumulator, object) => {
                                  return accumulator + object.QUANTITY;
                                }, 0.0)
                              ).toFixed(2)}
                            </StyledTableCell>
                            <StyledTableCell
                              align="left"
                              style={{
                                fontWeight: "inherit",
                              }}
                              className="border-0 pt-0 pr-[24px] pb-0 pl-[9px] text-[12px] leading-4"
                            >
                              {row.orderItm.length}
                            </StyledTableCell>
                            <StyledTableCell
                              align="left"
                              style={{
                                fontWeight: "inherit",
                              }}
                              className="border-0 pt-0 pr-[24px] pb-0 pl-[9px] text-[12px] leading-4"
                            >
                              {parseFloat(row.NET_AMT).toFixed(2)}
                            </StyledTableCell>
                          </StyledTableRow>
                        );
                      })
                  )}
                </TableBody>
                <TablePagination
                  rowsPerPageOptions={[25, 50, 100]}
                  component={Paper}
                  count={rows ? rows.length : null}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                  elevation={0}
                />
                {rows ? (
                  <TableFooter>
                    <TableCell>
                      <TableRow className=" text-black text-[14px]">
                        Total Item : {totalItem}
                      </TableRow>
                      <TableRow className=" text-black text-[14px]">
                        Total Amount : ₹{totalAmt}
                      </TableRow>
                    </TableCell>
                  </TableFooter>
                ) : null}
              </Table>
            </div>
          </TableContainer>
        </React.Fragment>
      </div>
    </OwnerHeader>
  );
}
