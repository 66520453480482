import { Container, Divider } from "@material-ui/core";
import React, { useContext, useEffect } from "react";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import BottomBar from "./Components/BottomBar";
import { Link } from "react-router-dom";
import GlobalContext from "../../Context/globalContext";
import { Redirect, useParams } from "react-router";
import { decode } from "jsonwebtoken";
import { SIGN_IN } from "../../Context/globalActions.types";
import CropOriginalIcon from "@material-ui/icons/CropOriginal";
import { imageUrl } from "../../Config";

const Category = () => {
  const params = useParams();
  let mobileNo = params.mobileNo;

  const { globalContext, dispatch } = useContext(GlobalContext);

  return (
    <div>
      <Header>
        <Container className="pt-8 pb-4">
          <div className="grid grid-cols-1 md:grid-cols-4 gap-4 mt-2">
            {globalContext.category.map((item) => (
              <div key={item.DEPT_CD}>
                <Link
                  to={`/${mobileNo}/shop?type=category&value=${item.DEPT_CD}`}
                >
                  <div className="flex border rounded">
                    {item.img ? (
                      <img
                        className="card-img-top w-[80px] h-[80px] object-contain hover:scale-110 transition ease-in-out"
                        src={imageUrl + "/" + item.img.IMGURL[0]}
                        alt="..."
                      />
                    ) : (
                      <img
                        className="card-img-top w-[80px] h-[80px] object-contain hover:scale-110 transition ease-in-out"
                        src="/img/11.jpg"
                        alt="Image Not Found"
                      />
                    )}

                    <div>
                      <div className="ml-3 font-medium">
                        {item.dept_name ? item.dept_name : ""}
                      </div>
                      <div className="ml-3 text-[9px]">
                        {item.ITEMDESC ? item.ITEMDESC : ""}
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            ))}
          </div>
        </Container>
        <Footer />
        <BottomBar />
      </Header>
    </div>
  );
};

export default Category;
