import React, { useEffect, useState, useContext } from "react";
import { Drawer } from "@material-ui/core";
import GlobalContext from "../Context/globalContext";
import { imageUrl } from "../Config";
import { useParams } from "react-router";
import { LOAD_PRODUCT } from "../Context/globalActions.types";
import { decode } from "jsonwebtoken";
import { Link } from "react-router-dom";
import useSearch from "../hooks/useSearch";
import SearchOutlinedIcon from "@material-ui/icons/SearchOutlined";

export default function SearchDrawer({ isOpen, toggleDrawer }) {
  const params = useParams();
  let mobileNo = params.mobileNo;

  const { globalContext, dispatch } = useContext(GlobalContext);

  const [search, setSearch] = useState("");

  const { data, error, loading } = useSearch(
    search,
    globalContext.user ? decode(globalContext.user).groupCd : "",
    globalContext.user ? decode(globalContext.user).cd : ""
  );

  useEffect(() => {
    if (data && !loading) {
      dispatch({
        type: LOAD_PRODUCT,
        payload: data,
      });
    }
  }, [data]);

  return (
    <React.Fragment>
      <li onClick={toggleDrawer(true)}>
        <div
          to="#"
          style={{
            color: globalContext.owner[0]
              ? globalContext.owner[0].COLOR?.primaryColorText
              : "",
          }}
        >
          <SearchOutlinedIcon className="cursor-pointer text-xl" />
        </div>
      </li>
      <Drawer anchor="right" open={isOpen} onClose={toggleDrawer(false)}>
        <div className="rightMenu-scroll w-80 md:w-96">
          <div
            className="d-flex align-items-center justify-content-between py-3 px-3"
            style={{
              backgroundColor: globalContext.owner[0]
                ? globalContext.owner[0].COLOR?.primaryColor
                : "",
              color: globalContext.owner[0]
                ? globalContext.owner[0].COLOR?.primaryColorText
                : "",
            }}
          >
            <h4
              className="fs-md ft-medium mb-0"
              style={{
                color: globalContext.owner[0]
                  ? globalContext.owner[0].COLOR?.primaryColorText
                  : "",
              }}
            >
              Search Products
            </h4>
            <button onClick={toggleDrawer(false)} className="close_slide">
              <i className="ti-close"></i>
            </button>
          </div>

          <div className="cart_action px-3 py-4">
            <form className="form m-0 p-0">
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Product Name.."
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </div>
            </form>
          </div>

          {search == "" ? (
            <React.Fragment>
              <div className="d-flex align-items-center justify-content-center br-top br-bottom py-2 px-3">
                <h4 className="cart_heading fs-md mb-0">Hot Categories</h4>
              </div>

              <div className="cart_action px-3 py-3">
                <div className="row">
                  {globalContext.category
                    ? globalContext.category.map((item) => (
                        <div
                          className="col-xl-4 col-lg-4 col-md-4 col-4 mb-3"
                          key={item.DEPT_CD}
                        >
                          <Link
                            to={`/${mobileNo}/shop?type=category&value=${item.DEPT_CD}`}
                            onClick={toggleDrawer(false)}
                          >
                            <div className="cats_side_wrap text-center">
                              <div className="sl_cat_01">
                                <div className="d-inline-flex align-items-center justify-content-center p-3 circle mb-2 gray">
                                  {item.img ? (
                                    <img
                                      src={imageUrl + "/" + item.img.IMGURL[0]}
                                      className="img-fluid"
                                      width="40"
                                      alt=""
                                    />
                                  ) : (
                                    <img
                                      src="/img/11.jpg"
                                      className="img-fluid"
                                      width="40"
                                      alt=""
                                    />
                                  )}
                                </div>
                              </div>
                              <div className="sl_cat_02">
                                <h6 className="m-0 ft-medium fs-sm capitalize">
                                  {item.dept_name
                                    ? item.dept_name.toString().toLowerCase()
                                    : ""}
                                </h6>
                              </div>
                            </div>
                          </Link>
                        </div>
                      ))
                    : null}
                </div>
              </div>
            </React.Fragment>
          ) : (
            data.map((item) => (
              <Link
                to={`/${params.mobileNo}/product/${item.ITEM_CD}`}
                key={item.cartId}
                target="_top"
              >
                <div className="max-w-sm w-full lg:max-w-full flex px-3 py-2">
                  {item.img ? (
                    <img
                      src={imageUrl + "/" + item.img.IMGURL[0]}
                      alt="Image Unavailable"
                      className="h-14 w-14 lg:h-16 lg:w-16 object-cover"
                    />
                  ) : (
                    <img
                      src="/img/11.jpg"
                      className="h-14 w-14 lg:h-16 lg:w-16 object-cover"
                      alt=""
                    />
                  )}
                  <div>
                    <div className="mb-8 ml-2">
                      <p className="text-gray-700 text-base capitalize line-break-word">
                        {item.ITEM_NAME.toString().toLowerCase()}
                      </p>
                    </div>
                  </div>
                </div>
              </Link>
            ))
          )}
        </div>
      </Drawer>
    </React.Fragment>
  );
}
