import React, { useState, useContext, useCallback } from "react";
import GlobalContext from "../../../Context/globalContext";
import {
  INIT_OWNER,
  SHOW_SNACKBAR,
} from "../../../Context/globalActions.types";
import { useParams } from "react-router-dom";
import Cropper from "react-easy-crop";
import { Instance } from "../../../Config";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Dialog,
  CircularProgress,
} from "@material-ui/core";
import getCroppedImg from "../../../hooks/cropImage";

export default function CompanyLogoDialog({ open, handleClose }) {
  const { globalContext, dispatch } = useContext(GlobalContext);
  const params = useParams();

  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [selectedImg, setSelectedImg] = useState(null);
  const [isUploading, setIsUploading] = useState(false);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(selectedImg, croppedAreaPixels);
      return croppedImage;
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels]);

  const deleteImage = async (id) => {
    await Instance.delete(`/uploads/${id}`);
  };

  const uploadImage = async (uploadData) => {
    const formData = new FormData();
    formData.append("images", uploadData);
    try {
      const res = await Instance.post("/uploads/images", formData);
      return res.data.result;
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth="sm">
        <DialogTitle>
          <div className="flex justify-between">
            <p>Upload Company Logo</p>
            <label htmlFor="contained-button-file3">
              <input
                accept="image/*"
                id="contained-button-file3"
                type="file"
                style={{
                  display: "none",
                }}
                onChange={async (e) => {
                  setSelectedImg(URL.createObjectURL(e.target.files[0]));
                }}
              />
              <Button
                variant="contained"
                disableElevation
                className=" bg-[#3333cc] text-white"
                component="span"
              >
                Select Image
              </Button>
            </label>
          </div>
        </DialogTitle>
        <DialogContent
          style={{
            position: "relative",
            width: "100%",
            height: "250px",
          }}
        >
          {croppedImage ? (
            <img src={croppedImage} />
          ) : selectedImg ? (
            <Cropper
              image={selectedImg}
              crop={crop}
              zoom={zoom}
              aspect={4 / 1}
              onCropChange={setCrop}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
            />
          ) : (
            "Please Select an Image"
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              handleClose();
              setSelectedImg(null);
              setCroppedImage(null);
            }}
            variant="text"
          >
            Close
          </Button>
          {isUploading ? (
            <CircularProgress size={30} className="mt-2" />
          ) : (
            <Button
              onClick={() => {
                showCroppedImage().then(async (val) => {
                  setCroppedImage(val);
                  if (val) {
                    if (globalContext.owner[0].LOGO) {
                      deleteImage(globalContext.owner[0].LOGO);
                    }
                    setIsUploading(true);
                    const imagesUrl = await uploadImage(val);
                    Instance.put("/owner", {
                      type: "logo",
                      data: imagesUrl,
                      mobileNo: params.mobileNo,
                    })
                      .then((res) => {
                        if (res.status == 200) {
                          globalContext.owner[0].LOGO = imagesUrl;
                          dispatch({
                            payload: globalContext.owner,
                            type: INIT_OWNER,
                          });
                          setCroppedImage(null);
                          setSelectedImg(null);
                          setIsUploading(false);
                          handleClose();
                        }
                      })
                      .catch(() => {
                        dispatch({
                          type: SHOW_SNACKBAR,
                          payload: {
                            message: "Something went wrong",
                            type: "error",
                          },
                        });
                      });
                  }
                });
              }}
              variant="text"
            >
              Save
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}
