import React, { useEffect, useState, useContext } from "react";

import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

import { useQuill } from "react-quilljs";
import "quill/dist/quill.snow.css";

import CircularProgress from "@material-ui/core/CircularProgress";
import {
  Container,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  LinearProgress,
  InputAdornment,
  Input,
  Chip,
  Select,
  MenuItem,
  FormControl,
  TextField,
} from "@material-ui/core";
import OwnerHeader from "../Components/OwnerHeader";
import { useHistory, useParams, useLocation, Link } from "react-router-dom";
import GlobalContext from "../../../Context/globalContext";
import { Autocomplete, Skeleton } from "@material-ui/lab";

import Slider from "react-slick";

import MoreVertIcon from "@material-ui/icons/MoreVert";
import SearchIcon from "@material-ui/icons/Search";
import StorefrontIcon from "@material-ui/icons/Storefront";

import { imageUrl, Instance } from "../../../Config";
import {
  INIT_CATEGORY,
  INIT_PRODUCT,
  REMOVE_ALL_PRODUCT,
  SHOW_SNACKBAR,
  UPDATE_IMAGE_PRODUCT,
} from "../../../Context/globalActions.types";
import useSWR from "swr";
import { resizeImg } from "../../../utils/resizeImg";
import ProductLeadingClickAway from "../Components/ProductLeadingClickAway";
import axios from "axios";

import jsPDF from "jspdf";
import "jspdf-autotable";
import { CSVLink } from "react-csv";
import SaveAltIcon from "@material-ui/icons/SaveAlt";
import InsertDriveFileOutlinedIcon from "@material-ui/icons/InsertDriveFileOutlined";

const settings = {
  centerMode: false,
  slidesToShow: 1,
  arrows: true,
  dots: true,
  infinite: true,
  speed: 500,
  cssEase: "linear",
  autoplaySpeed: 6000,
  autoplay: true,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        arrows: true,
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        arrows: true,
        slidesToShow: 1,
      },
    },
  ],
};

const OwnerViewProducts = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [nextPage, setNextPage] = useState(undefined);
  const [previousPage, setPreviousPage] = useState(undefined);
  const [totalItem, setTotalItem] = useState(0);

  const [isPageChange, setIsPageChange] = useState(false);

  const [search, setSearch] = useState("");

  const [rows, setRows] = useState([]);

  const history = useHistory();
  const params = useParams();

  //Update Priority DIalog
  const [open1, setOpen1] = useState(false);
  const [updateData, setUpdateData] = useState({
    itemCd: "",
    data: [],
    isLoading: false,
  });

  //Image Upload States
  const [open, setOpen] = useState(false);
  const [localImg, setLocalImg] = useState([]);

  const [imageError, setImageError] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState([]);

  const location = useLocation();
  function getQueryVariable(variable) {
    var query = location.search.substring(1);
    var vars = query.split("&");
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split("=");
      if (decodeURIComponent(pair[0]) == variable) {
        return decodeURIComponent(pair[1]);
      }
    }
  }

  const { globalContext, dispatch } = useContext(GlobalContext);

  const { data: resProducts, error: productsError } = useSWR(
    getQueryVariable("type") == "productsWithoutImage"
      ? `/products/itemsWithoutImage?mobileNo=${params.mobileNo}`
      : getQueryVariable("type") == "category"
      ? `/products?mobileNo=${
          params.mobileNo
        }&per_page=${rowsPerPage}&page=${page}&dept_cd=${getQueryVariable(
          "value"
        )}`
      : `/products?mobileNo=${params.mobileNo}&per_page=${rowsPerPage}&page=${page}`
  );

  const handleSearch = (search) => {
    if (search != "") {
      history.push(`/${params.mobileNo}/owner/viewproducts?type=search`);
      setRows([]);
      Instance.get(`/products?mobileNo=${params.mobileNo}&query=${search}`)
        .then((res) => {
          if (res.status == 200) {
            setRows(res.data.result);
            setNextPage(undefined);
            setPreviousPage(undefined);
            dispatch({
              type: INIT_PRODUCT,
              payload: res.data.result,
            });
          }
        })
        .catch((e) => {
          dispatch({
            type: SHOW_SNACKBAR,
            payload: {
              message: e.response.message || "Something went wrong",
              type: "error",
            },
          });
        });
    } else {
      history.push(`/${params.mobileNo}/owner/viewproducts`);
      setPage(0);
      setNextPage(undefined);
      setPreviousPage(undefined);
      setTotalItem(0);
      const url =
        getQueryVariable("type") == "productsWithoutImage"
          ? `/products/itemsWithoutImage?mobileNo=${params.mobileNo}`
          : getQueryVariable("type") == "category"
          ? `/products?mobileNo=${
              params.mobileNo
            }&per_page=${rowsPerPage}&page=${page}&dept_cd=${getQueryVariable(
              "value"
            )}`
          : `/products?mobileNo=${params.mobileNo}&per_page=${rowsPerPage}&page=${page}`;
      Instance.get(url)
        .then((res) => {
          if (res.status == 200) {
            setRows(res.data.result);
            dispatch({
              type: INIT_PRODUCT,
              payload: res.data.result,
            });
            if (res.data.pagination) {
              if (res.data.pagination.err) {
                setTotalItem(0);
                setNextPage(0);
                setPreviousPage(0);
              } else {
                setTotalItem(res.data.pagination.totalItems);
                setNextPage(res.data.pagination.nextPage);
                setPreviousPage(res.data.pagination.previousPage);
              }
            }
          }
        })
        .catch((e) => {
          dispatch({
            type: SHOW_SNACKBAR,
            payload: {
              message: e.response.message || "Something went wrong",
              type: "error",
            },
          });
        });
    }
  };

  const { data: resCategoryData, error: resCategoryDataError } = useSWR(
    `/dept?mobileNo=${params.mobileNo}`
  );

  // const fetchDataByCategory = (category) => {
  //   Instance.get(
  //     `/products?mobileNo=${params.mobileNo}&per_page=${rowsPerPage}&page=${page}&dept_cd=${category}`
  //   )
  //     .then((res) => {
  //       if (res.status == 200) {
  //         setNextPage(res.data.pagination.nextPage);
  //         setPreviousPage(res.data.pagination.previousPage);
  //         dispatch({
  //           type: INIT_PRODUCT,
  //           payload: res.data.result,
  //         });
  //       }
  //     })
  //     .catch((e) => {
  //       setError(e.response.message || "Something went wrong");
  //     });
  // };

  //Upload Image methods

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpen1 = () => {
    setOpen1(true);
  };

  const handleClose1 = () => {
    setOpen1(false);
  };

  const sendImage = (image) => {
    return new Promise((resolve) => {
      Instance.post("/uploads/signUrl", {
        fileType: image.type,
      })
        .then(async (res) => {
          const img = await resizeImg(image, image.type.split("/")[1]);
          axios
            .put(res.data.result, img, {
              headers: {
                "Content-Type": image.type,
                "x-amz-acl": "public-read",
              },
            })
            .then(() => {
              resolve(res.data.fileName);
            })
            .catch((e) => {
              console.log(e);
            });
        })
        .catch((err) => {
          console.log(err.data);
        });
    });
  };

  const [tempImg, setTempImg] = useState([]);

  const uploadImage = async (uploadData) => {
    let imgArry = [];
    setIsUploading(true);
    for (const image of uploadData) {
      const data = await sendImage(image);
      imgArry.push(data);
    }
    setIsUploading(false);
    return imgArry;
  };

  const deleteImage = async (id) => {
    await Instance.delete(`/uploads/${id}`);
    setLocalImg(localImg.filter((e) => e != id));
  };

  const { data: resProductsDownload, error: resProductsDownloadError } = useSWR(
    `/products/getAllItems?mobileNo=${params.mobileNo}`
  );

  const [exportData, setExportData] = useState([]);
  const [excelExport, setExcelExport] = useState([]);
  const headers = [
    { label: "Item Cd", key: "ITEM_CD" },
    { label: "Item Name", key: "ITEM_NAME" },
    { label: "Dept Name", key: "dept_name" },
    { label: "Image Uploaded", key: "img" },
    { label: "Size Cd", key: "SIZE_CD" },
    { label: "Sale Rate 1", key: "SALE_RATE1" },
    { label: "Sale Rate 2", key: "SALE_RATE2" },
    { label: "Sale Rate 3", key: "SALE_RATE3" },
    { label: "Sale Rate 4", key: "SALE_RATE4" },
    { label: "Sale Rate 5", key: "SALE_RATE5" },
    { label: "Sale Disc", key: "SALE_DISC" },
  ];

  const generatePdfRows = () => {
    return new Promise((resolve, reject) => {
      let tempArry = [];

      if (resProductsDownload) {
        for (let i = 0; i < resProductsDownload.data.result.length; i++) {
          if (resProductsDownload.data.result[i].variant) {
            for (
              let m = 0;
              m < resProductsDownload.data.result[i].variant.length;
              m++
            ) {
              tempArry.push([
                resProductsDownload.data.result[i].ITEM_CD,
                resProductsDownload.data.result[i].ITEM_NAME,
                resProductsDownload.data.result[i].dept.dept_name,
                resProductsDownload.data.result[i].img ? "Y" : "N",
                resProductsDownload.data.result[i].variant[m].SIZE_CD,
                resProductsDownload.data.result[i].variant[m].SALE_RATE1,
                resProductsDownload.data.result[i].variant[m].SALE_RATE2,
                resProductsDownload.data.result[i].variant[m].SALE_RATE3,
                resProductsDownload.data.result[i].variant[m].SALE_RATE4,
                resProductsDownload.data.result[i].variant[m].SALE_RATE5,
                resProductsDownload.data.result[i].variant[m].SALE_DISC,
              ]);
            }
          }
        }
      }
      resolve(tempArry);
    });
  };

  const generateExcelRows = () => {
    return new Promise((resolve, reject) => {
      let tempArry = [];

      if (resProductsDownload) {
        for (let i = 0; i < resProductsDownload.data.result.length; i++) {
          if (resProductsDownload.data.result[i].variant) {
            for (
              let m = 0;
              m < resProductsDownload.data.result[i].variant.length;
              m++
            ) {
              tempArry.push({
                ITEM_CD: resProductsDownload.data.result[i].ITEM_CD,
                ITEM_NAME: resProductsDownload.data.result[i].ITEM_NAME,
                dept_name: resProductsDownload.data.result[i].dept.dept_name,
                img: resProductsDownload.data.result[i].img ? "Y" : "N",
                SIZE_CD: resProductsDownload.data.result[i].variant[m].SIZE_CD,
                SALE_RATE1:
                  resProductsDownload.data.result[i].variant[m].SALE_RATE1,
                SALE_RATE2:
                  resProductsDownload.data.result[i].variant[m].SALE_RATE2,
                SALE_RATE3:
                  resProductsDownload.data.result[i].variant[m].SALE_RATE3,
                SALE_RATE4:
                  resProductsDownload.data.result[i].variant[m].SALE_RATE4,
                SALE_RATE5:
                  resProductsDownload.data.result[i].variant[m].SALE_RATE5,
                SALE_DISC:
                  resProductsDownload.data.result[i].variant[m].SALE_DISC,
              });
            }
          }
        }
      }
      resolve(tempArry);
    });
  };

  const exportPdf = (data) => {
    const unit = "pt";
    const size = "A4";
    const orientation = "landscape";

    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    const headers = [
      [
        "Item Cd",
        "Item Name",
        "Dept Name",
        "Image Uploaded",
        "Size Cd",
        "Sale Rate 1",
        "Sale Rate 2",
        "Sale Rate 3",
        "Sale Rate 4",
        "Sale Rate 5",
        "Sale Disc",
      ],
    ];

    let content = {
      startY: 90,
      head: headers,
      body: data,
    };

    let width1 = doc.internal.pageSize.getWidth();

    doc.setFontSize(14).setFont(undefined, "bold");
    doc
      .text(globalContext.company[0].COMP_NAME, width1 / 2, 30, {
        align: "center",
      })
      .setFontSize(8)
      .setFont(undefined, "bold");
    doc.text(globalContext.company[0].ADDRS, width1 / 2, 50, {
      align: "center",
    });
    doc.setFontSize(14).setFont(undefined, "bold");
    doc.text("Product List", width1 / 2, 80, {
      align: "center",
    });
    doc.setFontSize(10).setFont(undefined, "bold");
    doc.autoTable(content);
    doc.text(
      "Generated By Hub360",
      width1 / 2,
      doc.previousAutoTable.finalY + 30,
      {
        align: "center",
      }
    );
    doc.save("ProductList.pdf");
  };

  const saveData = () => {
    if (selectedProduct.img) {
      if (localImg.length == 0) {
        Instance.delete(`/products/${selectedProduct.img.imgid}`)
          .then((res) => {
            dispatch({
              payload: {
                ITEM_CD: selectedProduct.ITEM_CD,
              },
              type: UPDATE_IMAGE_PRODUCT,
            });
            setLocalImg([]);
            handleClose();
          })
          .catch((e) => {
            handleClose();
          });
      } else {
        Instance.put("/products/image", {
          imgid: selectedProduct.img.imgid,
          code: selectedProduct.ITEM_CD,
          imgUrl: localImg,
          mobileNo: selectedProduct.MOBNOCO,
        })
          .then((res) => {
            dispatch({
              payload: {
                imgid: selectedProduct.img.imgid,
                ITEM_CD: selectedProduct.ITEM_CD,
                IMGURL: localImg,
                MOBNOCO: selectedProduct.MOBNOCO,
              },
              type: UPDATE_IMAGE_PRODUCT,
            });
            handleClose();
          })
          .catch((e) => {
            handleClose();
            console.log(e);
          });
      }
    } else {
      if (localImg.length != 0) {
        Instance.post("/products/image", {
          code: selectedProduct.ITEM_CD,
          imgUrl: localImg,
          mobileNo: selectedProduct.MOBNOCO,
        })
          .then((res) => {
            dispatch({
              payload: {
                imgid: res.data.result.imgid,
                ITEM_CD: selectedProduct.ITEM_CD,
                IMGURL: localImg,
                MOBNOCO: selectedProduct.MOBNOCO,
              },
              type: UPDATE_IMAGE_PRODUCT,
            });
            handleClose();
          })
          .catch((e) => {
            handleClose();
            console.log(e);
          });
      }
    }
  };

  const handleUpdatePriority = () => {
    setUpdateData({
      ...updateData,
      isLoading: true,
    });
    Instance.put("/products", {
      itemCd: updateData.itemCd,
      data: updateData.data,
      mobileNo: params.mobileNo,
    })
      .then(() => {
        handleClose1();
        setUpdateData({
          isLoading: false,
          itemCd: "",
          data: [],
        });
      })
      .catch((e) => {
        handleClose1();
        setUpdateData({
          isLoading: false,
          itemCd: "",
          data: [],
        });
        console.log(e);
      });
  };

  useEffect(() => {
    dispatch({
      type: REMOVE_ALL_PRODUCT,
    });
    setRows([]);
  }, []);

  useEffect(() => {
    if (resCategoryData) {
      dispatch({
        type: INIT_CATEGORY,
        payload: resCategoryData.data.result,
      });
    }
  }, [resCategoryData]);

  useEffect(() => {
    if (resCategoryDataError) {
      dispatch({
        type: SHOW_SNACKBAR,
        payload: {
          message: "Something went wrong",
          type: "error",
        },
      });
    }
  }, [resCategoryDataError]);

  useEffect(() => {
    if (resProducts) {
      if (search != "") {
        dispatch({
          type: INIT_PRODUCT,
          payload: resProducts.data.result,
        });
        setRows(resProducts.data.result);
      } else {
        if (getQueryVariable("type") == "category") {
          dispatch({
            type: INIT_PRODUCT,
            payload: resProducts.data.result,
          });
          setRows(resProducts.data.result);
        } else if (getQueryVariable("type") == "productsWithoutImage") {
          dispatch({
            type: INIT_PRODUCT,
            payload: resProducts.data.result,
          });
          setRows(resProducts.data.result);
        } else {
          const uniqueArray = (a) =>
            [...new Set(a.map((o) => JSON.stringify(o)))].map((s) =>
              JSON.parse(s)
            );
          const newData = rows.concat(resProducts.data.result);
          const unique = uniqueArray(newData);
          setRows(unique);
          dispatch({
            type: INIT_PRODUCT,
            payload: unique,
          });
        }
      }
      if (resProducts.data.pagination) {
        if (resProducts.data.pagination.err) {
          setTotalItem(0);
          setNextPage(0);
          setPreviousPage(0);
        } else {
          setTotalItem(resProducts.data.pagination.totalItems);
          setNextPage(resProducts.data.pagination.nextPage);
          setPreviousPage(resProducts.data.pagination.previousPage);
        }
      }
    }
  }, [resProducts]);

  useEffect(() => {
    if (resProductsDownload) {
      setExportData(resProductsDownload.data.result);
      generateExcelRows().then((excel) => {
        setExcelExport(excel);
      });
    }
  }, [resProductsDownload]);

  useEffect(() => {
    if (productsError) {
      dispatch({
        type: SHOW_SNACKBAR,
        payload: {
          message: "Something went wrong",
          type: "error",
        },
      });
    }
  }, [productsError]);

  return (
    <div>
      <OwnerHeader>
        <div className="md:flex items-center justify-between px-3 md:px-4 py-3">
          <div>
            <div className="text-indigo-500 font-semibold text-sm md:text-base">
              Store Management
            </div>
            <div className="text-xl md:text-2xl font-extrabold leading-7 sm:leading-10 truncate mt-1">
              Products
            </div>
          </div>
          <div className="flex items-center mb-3 mt-2 md:mt-0">
            <Input
              placeholder="Search"
              className="bg-white border-[1px] border-[#3333cc] px-2 py-1 rounded-lg"
              disableUnderline
              value={search}
              onChange={(e) => {
                if (e.target.value == "") {
                  setSearch("");
                } else {
                  setSearch(e.target.value);
                }
              }}
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              }
            />
            <Button
              variant="contained"
              disableElevation
              className="ml-2 bg-[#3333cc] text-white"
              onClick={() => {
                handleSearch(search);
              }}
            >
              Search
            </Button>
          </div>
        </div>
        <Container maxWidth="xl" className="pt-3 bg-[#F1F5F9] min-h-screen">
          <UploadForm
            deleteImage={deleteImage}
            handleClose={handleClose}
            imageError={imageError}
            isUploading={isUploading}
            localImg={localImg}
            open={open}
            saveData={saveData}
            selectedProduct={selectedProduct}
            setImageError={setImageError}
            setLocalImg={setLocalImg}
            setTempImg={setTempImg}
            tempImg={tempImg}
            uploadImage={uploadImage}
          />
          <EditForm
            handleClose={handleClose1}
            open={open1}
            setUpdateData={setUpdateData}
            updateData={updateData}
            isLoading={updateData.isLoading}
            saveData={handleUpdatePriority}
          />
          <div>
            <div className="mb-3 flex items-center justify-between">
              <Chip
                label={"No Images"}
                onClick={(e) => {
                  dispatch({
                    type: REMOVE_ALL_PRODUCT,
                  });
                  setRows([]);
                  if (getQueryVariable("type") == "productsWithoutImage") {
                    history.push(`/${params.mobileNo}/owner/viewproducts`);
                  } else {
                    history.push(
                      `/${params.mobileNo}/owner/viewproducts?type=productsWithoutImage`
                    );
                  }
                }}
                variant={
                  getQueryVariable("type") == "productsWithoutImage"
                    ? "default"
                    : "outlined"
                }
                className={
                  getQueryVariable("type") == "productsWithoutImage"
                    ? "mx-1 bg-[#3333cc] text-white"
                    : "mx-1 border-[#3333cc]"
                }
              />
              <div>
                {!resProductsDownload ? (
                  <CircularProgress size={22} />
                ) : (
                  <>
                    <Button
                      variant="outlined"
                      className="text-blue-600 border-[#3333cc] rounded-full mt-2 md:mt-0"
                      startIcon={<SaveAltIcon />}
                    >
                      <CSVLink
                        data={excelExport}
                        headers={headers}
                        filename="ProductsList.csv"
                        className="text-blue-600"
                      >
                        Excel
                      </CSVLink>
                    </Button>
                    <Button
                      variant="outlined"
                      className="text-blue-600 border-[#3333cc] rounded-full mt-2 md:mt-0 ml-2"
                      startIcon={<InsertDriveFileOutlinedIcon />}
                      onClick={() => {
                        generatePdfRows().then((pdfData) => {
                          exportPdf(pdfData);
                        });
                      }}
                    >
                      PDF
                    </Button>
                  </>
                )}
              </div>
              {/* {globalContext.category.map((item) => (
                <Chip
                  key={item.DEPT_CD}
                  label={item.dept_name}
                  onClick={(e) => {
                    history.push(
                      `/${params.mobileNo}/owner/viewproducts?type=category&value=${item.DEPT_CD}`
                    );
                  }}
                  variant={
                    getQueryVariable("value") == item.DEPT_CD
                      ? "default"
                      : "outlined"
                  }
                  className="mx-1 border-[#3333cc]"
                />
              ))} */}
            </div>
          </div>
          {!resProducts && globalContext.products.length == 0 ? (
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-5">
              {["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L"].map(
                (item) => (
                  <div key={item}>
                    <Skeleton
                      animation="wave"
                      variant="rect"
                      width={350}
                      height={118}
                    />
                  </div>
                )
              )}
            </div>
          ) : globalContext.products.length == 0 ? (
            <div className="min-h-screen text-center">
              <StorefrontIcon className="text-black text-[12rem]" />
              <Typography className="text-2xl font-bold text-black">
                Oops!
              </Typography>
              <Typography>No Data Found.</Typography>
            </div>
          ) : (
            <>
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-3 w-full pb-6">
                {globalContext.products.map((item, index) => (
                  <CustomCard
                    key={index}
                    item={item}
                    params={params}
                    handleClickOpen={handleClickOpen}
                    setLocalImg={setLocalImg}
                    setSelectedProduct={setSelectedProduct}
                    handleClickOpen1={handleClickOpen1}
                    updateData={updateData}
                    setUpdateData={setUpdateData}
                  />
                ))}
              </div>
              {nextPage ? (
                <div className="text-center pb-4">
                  <Button
                    variant="contained"
                    className="bg-[#3333cc] text-white"
                    disableElevation
                    onClick={() => {
                      setPage((pr) => pr + 1);
                      setIsPageChange(!isPageChange);
                    }}
                  >
                    Load More
                  </Button>
                </div>
              ) : null}
            </>
          )}
        </Container>
      </OwnerHeader>
    </div>
  );
};

const CustomCard = ({
  item,
  params,
  setLocalImg,
  setSelectedProduct,
  handleClickOpen,
  handleClickOpen1,
  updateData,
  setUpdateData,
}) => {
  const { dispatch } = useContext(GlobalContext);
  const [dragging, setDragging] = React.useState(false);
  const [isUploading, setIsUploading] = useState(false);

  const drop = React.useRef(null);
  const drag = React.useRef(null);

  React.useEffect(() => {
    drop.current.addEventListener("dragover", handleDragOver);
    drop.current.addEventListener("drop", handleDrop);
    drop.current.addEventListener("dragenter", handleDragEnter);
    drop.current.addEventListener("dragleave", handleDragLeave);

    // return () => {
    //   if (drop) {
    //     drop.current.removeEventListener("dragover", handleDragOver);
    //     drop.current.removeEventListener("drop", handleDrop);
    //   }
    // };
  }, []);

  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (e.target !== drag.current) {
      setDragging(true);
    }
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (e.target === drag.current) {
      setDragging(false);
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const sendImage = (image) => {
    return new Promise((resolve) => {
      Instance.post("/uploads/signUrl", {
        fileType: image.type,
      })
        .then(async (res) => {
          const img = await resizeImg(image, image.type.split("/")[1]);
          axios
            .put(res.data.result, img, {
              headers: {
                "Content-Type": image.type,
                "x-amz-acl": "public-read",
              },
            })
            .then(() => {
              resolve(res.data.fileName);
            })
            .catch((e) => {
              console.log(e);
            });
        })
        .catch((err) => {
          console.log(err.data);
        });
    });
  };

  const uploadImage = async (uploadData) => {
    let imgArry = [];
    for (const image of uploadData) {
      const data = await sendImage(image);
      imgArry.push(data);
    }
    return imgArry;
  };

  const setValue = () => {
    return new Promise((resolve) => {
      if (item.img) {
        if (typeof item.img.IMGURL == "string") {
          resolve(item.img.IMGURL.length == 0 ? [] : item.img.IMGURL);
        } else {
          resolve(item.img.IMGURL.length == 0 ? [] : item.img.IMGURL);
        }
      } else {
        resolve([]);
      }
    });
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);

    const { files } = e.dataTransfer;

    setValue().then(async (localFiles) => {
      if (files.length > 4 || files.length + localFiles.length > 4) {
        dispatch({
          type: SHOW_SNACKBAR,
          payload: {
            message: `Only 4 Images are allowed for item ${item.ITEM_CD}`,
            type: "error",
          },
        });
        return;
      }

      if (files && files.length) {
        setIsUploading(true);
        if (item.img) {
          const total = files.length + localFiles.length;
          if (total <= 4) {
            const imgUrls = await uploadImage(files);
            saveData(localFiles.concat(imgUrls));
          } else {
            dispatch({
              type: SHOW_SNACKBAR,
              payload: {
                message: `Only 4 Images are allowed for item ${item.ITEM_CD}`,
                type: "error",
              },
            });
            return;
          }
        } else {
          const total = files.length + localFiles.length;
          if (total <= 4) {
            const imgUrls = await uploadImage(files);
            saveData(localFiles.concat(imgUrls));
          } else {
            dispatch({
              type: SHOW_SNACKBAR,
              payload: {
                message: `Only 4 Images are allowed for item ${item.ITEM_CD}`,
                type: "error",
              },
            });
            return;
          }
        }
      }
    });
  };

  const saveData = (localImg) => {
    if (item.img) {
      Instance.put("/products/image", {
        imgid: item.img.imgid,
        code: item.ITEM_CD,
        imgUrl: localImg,
        mobileNo: item.MOBNOCO,
      })
        .then((res) => {
          dispatch({
            payload: {
              imgid: item.img.imgid,
              ITEM_CD: item.ITEM_CD,
              IMGURL: localImg,
              MOBNOCO: item.MOBNOCO,
            },
            type: UPDATE_IMAGE_PRODUCT,
          });
          setIsUploading(false);
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      if (localImg.length != 0) {
        Instance.post("/products/image", {
          code: item.ITEM_CD,
          imgUrl: localImg,
          mobileNo: item.MOBNOCO,
        })
          .then((res) => {
            dispatch({
              payload: {
                imgid: res.data.result.imgid,
                ITEM_CD: item.ITEM_CD,
                IMGURL: localImg,
                MOBNOCO: item.MOBNOCO,
              },
              type: UPDATE_IMAGE_PRODUCT,
            });
            setIsUploading(false);
          })
          .catch((e) => {
            console.log(e);
          });
      }
    }
  };

  return (
    <Link
      key={item.ITEM_CD}
      to={`/${params.mobileNo}/owner/editproduct?id=${item.ITEM_CD}`}
    >
      <div
        ref={drop}
        className=" bg-white rounded-md border-[.5px] FilesDragAndDrop border-[#3333cc]"
      >
        {/* {dragging && (
          <div ref={drag} className="FilesDragAndDrop__placeholder"></div>
        )} */}
        {isUploading && (
          <div className="FilesDragAndDrop__placeholder">
            <CircularProgress size={30} />
          </div>
        )}
        <div className="flex flex-row">
          {item.img ? (
            typeof item.img.IMGURL == "string" ? (
              <img
                className="object-cover h-24 md:h-28 rounded-t-lg w-20 md:w-24 md:rounded-none md:rounded-l-lg p-1"
                src={imageUrl + "/" + item.img.IMGURL[0]}
                alt={item.ITEM_NAME}
              />
            ) : item.img.length != 0 ? (
              <img
                className="object-cover h-24 md:h-28 rounded-t-lg w-20 md:w-24 md:rounded-none md:rounded-l-lg p-1"
                src={imageUrl + "/" + item.img.IMGURL[0]}
                alt={item.ITEM_NAME}
              />
            ) : (
              <img
                src="/img/11.jpg"
                className="object-cover h-24 md:h-28 rounded-t-lg w-20 md:w-24 md:rounded-none md:rounded-l-lg p-1"
                alt={item.ITEM_NAME}
              />
            )
          ) : (
            <img
              src="/img/11.jpg"
              className="object-cover h-24 md:h-28 rounded-t-lg w-20 md:w-24 md:rounded-none md:rounded-l-lg p-1"
              alt={item.ITEM_NAME}
            />
          )}

          <div className="px-2 pt-3 w-full">
            <Typography
              variant="body1"
              className="mb-1 text-sm md:text-base font-bold text-gray-900 md:leading-4 leading-none "
            >
              <div className="flex items-start justify-between">
                <div>{item.ITEM_NAME}</div>
                <div>
                  <ProductLeadingClickAway
                    handleButtonClick={(e) => {
                      e.preventDefault();
                      if (item.img) {
                        if (typeof item.img.IMGURL == "string") {
                          setLocalImg(
                            item.img.IMGURL.length == 0 ? [] : item.img.IMGURL
                          );
                        } else {
                          setLocalImg(
                            item.img.IMGURL.length == 0 ? [] : item.img.IMGURL
                          );
                        }
                      } else {
                        setLocalImg([]);
                      }
                      setSelectedProduct(item);
                      handleClickOpen();
                    }}
                    children={
                      <MoreVertIcon className="text-gray-700 cursor-pointer" />
                    }
                  />
                </div>
              </div>
            </Typography>
            <Typography
              variant="body2"
              className="text-xs font-normal rounded bg-[#3333cc] max-w-max px-1 py-1 text-white"
            >
              {item.dept ? item.dept.dept_name : ""}
            </Typography>
            <div className="grid justify-end w-full">
              <Button
                variant="outlined"
                disableElevation
                className="border-[#3333cc] mt-2 mb-2"
                onClick={(e) => {
                  e.preventDefault();
                  handleClickOpen1();
                  setUpdateData({
                    ...updateData,
                    itemCd: item.ITEM_CD,
                    data: item,
                  });
                }}
              >
                Edit
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
};

const UploadForm = ({
  open,
  handleClose,
  setImageError,
  setTempImg,
  selectedProduct,
  localImg,
  isUploading,
  tempImg,
  deleteImage,
  saveData,
  imageError,
  setLocalImg,
  uploadImage,
}) => {
  return (
    <div>
      <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth="sm">
        <DialogTitle>
          <div className="flex justify-between">
            <p>Upload Images</p>
            <label htmlFor="contained-button-file">
              <input
                accept="image/*"
                id="contained-button-file"
                multiple
                type="file"
                style={{
                  display: "none",
                }}
                onChange={async (e) => {
                  setImageError(false);
                  if (selectedProduct.img) {
                    const total = e.target.files.length + localImg.length;
                    if (total <= 4) {
                      let a = [];
                      for (let i = 0; i < e.target.files.length; i++) {
                        a.push(URL.createObjectURL(e.target.files[i]));
                        if (a.length == e.target.files.length) {
                          setTempImg(a.concat(localImg));
                        }
                      }
                      const imgUrls = await uploadImage(e.target.files);
                      setLocalImg(localImg.concat(imgUrls));
                      setTempImg([]);
                    } else {
                      setImageError(true);
                    }
                  } else {
                    const total = e.target.files.length + localImg.length;
                    if (total <= 4) {
                      let a = [];
                      for (let i = 0; i < e.target.files.length; i++) {
                        a.push(URL.createObjectURL(e.target.files[i]));
                        if (a.length == e.target.files.length) {
                          setTempImg(a.concat(localImg));
                        }
                      }
                      const imgUrls = await uploadImage(e.target.files);
                      setLocalImg(localImg.concat(imgUrls));
                      setTempImg([]);
                    } else {
                      setImageError(true);
                    }
                  }
                }}
              />
              <Button
                variant="contained"
                className="bg-[#3333cc] text-white"
                disableElevation
                component="span"
              >
                Select Image
              </Button>
              {imageError ? (
                <div className="text-sm pt-2 text-red-500">
                  You can upload only 4 images.
                </div>
              ) : (
                ""
              )}
            </label>
          </div>
        </DialogTitle>
        <DialogContent>
          {isUploading ? <LinearProgress /> : null}
          <div className="wrapper">
            {!isUploading
              ? localImg.map((im) => (
                  <div className="cs-card" key={im}>
                    <img src={imageUrl + "/" + im} />
                    <div className="cs-info">
                      <button onClick={() => deleteImage(im)}>Delete</button>
                    </div>
                  </div>
                ))
              : tempImg.map((im) => {
                  return (
                    <div className="cs-card" key={im}>
                      {im.split(":")[0] == "blob" ? (
                        <img src={im} />
                      ) : (
                        <img src={imageUrl + "/" + im} />
                      )}
                    </div>
                  );
                })}
          </div>
        </DialogContent>
        <DialogActions>
          {!isUploading ? (
            <>
              <Button onClick={handleClose} variant="text">
                Close
              </Button>
              <Button onClick={saveData} variant="text">
                Save
              </Button>
            </>
          ) : null}
        </DialogActions>
      </Dialog>
    </div>
  );
};

const EditForm = ({
  open,
  handleClose,
  isLoading,
  saveData,
  setUpdateData,
  updateData,
}) => {
  const params = useParams();
  const [sizeCd, setSizeCd] = useState("");
  const [localImg, setLocalImg] = useState("");

  const { data: resCatalog, error } = useSWR(
    `/catalog?mobileNo=${params.mobileNo}`
  );

  useEffect(() => {
    if (updateData.data.length != 0) {
      if (updateData.data.img) {
        if (typeof updateData.data.img.IMGURL == "string") {
          setLocalImg(
            updateData.data.img.IMGURL.length == 0
              ? []
              : updateData.data.img.IMGURL
          );
        } else {
          setLocalImg(
            updateData.data.img.IMGURL.length == 0
              ? []
              : updateData.data.img.IMGURL
          );
        }
      } else {
        setLocalImg([]);
      }
      setSizeCd(updateData.data.variant[0].SIZE_CD);
    }
  }, [updateData]);

  return (
    <div>
      <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth="md">
        <DialogTitle>Edit Product</DialogTitle>
        <DialogContent>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
            <div className="quick_view_slide">
              {localImg.length != 0 ? (
                <Slider {...settings}>
                  {localImg.map((productImage) => (
                    <div key={productImage}>
                      <a
                        href={imageUrl + "/" + productImage}
                        data-lightbox="roadtrip"
                        className="d-block mb-4"
                      >
                        <img
                          src={imageUrl + "/" + productImage}
                          className="img-fluid w-screen h-[200px] md:w-[300px] md:h-[300px] object-contain"
                          alt=""
                        />
                      </a>
                    </div>
                  ))}
                </Slider>
              ) : (
                <div>
                  <a
                    href="/img/11.jpg"
                    data-lightbox="roadtrip"
                    className="d-block mb-4"
                  >
                    <img
                      src="/img/11.jpg"
                      className="img-fluid w-screen h-[200px] md:w-[300px] md:h-[300px] object-contain"
                      alt=""
                    />
                  </a>
                </div>
              )}
            </div>
            <div>
              <h3 className="ft-bold mb-2 capitalize">
                {updateData.data.ITEM_NAME
                  ? updateData.data.ITEM_NAME.toString().toLowerCase()
                  : null}
              </h3>
              <span className="rounded px-2 py-1 bg-[#3333cc] text-white">
                {updateData.data.dept ? updateData.data.dept.dept_name : ""}
              </span>
              <div className="grid grid-cols-1 md:grid-cols-4 gap-2 items-center mt-3">
                <div className="md:col-span-2">
                  <div className="font-bold">Priority:</div>
                  <Input
                    className="bg-white border-[1px] border-[#3333cc] px-2 py-1 rounded-lg"
                    disableUnderline
                    value={updateData.data.PRIORITY}
                    onChange={(e) => {
                      setUpdateData({
                        ...updateData,
                        data: {
                          ...updateData.data,
                          PRIORITY: e.target.value,
                        },
                      });
                    }}
                    type="number"
                  />
                </div>
                <div className="md:col-span-2">
                  <div>
                    <div className="font-bold">Catalog:</div>
                    <Autocomplete
                      id="combo-box-demo"
                      options={resCatalog ? resCatalog.data.result : []}
                      getOptionLabel={(option) => option.TITLE}
                      onChange={(e, newValue) => {
                        if (newValue) {
                          setUpdateData({
                            ...updateData,
                            data: {
                              ...updateData.data,
                              CATALOG: newValue.caId,
                            },
                          });
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          className="bg-white"
                          variant="outlined"
                        />
                      )}
                    />
                  </div>
                </div>
                <div>
                  <div>
                    <div className="font-bold">Size Cd:</div>
                    <Autocomplete
                      id="combo-box-demo"
                      options={
                        updateData.data.length != 0
                          ? updateData.data.variant
                          : []
                      }
                      getOptionLabel={(option) => option.SIZE_CD}
                      onChange={(e, newValue) => {
                        if (newValue) {
                          setSizeCd(newValue.SIZE_CD);
                        }
                      }}
                      value={
                        updateData.data.length != 0
                          ? updateData.data.variant.find(
                              (e) => e.SIZE_CD == sizeCd
                            )
                          : ""
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          className="bg-white"
                          variant="outlined"
                        />
                      )}
                    />
                  </div>
                </div>
                {sizeCd != "" ? (
                  <>
                    <div>
                      <div className="font-bold">Sale Rate 1:</div>
                      <Input
                        className="bg-white border-[1px] border-[#3333cc] px-2 py-1 rounded-lg"
                        disableUnderline
                        value={
                          updateData.data.length != 0 &&
                          updateData.data.variant.find(
                            (e) => e.SIZE_CD == sizeCd
                          )
                            ? updateData.data.variant.find(
                                (e) => e.SIZE_CD == sizeCd
                              ).SALE_RATE1
                            : ""
                        }
                        onChange={(e) => {
                          const salesRate1Index =
                            updateData.data.variant.findIndex(
                              (e) => e.SIZE_CD == sizeCd
                            );
                          updateData.data.variant[salesRate1Index].SALE_RATE1 =
                            e.target.value;
                          setUpdateData({
                            ...updateData,
                            data: {
                              ...updateData.data,
                              variant: updateData.data.variant,
                            },
                          });
                        }}
                        type="number"
                      />
                    </div>
                    <div>
                      <div className="font-bold">Sale Rate 2:</div>
                      <Input
                        className="bg-white border-[1px] border-[#3333cc] px-2 py-1 rounded-lg"
                        disableUnderline
                        value={
                          updateData.data.length != 0 &&
                          updateData.data.variant.find(
                            (e) => e.SIZE_CD == sizeCd
                          )
                            ? updateData.data.variant.find(
                                (e) => e.SIZE_CD == sizeCd
                              ).SALE_RATE2
                            : ""
                        }
                        onChange={(e) => {
                          const salesRate2Index =
                            updateData.data.variant.findIndex(
                              (e) => e.SIZE_CD == sizeCd
                            );
                          updateData.data.variant[salesRate2Index].SALE_RATE2 =
                            e.target.value;
                          setUpdateData({
                            ...updateData,
                            data: {
                              ...updateData.data,
                              variant: updateData.data.variant,
                            },
                          });
                        }}
                        type="number"
                      />
                    </div>
                    <div>
                      <div className="font-bold">Sale Rate 3:</div>
                      <Input
                        className="bg-white border-[1px] border-[#3333cc] px-2 py-1 rounded-lg"
                        disableUnderline
                        value={
                          updateData.data.length != 0 &&
                          updateData.data.variant.find(
                            (e) => e.SIZE_CD == sizeCd
                          )
                            ? updateData.data.variant.find(
                                (e) => e.SIZE_CD == sizeCd
                              ).SALE_RATE3
                            : ""
                        }
                        onChange={(e) => {
                          const salesRate3Index =
                            updateData.data.variant.findIndex(
                              (e) => e.SIZE_CD == sizeCd
                            );
                          updateData.data.variant[salesRate3Index].SALE_RATE3 =
                            e.target.value;
                          setUpdateData({
                            ...updateData,
                            data: {
                              ...updateData.data,
                              variant: updateData.data.variant,
                            },
                          });
                        }}
                        type="number"
                      />
                    </div>
                    <div>
                      <div className="font-bold">Sale Rate 4:</div>
                      <Input
                        className="bg-white border-[1px] border-[#3333cc] px-2 py-1 rounded-lg"
                        disableUnderline
                        value={
                          updateData.data.length != 0 &&
                          updateData.data.variant.find(
                            (e) => e.SIZE_CD == sizeCd
                          )
                            ? updateData.data.variant.find(
                                (e) => e.SIZE_CD == sizeCd
                              ).SALE_RATE4
                            : ""
                        }
                        onChange={(e) => {
                          const salesRate4Index =
                            updateData.data.variant.findIndex(
                              (e) => e.SIZE_CD == sizeCd
                            );
                          updateData.data.variant[salesRate4Index].SALE_RATE4 =
                            e.target.value;
                          setUpdateData({
                            ...updateData,
                            data: {
                              ...updateData.data,
                              variant: updateData.data.variant,
                            },
                          });
                        }}
                        type="number"
                      />
                    </div>
                    <div>
                      <div className="font-bold">Sale Rate 5:</div>
                      <Input
                        className="bg-white border-[1px] border-[#3333cc] px-2 py-1 rounded-lg"
                        disableUnderline
                        value={
                          updateData.data.length != 0 &&
                          updateData.data.variant.find(
                            (e) => e.SIZE_CD == sizeCd
                          )
                            ? updateData.data.variant.find(
                                (e) => e.SIZE_CD == sizeCd
                              ).SALE_RATE5
                            : ""
                        }
                        onChange={(e) => {
                          const salesRate5Index =
                            updateData.data.variant.findIndex(
                              (e) => e.SIZE_CD == sizeCd
                            );
                          updateData.data.variant[salesRate5Index].SALE_RATE5 =
                            e.target.value;
                          setUpdateData({
                            ...updateData,
                            data: {
                              ...updateData.data,
                              variant: updateData.data.variant,
                            },
                          });
                        }}
                        type="number"
                      />
                    </div>
                  </>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
          <QuillEditor updateData={updateData} setUpdateData={setUpdateData} />
        </DialogContent>
        <DialogActions>
          {!isLoading ? (
            <>
              <Button onClick={handleClose} variant="text">
                Close
              </Button>
              <Button onClick={saveData} variant="text">
                Save
              </Button>
            </>
          ) : (
            <CircularProgress size={20} className="mr-3 mb-2" />
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
};

const QuillEditor = ({ updateData, setUpdateData }) => {
  const { quill, quillRef } = useQuill({
    modules: {
      toolbar: [
        ["bold", "italic", "underline", "strike"],
        ["blockquote"],
        [{ script: "sub" }, { script: "super" }],
        [{ indent: "-1" }, { indent: "+1" }],
        [{ align: [] }],
        ["link", "video"],
      ],
    },
  });

  useEffect(() => {
    if (quill) {
      if (updateData.data.ITEM_DESC)
        quill.clipboard.dangerouslyPasteHTML(updateData.data.ITEM_DESC);
      quill.on("text-change", () => {
        setUpdateData({
          ...updateData,
          data: {
            ...updateData.data,
            ITEM_DESC: quill.root.innerHTML,
          },
        });
      });
    }
  }, [quill]);

  useEffect(() => {
    if (updateData) {
      console.log(updateData);
      // quill.root.innerHTML = updateData.data.ITEM_DESC;
    }
  }, [updateData]);

  return (
    <div className="w-full mt-4 ">
      <div ref={quillRef} />
    </div>
  );
};

export default OwnerViewProducts;
