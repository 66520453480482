import React, { useState, useCallback, useRef, useEffect } from "react";
import { decode } from "jsonwebtoken";
import usePagination from "../../../hooks/usePagination";
import Header from "../../../Components/Header";
import {
  ListItem,
  ListItemAvatar,
  Avatar,
  Divider,
  CircularProgress,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
} from "@material-ui/core";
import { useParams } from "react-router-dom";
import NotificationsIcon from "@material-ui/icons/Notifications";
import { imageUrl } from "../../../Config";

export default function Notification() {
  const params = useParams();

  const [notificationData, setNotificationData] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);

  const {
    data: resNotification,
    hasMore,
    loading: notificationLoading,
    error: notificationError,
  } = usePagination(
    `/notification?mobileNo=${params.mobileNo}&accCd=${
      decode(localStorage.getItem("user")).cd
    }&page=${pageNumber}`,
    pageNumber
  );

  const observer = useRef();
  const lastBookElementRef = useCallback(
    (node) => {
      if (notificationLoading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPageNumber((prevPageNumber) => prevPageNumber + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [notificationLoading, hasMore]
  );

  useEffect(() => {
    const array = notificationData.concat(resNotification);
    setNotificationData(array);
  }, [resNotification]);

  return (
    <div>
      <Header>
        <div className="mt-3">
          {notificationData.length != 0
            ? notificationData.map((data, index) => {
                if (notificationData.length === index + 1) {
                  return (
                    <div key={data.id} ref={lastBookElementRef}>
                      <ListItem divider={true}>
                        <ListItemAvatar>
                          <Avatar>
                            <NotificationsIcon />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          primary={data.body}
                          secondary={new Date(
                            data.createdAt
                          ).toLocaleDateString("us-en", {
                            dateStyle: "long",
                          })}
                        />
                        {data.imgUrl ? (
                          <img
                            height={50}
                            width={50}
                            src={imageUrl + "/" + data.imgUrl}
                          />
                        ) : null}
                      </ListItem>
                    </div>
                  );
                } else {
                  return (
                    <div key={data.id}>
                      <ListItem divider={true}>
                        <ListItemAvatar>
                          <Avatar>
                            <NotificationsIcon />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          primary={data.body}
                          secondary={new Date(
                            data.createdAt
                          ).toLocaleDateString("us-en", {
                            dateStyle: "long",
                          })}
                        />
                        {data.imgUrl ? (
                          <img
                            height={50}
                            width={50}
                            src={imageUrl + "/" + data.imgUrl}
                          />
                        ) : null}
                      </ListItem>
                    </div>
                  );
                }
              })
            : null}
        </div>
        {notificationLoading ? (
          <div className="flex justify-center">
            <CircularProgress />
          </div>
        ) : null}
      </Header>
    </div>
  );
}
