export const City = [
  "Adalaj",
  "Ahmedabad",
  "Ahwa",
  "Alang",
  "Alang-Sosiya",
  "Alikherva",
  "Amardad",
  "Ambaji",
  "Ambaliyasan",
  "Amboli",
  "Amod",
  "Amreli",
  "Anand",
  "Anandpar",
  "Andada",
  "Anjar",
  "Anklav",
  "Ankleshwar",
  "Antaliya",
  "Antarjal",
  "Arsodiya",
  "Atul",
  "Baben",
  "Babra",
  "Bagasara",
  "Bajwa",
  "Balasinor",
  "Balitha",
  "Baliyasan",
  "Bansda (Vansda)",
  "Bantwa (Bantva)",
  "Bardoli",
  "Bareja",
  "Barwala",
  "Bavla",
  "Bayad",
  "Bechar (Becharaji)",
  "Bhabhar",
  "Bhachau",
  "Bhadkodara",
  "Bhagal (Jagana)",
  "Bhagdawada",
  "Bhalpara",
  "Bhanvad",
  "Bharthana Kosad",
  "Bharuch",
  "Bhat",
  "Bhavnagar",
  "Bhayavadar",
  "Bhilad",
  "Bhiloda",
  "Bholav",
  "Bhuj",
  "Bhurivel",
  "Bilimora",
  "Bilimora (Talodh)",
  "Bodeli",
  "Bopal",
  "Boriavi",
  "Borsad",
  "Botad",
  "Chaklasi",
  "Chalala",
  "Chalthan",
  "Chanasma",
  "Chandrapur",
  "Chanod",
  "Chhapi",
  "Chhapra",
  "Chhatral",
  "Chhatral INA",
  "Chhaya",
  "Chhiri",
  "Chhota Udaipur",
  "Chikhli",
  "Chiloda (Naroda)",
  "Chorvad",
  "Chotila",
  "Dabhoi",
  "Daheli",
  "Dakor",
  "Damnagar",
  "Dediapada",
  "Deesa",
  "Dehari",
  "Dehgam (Dahegam)",
  "Deodar",
  "Devgadbaria (Devgadh Baria)",
  "Devsar",
  "Dhandhuka",
  "Dhanera",
  "Dharampur",
  "Dhasa Vishi",
  "Dhola",
  "Dholka",
  "Dhoraji",
  "Dhrangadhra",
  "Dhrol",
  "Digvijaygram",
  "Dohad (Dahod)",
  "Dungarpur",
  "Dwarka",
  "Freelandgunj",
  "Gadhada",
  "Gadkhol",
  "Galpadar",
  "Gamdi",
  "Gandevi",
  "Gandhidham",
  "Gandhinagar",
  "Gariadhar",
  "Ghanteshvar",
  "Ghogha",
  "Godhra",
  "Gondal",
  "GSFC (Motikhavdi Sikka)",
  "GSFC Complex INA",
  "Hadgood",
  "Hajira INA",
  "Halol",
  "Halvad",
  "Harij",
  "Himatnagar",
  "Ichchhapor",
  "Idar",
  "Jafrabad",
  "Jafrabad",
  "Jambusar",
  "Jamjodhpur",
  "Jamnagar",
  "Jarod",
  "Jasdan",
  "Jawaharnagar (Gujarat Refinery)",
  "Jetalsar",
  "Jetpur",
  "Jetpur Navagadh",
  "Jhadeshwar",
  "Jhalod",
  "Junagadh",
  "Kabilpor",
  "Kadi",
  "Kadodara",
  "Kakoshi",
  "Kalavad",
  "Kaliawadi",
  "Kalol",
  "Kalol",
  "Kalol INA",
  "Kalol INA",
  "Kandla",
  "Kanjari",
  "Kanodar",
  "Kansad",
  "Kapadvanj",
  "Karachiya",
  "Karamsad",
  "Karjan",
  "Karvad",
  "Kathlal",
  "Katpar",
  "Kavant",
  "Keshod",
  "Kevadiya",
  "Khambhalia (Jamkhambhaliya)",
  "Khambhat",
  "Khapat",
  "Kharach",
  "Kharaghoda",
  "Kheda",
  "Khedbrahma",
  "Kheralu",
  "Kim",
  "Kodinar",
  "Kosamba",
  "Kotharia",
  "Kutiyana",
  "Lathi",
  "Lavachha",
  "Lilia",
  "Limbdi",
  "Limkheda",
  "Limla",
  "Lodhika",
  "Lunawada (Lunavada)",
  "Madhapar",
  "Magdalla",
  "Mahendranagar",
  "Mahesana (Mehsana)",
  "Mahudha",
  "Mahuva",
  "Mahuvar",
  "Maktampur",
  "Malanka",
  "Maliya",
  "Malpur",
  "Manavadar",
  "Mandvi",
  "Mandvi",
  "Mangrol",
  "Mankuva",
  "Mansa",
  "Meghraj",
  "Mehmedabad",
  "Mirjhapar",
  "Mithapur",
  "Modasa",
  "Mora",
  "Morvi (Morbi)",
  "Mundra",
  "Nadiad",
  "Nanakwada (Nanakvada)",
  "Nandej",
  "Nandelav",
  "Nandesari",
  "Nandesari INA",
  "Nari",
  "Nasvadi",
  "Nava Bhildi",
  "Navsari",
  "Ode",
  "Okha",
  "Orvad",
  "Paddhari",
  "Padra",
  "Palaj",
  "Palanpur",
  "Palej",
  "Palitana",
  "Panoli",
  "Parabada",
  "Pardi",
  "Pardi Kanade",
  "Pardi Parnera",
  "Pardi Sondhpur",
  "Parnera",
  "Patan",
  "Patdi",
  "Pethapur",
  "Petlad",
  "Petro-Chemical Complex INA",
  "Porbandar",
  "Por-Ramangamdi",
  "Prantij",
  "Radhanpur",
  "Rajkot",
  "Rajpipla",
  "Rajula",
  "Ranavav",
  "Ranoli",
  "Ranpur",
  "Rapar",
  "Raval",
  "Ravapara",
  "Reliance Complex",
  "Sachin",
  "Sachin INA",
  "Sagbara",
  "Saij",
  "Saktasanala",
  "Salaya",
  "Salvav",
  "Sanand",
  "Sanjali",
  "Sanjan",
  "Sanjeli",
  "Santrampur",
  "Saputara",
  "Sarangpore",
  "Sarigam",
  "Sarigam INA",
  "Sathamba",
  "Savarkundla",
  "Savgadh",
  "Savli",
  "Sayan",
  "Selamba",
  "Shaktinagar",
  "Shapur",
  "Shehera",
  "Sherpura",
  "Sidhpur",
  "Sidsar",
  "Sihor",
  "Sikka",
  "Singarva",
  "Sojitra",
  "Solsumba",
  "Songadh",
  "Songadh",
  "Sukhpar",
  "Surat",
  "Surendranagar Dudhrej (Dudhrej)",
  "Sutrapada",
  "Talaja",
  "Talala",
  "Talod",
  "Tarsadi",
  "Tarsali",
  "Thangadh",
  "Thara",
  "Tharad",
  "Thasra",
  "Trajpar",
  "Ukai",
  "Umbergaon",
  "Umbergaon INA",
  "Umrala",
  "Umreth",
  "Una",
  "Undera",
  "Unjha",
  "Upleta",
  "Vadali",
  "Vadnagar",
  "Vadodara",
  "Vaghodia (Waghodia)",
  "Vaghodia INA",
  "Valia-Jhagadia, GNFC Scooter Project Area",
  "Valia-Naldhari",
  "Vallabhipur (Vallabhi)",
  "Vallabh Vidyanagar (Vallabh Vidhyanagar)",
  "Valsad",
  "Valsad INA",
  "Vanthali",
  "Vapi",
  "Vapi INA",
  "Vareli",
  "Vartej",
  "Vasna Borsad INA",
  "Vavdi Bujarg",
  "Vavol",
  "Veraval",
  "Veraval",
  "Vijalpor",
  "Vijapur",
  "Vijaynagar",
  "Viramgam",
  "Virpur",
  "Visavadar",
  "Visnagar",
  "Vithal Udyognagar INA",
  "Vyara",
  "Wadhwan",
  "Waghai",
  "Wankaner",
];
