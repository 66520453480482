import React, { useContext, useEffect, useState } from "react";
import ProfileDrawer from "./Components/ProfileDrawer";
import GlobalContext from "../../Context/globalContext";
import { SIGN_IN } from "../../Context/globalActions.types";
import { Redirect, useParams, Link } from "react-router-dom";
import { decode } from "jsonwebtoken";
import { Instance } from "../../Config";
import { Button, CircularProgress } from "@material-ui/core";

const ProfileInfo = () => {
  const { globalContext, dispatch } = useContext(GlobalContext);

  const params = useParams();

  const [data, setData] = useState({
    accCd: "",
    accName: "",
    personNm: "",
    clBal: "",
    creditDay: "",
    gstInNo: "",
    statCd: "",
    add1: "",
    add2: "",
    district: "",
    area: "",
    city: "",
    pincode: "",
    email: "",
    phone1: "",
    phone2: "",
    type: "user",
    mobnoCo: params.mobileNo,
  });

  const [isDisable, setIsDisable] = useState(true);

  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    Instance.put("/account", {
      accCd: data.accCd,
      accName: data.accName,
      personNm: data.personNm,
      clBal: data.clBal,
      creditDay: data.creditDay,
      gstInNo: data.gstInNo,
      statCd: data.statCd,
      add1: data.add1,
      add2: data.add2,
      add3: data.district,
      area: data.area,
      city: data.city,
      pincode: data.pincode,
      email: data.email,
      phone1: data.phone1,
      phone2: data.phone2,
      type: "user",
      mobnoCo: params.mobileNo,
    })
      .then(() => {
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (globalContext.account.ACC_CD) {
      setData({
        accCd: globalContext.account.ACC_CD,
        accName: globalContext.account.ACC_NAME || "",
        personNm: globalContext.account.PERSON_NM,
        email: globalContext.account.EMAIL,
        clBal: globalContext.account.CL_BAL,
        creditDay: globalContext.account.CREDIT_DAY,
        gstInNo: globalContext.account.GSTINNO,
        statCd: globalContext.account.STATECD,
        add1: globalContext.account.ADD1,
        add2: globalContext.account.ADD2,
        district: globalContext.account.ADD3,
        area: globalContext.account.AREA,
        city: globalContext.account.CITY,
        pincode: globalContext.account.PINCODE,
        phone1: globalContext.account.PHONE1,
        phone2: globalContext.account.PHONE2,
      });
      if (globalContext.account.ACC_CD.toString().split(0, 1)[0] == "B") {
        setIsDisable(false);
      }
    }
  }, [globalContext.account]);

  return (
    <ProfileDrawer
      breadcrubms={
        <div className="gray py-3">
          <div className="container">
            <div className="row">
              <div className="colxl-12 col-lg-12 col-md-12">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to={`/${params.mobileNo}/`}>Home</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Profile Info
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      }
    >
      <h2 className="ft-medium mb-4">Profile Info</h2>

      <form>
        <div className="row m-0">
          <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12">
            <div className="form-group">
              <label className="small text-dark ft-medium float-left">
                Account Code
              </label>
              <input
                type="text"
                className="form-control"
                value={data.accCd}
                disabled={true}
                onChange={(e) => {
                  setData({
                    ...data,
                    accCd: e.target.value,
                  });
                }}
              />
            </div>
          </div>
          <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12">
            <div className="form-group">
              <label className="small text-dark ft-medium float-left">
                Account Name
              </label>
              <input
                type="text"
                className="form-control"
                value={data.accName}
                disabled={isDisable}
                onChange={(e) => {
                  setData({
                    ...data,
                    accName: e.target.value,
                  });
                }}
              />
            </div>
          </div>
          <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12">
            <div className="form-group">
              <label className="small text-dark ft-medium float-left">
                Co Person Name
              </label>
              <input
                type="text"
                className="form-control"
                value={data.personNm}
                disabled={isDisable}
                onChange={(e) => {
                  setData({
                    ...data,
                    personNm: e.target.value,
                  });
                }}
              />
            </div>
          </div>
          <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12">
            <div className="form-group">
              <label className="small text-dark ft-medium float-left">
                Email
              </label>
              <input
                type="text"
                className="form-control"
                value={data.email}
                disabled={isDisable}
                onChange={(e) => {
                  setData({
                    ...data,
                    email: e.target.value,
                  });
                }}
              />
            </div>
          </div>
          <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12">
            <div className="form-group">
              <label className="small text-dark ft-medium float-left">
                Closing Balance
              </label>
              <input
                type="text"
                className="form-control"
                value={data.clBal}
                disabled={true}
                onChange={(e) => {
                  setData({
                    ...data,
                    clBal: e.target.value,
                  });
                }}
              />
            </div>
          </div>
          <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12">
            <div className="form-group">
              <label className="small text-dark ft-medium float-left">
                Credit Day
              </label>
              <input
                type="text"
                className="form-control"
                value={data.creditDay}
                disabled={true}
                onChange={(e) => {
                  setData({
                    ...data,
                    creditDay: e.target.value,
                  });
                }}
              />
            </div>
          </div>
          <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12">
            <div className="form-group">
              <label className="small text-dark ft-medium float-left">
                Gstin No:
              </label>
              <input
                type="text"
                className="form-control"
                value={data.gstInNo}
                disabled={isDisable}
                onChange={(e) => {
                  setData({
                    ...data,
                    gstInNo: e.target.value,
                  });
                }}
              />
            </div>
          </div>
          <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12">
            <div className="form-group">
              <label className="small text-dark ft-medium float-left">
                State Cd
              </label>
              <input
                type="text"
                className="form-control"
                value={data.statCd}
                disabled={true}
                onChange={(e) => {
                  setData({
                    ...data,
                    statCd: e.target.value,
                  });
                }}
              />
            </div>
          </div>
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
            <div className="form-group">
              <label className="small text-dark ft-medium float-left">
                Address Line 1:
              </label>
              <input
                type="text"
                className="form-control"
                value={data.add1}
                disabled={isDisable}
                onChange={(e) => {
                  setData({
                    ...data,
                    add1: e.target.value,
                  });
                }}
              />
            </div>
          </div>
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
            <div className="form-group">
              <label className="small text-dark ft-medium float-left">
                Address Line 2:
              </label>
              <input
                type="text"
                className="form-control"
                value={data.add2}
                disabled={isDisable}
                onChange={(e) => {
                  setData({
                    ...data,
                    add2: e.target.value,
                  });
                }}
              />
            </div>
          </div>
          <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12">
            <div className="form-group">
              <label className="small text-dark ft-medium float-left">
                District
              </label>
              <input
                type="text"
                className="form-control"
                value={data.district}
                disabled={isDisable}
                onChange={(e) => {
                  setData({
                    ...data,
                    district: e.target.value,
                  });
                }}
              />
            </div>
          </div>
          <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12">
            <div className="form-group">
              <label className="small text-dark ft-medium float-left">
                City
              </label>
              <input
                type="text"
                className="form-control"
                value={data.area}
                disabled={isDisable}
                onChange={(e) => {
                  setData({
                    ...data,
                    area: e.target.value,
                  });
                }}
              />
            </div>
          </div>
          <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12">
            <div className="form-group">
              <label className="small text-dark ft-medium float-left">
                State
              </label>
              <input
                type="text"
                className="form-control"
                value={data.city}
                disabled={isDisable}
                onChange={(e) => {
                  setData({
                    ...data,
                    city: e.target.value,
                  });
                }}
              />
            </div>
          </div>
          <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
            <div className="form-group">
              <label className="small text-dark ft-medium float-left">
                Pincode
              </label>
              <input
                type="text"
                className="form-control"
                value={data.pincode}
                disabled={isDisable}
                onChange={(e) => {
                  setData({
                    ...data,
                    pincode: e.target.value,
                  });
                }}
              />
            </div>
          </div>
          <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
            <div className="form-group">
              <label className="small text-dark ft-medium float-left">
                Phone No 1
              </label>
              <input
                type="text"
                className="form-control"
                value={data.phone1}
                disabled={isDisable}
                onChange={(e) => {
                  setData({
                    ...data,
                    phone1: e.target.value,
                  });
                }}
              />
            </div>
          </div>
          <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
            <div className="form-group">
              <label className="small text-dark ft-medium float-left">
                Phone No 2
              </label>
              <input
                type="text"
                className="form-control"
                value={data.phone2}
                disabled={isDisable}
                onChange={(e) => {
                  setData({
                    ...data,
                    phone2: e.target.value,
                  });
                }}
              />
            </div>
          </div>
        </div>
        {!isDisable ? (
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disableElevation
            className="mb-2"
            onClick={handleSubmit}
            disabled={isLoading}
          >
            {isLoading ? (
              <CircularProgress size={24} color="secondary" />
            ) : (
              "Save"
            )}
          </Button>
        ) : null}
      </form>
    </ProfileDrawer>
  );
};

export default ProfileInfo;
