import React, { useEffect, useState, useContext } from "react";

import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

import CircularProgress from "@material-ui/core/CircularProgress";
import {
  Container,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  InputAdornment,
  Input,
  DialogContentText,
} from "@material-ui/core";
import OwnerHeader from "./Components/OwnerHeader";
import { useHistory, useParams } from "react-router-dom";
import GlobalContext from "../../Context/globalContext";
import { Skeleton } from "@material-ui/lab";

import SearchIcon from "@material-ui/icons/Search";
import StorefrontIcon from "@material-ui/icons/Storefront";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import CloseIcon from "@material-ui/icons/Close";
import AddIcon from "@material-ui/icons/Add";

import { imageUrl, Instance } from "../../Config";
import {
  ADD_CATALOG,
  DELETE_CATALOG,
  INIT_CATALOG,
  SHOW_SNACKBAR,
  UPDATE_CATALOG,
} from "../../Context/globalActions.types";
import useSWR from "swr";
import axios from "axios";
import { resizeImg } from "../../utils/resizeImg";

import jsPDF from "jspdf";
import "jspdf-autotable";
import { CSVLink } from "react-csv";
import SaveAltIcon from "@material-ui/icons/SaveAlt";
import InsertDriveFileOutlinedIcon from "@material-ui/icons/InsertDriveFileOutlined";

const OwnerViewCatalog = () => {
  const [search, setSearch] = useState("");

  const [isUploading, setIsUploading] = useState(false);
  const [rows, setRows] = useState([]);
  const history = useHistory();

  const [isDownloading, setIsDownloading] = useState("");

  const [formValue, setFormValue] = useState({
    img: "",
    file: "",
    title: "",
    description: "",
    isUpdate: false,
    isFileUpdate: false,
    isImgUpdate: false,
    caId: "",
  });

  const [formError, setFormError] = useState({
    message: null,
    type: null,
  });

  const params = useParams();

  const [open, setOpen] = React.useState(false);
  const [deleteFId, setDeleteFId] = useState(0);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [open1, setOpen1] = React.useState(false);

  const handleClickOpen1 = () => {
    setFormValue({
      img: "",
      file: "",
      title: "",
      description: "",
      isUpdate: false,
      isFileUpdate: false,
      isImgUpdate: false,
      caId: "",
    });
    setFormError({
      message: null,
      type: null,
    });
    setOpen1(true);
  };

  const handleClose1 = () => {
    setOpen1(false);
  };

  const { globalContext, dispatch } = useContext(GlobalContext);

  const handleDelete = () => {
    Instance.delete(`/catalog/${deleteFId}`)
      .then(() => {
        dispatch({
          type: DELETE_CATALOG,
          payload: deleteFId,
        });
        handleClose();
      })
      .catch(() => {
        dispatch({
          type: SHOW_SNACKBAR,
          payload: {
            message: "Something went wrong",
            type: "error",
          },
        });
      });
  };

  const { data: resCatalog, error } = useSWR(
    `/catalog?mobileNo=${params.mobileNo}`
  );

  const [exportData, setExportData] = useState([]);
  const [excelExport, setExcelExport] = useState([]);
  const headers = [
    { label: "Title", key: "TITLE" },
    { label: "Description", key: "DESCRI" },
    { label: "File Uploaded", key: "FILE_NAME" },
    { label: "Image Uploaded", key: "IMG_URL" },
  ];

  const generatePdfRows = () => {
    return new Promise((resolve, reject) => {
      let tempArry = [];

      if (resCatalog) {
        for (let i = 0; i < resCatalog.data.result.length; i++) {
          tempArry.push([
            resCatalog.data.result[i].TITLE,
            resCatalog.data.result[i].DESCRI.length > 20
              ? resCatalog.data.result[i].DESCRI.split(0, 20) + "..."
              : resCatalog.data.result[i].DESCRI,
            resCatalog.data.result[i].FILE_NAME ? "Y" : "N",
            resCatalog.data.result[i].IMG_URL ? "Y" : "N",
          ]);
        }
      }
      resolve(tempArry);
    });
  };

  const generateExcelRows = () => {
    return new Promise((resolve, reject) => {
      let tempArry = [];

      if (resCatalog) {
        for (let i = 0; i < resCatalog.data.result.length; i++) {
          tempArry.push({
            TITLE: resCatalog.data.result[i].TITLE,
            DESCRI: resCatalog.data.result[i].DESCRI,
            FILE_NAME: resCatalog.data.result[i].FILE_NAME ? "Y" : "N",
            IMG_URL: resCatalog.data.result[i].IMG_URL ? "Y" : "N",
          });
        }
      }
      resolve(tempArry);
    });
  };

  const exportPdf = (data) => {
    const unit = "pt";
    const size = "A4";
    const orientation = "potrait";

    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    const headers = [
      ["Title", "Description", "File Uploaded", "Image Uploaded"],
    ];

    let content = {
      startY: 90,
      head: headers,
      body: data,
    };

    let width1 = doc.internal.pageSize.getWidth();

    doc.setFontSize(14).setFont(undefined, "bold");
    doc
      .text(globalContext.company[0].COMP_NAME, width1 / 2, 30, {
        align: "center",
      })
      .setFontSize(8)
      .setFont(undefined, "bold");
    doc.text(globalContext.company[0].ADDRS, width1 / 2, 50, {
      align: "center",
    });
    doc.setFontSize(14).setFont(undefined, "bold");
    doc.text("Catalog List", width1 / 2, 80, {
      align: "center",
    });
    doc.setFontSize(10).setFont(undefined, "bold");
    doc.autoTable(content);
    doc.text(
      "Generated By Hub360",
      width1 / 2,
      doc.previousAutoTable.finalY + 30,
      {
        align: "center",
      }
    );
    doc.save("CatalogList.pdf");
  };

  useEffect(() => {
    if (resCatalog) {
      dispatch({
        type: INIT_CATALOG,
        payload: resCatalog.data.result,
      });
      setRows(resCatalog.data.result);
      generateExcelRows().then((excel) => {
        setExcelExport(excel);
      });
    }
  }, [resCatalog]);

  const getSignedUrlPromise = (file) => {
    return new Promise((resolve) => {
      Instance.post("/uploads/signUrl", {
        fileType: file.type,
      })
        .then(async (res) => {
          axios
            .put(res.data.result, file, {
              headers: {
                "Content-Type": file.type,
                "x-amz-acl": "public-read",
              },
            })
            .then(() => {
              resolve(res.data.fileName);
            })
            .catch((e) => {
              console.log(e);
            });
        })
        .catch((err) => {
          console.log(err.data);
        });
    });
  };

  const handleApiSubmit = (fileUrl, imgUrl) => {
    Instance.post("/catalog", {
      mobileNo: params.mobileNo,
      files: fileUrl,
      title: formValue.title,
      description: formValue.description,
      imgUrl: imgUrl,
    })
      .then((res) => {
        dispatch({
          type: ADD_CATALOG,
          payload: res.data.result,
        });
        setIsUploading(false);
        handleClose1();
        setFormValue({
          img: "",
          file: "",
          title: "",
          description: "",
          isUpdate: false,
          isFileUpdate: false,
          isImgUpdate: false,
          caId: "",
        });
      })
      .catch(() => {
        dispatch({
          type: SHOW_SNACKBAR,
          payload: {
            message: "Something went wrong",
            type: "error",
          },
        });
      });
  };

  const handleSearch = (search) => {
    if (search != "") {
      const searchRes = rows.filter(
        (e) =>
          e.TITLE.toLowerCase().includes(search.toString().toLowerCase()) ||
          e.DESCRI.toLowerCase().includes(search.toString().toLowerCase())
      );
      dispatch({
        type: INIT_CATALOG,
        payload: searchRes,
      });
    } else {
      history.push(`/${params.mobileNo}/owner/catalog`);
      dispatch({
        type: INIT_CATALOG,
        payload: rows,
      });
    }
  };

  const deleteFile = async (id) => {
    await Instance.delete(`/uploads/${id}`);
  };

  const handleUpdateCatalog = async (e) => {
    console.log(formValue);
    e.preventDefault();
    if (formValue.title == "") {
      setFormError({
        message: "Please Enter Valid Title",
        type: "title",
      });
      return;
    }
    if (formValue.file == "") {
      setFormError({
        message: "Please Select Valid File",
        type: "file",
      });
      return;
    }
    setFormError({
      message: null,
      type: null,
    });
    let tempFileUrl = formValue.file;
    let tempImgUrl = formValue.img;
    console.log(formValue);
    setIsUploading(true);
    if (formValue.isFileUpdate) {
      deleteFile(
        globalContext.catalog.find((e) => e.caId == formValue.caId).FILE_NAME
      );
      await getSignedUrlPromise(formValue.file).then((fileUrl) => {
        tempFileUrl = fileUrl;
      });
    }
    if (formValue.isImgUpdate) {
      deleteFile(
        globalContext.catalog.find((e) => e.caId == formValue.caId).IMG_URL
      );
      const resizedImg = await resizeImg(
        formValue.img,
        formValue.img.type.split("/")[1]
      );
      await getSignedUrlPromise(resizedImg).then((imgUrl) => {
        tempImgUrl = imgUrl;
      });
    }
    Instance.put("/catalog", {
      mobileNo: params.mobileNo,
      files: tempFileUrl,
      title: formValue.title,
      description: formValue.description,
      imgUrl: tempImgUrl,
      caId: formValue.caId,
    })
      .then((res) => {
        dispatch({
          type: UPDATE_CATALOG,
          payload: {
            MOBNOCO: params.mobileNo,
            FILE_NAME: tempFileUrl,
            TITLE: formValue.title,
            DESCRI: formValue.description,
            IMG_URL: tempImgUrl,
            caId: formValue.caId,
          },
        });
        setIsUploading(false);
        handleClose1();
        setFormValue({
          img: "",
          file: "",
          title: "",
          description: "",
          isUpdate: false,
          isFileUpdate: false,
          isImgUpdate: false,
          caId: "",
        });
      })
      .catch(() => {
        setIsUploading(false);
        handleClose1();
        setFormValue({
          img: "",
          file: "",
          title: "",
          description: "",
          isUpdate: false,
          isFileUpdate: false,
          isImgUpdate: false,
          caId: "",
        });
        dispatch({
          type: SHOW_SNACKBAR,
          payload: {
            message: "Something went wrong",
            type: "error",
          },
        });
      });
  };

  const handleCatalogSubmit = async (e) => {
    e.preventDefault();
    if (formValue.title == "") {
      setFormError({
        message: "Please Enter Valid Title",
        type: "title",
      });
      return;
    }
    if (formValue.file == "") {
      setFormError({
        message: "Please Select Valid File",
        type: "file",
      });
      return;
    }
    setFormError({
      message: null,
      type: null,
    });
    setIsUploading(true);
    if (formValue.img) {
      const resizedImg = await resizeImg(
        formValue.img,
        formValue.img.type.split("/")[1]
      );
      getSignedUrlPromise(resizedImg).then((imgUrl) => {
        getSignedUrlPromise(formValue.file).then((fileUrl) => {
          handleApiSubmit(fileUrl, imgUrl);
        });
      });
    } else {
      getSignedUrlPromise(formValue.file).then((fileUrl) => {
        handleApiSubmit(fileUrl, null);
      });
    }
  };

  return (
    <div>
      <OwnerHeader>
        <div className="md:flex items-center justify-between px-3 md:px-4 py-3">
          <DeleteDialog
            open={open}
            handleClose={handleClose}
            handleSubmit={handleDelete}
          />
          <AddDialog
            open={open1}
            handleClose={handleClose1}
            formValue={formValue}
            setFormValue={setFormValue}
            isUploading={isUploading}
            handleSubmit={
              formValue.isUpdate ? handleUpdateCatalog : handleCatalogSubmit
            }
            formError={formError}
          />
          <div>
            <div className="text-indigo-500 font-semibold text-sm md:text-base">
              Store Management
            </div>
            <div className="text-xl md:text-2xl font-extrabold leading-7 sm:leading-10 truncate mt-1">
              Catalog
            </div>
          </div>
          <div className="flex items-center mb-3 mt-2 md:mt-0">
            <Input
              placeholder="Search"
              className="bg-white border-[1px] border-[#3333cc] px-2 py-1 rounded-lg"
              disableUnderline
              value={search}
              onChange={(e) => {
                if (e.target.value == "") {
                  setSearch("");
                } else {
                  setSearch(e.target.value);
                }
              }}
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              }
            />
            <Button
              variant="contained"
              disableElevation
              className="ml-2 bg-[#3333cc] text-white"
              onClick={() => {
                history.push(`/${params.mobileNo}/owner/catalog?type=search`);
                handleSearch(search);
              }}
            >
              Search
            </Button>
          </div>
        </div>
        <Container maxWidth="xl" className="pt-3 bg-[#F1F5F9] min-h-screen">
          <div className="flex justify-end items-start">
            <Button
              variant="contained"
              color="primary"
              className="ml-2 bg-[#3333cc] text-white"
              disableElevation
              startIcon={<AddIcon />}
              onClick={handleClickOpen1}
            >
              Add
            </Button>
            <div>
              {!resCatalog ? (
                <CircularProgress size={22} />
              ) : (
                <div>
                  <Button
                    variant="outlined"
                    className="text-blue-600 border-[#3333cc] rounded-full ml-2"
                    startIcon={<SaveAltIcon />}
                  >
                    <CSVLink
                      data={excelExport}
                      headers={headers}
                      filename="CatalogList.csv"
                      className="text-blue-600"
                    >
                      Excel
                    </CSVLink>
                  </Button>
                  <Button
                    variant="outlined"
                    className="text-blue-600 border-[#3333cc] rounded-full ml-2"
                    startIcon={<InsertDriveFileOutlinedIcon />}
                    onClick={() => {
                      generatePdfRows().then((pdfData) => {
                        exportPdf(pdfData);
                      });
                    }}
                  >
                    PDF
                  </Button>
                </div>
              )}
            </div>
          </div>
          {!resCatalog && globalContext.catalog.length == 0 ? (
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-5">
              {["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L"].map(
                (item) => (
                  <div key={item}>
                    <Skeleton
                      animation="wave"
                      variant="rect"
                      width={350}
                      height={118}
                    />
                  </div>
                )
              )}
            </div>
          ) : globalContext.catalog.length == 0 ? (
            <div className="min-h-screen text-center">
              <StorefrontIcon className="text-black text-[12rem]" />
              <Typography className="text-2xl font-bold text-black">
                Oops!
              </Typography>
              <Typography>No Data Found.</Typography>
            </div>
          ) : (
            <>
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-3 w-full pb-6 mt-3">
                {resCatalog
                  ? globalContext.catalog.map((item, index) => (
                      <div
                        className=" bg-white rounded-md border-[.5px] border-[#3333cc]"
                        key={index}
                      >
                        <div className="flex flex-row">
                          {item.IMG_URL ? (
                            <img
                              className="object-cover h-24 md:h-32 rounded-t-lg w-20 md:w-28 md:rounded-none md:rounded-l-lg p-1"
                              src={imageUrl + "/" + item.IMG_URL}
                              alt={item.TITLE}
                            />
                          ) : (
                            <img
                              src="/img/11.jpg"
                              className="object-cover h-24 md:h-32 rounded-t-lg w-20 md:w-28 md:rounded-none md:rounded-l-lg p-1"
                              alt={item.TITLE}
                            />
                          )}
                          <div className="flex flex-col justify-between px-2 pt-3 w-full">
                            <Typography
                              variant="body1"
                              className="mb-1 text-sm md:text-xl md:leading-4 leading-none"
                            >
                              <div>
                                <div className="text-base text-gray-900 font-bold line-break-word-one">
                                  {item.TITLE}
                                </div>
                                <div className="text-xs text-slate-400 mt-1 line-break-word">
                                  {item.DESCRI ? item.DESCRI : ""}
                                </div>
                              </div>
                            </Typography>
                            <div className="flex mb-2 justify-between items-center">
                              {isDownloading == item.caId ? (
                                <CircularProgress size={20} />
                              ) : (
                                <Button
                                  variant="outlined"
                                  onClick={() => {
                                    setIsDownloading(item.caId);
                                    fetch(imageUrl + "/" + item.FILE_NAME).then(
                                      (response) => {
                                        response.blob().then((blob) => {
                                          const fileURL =
                                            window.URL.createObjectURL(blob);
                                          let alink =
                                            document.createElement("a");
                                          alink.href = fileURL;
                                          alink.download = "Catalog.pdf";
                                          alink.click();
                                          setIsDownloading("");
                                        });
                                      }
                                    );
                                  }}
                                >
                                  View
                                </Button>
                              )}
                              <Button
                                variant="outlined"
                                onClick={() => {
                                  handleClickOpen1();
                                  setFormValue({
                                    description: item.DESCRI,
                                    file: item.FILE_NAME,
                                    img: item.IMG_URL,
                                    title: item.TITLE,
                                    isUpdate: true,
                                    caId: item.caId,
                                    isFileUpdate: false,
                                    isImgUpdate: false,
                                  });
                                }}
                              >
                                Edit
                              </Button>
                              <Button
                                variant="outlined"
                                onClick={() => {
                                  handleClickOpen();
                                  setDeleteFId(item.caId);
                                }}
                              >
                                Delete
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  : null}
              </div>
            </>
          )}
        </Container>
      </OwnerHeader>
    </div>
  );
};

const DeleteDialog = ({ open, handleClose, handleSubmit }) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Delete Catalog</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Are you sure you want to delete the Catalog?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          No
        </Button>
        <Button onClick={handleSubmit} color="primary">
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const AddDialog = ({
  open,
  handleClose,
  handleSubmit,
  formValue,
  setFormValue,
  isUploading,
  formError,
}) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle id="alert-dialog-title">
        {formValue.isUpdate ? "Update Catalog" : "Add Catalog"}
      </DialogTitle>
      <DialogContent>
        <div>
          <div className="font-bold">Title:</div>
          <Input
            className="bg-white border-[1px] border-[#3333cc] px-2 py-1 rounded-lg w-full"
            disableUnderline
            value={formValue.title}
            onChange={(e) => {
              setFormValue({ ...formValue, title: e.target.value });
            }}
          />
          {formError.type == "title" ? (
            <div className="text-red-500 text-xs mt-1">{formError.message}</div>
          ) : null}
        </div>
        <div className="mt-2">
          <div className="font-bold">Description:</div>
          <Input
            className="bg-white border-[1px] border-[#3333cc] px-2 py-1 rounded-lg w-full"
            disableUnderline
            rows={4}
            multiline
            value={formValue.description}
            onChange={(e) => {
              setFormValue({ ...formValue, description: e.target.value });
            }}
          />
        </div>
        <div className="md:flex justify-between mt-3">
          {formValue.img && !isUploading ? (
            <div className="relative">
              <div
                className="absolute right-0 cursor-pointer"
                onClick={() => {
                  setFormValue({
                    ...formValue,
                    img: "",
                  });
                }}
              >
                <CloseIcon />
              </div>
              <img
                src={
                  formValue.isUpdate && !formValue.isImgUpdate
                    ? imageUrl + "/" + formValue.img
                    : URL.createObjectURL(formValue.img)
                }
                className="h-32 w-32"
              />
            </div>
          ) : (
            <div className="mt-2">
              <input
                accept="img/*"
                id="contained-button-file"
                type="file"
                style={{
                  display: "none",
                }}
                onChange={(e) => {
                  if (formValue.isUpdate) {
                    setFormValue({
                      ...formValue,
                      img: e.target.files[0],
                      isImgUpdate: true,
                    });
                  } else {
                    setFormValue({
                      ...formValue,
                      img: e.target.files[0],
                    });
                  }
                }}
              />
              <label htmlFor="contained-button-file">
                <Button
                  variant="contained"
                  color="primary"
                  component="span"
                  className="bg-[#3333cc] text-white"
                  disableElevation
                  startIcon={<CloudUploadIcon />}
                >
                  Upload Image
                </Button>
              </label>
            </div>
          )}

          {formValue.file && !isUploading ? (
            <div className="flex items-start">
              <a
                target="_blank"
                href={
                  formValue.isUpdate && !formValue.isFileUpdate
                    ? imageUrl + "/" + formValue.file
                    : `${URL.createObjectURL(formValue.file)}`
                }
              >
                <div>File Uploaded</div>
              </a>
              <div
                className="cursor-pointer"
                onClick={() => {
                  setFormValue({
                    ...formValue,
                    file: "",
                  });
                }}
              >
                <CloseIcon />
              </div>
            </div>
          ) : (
            <div className="mt-2">
              <input
                accept="pdf/*"
                id="contained-button-file1"
                type="file"
                style={{
                  display: "none",
                }}
                onChange={(e) => {
                  if (formValue.isUpdate) {
                    setFormValue({
                      ...formValue,
                      file: e.target.files[0],
                      isFileUpdate: true,
                    });
                  } else {
                    setFormValue({
                      ...formValue,
                      file: e.target.files[0],
                    });
                  }
                }}
              />

              <label htmlFor="contained-button-file1">
                <Button
                  variant="outlined"
                  color="primary"
                  component="span"
                  className="border-[#3333cc]"
                  disableElevation
                  startIcon={<CloudUploadIcon />}
                >
                  Upload File
                </Button>
              </label>
              {formError.type == "file" ? (
                <div className="text-red-500 text-xs mt-1">
                  {formError.message}
                </div>
              ) : null}
            </div>
          )}
        </div>
      </DialogContent>
      <DialogActions>
        {!isUploading ? (
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        ) : null}
        <Button onClick={handleSubmit} disabled={isUploading} color="primary">
          {isUploading ? <CircularProgress size={20} /> : "Save"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default OwnerViewCatalog;
