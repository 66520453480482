import React, {
  useState,
  useContext,
  useEffect,
  useRef,
  useCallback,
} from "react";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import { Rating, Skeleton } from "@material-ui/lab";
import ProductCard from "../../Components/ProductCard";
import jsPDF from "jspdf";
import "jspdf-autotable";

import BottomBar from "./Components/BottomBar";
import useSWR from "swr";

import StorefrontIcon from "@material-ui/icons/Storefront";
import GetAppIcon from "@material-ui/icons/GetApp";

import GlobalContext from "../../Context/globalContext";
import { Redirect, useParams, useLocation } from "react-router";
import { decode } from "jsonwebtoken";
import {
  SIGN_IN,
  LOAD_PRODUCT,
  INIT_PRODUCT,
  SHOW_SNACKBAR,
} from "../../Context/globalActions.types";
import { imageUrl } from "../../Config";
import CustomSnakBar from "../../Components/CustomSnakBar";
import { Link } from "react-router-dom";
import { IconButton, Tooltip, Typography } from "@material-ui/core";

const Shop = () => {
  const [data, setData] = useState([]);

  const [nextPage, setNextPage] = useState(0);

  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(25);

  const params = useParams();
  let mobileNo = params.mobileNo;

  const [isLoading, setIsLoading] = useState(true);

  const location = useLocation();
  function getQueryVariable(variable) {
    var query = location.search.substring(1);
    var vars = query.split("&");
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split("=");
      if (decodeURIComponent(pair[0]) == variable) {
        return decodeURIComponent(pair[1]);
      }
    }
  }

  const { globalContext, dispatch } = useContext(GlobalContext);

  const { data: resProducts, error: resProductsError } = useSWR(
    globalContext.user && globalContext.settings.length != 0
      ? decode(globalContext.user).groupCd == "85" &&
        globalContext.settings.filter(
          (e) => e.SETTING_NAME == "PARTY_WISE_RATE"
        )[0].VALUE == "Y"
        ? getQueryVariable("type") == "category"
          ? `/products?mobileNo=${
              params.mobileNo
            }&per_page=${perPage}&page=${page}&dept_cd=${getQueryVariable(
              "value"
            )}&groupCd=${decode(globalContext.user).groupCd}&partyCd=${
              decode(globalContext.user).cd
            }`
          : getQueryVariable("q") != "" && getQueryVariable("q")
          ? `/products?mobileNo=${params.mobileNo}&query=${getQueryVariable(
              "q"
            )}&groupCd=${decode(globalContext.user).groupCd}&partyCd=${
              decode(globalContext.user).cd
            }`
          : `/products?mobileNo=${
              params.mobileNo
            }&per_page=${perPage}&page=${page}&groupCd=${
              decode(globalContext.user).groupCd
            }&partyCd=${decode(globalContext.user).cd}`
        : getQueryVariable("type") == "category"
        ? `/products?mobileNo=${
            params.mobileNo
          }&per_page=${perPage}&page=${page}&dept_cd=${getQueryVariable(
            "value"
          )}&groupCd=${decode(globalContext.user).groupCd}`
        : getQueryVariable("q") != "" && getQueryVariable("q")
        ? `/products?mobileNo=${params.mobileNo}&query=${getQueryVariable(
            "q"
          )}&groupCd=${decode(globalContext.user).groupCd}`
        : `/products?mobileNo=${
            params.mobileNo
          }&per_page=${perPage}&page=${page}&groupCd=${
            decode(globalContext.user).groupCd
          }`
      : ""
  );

  const { data: resProductsDownload, error: resProductsDownloadError } = useSWR(
    globalContext.user && globalContext.settings.length != 0
      ? decode(globalContext.user).groupCd == "85" &&
        globalContext.settings.filter(
          (e) => e.SETTING_NAME == "PARTY_WISE_RATE"
        )[0].VALUE == "Y"
        ? `/products/getAllItems?mobileNo=${params.mobileNo}&groupCd=${
            decode(globalContext.user).groupCd
          }&partyCd=${decode(globalContext.user).cd}`
        : `/products/getAllItems?mobileNo=${params.mobileNo}&groupCd=${
            decode(globalContext.user).groupCd
          }`
      : ""
  );

  const generatePdfRows = () => {
    return new Promise((resolve, reject) => {
      let tempArry = [];

      if (resProductsDownload) {
        for (let i = 0; i < resProductsDownload.data.result.length; i++) {
          if (resProductsDownload.data.result[i].variant) {
            for (
              let m = 0;
              m < resProductsDownload.data.result[i].variant.length;
              m++
            ) {
              if (
                globalContext.settings.filter(
                  (e) => e.VARIABLE == "itemDisc"
                )[0].VALUE == "Y"
              ) {
                if (
                  globalContext.settings.filter(
                    (e) => e.SETTING_NAME == "PARTY_WISE_RATE"
                  )[0].VALUE == "Y"
                ) {
                  const discount =
                    resProductsDownload.data.result[i].variant[m].SALE_DISC /
                    100;
                  const discountAmt =
                    discount *
                    resProductsDownload.data.result[i].variant[m].SALE_RATE1;
                  tempArry.push([
                    resProductsDownload.data.result[i].ITEM_CD,
                    resProductsDownload.data.result[i].ITEM_NAME,
                    resProductsDownload.data.result[i].variant[m].SIZE_CD,
                    resProductsDownload.data.result[i].variant[m].SALE_RATE1,
                    Math.round(
                      resProductsDownload.data.result[i].variant[m].SALE_RATE1 -
                        discountAmt
                    ),
                  ]);
                } else {
                  const grade = decode(globalContext.user).partyGd;
                  const discount =
                    resProductsDownload.data.result[i].variant[m].SALE_DISC /
                    100;
                  if (grade == "A") {
                    const discountAmt =
                      discount *
                      resProductsDownload.data.result[i].variant[m].SALE_RATE1;
                    tempArry.push([
                      resProductsDownload.data.result[i].ITEM_CD,
                      resProductsDownload.data.result[i].ITEM_NAME,
                      resProductsDownload.data.result[i].variant[m].SIZE_CD,
                      resProductsDownload.data.result[i].variant[m].SALE_RATE1,
                      Math.round(
                        resProductsDownload.data.result[i].variant[m]
                          .SALE_RATE1 - discountAmt
                      ),
                    ]);
                  } else if (grade == "B") {
                    const discountAmt =
                      discount *
                      resProductsDownload.data.result[i].variant[m].SALE_RATE2;
                    tempArry.push([
                      resProductsDownload.data.result[i].ITEM_CD,
                      resProductsDownload.data.result[i].ITEM_NAME,
                      resProductsDownload.data.result[i].variant[m].SIZE_CD,
                      resProductsDownload.data.result[i].variant[m].SALE_RATE2,
                      Math.round(
                        resProductsDownload.data.result[i].variant[m]
                          .SALE_RATE2 - discountAmt
                      ),
                    ]);
                  } else if (grade == "C") {
                    const discountAmt =
                      discount *
                      resProductsDownload.data.result[i].variant[m].SALE_RATE3;
                    tempArry.push([
                      resProductsDownload.data.result[i].ITEM_CD,
                      resProductsDownload.data.result[i].ITEM_NAME,
                      resProductsDownload.data.result[i].variant[m].SIZE_CD,
                      resProductsDownload.data.result[i].variant[m].SALE_RATE3,
                      Math.round(
                        resProductsDownload.data.result[i].variant[m]
                          .SALE_RATE3 - discountAmt
                      ),
                    ]);
                  } else {
                    tempArry.push([
                      resProductsDownload.data.result[i].ITEM_CD,
                      resProductsDownload.data.result[i].ITEM_NAME,
                      resProductsDownload.data.result[i].variant[m].SIZE_CD,
                      0,
                      0,
                    ]);
                  }
                }
              } else {
                if (
                  globalContext.settings.filter(
                    (e) => e.SETTING_NAME == "PARTY_WISE_RATE"
                  )[0].VALUE == "Y"
                ) {
                  tempArry.push([
                    resProductsDownload.data.result[i].ITEM_CD,
                    resProductsDownload.data.result[i].ITEM_NAME,
                    resProductsDownload.data.result[i].variant[m].SIZE_CD,
                    resProductsDownload.data.result[i].variant[m].SALE_RATE1,
                    resProductsDownload.data.result[i].variant[m].SALE_RATE1,
                  ]);
                } else {
                  const grade = decode(globalContext.user).partyGd;
                  if (grade == "A") {
                    tempArry.push([
                      resProductsDownload.data.result[i].ITEM_CD,
                      resProductsDownload.data.result[i].ITEM_NAME,
                      resProductsDownload.data.result[i].variant[m].SIZE_CD,
                      resProductsDownload.data.result[i].variant[m].SALE_RATE1,
                      resProductsDownload.data.result[i].variant[m].SALE_RATE1,
                    ]);
                  } else if (grade == "B") {
                    tempArry.push([
                      resProductsDownload.data.result[i].ITEM_CD,
                      resProductsDownload.data.result[i].ITEM_NAME,
                      resProductsDownload.data.result[i].variant[m].SIZE_CD,
                      resProductsDownload.data.result[i].variant[m].SALE_RATE2,
                      resProductsDownload.data.result[i].variant[m].SALE_RATE2,
                    ]);
                  } else if (grade == "C") {
                    tempArry.push([
                      resProductsDownload.data.result[i].ITEM_CD,
                      resProductsDownload.data.result[i].ITEM_NAME,
                      resProductsDownload.data.result[i].variant[m].SIZE_CD,
                      resProductsDownload.data.result[i].variant[m].SALE_RATE3,
                      resProductsDownload.data.result[i].variant[m].SALE_RATE3,
                    ]);
                  } else {
                    tempArry.push([
                      resProductsDownload.data.result[i].ITEM_CD,
                      resProductsDownload.data.result[i].ITEM_NAME,
                      resProductsDownload.data.result[i].variant[m].SIZE_CD,
                      0,
                      0,
                    ]);
                  }
                }
              }
            }
          }
        }
      }
      resolve(tempArry);
    });
  };

  const exportPdf = (data) => {
    const unit = "pt";
    const size = "A4";
    const orientation = "portrait";

    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    const headers = [
      ["Item Cd", "Item Name", "Size", "Regular Price", "Discounted Price"],
    ];

    let content = {
      startY: 90,
      head: headers,
      body: data,
    };

    let width1 = doc.internal.pageSize.getWidth();

    doc.setFontSize(14).setFont(undefined, "bold");
    doc
      .text(globalContext.company[0].COMP_NAME, width1 / 2, 30, {
        align: "center",
      })
      .setFontSize(8)
      .setFont(undefined, "bold");
    doc.text(globalContext.company[0].ADDRS, width1 / 2, 50, {
      align: "center",
    });
    doc.setFontSize(14).setFont(undefined, "bold");
    doc.text("Product List", width1 / 2, 80, {
      align: "center",
    });
    doc.setFontSize(10).setFont(undefined, "bold");
    doc.autoTable(content);
    doc.text(
      "Generated By Hub360",
      width1 / 2,
      doc.previousAutoTable.finalY + 30,
      {
        align: "center",
      }
    );
    doc.save("ProductList.pdf");
  };

  const observer = useRef();
  const lastBookElementRef = useCallback(
    (node) => {
      if (!resProducts) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && nextPage) {
          setPage((prevPageNumber) => prevPageNumber + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [resProducts, nextPage]
  );

  useEffect(() => {
    if (resProducts) {
      setIsLoading(false);
      if (getQueryVariable("q") != "" && getQueryVariable("q")) {
        setNextPage(undefined);
        setData(resProducts.data.result);
        dispatch({
          type: INIT_PRODUCT,
          payload: resProducts.data.result,
        });
      } else {
        setNextPage(resProducts.data.pagination.nextPage);
        const products = data.concat(resProducts.data.result);
        setData(products);
        dispatch({
          type: LOAD_PRODUCT,
          payload: resProducts.data.result,
        });
      }
    }
  }, [resProducts]);

  useEffect(() => {
    if (resProductsError) {
      dispatch({
        type: SHOW_SNACKBAR,
        payload: {
          message: "Something went wrong",
          type: "error",
        },
      });
    }
  }, [resProductsError]);

  //TODO: Optimize search query

  useEffect(() => {
    setData([]);
    setIsLoading(true);
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }, [getQueryVariable("value")]);

  // useEffect(() => {
  //   setInitLoading(true);
  //   if (isSearch) {
  //     if (search == "") {
  //       setPage(0);
  //       setData([]);
  //       dispatch({
  //         type: REMOVE_ALL_PRODUCT,
  //       });
  //       fetchData();
  //       setIsSearch(false);
  //     } else {
  //       handleSearch();
  //     }
  //   }
  // }, [search]);

  return (
    <div>
      <Header>
        <section className="gray pt-10 pb-2">
          <div className="container">
            {/* <div className="row">
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-3 text-center">
                <h2 className="ft-medium mb-3">Shop Categories</h2>
              </div>
            </div> */}

            <div className="flex justify-between items-center mb-1">
              <div></div>
              <h3 className="ft-medium mb-0">Shop All Products</h3>
              <div className="border rounded px-2 py-1">
                <Tooltip title="Download Products List" arrow>
                  <div
                    className="cursor-pointer"
                    onClick={() => {
                      generatePdfRows().then((rows) => {
                        exportPdf(rows);
                      });
                    }}
                  >
                    <GetAppIcon />
                  </div>
                </Tooltip>
              </div>
            </div>

            {/* <div className="row align-items-center justify-content-center">
              <div className="col-xl-8 col-lg-10 col-md-12 col-sm-12">
                <div className="row">
                  {globalContext.category
                    .slice(
                      globalContext.category.length - 4,
                      globalContext.category.length
                    )
                    .map((item) => (
                      <div
                        className="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-3"
                        key={item.DEPT_CD}
                      >
                        <div className="cats_side_wrap text-center m-auto">
                          <div className="sl_cat_01">
                            <div className="d-inline-flex align-items-center justify-content-center p-2 circle mb-2 bg-white">
                              <Link
                                className="d-block"
                                to={`/${mobileNo}/shop?type=category&value=${item.DEPT_CD}`}
                                onClick={() => {
                                  setPage(0);
                                }}
                              >
                                {item.img ? (
                                  <img
                                    src={
                                      imageUrl +
                                      "/" +
                                      item.img.IMGURL.replace("[", "")
                                        .replace("]", "")
                                        .replace(/"/g, "")
                                        .split(",")[0]
                                    }
                                    className="img-fluid circle"
                                    width="60"
                                    alt=""
                                  />
                                ) : (
                                  <img
                                    src="/img/11.jpg"
                                    className="img-fluid circle"
                                    width="60"
                                    alt=""
                                  />
                                )}
                              </Link>
                            </div>
                          </div>
                          <div className="sl_cat_02">
                            <h6 className="m-0 ft-medium fs-sm">
                              <Link
                                to={`/${mobileNo}/shop?type=category&value=${item.DEPT_CD}`}
                              >
                                {item.dept_name ? item.dept_name : ""}
                              </Link>
                            </h6>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div> */}
          </div>
        </section>

        {/* <section className="py-2 br-bottom br-top">
          <div className="container-fluid">
            <div className="row align-items-center justify-content-between">
              <div className="col-xl-3 col-lg-4 col-md-5 col-sm-12">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to={`/${params.mobileNo}/`}>Home</Link>
                    </li>
                    <li className="breadcrumb-item">
                      <Link to={`/${params.mobileNo}/shop`}>Shop</Link>
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </section> */}

        <section className="middle">
          <div className="container-fluid">
            <div className="row align-items-center rows-products">
              {data.length != 0 ? (
                data.map((d, index) => {
                  if (data.length === index + 1) {
                    return (
                      <div
                        key={d.ITEM_CD}
                        ref={lastBookElementRef}
                        className="col-xl-2 col-lg-3 col-md-3 col-6 px-2 d-flex align-items-stretch"
                      >
                        <ProductCard product={d} />
                      </div>
                    );
                  } else {
                    return (
                      <div
                        key={d.ITEM_CD}
                        className="col-xl-2 col-lg-3 col-md-3 col-6 px-2 d-flex align-items-stretch"
                      >
                        <ProductCard product={d} />
                      </div>
                    );
                  }
                })
              ) : !resProducts ? (
                new Array(12)
                  .fill()
                  .map((a, i) => (a = i))
                  .sort(() => Math.random() - 0.5)
                  .map((e, index) => (
                    <div
                      key={index}
                      className="col-xl-2 col-lg-3 col-md-3 col-6 px-2"
                    >
                      <Skeleton
                        variant="rect"
                        animation="wave"
                        style={{
                          height: "10rem",
                          objectFit: "fill",
                        }}
                      />
                      <Skeleton variant="text" />
                      <Skeleton variant="text" />
                    </div>
                  ))
              ) : data.length == 0 && resProducts ? (
                <div className="min-h-screen text-center w-full">
                  <StorefrontIcon className="text-black text-[12rem]" />
                  <Typography className="text-2xl font-bold text-black">
                    Oops!
                  </Typography>
                  <Typography>No Data Found.</Typography>
                </div>
              ) : null}
            </div>
          </div>
        </section>

        <Footer />
        <BottomBar />
      </Header>
    </div>
  );
};

export default Shop;
