import {
  Container,
  Grid,
  Typography,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Input,
  Checkbox,
  CardHeader,
  CardContent,
  Card,
} from "@material-ui/core";
import React, { useEffect, useState, useContext } from "react";
import OwnerHeader from "./Components/OwnerHeader";
import CustomSnakBar from "../../Components/CustomSnakBar";
import { Instance } from "../../Config";
import { Link, useParams } from "react-router-dom";
import { decode } from "jsonwebtoken";
import useSWR from "swr";
import GlobalContext from "../../Context/globalContext";
import {
  INIT_COMPANY_INFO,
  INIT_OWNER,
  INIT_SETTINGS,
  SHOW_SNACKBAR,
} from "../../Context/globalActions.types";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  BarChart,
  Bar,
  ResponsiveContainer,
  PieChart,
  Pie,
  Sector,
  Cell,
} from "recharts";
import { Skeleton } from "@material-ui/lab";

import ShoppingCartOutlinedIcon from "@material-ui/icons/ShoppingCartOutlined";

import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

const OwnerDashboard = () => {
  const [totalOrder, setTotalOrder] = useState(0);
  const [pendingOrder, setPendingOrder] = useState(0);
  const [completedOrder, setCompletedOrder] = useState(0);
  const [canceledOrder, setCanceledOrder] = useState(0);
  const [companyInfo, setCompanyInfo] = useState({});
  const params = useParams();

  const { dispatch } = useContext(GlobalContext);

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const { globalContext } = useContext(GlobalContext);

  const { data: resCanceledOrder, error: resCanceledOrderError } = useSWR(
    `/orders/status/total?mobileNo=${params.mobileNo}&type=Canceled`
  );
  const { data: resDispatchOrder, error: resDispatchOrderError } = useSWR(
    `/orders/status/total?mobileNo=${params.mobileNo}&type=Dispatched`
  );
  const { data: resTotalOrder, error: resTotalOrderError } = useSWR(
    `/orders/status/total?mobileNo=${params.mobileNo}&type=total`
  );
  const { data: resPendingOrder, error: resPendingOrderError } = useSWR(
    `/orders/status/total?mobileNo=${params.mobileNo}&type=Pending`
  );

  const { data: resTotalSalesGraph, error: resTotalSalesGraphError } = useSWR(
    `/dashboard?mobileNo=${params.mobileNo}&type=totalsales`
  );

  const { data: resCategorySalesGraph, error: resCategorySalesGraphError } =
    useSWR(`/dashboard?mobileNo=${params.mobileNo}&type=categorysales`);

  const { data: resStatusWiseSalesGraph, error: resStatusWiseSalesGraphError } =
    useSWR(`/dashboard?mobileNo=${params.mobileNo}&type=statusWise`);

  useEffect(() => {
    if (resCanceledOrder) {
      setCanceledOrder(resCanceledOrder.data.result);
    }
  }, [resCanceledOrder]);

  useEffect(() => {
    if (resDispatchOrder) {
      setCompletedOrder(resDispatchOrder.data.result);
    }
  }, [resDispatchOrder]);

  useEffect(() => {
    if (resTotalOrder) {
      setTotalOrder(resTotalOrder.data.result);
    }
  }, [resTotalOrder]);

  useEffect(() => {
    if (resPendingOrder) {
      setPendingOrder(resPendingOrder.data.result);
    }
  }, [resPendingOrder]);

  useEffect(() => {
    if (
      resCanceledOrderError ||
      resDispatchOrderError ||
      resTotalOrderError ||
      resPendingOrderError
    ) {
      dispatch({
        type: SHOW_SNACKBAR,
        payload: {
          message: "Something went wrong",
          type: "error",
        },
      });
    }
  }, [
    resCanceledOrderError,
    resDispatchOrderError,
    resTotalOrderError,
    resPendingOrderError,
  ]);

  useEffect(() => {
    if (localStorage.getItem("user")) {
      const user = decode(localStorage.getItem("user"));
      if (user.mobileNo != params.mobileNo) {
        localStorage.setItem("user", "");
      } else if (user.mobileNo != params.mobileNo) {
        localStorage.setItem("user", "");
      }
    }
  }, []);

  // const handleSyncBtn = () => {
  //   setIsSync(true);
  //   Instance.post("/sync", {
  //     mobileNo: params.mobileNo,
  //     dataNm: dataNm
  //       .sort((a, b) => {
  //         return a.localeCompare(b, undefined, {
  //           numeric: true,
  //           sensitivity: "base",
  //         });
  //       })
  //       .reverse(),
  //   })
  //     .then((res) => {
  //       if (res.status == 201) {
  //         setIsSync(false);
  //       }
  //     })
  //     .catch((e) => {
  //       dispatch({
  //         type: SHOW_SNACKBAR,
  //         payload: {
  //           message: "Something went wrong. Please open the app again.",
  //           type: "error",
  //         },
  //       });
  //       setIsSync(false);
  //     });
  // };

  // const CompanyModal = () => {
  //   return (
  //     <Dialog
  //       open={open}
  //       aria-labelledby="form-dialog-title"
  //       maxWidth="xs"
  //       fullWidth={true}
  //     >
  //       <DialogTitle id="form-dialog-title">
  //         <div className="flex justify-between">
  //           <div>Sync Data</div>
  //           {!isSync ? (
  //             <button
  //               type="button"
  //               className="close"
  //               aria-label="Close"
  //               onClick={handleClose}
  //             >
  //               <span className="ti-close"></span>
  //             </button>
  //           ) : null}
  //         </div>
  //       </DialogTitle>
  //       <DialogContent>
  //         <FormControl className="min-w-full">
  //           <InputLabel id="demo-mutiple-checkbox-label">
  //             Please Select Company
  //           </InputLabel>
  //           <Select
  //             labelId="demo-mutiple-checkbox-label"
  //             id="demo-mutiple-checkbox"
  //             multiple
  //             value={dataNm}
  //             onChange={(e) => {
  //               setDataNm(e.target.value);
  //             }}
  //             input={<Input />}
  //             renderValue={(selected) => selected.join(", ")}
  //           >
  //             {resCompany
  //               ? resCompany.data.result.map((name) => (
  //                   <MenuItem key={name.DATANM} value={name.DATANM}>
  //                     <Checkbox checked={dataNm.indexOf(name.DATANM) > -1} />
  //                     <p>
  //                       {name.fromyr}-{name.toyr} ({name.DATANM})
  //                     </p>
  //                   </MenuItem>
  //                 ))
  //               : "Loading..."}
  //           </Select>
  //         </FormControl>
  //       </DialogContent>
  //       <DialogActions>
  //         <Button
  //           variant="contained"
  //           disableElevation
  //           className="bg-[#3333cc] mb-2 text-white min-w-max stretched-link"
  //           onClick={handleSyncBtn}
  //           disabled={isSync}
  //         >
  //           {isSync ? (
  //             <CircularProgress
  //               size={25}
  //               className="hover:text-white text-black"
  //             />
  //           ) : (
  //             "Sync"
  //           )}
  //         </Button>
  //       </DialogActions>
  //     </Dialog>
  //   );
  // };

  return (
    <div>
      <OwnerHeader>
        <div className="md:flex items-center justify-between px-3 md:px-4 py-[1.5rem]">
          <div>
            {/* <div className="text-indigo-500 font-semibold text-sm md:text-base">
              Store Management
            </div> */}
            <div className="text-xl md:text-3xl font-extrabold leading-7 sm:leading-10 truncate mt-1">
              Welcome Back
            </div>
          </div>
        </div>
        <Container
          maxWidth="xl"
          className="pt-3 bg-[#F1F5F9] min-h-screen pb-3"
        >
          {/* <CompanyModal /> */}
          {/* <div className="flex justify-between items-start">
            <Button
              variant="contained"
              disableElevation
              className="bg-[#3333cc] mb-2 text-white min-w-max stretched-link"
              onClick={handleClickOpen}
            >
              SYNC DATA
            </Button>
          </div> */}
          <div className="font-bold text-xl mb-2">Last 30 Days Orders</div>
          <div className="row">
            <div className="col-xl-3 col-md-6 mb-4">
              <Link to={`/${params.mobileNo}/owner/orders?type=total`}>
                <div className="flex items-center bg-white rounded shadow">
                  <div className="ml-3 h-7 w-7 p-4 rounded-xl flex justify-center items-center bg-red-400 mr-2">
                    <ShoppingCartOutlinedIcon className="text-white" />
                  </div>
                  <div className="flex flex-col px-2 py-3 justify-between leading-normal">
                    <Typography
                      variant="subtitle1"
                      className="font-medium tracking-tight text-gray-500 text-xs"
                    >
                      Total Orders
                    </Typography>

                    <div className="flex items-end">
                      <Typography
                        variant="subtitle2"
                        className="font-bold text-gray-900 text-2xl"
                      >
                        {totalOrder.currentMonth}
                      </Typography>
                      {totalOrder.type == "incr" ? (
                        <span className="text-xs pl-2 text-green-500">
                          {Math.round(totalOrder.percentageChange)}%
                          <ArrowUpwardIcon className="text-sm" />
                        </span>
                      ) : (
                        <span className="text-xs mb-1 ml-1 text-red-500">
                          {Math.round(totalOrder.percentageChange)}%
                          <ArrowDownwardIcon className="text-sm" />
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </Link>
            </div>

            <div className="col-xl-3 col-md-6 mb-4">
              <Link to={`/${params.mobileNo}/owner/orders?type=Pending`}>
                <div className="flex items-center bg-white rounded shadow">
                  <div className="ml-3 h-7 w-7 p-4 rounded-xl flex justify-center items-center bg-[#ff6600] mr-2">
                    <ShoppingCartOutlinedIcon className="text-white" />
                  </div>
                  <div className="flex flex-col px-2 py-3 justify-between leading-normal">
                    <Typography
                      variant="subtitle1"
                      className="font-medium tracking-tight text-gray-500 text-xs"
                    >
                      Pending Item
                    </Typography>
                    <div className="flex items-end">
                      <Typography
                        variant="subtitle2"
                        className="font-bold text-gray-900 text-2xl"
                      >
                        {pendingOrder.currentMonth}
                      </Typography>
                      {pendingOrder.type == "incr" ? (
                        <span className="text-xs pl-2 text-green-500">
                          {Math.round(pendingOrder.percentageChange)}%
                          <ArrowUpwardIcon className="text-sm" />
                        </span>
                      ) : (
                        <span className="text-xs mb-1 ml-1 text-red-500">
                          {Math.round(pendingOrder.percentageChange)}%
                          <ArrowDownwardIcon className="text-sm" />
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </Link>
            </div>

            <div className="col-xl-3 col-md-6 mb-4">
              <Link to={`/${params.mobileNo}/owner/orders?type=Dispatched`}>
                <div className="flex items-center bg-white rounded shadow">
                  <div className="ml-3 h-7 w-7 p-4 rounded-xl flex justify-center items-center bg-[#049aab] mr-2">
                    <ShoppingCartOutlinedIcon className="text-white" />
                  </div>
                  <div className="flex flex-col px-2 py-3 justify-between leading-normal">
                    <Typography
                      variant="subtitle1"
                      className="font-medium tracking-tight text-gray-500 text-xs"
                    >
                      Dispatch Item
                    </Typography>
                    <div className="flex items-end">
                      <Typography
                        variant="subtitle2"
                        className="font-bold text-gray-900 text-2xl"
                      >
                        {completedOrder.currentMonth}
                      </Typography>
                      {completedOrder.type == "incr" ? (
                        <span className="text-xs pl-2 text-green-500">
                          {Math.round(completedOrder.percentageChange)}%
                          <ArrowUpwardIcon className="text-sm" />
                        </span>
                      ) : (
                        <span className="text-xs mb-1 ml-1 text-red-500">
                          {Math.round(completedOrder.percentageChange)}%
                          <ArrowDownwardIcon className="text-sm" />
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </Link>
            </div>

            <div className="col-xl-3 col-md-6 mb-4">
              <Link to={`/${params.mobileNo}/owner/orders?type=Canceled`}>
                <div className="flex items-center bg-white rounded shadow">
                  <div className="ml-3 h-7 w-7 p-4 rounded-xl flex justify-center items-center bg-[#3333cc] mr-2">
                    <ShoppingCartOutlinedIcon className="text-white" />
                  </div>
                  <div className="flex flex-col px-2 py-3 justify-between leading-normal">
                    <Typography
                      variant="subtitle1"
                      className="font-medium tracking-tight text-gray-500 text-xs"
                    >
                      Canceled Item
                    </Typography>
                    <div className="flex items-end">
                      <Typography
                        variant="subtitle2"
                        className="font-bold text-gray-900 text-2xl"
                      >
                        {canceledOrder.currentMonth}
                      </Typography>
                      {canceledOrder.type == "incr" ? (
                        <span className="text-xs pl-2 text-green-500">
                          {Math.round(canceledOrder.percentageChange)}%
                          <ArrowUpwardIcon className="text-sm" />
                        </span>
                      ) : (
                        <span className="text-xs mb-1 ml-1 text-red-500">
                          {Math.round(canceledOrder.percentageChange)}%
                          <ArrowDownwardIcon className="text-sm" />
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
          <div>
            <Card>
              <div className="font-bold text-xl p-3">Graphs Reports</div>
            </Card>
            <div className="grid grid-cols-1 md:grid-cols-3 mt-2 gap-3">
              <Card className="col-span-2">
                <CardContent>
                  <div className="text-xl pb-3 font-bold">
                    Total Order Received
                  </div>
                  {resTotalSalesGraph ? (
                    <div className="w-auto md:h-96 h-96">
                      <ResponsiveContainer width="100%" height="100%">
                        <BarChart
                          width={500}
                          height={500}
                          data={resTotalSalesGraph.data.result}
                          margin={{ top: 0, left: -30, right: 0, bottom: 0 }}
                        >
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis dataKey="Month" />
                          <YAxis dataKey="SalesCount" />
                          <Tooltip
                            cursor={{ fill: "none" }}
                            contentStyle={{
                              padding: "4px",
                              fontSize: 16,
                            }}
                            itemStyle={{
                              fontSize: 10,
                            }}
                          />
                          <Bar
                            dataKey="SalesCount"
                            fill="#1B98F5"
                            name="Order Received"
                          />
                        </BarChart>
                      </ResponsiveContainer>
                    </div>
                  ) : (
                    <div className="grid justify-center items-center">
                      <CircularProgress size={30} />
                    </div>
                  )}
                </CardContent>
              </Card>
              <Card>
                <CardContent>
                  <div className="text-xl pb-3 font-bold">
                    Status Wise Sales
                  </div>
                  {resStatusWiseSalesGraph ? (
                    <div className="w-auto md:h-96 h-80">
                      <ResponsiveContainer width="100%" height="100%">
                        {resStatusWiseSalesGraph.data.result[0].ordervalue !=
                        0 ? (
                          <PieChart width={400} height={400}>
                            <Pie
                              data={resStatusWiseSalesGraph.data.result}
                              cx="50%"
                              cy="50%"
                              labelLine={false}
                              label={renderCustomizedLabel}
                              outerRadius={120}
                              fill="#8884d8"
                              dataKey="ordervalue"
                            >
                              {resStatusWiseSalesGraph.data.result.map(
                                (entry, index) => (
                                  <Cell
                                    key={`cell-${index}`}
                                    fill={
                                      entry.name == "Pending"
                                        ? "#ff6600"
                                        : entry.name == "Completed"
                                        ? "#009999"
                                        : ""
                                    }
                                  />
                                )
                              )}
                            </Pie>
                            <Legend />
                          </PieChart>
                        ) : (
                          <div className="grid justify-center items-center">
                            <div>Insufficient Data Available</div>
                          </div>
                        )}
                      </ResponsiveContainer>
                    </div>
                  ) : (
                    <div className="grid justify-center items-center">
                      <CircularProgress size={30} />
                    </div>
                  )}
                </CardContent>
              </Card>
            </div>
          </div>
          <div className="grid grid-cols-1 mt-3 gap-3">
            <Card>
              <CardContent>
                <div className="text-xl pb-3 font-bold">Category Sales</div>
                {resCategorySalesGraph ? (
                  <div className="w-auto md:h-96 h-80">
                    <ResponsiveContainer width="100%" height="100%">
                      <BarChart
                        width={500}
                        height={500}
                        data={resCategorySalesGraph.data.result}
                        margin={{ top: 0, left: -30, right: 0, bottom: 0 }}
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis
                          dataKey="dept_name"
                          tick={{
                            fontSize: 10,
                            height: 100,
                            width: 10,
                          }}
                        />
                        <YAxis
                          dataKey="Count"
                          tick={{
                            fontSize: 12,
                          }}
                        />
                        <Tooltip
                          cursor={{ fill: "none" }}
                          contentStyle={{
                            padding: "4px",
                            fontSize: 14,
                          }}
                          itemStyle={{
                            fontSize: 10,
                          }}
                        />
                        <Bar dataKey="Count" fill="#1B98F5" />
                      </BarChart>
                    </ResponsiveContainer>
                  </div>
                ) : (
                  <div className="grid justify-center items-center">
                    <CircularProgress size={30} />
                  </div>
                )}
              </CardContent>
            </Card>
          </div>
          {/* <Box className="mt-10">
            <Typography variant="h5" className="text-2xl">
              About the Company
            </Typography>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <h6 className="pt-2">
                  <span className="font-bold text-xl">Company Name:</span>{" "}
                  {companyInfo.COMP_NAME}
                </h6>
                <div className="pt-4">
                  <h6 className="pt-2">
                    <span className="font-bold text-xl">Owner Name:</span>{" "}
                    {companyInfo.PERSON_NM}
                  </h6>
                </div>
                <div className="pt-4">
                  <h6 className="pt-2">
                    <span className="font-bold text-xl">Owner Email:</span>{" "}
                    {companyInfo.EMAILID}
                  </h6>
                </div>
              </Grid>
            </Grid>
          </Box> */}
        </Container>
      </OwnerHeader>
    </div>
  );
};

export default OwnerDashboard;
