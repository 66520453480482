import { useEffect, useState } from "react";
import { Instance } from "../Config";

export default function usePagination(url) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [data, setData] = useState([]);
  const [hasMore, setHasMore] = useState(false);

  useEffect(() => {
    setData([]);
  }, [url]);

  useEffect(() => {
    setLoading(true);
    setError(false);
    Instance.get(url)
      .then((res) => {
        setData((prevBooks) => {
          if (typeof res.data.result != "undefined" && prevBooks) {
            return [...new Set([...prevBooks, ...res.data.result])];
          }
        });
        setHasMore(res.data.pagination.nextPage);
        setLoading(false);
      })
      .catch((e) => {
        setError(true);
      });
  }, [url]);

  return { loading, error, data, hasMore };
}
