import { AppBar, Container } from "@material-ui/core";
import React, { useContext, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import useSWR from "swr";
import {
  INIT_COMPANY_INFO,
  INIT_OWNER,
} from "../../Context/globalActions.types";
import useScrollPosition from "../../hooks/useScrollPosition";
import GlobalContext from "../../Context/globalContext";
import PhoneOutlinedIcon from "@material-ui/icons/PhoneOutlined";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import LocationOnOutlinedIcon from "@material-ui/icons/LocationOnOutlined";
import { imageUrl } from "../../Config";

export default function ContactUs() {
  const scrollPosition = useScrollPosition();
  const params = useParams();
  const { globalContext, dispatch } = useContext(GlobalContext);

  const { data: resOwnerDetail, error: resOwnerDetailError } = useSWR(
    `/owner?mobileNo=${params.mobileNo}`
  );

  const { data: resAbout, error: resAboutError } = useSWR(
    `/saral/about?mobileNo=${params.mobileNo}`
  );

  useEffect(() => {
    if (resOwnerDetail) {
      dispatch({
        payload: resOwnerDetail.data.result,
        type: INIT_OWNER,
      });
    }
  }, [resOwnerDetail]);

  useEffect(() => {
    if (resAbout) {
      if (resAbout.data.result.length != 0) {
        dispatch({
          type: INIT_COMPANY_INFO,
          payload: resAbout.data.result,
        });
      } else {
        dispatch({
          type: INIT_COMPANY_INFO,
          payload: [{ COMP_NAME: "", PERSON_NM: "", EMAILID: "" }],
        });
      }
    }
  }, [resAbout]);

  return (
    <div>
      <AppBar
        elevation={0}
        className={scrollPosition > 0 ? "shadow-md  text-black" : " text-black"}
        color="primary"
        variant="elevation"
      >
        <div className="shadow">
          <div className="container">
            <div className="flex">
              <Link className="nav-brand mt-2" to={`/${params.mobileNo}`}>
                <img
                  src={
                    globalContext.owner &&
                    globalContext.owner.length != 0 &&
                    globalContext.owner[0].LOGO
                      ? imageUrl + "/" + globalContext.owner[0].LOGO
                      : ""
                  }
                  className="h-8 w-auto sm:h-10"
                />
              </Link>
            </div>
          </div>
        </div>
      </AppBar>
      <Container>
        <div className="mt-14 md:mt-28">
          <div className="text-2xl font-semibold">Contact Information:</div>
          <div className="mt-2">
            <LocationOnOutlinedIcon />
            <span>
              {globalContext.company[0] ? globalContext.company[0].ADDRS : ""}
              {globalContext.company[0] ? globalContext.company[0].AREA : ""}
              {globalContext.company[0] ? globalContext.company[0].CITY : ""}
              {globalContext.company[0] ? globalContext.company[0].PINCODE : ""}
            </span>
          </div>
          <div className="mt-2">
            <PhoneOutlinedIcon fontSize="small" />{" "}
            {globalContext.company[0] ? globalContext.company[0].PH1 : ""}
          </div>
          <div className="mt-2">
            <MailOutlineIcon fontSize="small" />{" "}
            {globalContext.company[0] ? globalContext.company[0].EMAIL : ""}
          </div>
        </div>
      </Container>
    </div>
  );
}
