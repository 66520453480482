export const SIGN_IN = "SIGN_IN";
export const SIGN_OUT = "SIGN_OUT";

export const INIT_ORDER = "INIT_ORDER";
export const LOAD_ORDER = "LOAD_ORDER";

export const INIT_CATEGORY = "INIT_CATEGORY";
export const UPDATE_CATEGORY = "UPDATE_CATEGORY";

export const INIT_PRODUCT = "INIT_PRODUCT";
export const LOAD_PRODUCT = "LOAD_PRODUCT";
export const UPDATE_IMAGE_PRODUCT = "UPDATE_IMAGE_PRODUCT";
export const REMOVE_ALL_PRODUCT = "REMOVE_ALL_PRODUCT";

export const INIT_CART = "INIT_CART";
export const ADD_CART_QUANTITY = "ADD_CART_QUANTITY";
export const REMOVE_CART_QUANTITY = "REMOVE_CART_QUANTITY";
export const ADD_CART = "ADD_CART";
export const DELETE_CART = "DELETE_CART";

export const INIT_CATALOG = "INIT_CATALOG";
export const ADD_CATALOG = "ADD_CATALOG";
export const UPDATE_CATALOG = "UPDATE_CATALOG";
export const DELETE_CATALOG = "DELETE_CATALOG";

export const LOAD_PRODUCTBYCATEGORY = "LOAD_PRODUCTBYCATEGORY";

export const INIT_SIMILARPRODUCTS = "INIT_SIMILARPRODUCTS";

export const SHOW_SNACKBAR = "SHOW_SNACKBAR";
export const HIDE_SNACKBAR = "HIDE_SNACKBAR";

export const INIT_SETTINGS = "INIT_SETTINGS";

export const INIT_ACCOUNTS_SETTINGS = "INIT_ACCOUNTS_SETTINGS";

export const INIT_OWNER = "INIT_OWNER";

export const INIT_COMPANY_INFO = "INIT_COMPANY_INFO";

export const INIT_ACCOUNT_INFO = "INIT_ACCOUNT_INFO";

export const INIT_NOTIFICATION = "INIT_NOTIFICATION";
